import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Modal, Form, Button } from "react-bootstrap";
import {
  CardElement,
  AuBankAccountElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { StripePaymentModalSchema } from "../../pages/common/Validation";
import Spin from "../../pages/common/Spin";
import { API, fileUpload, get } from "../../config";
import { useNavigate } from "react-router-dom";
import { encrypt, formatNumber } from "../../pages/common/Misc";
import moment from "moment";
import { useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";

// *Card Element Config - Start
const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
// *Card Element Config - End

const StripePaymentModal = (props) => {
  let {
    showModal,
    handleModalClose,
    id,
    planDetail,
    promoCode,
    planType,
    supportPlan,
    login,
    subscriptionDetail,
    projectId = "",
    // builderId = "",
    handleSucessProjectSub = "",
    updateSubscription,
  } = props;
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const [defaultPromoCode, setDefaultPromoCode] = useState("");
  const [promocodebtn, setPromocodeBtn] = useState(false);
  const [promoCodeSection, setPromoCodeSection] = useState(false);
  const [amontObj, setAmontObj] = useState({});

  const cardActive = true;
  const bankActive = false;
  const [loadnig, setLoading] = useState(false);
  const [exist, setExist] = useState("");
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [selectedList, setSelectedPayment] = useState("");
  const [upcomingInvoice, setUpcomingInvoice] = useState();
  const [infoUpcomingInvoiceModal, setInfoUpcomingInvoiceModal] =
    useState(false);

  const perPlan = planDetail?.unit_amount / 100;

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  // *API Call for Subscription - Start
  const sendPayment = async (
    values,
    error,
    paymentMethodId = null,
    quantity
  ) => {
    if (paymentMethodId) {
      setLoading(true);
      values.user_id = id;
      // if (builderId) {
      //   values.builderId = builderId;
      // }
      if (projectId) {
        values.projectId = projectId;
      }
      values.price_id = planDetail.id;
      values.payment_method_id = paymentMethodId;
      values.coupon_code_id =
        defaultPromoCode && promocodebtn ? defaultPromoCode : "";
      values.quantity = quantity ? quantity : "";
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (planType === "supportPlan" && key === "price_id") {
          formData.append("support_price_id", values[key]);
        } else if (key === "projectId") {
          formData.append("builder_project_id", values.projectId);
        } else {
          formData.append([key], values[key]);
        }
      });

      if (supportPlan) {
        formData.append(
          "support_price_id",
          supportPlan?.prices?.data?.find((item) => item.active === true)?.id
        );
      }

      try {
        let response = await fileUpload(
          updateSubscription && planType !== "supportPlan"
            ? API.UPDATE_SUBSCRIPTION
            : API.FIRM_SUBSCRIPTION,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (response.data.status === 200) {
          if (handleSucessProjectSub) {
            handleSucessProjectSub();
          } else {
            handleModalClose();
          }
          setLoading(false);
          toast.success(response.data.message);
          localStorage.setItem("bt-subscription", encrypt("1"));
          if (updateSubscription) {
            if (projectId) {
              navigate(
                `/projects/${projectId}/settings?tab=subscriptions&childTab=current-subscription`,
                {
                  replace: true,
                }
              );
            } else {
              navigate(
                "/user-settings?tab=subscriptions&childTab=current-subscription",
                { replace: true }
              );
            }
          } else {
            navigate("/congratulations", {
              state: {
                data: response.data.data,
                message: response.data.message,
                islogin: login,
                projectId: projectId ? projectId : "",
              },
            });
          }
        }
      } catch (e) {
        toast.error(e.response?.data?.errors?.bt_error[0][0]);
        handleModalClose();
        setLoading(false);
      }
    } else {
      if (error?.param === "billing_details[name]") {
        toast.error("Name is required.");
      } else if (error?.param === "billing_details[email]") {
        toast.error("Email is required.");
      } else {
        toast.error(error.message);
      }
    }
  };
  // *API Call for Subscription - End

  // *Function in which its call subscription api based on type of method - Start
  const handleFinish = async (values) => {
    if (selectedList) {
      let type = selectedList.includes("card_");
      if (type) {
        sendPayment({}, {}, selectedList, values?.quantity);
      } else {
        sendPayment({}, {}, selectedList, values?.quantity);
      }
    } else {
      const { error, paymentMethod } =
        cardActive === true
          ? await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardElement),
              billing_details: {
                name: values?.name ? values?.name : "",
              },
            })
          : await stripe.createPaymentMethod({
              type: "au_becs_debit",
              au_becs_debit: elements.getElement(AuBankAccountElement),
              billing_details: {
                name: values?.name ? values?.name : "",
                email: values?.email ? values?.email : "",
              },
            });
      sendPayment(
        values,
        error,
        paymentMethod?.id ? paymentMethod?.id : null,
        values?.quantity
      );
    }
  };
  // *Function in which its call subscription api based on type of method - End

  // *API Call for Existing Payment Method List - Start
  const getpaymentMethodList = async () => {
    try {
      setLoading(true);
      const { data } = await get(API.GET_PAYMENTMETHOD_LIST);
      if (data) {
        setLoading(false);
        setPaymentMethodList(data.data);
      } else {
        setLoading(false);

        setPaymentMethodList();
      }
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    if (login) {
      getpaymentMethodList();
    }
  }, [login]);

  const handleOnchange = (id) => {
    setSelectedPayment(id);
  };

  const onhandlePaymentMethod = () => {
    // if (e.target.checked) {
    //   setExist(true);
    // } else {
    //   setExist(false);
    // }
    setExist(!exist);
  };

  // *API Call for Existing Payment Method List - End

  // *Promo Code get, select and validate - Start

  const validatePromoCode = (couponCode, isDefault = false) => {
    for (let item of promoCode) {
      if (item.id === couponCode) {
        if (
          item.applies_to.products.find(
            (item) => String(item) === String(planDetail.product)
          )
        ) {
          if (!isDefault) toast.success("Coupon code applied successfully");
          setDefaultPromoCode(item?.id);
          setPromocodeBtn(true);

          if (item?.percent_off) {
            setAmontObj({
              ...amontObj,
              percent_off: item?.percent_off,
              amount_off: item?.amount_off,
              payableAmount: (
                ((planDetail?.unit_amount / 100) * (100 - item?.percent_off)) /
                100
              ).toFixed(2),
            });
          } else {
            setAmontObj({
              ...amontObj,
            });
          }

          return;
        }
      }
    }

    if (!isDefault) {
      setAmontObj({
        ...amontObj,
        amount_off: 0,
        percent_off: 0,
        payableAmount: (planDetail?.unit_amount / 100).toFixed(2),
      });
      toast.error("Coupon code is not valid");
    }
  };

  const handleCanclePromocode = () => {
    setPromoCodeSection(false);
    setDefaultPromoCode();
    setPromocodeBtn(false);
    setAmontObj({
      ...amontObj,
      amount_off: 0,
      payableAmount: (planDetail?.unit_amount / 100).toFixed(2),
    });
  };

  const handleCouponChange = (e) => {
    setPromocodeBtn(false);
    setDefaultPromoCode(e.target.value);
  };

  const handleApplyClick = () => {
    validatePromoCode(defaultPromoCode);
  };

  // *Promo Code get, select and validate - End

  const supportPriceValue = supportPlan
    ? supportPlan?.prices?.data?.find((item) => item.active === true)
        ?.tax_behavior === "exclusive"
      ? supportPlan?.prices?.data?.find((item) => item.active === true)
          ?.unit_amount /
          100 +
        (supportPlan?.prices?.data?.find((item) => item.active === true)
          ?.unit_amount /
          100) *
          0.1
      : supportPlan?.prices?.data?.find((item) => item.active === true)
          ?.unit_amount / 100
    : 0;

  // *API For Fetching the Upcoming invoice details - Start
  const fetchUpcomingInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (projectId) {
        formData.append("builder_project_id", projectId);
      }
      formData.append("price_id", planDetail.id);
      // formData.append("subscription_id", subscriptionDetail?.subscription_id);
      formData.append(
        "coupon_code_id",
        defaultPromoCode && promocodebtn ? defaultPromoCode : ""
      );
      formData.append("quantity", 1);
      const { data } = await fileUpload(API.GET_UPCOMING_INVOICE, formData);
      if (data) {
        setLoading(false);
        setUpcomingInvoice(data.data);
      } else {
        setLoading(false);
        setUpcomingInvoice();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.warn(error?.response?.data?.message);
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors)?.forEach((key) => {
          toast.error(errors?.[key]?.[0]);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetail.id, projectId, promocodebtn]);

  useEffect(() => {
    if (
      !supportPlan &&
      planType !== "supportPlan" &&
      subscriptionDetail?.status
    ) {
      fetchUpcomingInvoice();
    }
  }, [fetchUpcomingInvoice, subscriptionDetail, supportPlan, planType]);

  const toggleUpcomingInvoiceList = () => {
    setInfoUpcomingInvoiceModal(!infoUpcomingInvoiceModal);
  };

  const dollarNumberFormat = (data) => {
    if (Number(data) < 0) {
      return `-$${formatNumber(Math.abs(Number(data)))}`;
    } else {
      return `$${formatNumber(Math.abs(Number(data)))}`;
    }
  };

  const viewJournalDataGenerator = (quantity) => {
    const items = [];
    let mainData = upcomingInvoice?.lines?.data;
    let discountTotalValue = 0;
    let SubTotalValue = 0;
    let TaxValue = 0;
    let TotalValue = 0;

    let planSubTotal = 0;
    let planTaxValue = 0;
    let planDiscountValue = 0;
    let planTotalValue = 0;
    // let lastItem = 0;

    for (let i = 0; i < quantity; i++) {
      SubTotalValue =
        SubTotalValue + Number(mainData[i]?.amount_excluding_tax / 100);
      TaxValue = TaxValue + Number(mainData[i]?.tax_amounts?.[0]?.amount / 100);
      discountTotalValue =
        discountTotalValue +
        Number(mainData[i]?.discount_amounts?.[0]?.amount / 100);
      TotalValue =
        TotalValue +
        (Number(mainData[i]?.amount_excluding_tax / 100) +
          Number(mainData[i]?.tax_amounts?.[0]?.amount / 100));

      var startDate = new Date(Number(mainData[i]?.period?.start) * 1000);
      var formattedStartDate =
        padWithLeadingZeros(Number(startDate.getUTCDate()), 2) +
        "-" +
        padWithLeadingZeros(Number(startDate.getUTCMonth() + 1), 2) +
        "-" +
        startDate.getUTCFullYear();
      var endDate = new Date(Number(mainData[i]?.period?.end) * 1000);
      var formattedEndDate =
        padWithLeadingZeros(Number(endDate.getUTCDate()), 2) +
        "-" +
        padWithLeadingZeros(Number(endDate.getUTCMonth() + 1), 2) +
        "-" +
        endDate.getUTCFullYear();

      let planSubTotalCalc = mainData[i]?.plan?.amount
        ? mainData[i]?.plan?.tax_behavior === "inclusive"
          ? (
              mainData[i]?.price?.unit_amount / 100 -
              (mainData[i]?.price?.unit_amount / 100 / 1.1) * 0.1
            ).toFixed(2)
          : (mainData[i]?.price?.unit_amount / 100).toFixed(2)
        : 0;

      planSubTotal = Number(planSubTotal) + Number(planSubTotalCalc);

      let planTaxCalc = mainData[i]?.plan
        ? mainData[i]?.plan?.tax_behavior === "inclusive"
          ? ((mainData[i]?.price?.unit_amount / 100 / 1.1) * 0.1).toFixed(2)
          : ((mainData[i]?.price?.unit_amount / 100) * 0.1).toFixed(2)
        : 0;

      planTaxValue = Number(planTaxValue) + Number(planTaxCalc);

      let discountPercentageAmonunt =
        ((mainData[i]?.price?.unit_amount / 100) *
          (100 - mainData[i]?.discount?.percent_off)) /
        100;
      let planDiscountCalc = mainData[i]?.discount
        ? mainData[i]?.plan?.tax_behavior === "inclusive"
          ? (mainData[i]?.price?.unit_amount / 100).toFixed(2) -
            discountPercentageAmonunt.toFixed(2)
            ? discountPercentageAmonunt.toFixed(2)
            : 0
          : (mainData[i]?.price?.unit_amount / 100).toFixed(2) -
            discountPercentageAmonunt.toFixed(2)
          ? (
              ((mainData[i]?.price?.unit_amount / 100).toFixed(2) -
                discountPercentageAmonunt.toFixed(2)) *
              1.1
            ).toFixed(2)
          : 0
        : 0;

      planDiscountValue = Number(planDiscountValue) + Number(planDiscountCalc);

      let planTotalCalc =
        mainData[i]?.plan?.tax_behavior === "inclusive"
          ? Number(discountPercentageAmonunt)
            ? discountPercentageAmonunt
              ? (
                  mainData[i]?.price?.unit_amount / 100 -
                  Number(discountPercentageAmonunt)
                ).toFixed(2)
              : Number(discountPercentageAmonunt).toFixed(2)
            : discountPercentageAmonunt === 0
            ? 0
            : mainData[i]?.price?.unit_amount / 100
          : Number(discountPercentageAmonunt)
          ? discountPercentageAmonunt
            ? (
                (mainData[i]?.price?.unit_amount / 100) * 1.1 -
                Number(discountPercentageAmonunt) * 1.1
              ).toFixed(2)
            : (Number(discountPercentageAmonunt) * 1.1).toFixed(2)
          : discountPercentageAmonunt === 0
          ? 0
          : ((mainData[i]?.price?.unit_amount / 100) * 1.1).toFixed(2);

      planTotalValue = Number(planTotalValue) + Number(planTotalCalc);

      items.push({
        key: mainData[i]?.id,
        start_date: formattedStartDate ? formattedStartDate : "-",
        end_date: formattedEndDate ? formattedEndDate : "-",
        description: mainData[i]?.description ? mainData[i]?.description : "-",
        plan_sub_total: planSubTotalCalc
          ? dollarNumberFormat(Number(planSubTotalCalc))
          : "-",
        plan_gst: planTaxCalc ? dollarNumberFormat(Number(planTaxCalc)) : "-",
        plan_discount: planDiscountCalc
          ? dollarNumberFormat(Number(planDiscountCalc))
          : "-",
        plan_total: planTotalCalc
          ? dollarNumberFormat(Number(planTotalCalc))
          : "-",
        sub_total: mainData[i]?.amount_excluding_tax
          ? dollarNumberFormat(Number(mainData[i]?.amount_excluding_tax / 100))
          : "$0.00",
        tax: mainData[i]?.tax_amounts?.[0]?.amount
          ? dollarNumberFormat(
              Number(mainData[i]?.tax_amounts?.[0]?.amount / 100)
            )
          : "$0.00",
        discount: mainData[i]?.discount_amounts?.[0]?.amount
          ? dollarNumberFormat(
              Number(mainData[i]?.discount_amounts?.[0]?.amount / 100)
            )
          : "-",
        total_amount: mainData[i]?.amount_excluding_tax
          ? dollarNumberFormat(
              Number(mainData[i]?.amount_excluding_tax / 100) +
                Number(mainData[i]?.tax_amounts[0]?.amount / 100)
            )
          : 0,
      });

      if (i === quantity - 1) {
        items.push({
          description: (
            <div style={{ textAlign: "right" }}>
              <strong>Prorated amlount to be added in the next invoice:</strong>
            </div>
          ),
          // plan_sub_total: <strong>{formatNumber(Number(planSubTotal))}</strong>,
          // plan_gst: <strong>{formatNumber(Number(planTaxValue))}</strong>,
          // plan_discount: (
          //   <strong>{formatNumber(Number(planDiscountValue))}</strong>
          // ),
          // plan_total: <strong>{formatNumber(Number(planTotalValue))}</strong>,
          sub_total: (
            <strong>{dollarNumberFormat(Number(SubTotalValue))}</strong>
          ),
          tax: <strong>{dollarNumberFormat(Number(TaxValue))}</strong>,
          discount: (
            <strong>
              {discountTotalValue
                ? dollarNumberFormat(Number(discountTotalValue))
                : "-"}
            </strong>
          ),
          total_amount: (
            <strong>{dollarNumberFormat(Number(TotalValue))}</strong>
          ),
        });
        // items.push({
        //   description: (
        //     <div style={{ textAlign: "right" }}>
        //       <strong>Plan Amount:</strong>
        //     </div>
        //   ),
        //   sub_total: (
        //     <strong>{formatNumber(Number(lastItem?.plan_sub_total))}</strong>
        //   ),
        //   tax: <strong>{formatNumber(Number(lastItem?.plan_gst))}</strong>,
        //   discount: (
        //     <strong>
        //       {discountTotalValue
        //         ? formatNumber(Number(lastItem?.plan_discount))
        //         : "-"}
        //     </strong>
        //   ),
        //   total_amount: (
        //     <strong>{formatNumber(Number(lastItem?.plan_total))}</strong>
        //   ),
        // });

        // items.push({
        //   description: (
        //     <div style={{ textAlign: "right" }}>
        //       <strong>New Plan Amount:</strong>
        //     </div>
        //   ),
        //   sub_total: (
        //     <strong>
        //       {formatNumber(
        //         Number(SubTotalValue) + Number(lastItem?.plan_sub_total)
        //       )}
        //     </strong>
        //   ),
        //   tax: (
        //     <strong>
        //       {formatNumber(Number(TaxValue) + Number(lastItem?.plan_gst))}
        //     </strong>
        //   ),
        //   discount: (
        //     <strong>
        //       {discountTotalValue
        //         ? formatNumber(
        //             Number(discountTotalValue) + Number(lastItem?.plan_discount)
        //           )
        //         : "-"}
        //     </strong>
        //   ),
        //   total_amount: (
        //     <strong>
        //       {formatNumber(Number(TotalValue) + Number(lastItem?.plan_total))}
        //     </strong>
        //   ),
        // });
      }
    }
    return items;
  };
  const viewJournalData = viewJournalDataGenerator(
    upcomingInvoice?.lines?.data?.length
  );

  const viewColumns = [
    {
      dataField: "start_date",
      text: "Start Date",
    },
    {
      dataField: "end_date",
      text: "End Date",
    },

    {
      dataField: "plan_sub_total",
      text: "Plan Sub Total",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
    {
      dataField: "plan_gst",
      text: "Plan GST",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
    {
      dataField: "plan_discount",
      text: "Plan Discount",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
    {
      dataField: "plan_total",
      text: "Plan Total",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },

    {
      dataField: "description",
      text: "Description",
    },

    {
      dataField: "sub_total",
      text: "Sub Total",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
    {
      dataField: "tax",
      text: "Tax",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
    // {
    //   dataField: "discount",
    //   text: "Discount",
    //   headerStyle: {
    //     textAlign: "right",
    //   },
    //   style: {
    //     textAlign: "right",
    //   },
    // },
    {
      dataField: "total_amount",
      text: "Amount",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
  ];

  // *API For Fetching the Upcoming invoice details - End

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-50w larg-popup review-popup small-review payment-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Payment Details
      </Modal.Header>
      <Modal.Body>
        <div className="content-details">
          <Formik
            initialValues={{
              quantity: 1,
              name: "",
              email: "",
              bank: bankActive,
            }}
            enableReinitialize
            onSubmit={handleFinish}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={StripePaymentModalSchema}
          >
            {({ values, handleSubmit, handleChange, handleBlur }) => {
              return (
                <Form
                  name="stripeform"
                  layout="vertical"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  {loadnig && <Spin />}
                  <div className="row">
                    {paymentMethodList?.length > 0 && (
                      <div>
                        <span
                          className="existing-method-body"
                          onClick={(e) => onhandlePaymentMethod(e)}
                        >
                          <input
                            type="checkbox"
                            className="check-method"
                            checked={exist}
                            // onClick={(e) => onhandlePaymentMethod(e)}
                          />
                          Use Existing Payments Method
                        </span>
                      </div>
                    )}
                    <div className="col-md-12 payment-cards">
                      {exist && paymentMethodList?.length > 0 ? (
                        <div className="row table-top-btn payment-card-body">
                          <ul className="list-group payment-card-list">
                            {paymentMethodList &&
                              paymentMethodList.map((item) => {
                                let expiredclass = "";
                                if (item?.type === "card") {
                                  expiredclass = moment().isBefore(
                                    moment(item.expiry, "MM/YYYY")
                                  )
                                    ? ""
                                    : "expired";
                                }
                                return (
                                  <>
                                    <li
                                      className={
                                        "list-group-item d-flex justify-content-between align-items-start payment-card-list-item" +
                                        expiredclass
                                      }
                                      onClick={() => handleOnchange(item?.id)}
                                    >
                                      <input
                                        type="radio"
                                        className="mt-1 mr-1"
                                        name="card"
                                        id={item?.id}
                                        value={item?.id}
                                        checked={selectedList === item?.id}
                                      />
                                      <div className="ms-0 me-auto card-detail">
                                        <p>
                                          XXXX XXXX XXXX {item?.last4}{" "}
                                          {item?.type === "card" && (
                                            <span className="card-brand">
                                              {" "}
                                              ({item.brand})
                                            </span>
                                          )}
                                        </p>

                                        {item.type === "au_becs_debit" ? (
                                          <div>
                                            <strong>BSB: </strong>
                                            {item?.bsb}
                                          </div>
                                        ) : (
                                          <div>
                                            <strong>Exp.: </strong>
                                            {item?.expiry}
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                          </ul>
                          {/* </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                      {cardActive === true && !exist && (
                        <>
                          <div className="payment-cardtabs">
                            <p>
                              <label>Card Details </label>
                              <CardElement options={CARD_ELEMENT_OPTIONS} />
                            </p>
                          </div>
                        </>
                      )}
                      {bankActive === true && !exist && (
                        <div>
                          <p>
                            <label>Account Details</label>
                            <AuBankAccountElement />
                          </p>
                          <p>
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              value={values?.name}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <span
                              className="text-danger"
                              style={{ color: "red", fontSize: "20px" }}
                            >
                              <ErrorMessage name="name" />
                            </span>
                          </p>
                          <p>
                            <label>Email</label>
                            <input
                              type="email"
                              name="email"
                              value={values?.email}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <span
                              className="text-danger"
                              style={{ color: "red", fontSize: "20px" }}
                            >
                              <ErrorMessage name="email" />
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div
                      className="col-md-12 payment-details"
                      style={{ padding: "15px" }}
                    >
                      {/* {planType !== "supportPlan" && (
                        <p>
                          Qty{" "}
                          <input
                            type="number"
                            name="quantity"
                            style={{
                              width: "50px",
                              marginRight: "8px",
                              height: "21px",
                              borderRadius: "5px",
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={values?.quantity}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                          />
                          Billed {planType === "month" ? "monthly" : "yearly"}{" "}
                          <span>${perPlan.toFixed(2)} each</span>
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="quantity" />
                          </span>
                        </p>
                      )} */}

                      {planType !== "supportPlan" && (
                        <div className="promo-field">
                          {promoCodeSection === false ? (
                            <button
                              className="promocode"
                              onClick={() => {
                                setPromoCodeSection(true);
                              }}
                            >
                              Have A Promocode
                            </button>
                          ) : (
                            <Form
                              label="Coupon Code"
                              name="couponCode"
                              className="half-width"
                            >
                              <input
                                type="text"
                                placeholder="Coupon Code"
                                value={defaultPromoCode}
                                onChange={handleCouponChange}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                              />
                              <Button
                                className="btn"
                                disabled={promocodebtn}
                                onClick={handleApplyClick}
                              >
                                {promocodebtn ? "Applied" : "Apply"}
                              </Button>
                              <Button
                                className="btn cancel-btn"
                                onClick={() => {
                                  handleCanclePromocode(false);
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Form>
                          )}
                        </div>
                      )}

                      <div className="promo-features" style={{ width: "100%" }}>
                        {/* <p>
                          <strong>Frequency:</strong>{" "}
                          {planType === "supportPlan"
                            ? "One Time"
                            : planDetail.metadata.type}
                        </p> */}
                        <p>
                          <strong>Subtotal:</strong>{" "}
                          {upcomingInvoice ? (
                            upcomingInvoice?.subtotal_excluding_tax > 0 ? (
                              `$${formatNumber(
                                Math.abs(
                                  Number(
                                    upcomingInvoice?.subtotal_excluding_tax /
                                      100
                                  )
                                )
                              )}`
                            ) : upcomingInvoice?.subtotal_excluding_tax < 0 ? (
                              `-$${formatNumber(
                                Math.abs(
                                  Number(
                                    upcomingInvoice?.subtotal_excluding_tax /
                                      100
                                  )
                                )
                              )}`
                            ) : (
                              "$0.00"
                            )
                          ) : (
                            <>
                              $
                              {planDetail?.tax_behavior === "inclusive"
                                ? (
                                    values?.quantity * perPlan -
                                    ((values?.quantity * perPlan) / 1.1) * 0.1
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : (values?.quantity * perPlan).toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </>
                          )}
                        </p>
                        <p>
                          <strong>GST:</strong>{" "}
                          {upcomingInvoice ? (
                            upcomingInvoice?.tax > 0 ? (
                              `$${formatNumber(
                                Math.abs(Number(upcomingInvoice?.tax / 100))
                              )}`
                            ) : upcomingInvoice?.tax < 0 ? (
                              `-$${formatNumber(
                                Math.abs(Number(upcomingInvoice?.tax / 100))
                              )}`
                            ) : (
                              `$0.00`
                            )
                          ) : (
                            <>
                              {planDetail?.tax_behavior === "inclusive"
                                ? `$${(
                                    ((values?.quantity * perPlan) / 1.1) *
                                    0.1
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : `$${(
                                    values?.quantity *
                                    perPlan *
                                    0.1
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}
                            </>
                          )}
                        </p>
                        <p>
                          <strong>Total Discount:</strong>
                          {upcomingInvoice ? (
                            upcomingInvoice?.total_discount_amounts?.[0]
                              ?.amount > 0 ? (
                              `$${formatNumber(
                                Math.abs(
                                  Number(
                                    upcomingInvoice?.total_discount_amounts?.[0]
                                      ?.amount / 100
                                  )
                                )
                              )}`
                            ) : upcomingInvoice?.total_discount_amounts?.[0]
                                ?.amount < 0 ? (
                              `-$${formatNumber(
                                Math.abs(
                                  Number(
                                    upcomingInvoice?.total_discount_amounts?.[0]
                                      ?.amount / 100
                                  )
                                )
                              )}`
                            ) : (
                              `$0.00`
                            )
                          ) : (
                            <>
                              {planDetail?.tax_behavior === "inclusive"
                                ? (values?.quantity * perPlan).toFixed(2) -
                                  (
                                    values?.quantity * amontObj?.payableAmount
                                  ).toFixed(2)
                                  ? "$" +
                                    (
                                      (values?.quantity * perPlan).toFixed(2) -
                                      values?.quantity * amontObj?.payableAmount
                                    )
                                      .toFixed(2)
                                      .toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  : "$0.00"
                                : (values?.quantity * perPlan).toFixed(2) -
                                  (
                                    values?.quantity * amontObj?.payableAmount
                                  ).toFixed(2)
                                ? "$" +
                                  (
                                    ((values?.quantity * perPlan).toFixed(2) -
                                      (
                                        values?.quantity *
                                        amontObj?.payableAmount
                                      ).toFixed(2)) *
                                    1.1
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "$0.00"}
                            </>
                          )}
                        </p>
                        {supportPlan && (
                          <p>
                            <strong>White Glove Onboarding Service:</strong>$
                            {supportPriceValue.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        )}
                        <p>
                          <strong>
                            Total Net Amount:{" "}
                            {upcomingInvoice && (
                              <span
                                className="info-icon"
                                onClick={() => toggleUpcomingInvoiceList()}
                              ></span>
                            )}
                          </strong>{" "}
                          {upcomingInvoice ? (
                            upcomingInvoice?.total > 0 ? (
                              `$${formatNumber(
                                Math.abs(Number(upcomingInvoice?.total / 100))
                              )}`
                            ) : upcomingInvoice?.total < 0 ? (
                              `-$${formatNumber(
                                Math.abs(Number(upcomingInvoice?.total / 100))
                              )}`
                            ) : (
                              `$0.00`
                            )
                          ) : (
                            <>
                              $
                              {supportPlan
                                ? planDetail?.tax_behavior === "inclusive"
                                  ? values?.quantity *
                                    Number(amontObj?.payableAmount)
                                    ? amontObj?.payableAmount &&
                                      amontObj?.amount_off !== 0
                                      ? (
                                          values?.quantity * perPlan -
                                          values?.quantity *
                                            Number(amontObj?.payableAmount) +
                                          Number(supportPriceValue)
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : (
                                          values?.quantity *
                                            Number(amontObj?.payableAmount) +
                                          Number(supportPriceValue)
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                    : amontObj?.payableAmount === "0.00" &&
                                      amontObj?.amount_off !== 0
                                    ? Number(supportPriceValue).toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : (
                                        values?.quantity * perPlan +
                                        Number(supportPriceValue)
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  : values?.quantity *
                                    Number(amontObj?.payableAmount)
                                  ? amontObj?.payableAmount &&
                                    amontObj?.amount_off !== 0
                                    ? (
                                        values?.quantity * perPlan * 1.1 -
                                        values?.quantity *
                                          Number(amontObj?.payableAmount) *
                                          1.1 +
                                        Number(supportPriceValue)
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (
                                        values?.quantity *
                                          Number(amontObj?.payableAmount) *
                                          1.1 +
                                        Number(supportPriceValue)
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  : amontObj?.payableAmount === "0.00" &&
                                    amontObj?.amount_off !== 0
                                  ? Number(supportPriceValue).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                  : (
                                      values?.quantity * perPlan * 1.1 +
                                      Number(supportPriceValue)
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                : planDetail?.tax_behavior === "inclusive"
                                ? values?.quantity *
                                  Number(amontObj?.payableAmount)
                                  ? amontObj?.payableAmount &&
                                    amontObj?.amount_off !== 0
                                    ? (
                                        values?.quantity * perPlan -
                                        values?.quantity *
                                          Number(amontObj?.payableAmount)
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (
                                        values?.quantity *
                                        Number(amontObj?.payableAmount)
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  : amontObj?.payableAmount === "0.00" &&
                                    amontObj?.amount_off !== 0
                                  ? "0.00"
                                  : (values?.quantity * perPlan).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                : values?.quantity *
                                  Number(amontObj?.payableAmount)
                                ? amontObj?.payableAmount &&
                                  amontObj?.amount_off !== 0
                                  ? (
                                      values?.quantity * perPlan * 1.1 -
                                      values?.quantity *
                                        Number(amontObj?.payableAmount) *
                                        1.1
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : (
                                      values?.quantity *
                                      Number(amontObj?.payableAmount) *
                                      1.1
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                : amontObj?.payableAmount === "0.00" &&
                                  amontObj?.amount_off !== 0
                                ? "0.00"
                                : (
                                    values?.quantity *
                                    perPlan *
                                    1.1
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </>
                          )}
                        </p>
                      </div>
                    </div>

                    <div
                      className="action-box"
                      style={{
                        flexDirection: "column",
                        padding: "0 15px",
                      }}
                    >
                      {updateSubscription && (
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          This change in plan will be actioned
                          {login && subscriptionDetail?.next_payment_date
                            ? " at the end of the current plan "
                            : " Immediately."}
                          {login &&
                            subscriptionDetail?.next_payment_date &&
                            moment(
                              subscriptionDetail?.next_payment_date,
                              "DD/MM/YYYY"
                            ).format("DD MMMM YYYY")}
                        </p>
                      )}
                      <Button className="btn" type="primary" htmlType="submit">
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {infoUpcomingInvoiceModal && (
            <Modal
              size="lg"
              show={infoUpcomingInvoiceModal}
              onHide={() => {
                toggleUpcomingInvoiceList();
              }}
              dialogClassName="small-review payment-schedule-calender-modal"
              aria-labelledby="contained-modal-title-vcenter"
              className="project-section"
              centered
            >
              <Modal.Header className="mb-0" closeButton>
                Upcoming Invoice
              </Modal.Header>
              <Modal.Body style={{ padding: "15px" }}>
                <BootstrapTable
                  keyField="key"
                  data={viewJournalData}
                  columns={viewColumns}
                  noDataIndication="No Data Found"
                />
              </Modal.Body>
            </Modal>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StripePaymentModal;
