import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { API, get, STRIPE_PUBLISHABLE_KEY } from "../../../../config";
import NumberFormat from "react-number-format";
import { formatNumber } from "../../../common/Misc";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentModal from "../../../../components/Modals/StripePaymentModal";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import Spin from "../../../common/Spin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ProjectSubscription = ({
  showSubscription,
  toggleShowSubscription,
  projectId,
  builderId,
  fetchProjects,
}) => {
  const stripeTestPromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const [selectsubscription, selectsubscriptionplan] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [serviceChecked, setServiceChecked] = useState(false);

  let login = true;
  const userId = localStorage.getItem("userId");

  const [setsubscription, setsubscriptionlist] = useState([]);
  const [supportPlan, setSupportPlan] = useState([]);
  const [promoCode, setPromoCode] = useState(false);
  const [defaultPromoCode, setDefaultPromoCode] = useState("");
  const [isSubLoading, setIsSubLoading] = useState(true);
  const [selectedPeriodPlan, setSelectedPeriodPlan] = useState("month");

  const setsubscriptiondata = useCallback(async () => {
    try {
      setIsSubLoading(true);
      const { data } = await get(API.SUBSCRIPTION);
      let array = login
        ? [
            { type: "supportPlan", plan: [] },
            { type: "month", plan: [] },
            // { type: "year", plan: [] },
          ]
        : [
            { type: "month", plan: [] },
            // { type: "year", plan: [] },
          ];
      data.data.map((item) => {
        if (item?.prices?.data && item?.prices?.data?.length > 0) {
          item?.prices?.data?.map((pricePlan) => {
            if (
              pricePlan?.recurring?.interval === "month" &&
              pricePlan?.active === true
            ) {
              const nameObj = { name: item?.name, unitLabel: item?.unit_label };
              login
                ? array[1].plan.push({ ...pricePlan, ...nameObj })
                : array[0].plan.push({ ...pricePlan, ...nameObj });
            }
            if (
              pricePlan?.recurring?.interval === "year" &&
              pricePlan?.active === true
            ) {
              const nameObj = { name: item?.name, unitLabel: item?.unit_label };
              login
                ? array[2].plan.push({ ...pricePlan, ...nameObj })
                : array[1].plan.push({ ...pricePlan, ...nameObj });
            }
            return 0;
          });
        }
        return 0;
      });
      if (login) {
        array[0]?.plan?.sort(
          (a, b) => Number(a?.unit_amount) - Number(b?.unit_amount)
        );
        array[1]?.plan?.sort(
          (a, b) => Number(a?.unit_amount) - Number(b?.unit_amount)
        );
      } else {
        array[0]?.plan?.sort(
          (a, b) => Number(a?.unit_amount) - Number(b?.unit_amount)
        );
      }
      if (login && data?.supportPlans?.metadata?.type === "support_plan") {
        data?.supportPlans?.prices?.data?.map((pricePlan) => {
          if (pricePlan?.active === true) {
            const nameObj = {
              name: data?.supportPlans?.name,
              unitLabel: data?.supportPlans?.unit_label,
            };
            array[0].plan.push({ ...pricePlan, ...nameObj });
          }
          return 0;
        });
      }
      setsubscriptionlist(array);
      setSupportPlan(data?.supportPlans);
      setSelectedPeriodPlan(login ? array[1] : array[0]);
      setPromoCode(data.coupons);
      data.coupons &&
        data.coupons?.length > 0 &&
        data.coupons?.map((el) => {
          el?.id === "FIRST50" && setDefaultPromoCode(el?.id);
          return 0;
        });

      setIsSubLoading(false);
    } catch (error) {
      setIsSubLoading(false);
    }
  }, [login]);

  useEffect(() => {
    setsubscriptiondata();
  }, [setsubscriptiondata]);

  const handleStripePaymentmodalclose = () => {
    setShowPaymentModal(false);
  };

  const handleSucessProjectSub = () => {
    toggleShowSubscription();
    setShowPaymentModal(false);
    fetchProjects();
  };

  const ProceedPayment = async (selectedPlan) => {
    if (selectedPlan) {
      selectsubscriptionplan(selectedPlan);
      setShowPaymentModal(true);
    } else {
      toast.error("Please select subscription plan");
    }
  };

  return (
    <Modal
      size="lg"
      show={showSubscription}
      onHide={toggleShowSubscription}
      dialogClassName="modal-50w larg-popup review-popup small-review project-subscription-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Subscription
      </Modal.Header>
      <Modal.Body style={{ padding: "0px 15px 15px 15px" }}>
        <div className={"container-fluid subscription-plan"}>
          {isSubLoading ? (
            <Spin />
          ) : (
            <div className="row custom-tabs pricetabs">
              <div className="pricetab-block">
                <div className="price-tabs-block">
                  {setsubscription &&
                    setsubscription.length > 0 &&
                    setsubscription.map((el) => {
                      return (
                        <>
                          <h6
                            className={`${
                              el.type === "month"
                                ? "monthly-tab"
                                : el.type === "year"
                                ? "yearly-tab"
                                : login && "support-tab"
                            } ${
                              selectedPeriodPlan.type === el.type && "active"
                            }`}
                            onClick={() => setSelectedPeriodPlan(el)}
                          >
                            {el.type === "month"
                              ? "Monthly"
                              : el.type === "year"
                              ? "Yearly"
                              : login && "Onboarding"}
                          </h6>
                        </>
                      );
                    })}
                </div>
                <div className="pricetab-table">
                  <div
                    className={
                      selectedPeriodPlan.type === "month"
                        ? "monthly active"
                        : selectedPeriodPlan?.type === "year"
                        ? "yearly active"
                        : "support active"
                    }
                  >
                    {selectedPeriodPlan?.plan &&
                      selectedPeriodPlan?.plan?.length > 0 &&
                      selectedPeriodPlan?.plan?.map((data) => {
                        return (
                          <>
                            {data?.recurring?.interval ===
                              selectedPeriodPlan?.type}
                            <div className="col-lg-6 col-md-12 mb-4 price-tabs">
                              <div className="card-body">
                                <div className="text-center">
                                  <h5
                                    className="card-title"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "19px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {/* {data?.name ? data?.name : ""} */}
                                    {data?.nickname ? data?.nickname : ""}
                                  </h5>
                                  <h2
                                    className="h2"
                                    style={{
                                      margin: "0",
                                      fontSize: "26px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    <sup>$</sup>
                                    <NumberFormat
                                      thousandsGroupStyle="thousand"
                                      value={
                                        data?.unit_amount
                                          ? (data?.unit_amount / 100).toFixed(2)
                                          : ""
                                      }
                                      decimalSeparator="."
                                      displayType="text"
                                      type="text"
                                      thousandSeparator={true}
                                      allowNegative={true}
                                    />
                                    <sub>
                                      <small>
                                        {selectedPeriodPlan.type === "month"
                                          ? data?.tax_behavior === "inclusive"
                                            ? "/pm Inc. GST"
                                            : "/pm Ex. GST"
                                          : selectedPeriodPlan.type === "year"
                                          ? data?.tax_behavior === "inclusive"
                                            ? "/pm Inc. GST"
                                            : "/pm Ex. GST"
                                          : data?.tax_behavior === "inclusive"
                                          ? "/pm Inc. GST"
                                          : "/pm Ex. GST"}
                                      </small>
                                    </sub>
                                  </h2>
                                  {selectedPeriodPlan.type !==
                                    "supportPlan" && (
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        textTransform: "uppercase",
                                        opacity: "0.7",
                                        margin: 0,
                                        padding: "10px",
                                      }}
                                    >
                                      Per{" "}
                                      {data?.unitLabel ? data?.unitLabel : ""}
                                    </p>
                                  )}
                                  {data?.metadata?.best_for && (
                                    <div className="plan-description">
                                      {data?.metadata?.best_for}
                                    </div>
                                  )}

                                  {selectedPeriodPlan.type === "supportPlan" ? (
                                    <button
                                      style={{ marginTop: "15px" }}
                                      onClick={() => ProceedPayment(data)}
                                      className={`btn`}
                                    >
                                      Get Started
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => ProceedPayment(data)}
                                      className={`btn`}
                                    >
                                      Get Started
                                    </button>
                                  )}
                                </div>
                                <div className="text-center">
                                  <ul className="list-group list-group-flush h-100 ">
                                    {selectedPeriodPlan.type ===
                                    "supportPlan" ? (
                                      <>
                                        <div
                                          style={{
                                            listStyle: "none",
                                            textAlign: "left",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              marginBottom: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            A. Setup of :
                                          </p>

                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Project Trust and/or Retention Trust
                                            Accounts
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Receipts, Documents
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Integration for direct Bank Feeds
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Sub-Contractors
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Payment Schdules
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>{" "}
                                            Connection with Accounting Firm
                                          </li>

                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            B. Training on :
                                          </p>

                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Import Sub contractors lists
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Import Payment Schedules
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Dashboard
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            .ABA file management
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Bank Reconciliation
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            How to produce reports for auditors
                                          </li>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          One Project Trust Account (PTA) with
                                          Unlimited Bank Feeds
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          One Retention Trust Account (RTA) with
                                          Unlimited Bank Feeds
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          {/* Trust Specific Chart of Accounts */}
                                          No Hidden Transactions Fee
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Manage Unlimited Subbies
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Invite Accounting Firm
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Up to {data?.metadata?.users} Users
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Reports as per QBCC Trust Guidelines
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Upload Project Documents
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Unlimited .aba files
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Automatic Receipts for amounts
                                          retained in RTA
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Most Advanced OCR to extract invoice
                                          data
                                        </li>{" "}
                                      </>
                                    )}
                                    {!login && (
                                      <li className="list-group-item">
                                        <div className="service-plan-card">
                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              White Glove Onboarding Service{" "}
                                              <span className="integration-card-info service-card-info">
                                                <DropdownButton className="info-icon">
                                                  <Dropdown.Item>
                                                    <div>
                                                      <p
                                                        style={{
                                                          fontWeight: "bold",
                                                          marginBottom: "15px",
                                                          color: "white",
                                                        }}
                                                      >
                                                        A. Setup of :
                                                      </p>
                                                      <ol>
                                                        <li>
                                                          Project Trust and/or
                                                          Retention Trust
                                                          Accounts
                                                        </li>
                                                        <li>
                                                          Receipts, Documents
                                                        </li>
                                                        <li>
                                                          Integration for direct
                                                          Bank Feeds
                                                        </li>
                                                        <li>Sub-Contractors</li>
                                                        <li>
                                                          Payment Schdules
                                                        </li>
                                                        <li>
                                                          {" "}
                                                          Connection with
                                                          Accounting Firm
                                                        </li>
                                                      </ol>
                                                      <br />
                                                      <p
                                                        style={{
                                                          fontWeight: "bold",
                                                          marginBottom: "15px",
                                                          color: "white",
                                                        }}
                                                      >
                                                        B. Training on :
                                                      </p>
                                                      <ol>
                                                        <li>
                                                          Import Sub contractors
                                                          lists
                                                        </li>
                                                        <li>
                                                          Import Payment
                                                          Schedules
                                                        </li>
                                                        <li>Dashboard</li>
                                                        <li>
                                                          .ABA file management
                                                        </li>
                                                        <li>
                                                          Bank Reconciliation
                                                        </li>
                                                        <li>
                                                          How to produce reports
                                                          for auditors
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </Dropdown.Item>
                                                </DropdownButton>
                                              </span>
                                            </div>
                                            <div>
                                              <>
                                                <span className="service-price">
                                                  $
                                                  {formatNumber(
                                                    supportPlan?.prices?.data?.find(
                                                      (item) =>
                                                        item.active === true
                                                    )?.unit_amount / 100
                                                  )}
                                                  {supportPlan?.prices?.data?.find(
                                                    (item) =>
                                                      item.active === true
                                                  )?.tax_behavior ===
                                                  "exclusive"
                                                    ? " Ex. GST"
                                                    : " Inc. GST"}
                                                </span>
                                              </>
                                            </div>
                                          </div>
                                          <input
                                            type="checkbox"
                                            name="service"
                                            onChange={(e) =>
                                              setServiceChecked(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                {showPaymentModal && (
                  <Elements stripe={stripeTestPromise}>
                    <StripePaymentModal
                      showModal={showPaymentModal}
                      handleModalClose={handleStripePaymentmodalclose}
                      id={userId}
                      planDetail={selectsubscription}
                      promoCode={promoCode}
                      defaultPromocode={defaultPromoCode}
                      planType={selectedPeriodPlan?.type}
                      supportPlan={!login && serviceChecked && supportPlan}
                      // subscriptionDetail={subscriptionDetail}
                      projectId={projectId}
                      builderId={builderId}
                      login={login}
                      handleSucessProjectSub={handleSucessProjectSub}
                    />
                  </Elements>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectSubscription;
