import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import {
  getContractorDetail,
  useStateList,
  useBankList,
} from "./components/SubContractorHelper";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "./components/Form";
import Spin from "../../common/Spin";
import { AddContractorSchema } from "../../common/Validation";
import BackButton from "../../../components/Form/BackButton";
import {
  checkIfFileExists,
  formatedPostalCode,
  formatName,
} from "../../common/Misc";
import { getProjectData } from "../components/ProjectHelper";

const EditSubContractor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;
  const [loading, setLoading] = useState(false);
  const [contractorData, setContractorData] = useState();
  const formValues = useRef();
  const contractorId = params?.sub_contractor_id;
  let existingValues = [];
  let overwrittenFields = [];

  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();

  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  useEffect(() => {
    async function fetchData() {
      try {
        // await getUsers();
        setLoading(true);
        const data = await getContractorDetail(contractorId);
        setContractorData(data);
        setLoading(false);
      } catch (e) {
        setContractorData(null);
      }
    }
    fetchData();
  }, [contractorId]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";
    value.opening_balance = Number(
      `${value?.opening_balance}`.replace(/,/g, "")
    );
    value.contract_value = Number(`${value?.contract_value}`.replace(/,/g, ""));

    value.retention_amount = Number(
      `${value?.retention_value}`.replace(/,/g, "")
    );

    value.retention_percentage = Number(
      `${value?.retention_precentage}`.replace("%", "")
    );

    value.contact_type_id = value?.contact_type_id?.id;
    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;
    value.bank_id = value?.bank_id?.value;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.id;

    formData.append("builder_project_id", projectId);
    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "contact_type_id",
      value?.contact_type_id ? value?.contact_type_id : ""
    );
    formData.append(
      "qbcc_number",
      value?.qbcc_number ? value?.qbcc_number : ""
    );
    formData.append("abn", value?.abn);
    formData.append("_method", "put");
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );
    formData.append(
      "account_name",
      value?.account_name ? value?.account_name : ""
    );
    formData.append("bsb", value?.bsb ? value?.bsb : "");
    formData.append(
      "account_number",
      value?.account_number ? value?.account_number : ""
    );
    formData.append(
      "opening_balance",
      value?.opening_balance ? value?.opening_balance : 0
    );
    formData.append("bank_id", value?.bank_id ? value?.bank_id : "");

    formData.append(
      "contract_file",
      value?.contract_file ? value?.contract_file : ""
    );

    formData.append(
      "contract_value",
      value?.contract_value ? value?.contract_value : ""
    );

    formData.append(
      "retention_amount",
      value?.retention_amount ? value?.retention_amount : ""
    );
    formData.append(
      "retention_percentage",
      value?.retention_percentage ? value?.retention_percentage : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.CONTRACTOR_Add}/${contractorId}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        // navigate(-1);
        navigate("/projects/" + projectId + "/sub-contractors");
      }
      return data?.data;
    } catch (error) {
      setContractorData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === contractorData?.state_id
  );

  //ShippingState
  const shippingState = stateListing?.find(
    (item) => item?.value === contractorData?.shipping_state_id
  );

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const bankListing = bankList ? bankList : [];
  const projectBank = bankListing?.find(
    (item) => item?.value === contractorData?.bank_id
  );
  const ContractorType = contractorData?.contact_type
    ? {
        id: contractorData?.contact_type?.id
          ? contractorData?.contact_type?.id
          : "",
        label: contractorData?.contact_type?.name,
      }
    : null;
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {formatName(contractorData?.first_name, contractorData?.last_name)}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  first_name: contractorData?.first_name,
                  last_name: contractorData?.last_name,
                  phone: contractorData?.phone,
                  mobile: contractorData?.mobile,
                  email: contractorData?.email,
                  business_name: contractorData?.business_name,
                  abn: contractorData?.abn,
                  contact_type_id: ContractorType ? ContractorType : null,
                  qbcc_number: contractorData?.qbcc_number,
                  street: contractorData?.street,
                  state_id: state,
                  suburb_id: contractorData?.suburb,
                  postal_code: formatedPostalCode(contractorData?.postal_code),

                  same_address: false,

                  shipping_street: contractorData?.shipping_street,
                  shipping_state_id: shippingState,
                  shipping_suburb_id: contractorData?.shipping_suburb,
                  shipping_postal_code: formatedPostalCode(
                    contractorData?.shipping_postal_code
                  ),
                  account_name: contractorData?.account_name
                    ? contractorData?.account_name
                    : "",
                  bsb: contractorData?.bsb ? contractorData?.bsb : "",
                  account_number: contractorData?.account_number
                    ? contractorData?.account_number
                    : "",
                  opening_balance: contractorData?.opening_balance
                    ? contractorData?.opening_balance
                    : 0,
                  bank_id: projectBank ? projectBank : "",
                  contract_value: contractorData?.contract_value
                    ? contractorData?.contract_value
                    : "",
                  contract_file: contractorData?.contract_file
                    ? contractorData?.contract_file
                    : "",
                  retention_value: contractorData?.retention_amount
                    ? contractorData?.retention_amount
                    : "",
                  retention_precentage: contractorData?.retention_percentage
                    ? contractorData?.retention_percentage
                    : "",
                }}
                enableReinitialize
                innerRef={formValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddContractorSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                        bankListing={bankListing}
                        bankLoading={bankLoading}
                        projectId={projectId}
                        edit={true}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubContractor;
