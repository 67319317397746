import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { Modal } from "react-bootstrap";
import { getOfficeDetail, useStateList } from "./components/OfficeHelper";
import { AddOfficeSchema } from "../common/Validation";
import Spin from "../common/Spin";
import { API, fileUpload } from "../../config";
import { toast } from "react-toastify";

const EditOffice = ({ officeId, toggleOfficeModal, showOffice }) => {
  const [loading, setLoading] = useState(false);
  const [officeData, setOfficeData] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;

    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.OFFICE}/${officeId}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleOfficeModal();
      }
      return data?.data;
    } catch (error) {
      setOfficeData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const data = await getOfficeDetail(officeId);
        setOfficeData(data);
        setLoading(false);
      } catch (e) {
        setOfficeData(null);
      }
    }
    fetchData();
  }, [officeId]);

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === officeData?.state_id
  );

  return (
    <>
      <Modal
        size="lg"
        show={showOffice}
        onHide={toggleOfficeModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Office Details
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              street: officeData?.street,
              postal_code: officeData?.postal_code,
              state_id: state,
              suburb_id: officeData?.suburb,
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddOfficeSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleOfficeModal={toggleOfficeModal}
                    stateListing={stateListing}
                    stateLoading={stateLoading}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditOffice;
