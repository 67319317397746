import { faUpload, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  // Dropdown,
  // DropdownButton,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API, fileUpload, get } from "../../../config";
import { toast } from "react-toastify";
import { getContractorDetail } from "../sub-contractors/components/SubContractorHelper";
import {
  // formatName,
  formatNumber,
  formatSubcontractorName,
} from "../../common/Misc";
import moment from "moment";
// import CancelButton from "../../../components/Form/CancleButton";
import AddPaymnetClaim from "./components/AddPaymentClaim";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const defaulDescriptionForContractor =
  "To take up the payments net of retentions to contractors from the trust account - Payment Claim #";

const defaulDescriptionForRetention =
  "To take up the payments of retentions to the retention trust account (retained amount) - Payment Claim #";

const newEntryData = { document_type: "", paymentClaims: [] };

const Upload = ({ edit = false, id = "", handleEditSucess }) => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;
  const [loading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState([newEntryData]);
  const [holidayList, setHolidaysList] = useState();

  const [finalSubminData, setFinalSubminData] = useState([]);
  const [show, setShow] = useState(false);

  const [invoiceNumber, setInvoiceNumber] = useState();

  const defaulEntryData = [
    {
      scheduled_date: "",
      // description: `${defaulDescriptionForContractor?.split("#")[0]}#${
      //   invoiceNumber ? invoiceNumber : ""
      // }`,
      description: "",
      // claimed_amount: "",
      schedule_amount: "",
      amount_withheld: "",
      reason: "",
    },
  ];

  const [entryData, setEntryData] = useState(defaulEntryData);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(defaulEntryData);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const yestarday = new Date(today);
  yestarday.setDate(yestarday.getDate() - 1);
  const [invoiceDate, setInvoiceDate] = useState(today);
  const [receiveDate, setReceiveDate] = useState(today);
  const [dueDate, setDueDate] = useState(today);
  const [claimedAmount, setClaimedAmount] = useState();

  const [totalAmountWithheld, setTotalAmountWithheld] = useState(0);
  const [totalClaimedAmount, setTotalClaimedAmount] = useState(0);
  const [totalScheduleAmount, setTotalScheduleAmount] = useState(0);
  const [disableState, setDisableState] = useState(true);
  const [contractorDetails, setContractorDetails] = useState();
  const [previousdate, setPreviousDate] = useState(yestarday);
  const [saveDisable, setSaveDisable] = useState(true);

  const [file, setFile] = useState();
  const [fileModal, setFileModal] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [claimABN, setClaimABN] = useState();
  const [contractBusiness, setContractBusiness] = useState();

  const toggleFileModal = () => {
    setFileModal(!fileModal);
  };

  // const [preview, setPreview] = useState(null);

  // const [approvedAmount, setApprovedAmount] = useState();
  // const [approvedDate, setApprovedDate] = useState(today);
  // const [approvedReason, setApprovedReason] = useState();
  const [contractorData, setContractorData] = useState();

  const trust_type = params?.trust_type === "retention" ? 0 : 1;
  // const handleAddEntry = (data, errorsData, index) => {
  //   setEntryData([...data, newEntryData]);
  //   setErrors([...errorsData, newEntryData]);
  // };

  // const handleDeleteEntry = (data, errorsData, index) => {
  //   data.splice(index, 1);
  //   errorsData.splice(index, 1);
  //   setEntryData([...data]);
  //   setErrors([...errorsData]);
  // };

  const fetchPaymentClaimData = useCallback(async (id) => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await get(`${API.GET_PAYMENT_CLAIM_LIST}/${id}`);
        setLoading(false);

        // let paymentClaimData = [];
        // eslint-disable-next-line
        // data?.data?.payment_claim_items?.map((item) => {
        //   let scheduleDateParts = item?.scheduled_date?.split("-");
        //   let data = {
        //     id: item?.id,
        //     scheduled_date: item?.scheduled_date
        //       ? new Date(
        //           Number(scheduleDateParts[0]),
        //           Number(scheduleDateParts[1]) - 1,
        //           Number(scheduleDateParts[2])
        //         )
        //       : null,
        //     description: item?.description,
        //     claimed_amount: item?.claimed_amount,
        //     schedule_amount: item?.scheduled_amount,
        //     amount_withheld: item?.amount_withheld,
        //     reason: item?.withheld_description,
        //   };
        //   paymentClaimData.push(data);
        // });
        let dateParts = data?.data?.invoice_date?.split("-");
        let dueDateParts = data?.data?.due_date?.split("-");
        let receiveParts = data?.data?.receive_date?.split("-");

        // let approvedDateParts = data?.data?.approved_date?.split("-");
        setClaimABN(data?.data?.contact?.abn);
        setContractBusiness(data?.data?.contact?.business_name);
        setInvoiceDate(
          new Date(
            Number(dateParts[0]),
            Number(dateParts[1] - 1),
            Number(dateParts[2])
          )
        );
        if (receiveParts) {
          setReceiveDate(
            new Date(
              Number(receiveParts[0]),
              Number(receiveParts[1] - 1),
              Number(receiveParts[2])
            )
          );
        }

        setInvoiceNumber(data?.data?.invoice_number);
        setDueDate(
          new Date(
            Number(dueDateParts[0]),
            Number(dueDateParts[1] - 1),
            Number(dueDateParts[2])
          )
        );
        setTotalAmountWithheld(data?.data?.total_amount_withheld);
        setTotalClaimedAmount(data?.data?.total_claimed_amount);
        setClaimedAmount(data?.data?.total_claimed_amount.toString());
        // setApprovedAmount(data?.data?.approved_amount);
        // setApprovedDate(
        //   new Date(
        //     Number(approvedDateParts[0]),
        //     Number(approvedDateParts[1] - 1),
        //     Number(approvedDateParts[2])
        //   )
        // )
        // setApprovedReason(data?.data?.approvedReason);
        let contact = data?.data?.contact;
        contact.value = contact?.id;
        contact.label =
          contact?.is_pta === 0
            ? `${formatSubcontractorName(contact)} (RTA)`
            : contact?.is_pta === 1
            ? `${formatSubcontractorName(contact)} (PTA)`
            : contact?.is_pta === 2
            ? `${formatSubcontractorName(contact)} (Withdrawal)`
            : contact?.is_pta === 3
            ? `${formatSubcontractorName(contact)} (Principal)`
            : contact?.is_pta === 4
            ? `${formatSubcontractorName(contact)} (Trustee)`
            : formatSubcontractorName(contact);
        setContractorDetails(contact);
        setTotalScheduleAmount(data?.data?.total_schedule_amount);
        setFinalData([
          {
            document_type: 3,
            paymentClaims: [
              {
                name: data?.data?.file,
              },
            ],
          },
        ]);
        setFile(data?.data?.file);
        // setEntryData(paymentClaimData);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, []);

  const fetchHolidays = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_HOLIDAY}?limit=-1&page=1`);
      setLoading(false);
      const holidayList = data?.data?.data;
      setHolidaysList(holidayList);
      return 0;
    } catch (error) {
      setLoading(false);
      setHolidaysList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, []);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  useEffect(() => {
    if (edit) {
      fetchPaymentClaimData(id);
    }
  }, [fetchPaymentClaimData, edit, id]);

  const handleDescriptionChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleScheduleAmountChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`?.replace(/,/g, ""));
        if (claimedAmount) {
          if (Number(claimedAmount?.replace(/,/g, "")) < value) {
            errors[index].schedule_amount =
              "Enter amount less than claimed amount";
          } else {
            errors[index].schedule_amount = null;
          }
        } else {
          errors[index].schedule_amount =
            "Enter amount less than claimed amount.";
        }
        return {
          ...obj,
          schedule_amount: value,
          description: value
            ? obj?.description
              ? `${obj?.description?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
              : `${defaulDescriptionForContractor?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
            : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  // const handleClaimedAmountChange = async (data, index, e) => {
  //   const newArr = data.map((obj, i) => {
  //     if (i === index) {
  //       const value = Number(`${e.target.value}`?.replace(/,/g, ""));
  //       return {
  //         ...obj,
  //         claimed_amount: value,
  //       };
  //     }
  //     return obj;
  //   });
  //   setEntryData(newArr);
  // };

  const handleScheduledDateChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        if (invoiceDate && dueDate) {
          if (invoiceDate <= e && e <= dueDate) {
            errors[index].scheduled_date = "";
            return {
              ...obj,
              scheduled_date: e,
            };
          } else {
            errors[index].scheduled_date =
              "Selected Schedule date cannot be greater then due date.";
            return {
              ...obj,
              scheduled_date: e,
            };
          }
        } else {
          setDueDate(e);
          return {
            ...obj,
            scheduled_date: e,
          };
        }
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAmountWithheldChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`?.replace(/,/g, ""));

        if (claimedAmount) {
          if (Number(claimedAmount?.replace(/,/g, "")) < Number(value)) {
            errors[index].amount_withheld =
              "Enter amount less than claimed amount.";
          } else {
            errors[index].amount_withheld = null;
          }
        } else {
          errors[index].amount_withheld =
            "Enter amount less than claimed amount.";
        }
        return {
          ...obj,
          amount_withheld: value,
          reason: value
            ? obj?.reason
              ? `${obj?.reason?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
              : `${defaulDescriptionForRetention?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
            : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleReasonChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, reason: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const getContractor = async (contractorId) => {
    try {
      setLoading(true);
      const data = await getContractorDetail(contractorId);
      const contractorData = data ? data : {};
      setContractorData(data);
      if (contractorData?.id) {
        if (
          contractorData?.account_name &&
          contractorData?.account_number &&
          contractorData?.bsb
        ) {
          setDisableState(true);
        } else {
          setDisableState(false);
          toast.error("Please add bank details of selected contractor.");
        }
      }
      setLoading(false);
    } catch (e) {
      setContractorData();
      toast.error("Something went wrong.");
    }
  };

  const handleContractorSelect = async (contractor) => {
    setClaimABN(contractor?.abn);
    setContractBusiness(contractor?.business_name);
    setContractorDetails(contractor);
    getContractor(contractor?.id, contractor);
  };

  useEffect(() => {
    // let claimedAmountTotal = 0;
    let scheduleAmountTotal = 0;
    let amountWithheldTotal = 0;
    entryData &&
      entryData?.length > 0 &&
      entryData?.map((item) => {
        // claimedAmountTotal += item?.claimed_amount
        //   ? Number(item?.claimed_amount)
        //   : 0;
        scheduleAmountTotal += item?.schedule_amount
          ? Number(item?.schedule_amount)
          : 0;
        amountWithheldTotal += item?.amount_withheld
          ? Number(item?.amount_withheld)
          : 0;
        return 0;
      });
    if (claimedAmount) {
      setTotalClaimedAmount(
        formatNumber(Number(claimedAmount.replace(/,/g, "")))
      );
    }
    setTotalScheduleAmount(formatNumber(Number(scheduleAmountTotal)));
    setTotalAmountWithheld(formatNumber(Number(amountWithheldTotal)));
  }, [entryData, claimedAmount]);

  const handleSubmitPost = async (values) => {
    const formData = new FormData();
    formData.append(
      `contact_id`,
      contractorDetails?.id ? contractorDetails?.id : ""
    );
    formData.append(`invoice_number`, invoiceNumber ? invoiceNumber : "");
    // formData.append(`claimed_amount`, claimedAmount ? claimedAmount : "");
    // formData.append(
    //   "invoice_date",
    //   invoiceDate ? moment(invoiceDate).format("DD-MM-YYYY") : ""
    // );
    // formData.append(
    //   "due_date",
    //   dueDate ? moment(dueDate).format("DD-MM-YYYY") : ""
    // );
    formData.append(
      "invoice_date",
      invoiceDate
        ? String(invoiceDate)?.includes("-")
          ? invoiceDate
          : moment(invoiceDate).format("DD-MM-YYYY")
        : ""
    );
    formData.append(
      "due_date",
      dueDate
        ? String(dueDate)?.includes("-")
          ? dueDate
          : moment(dueDate).format("DD-MM-YYYY")
        : ""
    );
    // formData.append("abn", claimABN ? claimABN : "");
    formData.append("builder_project_id", projectId ? projectId : "");
    // formData.append(
    //   "approved_date",
    //   approvedDate ? moment(approvedDate).format("DD-MM-YYYY") : ""
    // );
    // formData.append(
    //   "approved_amount",
    //   approvedAmount ? Number(`${approvedAmount}`?.replace(/,/g, "")) : ""
    // );
    // formData.append(`difference_reason`, approvedReason ? approvedReason : "");
    // formData.append(
    //   `difference`,
    //   approvedAmount && claimedAmount
    //     ? Number(claimedAmount.replace(/,/g, "")) -
    //         Number(approvedAmount.replace(/,/g, ""))
    //     : 0.0
    // );
    formData.append(
      `total_claimed_amount`,
      totalClaimedAmount
        ? Number(`${totalClaimedAmount}`?.replace(/,/g, ""))
        : ""
    );
    // formData.append(
    //   `total_scheduled_amount`,
    //   totalScheduleAmount
    //     ? Number(`${totalScheduleAmount}`?.replace(/,/g, ""))
    //     : ""
    // );
    // formData.append(
    //   `total_amount_withheld`,
    //   totalAmountWithheld
    //     ? Number(`${totalAmountWithheld}`?.replace(/,/g, ""))
    //     : ""
    // );
    formData.append(`trust_type`, trust_type);
    formData.append("document_type", 3);

    if (!edit) {
      // formData.append(
      //   "receive_date",
      //   receiveDate ? moment(receiveDate).format("DD-MM-YYYY") : ""
      // );
      formData.append(
        "receive_date",
        receiveDate
          ? String(receiveDate)?.includes("-")
            ? receiveDate
            : moment(receiveDate).format("DD-MM-YYYY")
          : ""
      );
    }

    if (
      finalData[0]?.paymentClaims[0]?.type &&
      finalData[0]?.paymentClaims[0] !== undefined
    ) {
      formData.append(
        "file",
        finalData[0]?.paymentClaims ? finalData[0]?.paymentClaims[0] : ""
      );
    } else {
      if (finalData[0]?.paymentClaims[0]?.name !== undefined) {
        formData.append(
          "file",
          finalData[0]?.paymentClaims
            ? finalData[0]?.paymentClaims[0]?.name
            : ""
        );
      }
    }

    if (edit) {
      formData.append("_method", "put");
    }

    // values?.forEach((item, index) => {
    //   if (item) {
    //     formData.append(
    //       `paymentClaimItems[${index}][description]`,
    //       item?.description ? item?.description : ""
    //     );
    //     formData.append(
    //       `paymentClaimItems[${index}][claimed_amount]`,
    //       item?.schedule_amount || item?.amount_withheld
    //         ? Number(
    //             Number(item?.schedule_amount ? item?.schedule_amount : 0) +
    //               Number(item?.amount_withheld ? item?.amount_withheld : 0)
    //           )
    //         : ""
    //     );
    //     formData.append(
    //       `paymentClaimItems[${index}][scheduled_date]`,
    //       item?.scheduled_date
    //         ? moment(item?.scheduled_date).format("DD-MM-YYYY")
    //         : ""
    //     );
    //     formData.append(
    //       `paymentClaimItems[${index}][scheduled_amount]`,
    //       item?.schedule_amount ? item?.schedule_amount : ""
    //     );
    //     formData.append(
    //       `paymentClaimItems[${index}][amount_withheld]`,
    //       item?.amount_withheld ? item?.amount_withheld : ""
    //     );
    //     formData.append(
    //       `paymentClaimItems[${index}][withheld_description]`,
    //       item?.reason ? item?.reason : ""
    //     );
    //   }
    // });

    try {
      setLoading(true);
      const response = await fileUpload(
        `${API.UPLOAD_PAYMENT_CLAIM}${edit ? "/" + id : ""}`,
        formData
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        handleConfirmModal();
        if (edit) {
          handleEditSucess();
        }
        navigate(`/projects/${projectId}/payment-claims?tab=list`);
      }
      setLoading(false);
    } catch (error) {
      handleConfirmModal();
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postDisabled = (values) => {
    if (values && values?.length > 0) {
      const checkData = values?.map((item, index) => {
        // let totalclaimed = Number(item?.schedule_amount) + Number(item?.amount_withheld);
        // if (item?.amount_withheld && item?.schedule_amount) {
        //   if (
        //     item?.description &&
        //     item?.scheduled_date &&
        //     item?.schedule_amount &&
        //     item?.amount_withheld &&
        //     !errors[index]?.scheduled_date &&
        //     item?.reason &&
        //     disableState &&
        //     Number(`${claimedAmount}`?.replace(/,/g, "")).toFixed(2) ===
        //       (
        //         (totalScheduleAmount !== 0
        //           ? Number(`${totalScheduleAmount}`?.replace(/,/g, ""))
        //           : 0) +
        //         (totalAmountWithheld !== 0
        //           ? Number(`${totalAmountWithheld}`?.replace(/,/g, ""))
        //           : 0)
        //       ).toFixed(2) &&
        //     claimedAmount &&
        //     dueDate &&
        //     invoiceDate &&
        //     invoiceNumber &&
        //     contractorDetails &&
        //     finalData[0]?.paymentClaims?.length > 0
        //   ) {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // } else if (item?.schedule_amount) {
        //   if (
        //     item?.description &&
        //     claimedAmount &&
        //     !errors[index]?.scheduled_date &&
        //     Number(`${claimedAmount}`?.replace(/,/g, "")).toFixed(2) ===
        //       (
        //         (totalScheduleAmount !== 0
        //           ? Number(`${totalScheduleAmount}`?.replace(/,/g, ""))
        //           : 0) +
        //         (totalAmountWithheld !== 0
        //           ? Number(`${totalAmountWithheld}`?.replace(/,/g, ""))
        //           : 0)
        //       ).toFixed(2) &&
        //     item?.scheduled_date &&
        //     item?.schedule_amount &&
        //     disableState &&
        //     dueDate &&
        //     invoiceDate &&
        //     invoiceNumber &&
        //     contractorDetails &&
        //     finalData[0]?.paymentClaims?.length > 0
        //   ) {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // } else if (item?.amount_withheld) {
        //   if (
        //     item?.scheduled_date &&
        //     item?.amount_withheld &&
        //     !errors[index]?.scheduled_date &&
        //     item?.reason &&
        //     disableState &&
        //     Number(`${claimedAmount}`?.replace(/,/g, "")).toFixed(2) ===
        //       (
        //         (totalScheduleAmount !== 0
        //           ? Number(`${totalScheduleAmount}`?.replace(/,/g, ""))
        //           : 0) +
        //         (totalAmountWithheld !== 0
        //           ? Number(`${totalAmountWithheld}`?.replace(/,/g, ""))
        //           : 0)
        //       ).toFixed(2) &&
        //     claimedAmount &&
        //     dueDate &&
        //     invoiceDate &&
        //     invoiceNumber &&
        //     contractorDetails &&
        //     finalData[0]?.paymentClaims?.length > 0
        //   ) {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // }
        if (
          claimedAmount &&
          dueDate &&
          invoiceDate &&
          invoiceNumber &&
          contractorDetails
          // finalData[0]?.paymentClaims?.length > 0
        ) {
          return false;
        } else {
          return true;
        }
      });
      let disableStateData = checkData?.find((item) => item === true)
        ? true
        : false;
      setSaveDisable(disableStateData);
      return checkData?.find((item) => item === true) ? true : false;
    } else {
      setSaveDisable(true);
      return true;
    }
  };

  const handlePost = async (finalData) => {
    setShow(true);
    setFinalSubminData(finalData);
  };

  const handleConfirmModal = () => {
    setShow(false);
    setFinalSubminData([]);
  };

  useEffect(() => {
    if (errors) {
      //eslint-disable-next-line
      errors?.map((i, index) => {
        if (claimedAmount) {
          if (entryData[index].schedule_amount) {
            if (
              Number(claimedAmount?.replace(/,/g, "")) <
              entryData[index].schedule_amount
            ) {
              errors[index].schedule_amount =
                "Enter amount less than claimed amount";
            } else {
              errors[index].schedule_amount = null;
            }
          }
          if (entryData[index].amount_withheld) {
            if (
              Number(claimedAmount?.replace(/,/g, "")) <
              entryData[index].amount_withheld
            ) {
              errors[index].amount_withheld =
                "Enter amount less than claimed amount";
            } else {
              errors[index].amount_withheld = null;
            }
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [claimedAmount]);

  const handlePaymentClaimChange = (data, finalIndex, file) => {
    // const newpaymentClaims = [...data[finalIndex].paymentClaims, file];
    let newData = [...data];
    const selectedFile = file;
    setFile(selectedFile);
    if (selectedFile) {
      if (selectedFile && selectedFile.type === "application/pdf") {
        setNumPages(null);
        setPageNumber(1);
      }
    }

    if (finalIndex > -1) {
      newData[finalIndex] = {
        ...newData[finalIndex],
        paymentClaims: [file],
      };
      setFinalData([...newData]);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const handlePaymentClaimDelete = (finalData, finalIndex, index) => {
    finalData[finalIndex]?.paymentClaims.splice(index, 1);

    const newpaymentClaims = [...finalData[finalIndex].paymentClaims];
    let newData = [...finalData];

    if (finalIndex > -1) {
      newData[finalIndex] = {
        ...newData[finalIndex],
        paymentClaims: newpaymentClaims,
      };
      setFinalData([...newData]);
    }
    setFile();
    setNumPages(null);
    setPageNumber(1);
  };

  const isImage = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png|bmp|webp)$/i);
  };

  const isPdf = (url) => {
    return url.match(/\.pdf$/i);
  };

  const handleFinalDelete = (finalData, finalIndex, finalItem) => {
    finalData.splice(finalIndex, 1);
    setFinalData([...finalData]);
  };

  // const handleAdd = () => {
  //   setFinalData([...finalData, newEntryData]);
  // };

  useEffect(() => {
    postDisabled(entryData);
  }, [
    entryData,
    claimedAmount,
    dueDate,
    invoiceDate,
    invoiceNumber,
    contractorDetails,
    finalData,
    postDisabled,
  ]);

  useEffect(() => {
    if (!edit) {
      navigate(`/projects/${projectId}/payment-claims?tab=upload`, {
        replace: true,
      });
    }
  }, [projectId, navigate, edit]);

  // const handleView = async (value) => {
  //   window.open(`${value}`, "_blank", "noreferrer");
  // };

  return (
    <div className="project-claim-tab">
      <div
        className="documents-upload row"
        style={{
          display: "block",
        }}
      >
        {finalData &&
          finalData?.length > 0 &&
          finalData?.map((finalItem, finalIndex) => {
            return (
              <>
                <div className="col-md-4">
                  <div
                    className="documents-upload-content"
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <h6>Payment Claim Upload</h6>
                    {finalItem?.paymentClaims?.length === 0 && (
                      <div className="upload-header">
                        {finalData?.length > 1 && (
                          <>
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                handleFinalDelete(
                                  finalData,
                                  finalIndex,
                                  finalItem
                                );
                              }}
                            />
                          </>
                        )}
                      </div>
                    )}
                    <div>
                      <div className="upload-div" style={{ margin: 0 }}>
                        <div className="modal-body">
                          <Form
                            style={{
                              padding: "0px",
                            }}
                          >
                            <div>
                              {finalItem?.paymentClaims &&
                                finalItem?.paymentClaims?.length > 0 &&
                                finalItem?.paymentClaims?.map((item, index) => {
                                  return (
                                    <div
                                      className="file-names"
                                      style={{
                                        gap: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{`${
                                        item?.name
                                          ? typeof item?.name === "string"
                                            ? item?.name
                                                ?.split("/")
                                                ?.slice(-1)[0]
                                            : item?.name
                                          : ""
                                      }`}</span>
                                      <div className="remove">
                                        {edit ? (
                                          item?.name && (
                                            <>
                                              {/* {!item?.size && item?.name && ( */}
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                size="1x"
                                                onClick={
                                                  () => toggleFileModal()
                                                  // handleView(item?.name)
                                                }
                                                data-toggle="modal"
                                                data-target="#account"
                                              />
                                              {/* )} */}
                                            </>
                                          )
                                        ) : (
                                          <>
                                            <FontAwesomeIcon
                                              icon={faEye}
                                              size="1x"
                                              onClick={
                                                () => toggleFileModal()
                                                // handleView(item?.name)
                                              }
                                              data-toggle="modal"
                                              data-target="#account"
                                            />
                                            <span></span>
                                            &nbsp;&nbsp;/&nbsp;&nbsp;
                                            <FontAwesomeIcon
                                              icon={faTrash}
                                              title="Remove"
                                              onClick={() => {
                                                handlePaymentClaimDelete(
                                                  finalData,
                                                  finalIndex,
                                                  index
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            {/* {finalItem?.paymentClaims?.length === 0 && ( */}
                            <div
                              className="upload-file"
                              style={{ marginTop: "10px" }}
                            >
                              <FormControl
                                accept={
                                  "image/jpeg, image/jpg, image/x-png, .pdf"
                                }
                                type="file"
                                title=""
                                name="paymentClaims"
                                className="form-control"
                                id="paymentClaims"
                                onChange={(e) => {
                                  e.currentTarget.files[0]?.type ===
                                    "image/jpeg" ||
                                  e.currentTarget.files[0]?.type ===
                                    "image/jpg" ||
                                  e.currentTarget.files[0]?.type ===
                                    "image/png" ||
                                  e.currentTarget.files[0]?.type ===
                                    "application/pdf"
                                    ? handlePaymentClaimChange(
                                        finalData,
                                        finalIndex,
                                        e.currentTarget.files[0]
                                      )
                                    : toast.error(
                                        "Please upload .pdf, .jpeg, .jpg or .png only."
                                      );
                                }}
                              />
                              <span>
                                <p className="ant-upload-drag-icon">
                                  <FontAwesomeIcon
                                    title=""
                                    icon={faUpload}
                                    size="3x"
                                  />
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                              </span>
                            </div>
                            {/* )} */}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-8"
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <h6>Payment Claim</h6>
                  <AddPaymnetClaim
                    loading={loading}
                    projectId={projectId}
                    contractorDetails={contractorDetails}
                    handleContractorSelect={handleContractorSelect}
                    disableState={disableState}
                    invoiceNumber={invoiceNumber}
                    setInvoiceNumber={setInvoiceNumber}
                    setEntryData={setEntryData}
                    entryData={entryData}
                    claimedAmount={claimedAmount}
                    setClaimedAmount={setClaimedAmount}
                    invoiceDate={invoiceDate}
                    setInvoiceDate={setInvoiceDate}
                    setPreviousDate={setPreviousDate}
                    receiveDate={receiveDate}
                    setReceiveDate={setReceiveDate}
                    holidayList={holidayList}
                    tomorrow={tomorrow}
                    dueDate={dueDate}
                    previousdate={previousdate}
                    setDueDate={setDueDate}
                    totalClaimedAmount={totalClaimedAmount}
                    totalScheduleAmount={totalScheduleAmount}
                    totalAmountWithheld={totalAmountWithheld}
                    handleScheduledDateChange={handleScheduledDateChange}
                    errors={errors}
                    handleScheduleAmountChange={handleScheduleAmountChange}
                    handleDescriptionChange={handleDescriptionChange}
                    handleAmountWithheldChange={handleAmountWithheldChange}
                    handleReasonChange={handleReasonChange}
                    show={show}
                    handleConfirmModal={handleConfirmModal}
                    handleSubmitPost={handleSubmitPost}
                    finalSubminData={finalSubminData}
                    setClaimABN={setClaimABN}
                    claimABN={claimABN}
                    setContractBusiness={setContractBusiness}
                    contractBusiness={contractBusiness}
                    // approvedAmount={approvedAmount}
                    // setApprovedAmount={setApprovedAmount}
                    // approvedDate={approvedDate}
                    // setApprovedDate={setApprovedDate}
                    // approvedReason={approvedReason}
                    // setApprovedReason={setApprovedReason}
                    contractorData={contractorData}
                    edit={edit}
                  />
                </div>
              </>
            );
          })}
        {/* {finalData && finalData?.length < 5 && (
          <div className="add-button">
            <div className="add-document-card">
              <FontAwesomeIcon
                icon={faPlus}
                size="9x"
                onClick={() => {
                  handleAdd();
                }}
              />
            </div>
          </div>
        )} */}
      </div>
      <div className="action-box">
        <Button
          disabled={saveDisable}
          onClick={() => {
            handlePost(entryData);
          }}
          style={{
            marginRight: "0px",
            marginBottom: "15px",
          }}
        >
          {edit ? "Update" : "Post"}
        </Button>
      </div>

      {fileModal && (
        <Modal
          size="lg"
          show={fileModal}
          onHide={toggleFileModal}
          dialogClassName="small-review payment-claim-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Payment Claim File
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            {edit &&
            !finalData[0]?.paymentClaims[0]?.size &&
            finalData[0]?.paymentClaims[0]?.name ? (
              <>
                {file && (
                  <div
                    className="documents-upload row"
                    style={{
                      margin: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="documents-upload-content "
                      style={{
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        {/* Render Image URL */}
                        {isImage(file) && (
                          <>
                            <img
                              src={file}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "400px",
                              }}
                            />
                          </>
                        )}

                        {/* Render PDF */}
                        {isPdf(file) && (
                          <>
                            <form
                              style={{ padding: " 0px" }}
                              noValidate
                              className="entry-section claim-pdf-view"
                            >
                              <div className="table-pagination claim-pdf-header">
                                <div className="pager claim-pdf-pager">
                                  <div className="claim-pdf-pager-goto">
                                    Go to page{" "}
                                    <div
                                      className="form-box"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          value={pageNumber}
                                          onChange={(e) => {
                                            setPageNumber(
                                              Number(e?.target?.value)
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (e?.target?.value <= numPages) {
                                              setPageNumber(
                                                Number(e?.target?.value)
                                              );
                                            } else {
                                              setPageNumber(1);
                                            }
                                          }}
                                          style={{
                                            width: "60px",
                                            padding: "5px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      goToPrevPage();
                                    }}
                                    disabled={pageNumber <= 1}
                                  >
                                    <i className="fa fa-angle-left"></i> Prev
                                  </button>
                                  <span style={{ margin: "0 10px" }}>
                                    Page {pageNumber} of {numPages}
                                  </span>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      goToNextPage();
                                    }}
                                    disabled={pageNumber >= numPages}
                                  >
                                    Next <i className="fa fa-angle-right"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="claim-pdf-doc">
                                <Document
                                  file={file}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                  renderError={(error) => (
                                    <p>Error loading PDF: {error.message}</p>
                                  )}
                                >
                                  <Page
                                    pageNumber={pageNumber}
                                    height={1123}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                  />
                                </Document>
                              </div>

                              <div
                                style={{
                                  justifyContent: "end",
                                }}
                                className="table-pagination claim-pdf-header"
                              >
                                <div className="pager claim-pdf-pager">
                                  <div className="claim-pdf-pager-goto">
                                    Go to page{" "}
                                    <div
                                      className="form-box"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          value={pageNumber}
                                          onChange={(e) => {
                                            setPageNumber(
                                              Number(e?.target?.value)
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (e?.target?.value <= numPages) {
                                              setPageNumber(
                                                Number(e?.target?.value)
                                              );
                                            } else {
                                              setPageNumber(1);
                                            }
                                          }}
                                          style={{
                                            width: "60px",
                                            padding: "5px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      goToPrevPage();
                                    }}
                                    disabled={pageNumber <= 1}
                                  >
                                    <i className="fa fa-angle-left"></i> Prev
                                  </button>
                                  <span style={{ margin: "0 10px" }}>
                                    Page {pageNumber} of {numPages}
                                  </span>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      goToNextPage();
                                    }}
                                    disabled={pageNumber >= numPages}
                                  >
                                    Next <i className="fa fa-angle-right"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </>
                        )}

                        {/* Unsupported file type */}
                        {file && !isImage(file) && !isPdf(file) && (
                          <p>File type not supported for preview</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              file && (
                <div
                  className="documents-upload row"
                  style={
                    edit
                      ? {
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }
                      : {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }
                  }
                >
                  <div
                    className="documents-upload-content"
                    style={{
                      padding: "15px",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      {/* Render Image */}
                      {file && file.type.startsWith("image/") && (
                        <>
                          <img
                            src={URL.createObjectURL(file)}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "400px",
                            }}
                          />
                        </>
                      )}

                      {/* Render PDF */}
                      {file && file.type === "application/pdf" && (
                        <>
                          <form
                            style={{ padding: " 0px" }}
                            noValidate
                            className="entry-section claim-pdf-view"
                          >
                            <div className="table-pagination claim-pdf-header">
                              <div className="pager claim-pdf-pager">
                                <div className="claim-pdf-pager-goto">
                                  Go to page{" "}
                                  <div
                                    className="form-box"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        value={pageNumber}
                                        onChange={(e) => {
                                          setPageNumber(
                                            Number(e?.target?.value)
                                          );
                                        }}
                                        onBlur={(e) => {
                                          if (e?.target?.value <= numPages) {
                                            setPageNumber(
                                              Number(e?.target?.value)
                                            );
                                          } else {
                                            setPageNumber(1);
                                          }
                                        }}
                                        style={{
                                          width: "60px",
                                          padding: "5px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    goToPrevPage();
                                  }}
                                  disabled={pageNumber <= 1}
                                >
                                  <i className="fa fa-angle-left"></i> Prev
                                </button>
                                <span style={{ margin: "0 10px" }}>
                                  Page {pageNumber} of {numPages}
                                </span>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    goToNextPage();
                                  }}
                                  disabled={pageNumber >= numPages}
                                >
                                  Next <i className="fa fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                            <div className="claim-pdf-doc">
                              <Document
                                file={URL.createObjectURL(file)}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page
                                  pageNumber={pageNumber}
                                  height={1123}
                                  renderTextLayer={false}
                                  renderAnnotationLayer={false}
                                />
                              </Document>
                            </div>

                            <div
                              style={{
                                justifyContent: "end",
                              }}
                              className="table-pagination claim-pdf-header"
                            >
                              <div className="pager claim-pdf-pager">
                                <div className="claim-pdf-pager-goto">
                                  Go to page{" "}
                                  <div
                                    className="form-box"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        value={pageNumber}
                                        onChange={(e) => {
                                          setPageNumber(
                                            Number(e?.target?.value)
                                          );
                                        }}
                                        onBlur={(e) => {
                                          if (e?.target?.value <= numPages) {
                                            setPageNumber(
                                              Number(e?.target?.value)
                                            );
                                          } else {
                                            setPageNumber(1);
                                          }
                                        }}
                                        style={{
                                          width: "60px",
                                          padding: "5px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    goToPrevPage();
                                  }}
                                  disabled={pageNumber <= 1}
                                >
                                  <i className="fa fa-angle-left"></i> Prev
                                </button>
                                <span style={{ margin: "0 10px" }}>
                                  Page {pageNumber} of {numPages}
                                </span>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    goToNextPage();
                                  }}
                                  disabled={pageNumber >= numPages}
                                >
                                  Next <i className="fa fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}

                      {/* Unsupported file type */}
                      {file &&
                        !file.type.startsWith("image/") &&
                        file.type !== "application/pdf" && (
                          <p>File type not supported for preview</p>
                        )}
                    </div>
                  </div>
                </div>
              )
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
export default Upload;
