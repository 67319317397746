import { toast } from "react-toastify";
import { API, get } from "../../../config";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

export const getUserPopupDetail = async (userId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_USER}/${userId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.GET_USER}/${userId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const useOfficeList = () => {
  const [officeList, setOfficeList] = useState([]);
  const [officeLoading, setOfficeLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setOfficeLoading(true);
      const { data } = await get(`${API.GET_OFFICE_LIST}?page=1&limit=-1`);
      setOfficeLoading(false);
      setOfficeList(data.data.offices.data);
    } catch (e) {
      setOfficeList([]);
      setOfficeLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeLoading,
  };
};

export const useRoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRoleLoading(true);
      const { data } = await get(`${API.GET_ROLE_LIST}?page=1&limit=-1`);
      setRoleLoading(false);
      setRoleList(data.data);
    } catch (e) {
      setRoleList([]);
      setRoleLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    roleList,
    roleLoading,
  };
};

export const getUserDetail = async (userId) => {
  try {
    const { data } = await get(`${API.USER}/` + userId);
    return data.data;
  } catch (e) {
    return null;
  }
};
