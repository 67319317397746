/* eslint-disable eqeqeq */
import {
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../../common/Spin";
import {
  API,
  deleteCall,
  get,
  imageDefaultPrefixPath,
} from "../../../../config";
import { toast } from "react-toastify";
import {
  DDMMYYYYFormat,
  formatABN,
  formatName,
  formatNumber,
  formatPhone,
  formatSubcontractorName,
  formatedAddress,
} from "../../../common/Misc";
// import axios from "axios";
import Upload from "../Upload";

const PaymentClaimListActionButton = ({
  dataView,
  id,
  handleDelete,
  file,
  approve,
  email,
  fetchPaymnetClaims,
  projectId,
  trust_type,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentClaimData, setPaymentClaimData] = useState(null);
  const [showSendEmail, setShowSendEmail] = useState(false);
  // const [showApprove, setShowApprove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  // const [approveStatus, setApproveStatus] = useState(false);
  // const [disapprovedReason, setDisapprovedReason] = useState();

  const [mailLoading, setMailLoading] = useState(false);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleShowModal = (data) => {
    setShow(!show);
  };

  const handleSendEmail = () => {
    setShowSendEmail(!showSendEmail);
  };

  // const handleApprove = () => {
  //   if (showApprove) {
  //     setDisapprovedReason();
  //   }
  //   setShowApprove(!showApprove);
  // };

  const toggleEditModal = () => {
    setShowEdit(!showEdit);
  };

  // eslint-disable-next-line no-unused-vars
  const fetchPaymentClaimData = useCallback(async (id) => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await get(`${API.GET_PAYMENT_CLAIM_LIST}/${id}`);
        setPaymentClaimData(data?.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.errors) {
          setLoading(false);
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          setLoading(false);
          toast.error(error?.message);
        }
      }
    }
  }, []);

  const handlePaymentClaimDelete = async (id) => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.GET_PAYMENT_CLAIM_LIST}/${id}`);
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  const fetchPaymentClaimMail = useCallback(async (id, action) => {
    if (id) {
      try {
        setMailLoading(true);
        const { data } = await get(
          `${API.GET_PAYMENT_CLAIM_LIST}/${id}/${action}`
        );
        data?.message && toast.success(data?.message);
        handleSendEmail();
        setMailLoading(false);
      } catch (error) {
        if (error?.response?.data?.errors) {
          setMailLoading(false);
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          setMailLoading(false);
          toast.error(error?.message);
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  // const downloadLetter = async (path) => {
  //   try {
  //     if (path) {
  //       const response = await axios.get(`${path}`, {
  //         responseType: "blob",
  //       });
  //       const fileData = response.data;
  //       const url = window.URL.createObjectURL(new Blob([fileData]));
  //       var a = document.createElement("a");
  //       a.href = url;
  //       var file = path.split("/");
  //       a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     } else {
  //       toast.error("File does not exist.");
  //     }
  //   } catch (e) {
  //     const errors = e.response?.data?.errors;
  //     Object.keys(errors).forEach((key) => {
  //       toast.error(errors[key][0]);
  //     });
  //   }
  // };

  return (
    <>
      <span>
        {approve === 0 && (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={toggleEditModal}
              data-toggle="modal"
              data-target="#account"
            />
          </>
        )}
        {/* {file && (
          <>
            {approve === 0 && (
              <>
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              </>
            )}
            <FontAwesomeIcon
              icon={faDownload}
              size="1x"
              onClick={() => {
                downloadLetter(file);
              }}
            />
          </>
        )} */}
      </span>
      {showEdit && (
        <Modal
          size="lg"
          show={showEdit}
          onHide={toggleEditModal}
          dialogClassName="small-review payment-schedule-calender-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Edit Payment Claim
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <Upload
              edit={true}
              id={id}
              handleEditSucess={() => {
                toggleEditModal();
                fetchPaymnetClaims();
              }}
            />
          </Modal.Body>
        </Modal>
      )}
      {showSendEmail && (
        <Modal
          size="lg"
          show={showSendEmail}
          onHide={() => {
            handleSendEmail();
          }}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Send E-mail
          </Modal.Header>
          <Modal.Body>
            {mailLoading && <Spin />}
            <div className="modal-body">
              Are you sure you want to email this payment claim to {email}?
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  fetchPaymentClaimMail(id, "email");
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleSendEmail();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={toggleShowModal}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          // className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Payment Claim Details
          </Modal.Header>
          <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
            <>
              {loading && <Spin />}
              <div
                className="payment-schedule-view"
                style={{ height: "90vh", overflow: "auto" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    // margin: "50px 30px",
                    margin: "30px",
                  }}
                >
                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td colspan="3" style={{ textAlign: "center" }}>
                        <h2
                          style={{
                            fontSize: "25px",
                            fontWeight: "normal",
                            marginBottom: "20px",
                            marginTop: "10px",
                          }}
                        >
                          PAYMENT CLAIM
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "40%", paddingBottom: "10px" }}>
                        {paymentClaimData?.builder_project?.builder?.logo ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              paymentClaimData?.builder_project?.builder?.logo
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "center",
                          verticalAlign: "baseline",
                        }}
                      >
                        From
                      </td>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingBottom: "20px",
                        }}
                      >
                        <p>
                          {paymentClaimData?.builder_project?.builder
                            ?.builder_details
                            ? formatName(
                                paymentClaimData?.builder_project?.builder
                                  ?.builder_details?.first_name,
                                paymentClaimData?.builder_project?.builder
                                  ?.builder_details?.last_name
                              )
                            : "NA"}
                        </p>
                        {paymentClaimData?.builder_project?.builder
                          ?.business_name && (
                          <p>
                            {paymentClaimData?.builder_project?.builder
                              ?.business_name
                              ? paymentClaimData?.builder_project?.builder
                                  ?.business_name
                              : "NA"}
                          </p>
                        )}
                        {paymentClaimData?.builder_project?.builder?.abn && (
                          <p>
                            ABN/ACN:{" "}
                            {paymentClaimData?.builder_project?.builder?.abn
                              ? formatABN(
                                  paymentClaimData?.builder_project?.builder
                                    ?.abn
                                )
                              : "NA"}
                          </p>
                        )}
                        {paymentClaimData?.builder_project?.builder?.street && (
                          <p>
                            {formatedAddress(
                              paymentClaimData?.builder_project?.builder
                                ?.street,
                              paymentClaimData?.builder_project?.builder?.suburb
                                ?.name,
                              paymentClaimData?.builder_project?.builder?.state
                                ?.short_name,
                              paymentClaimData?.builder_project?.builder
                                ?.postal_code
                            )}
                          </p>
                        )}
                        {paymentClaimData?.builder_project?.builder
                          ?.builder_details?.phone && (
                          <p>
                            Phone:{" "}
                            {paymentClaimData?.builder_project?.builder
                              ?.builder_details?.phone
                              ? formatPhone(
                                  paymentClaimData?.builder_project?.builder
                                    ?.builder_details?.phone
                                )
                              : "NA"}
                          </p>
                        )}
                      </td>
                    </tr>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "45px" }}
                  >
                    <tr>
                      <td style={{ width: "40%" }}></td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "center",
                          verticalAlign: "baseline",
                        }}
                      >
                        To
                      </td>
                      <td style={{ width: "50%", textAlign: "right" }}>
                        <p>
                          {" "}
                          {/* {paymentClaimData?.contact
                            ? formatName(
                                paymentClaimData?.contact?.first_name,
                                paymentClaimData?.contact?.last_name
                              )
                            : "NA"} */}
                          {paymentClaimData?.contact
                            ? formatSubcontractorName(paymentClaimData?.contact)
                            : "NA"}
                        </p>
                        {paymentClaimData?.contact?.business_name && (
                          <p>
                            {paymentClaimData?.contact?.business_name
                              ? paymentClaimData?.contact?.business_name
                              : "NA"}
                          </p>
                        )}
                        {paymentClaimData?.contact?.abn && (
                          <p>
                            ABN/ACN:{" "}
                            {paymentClaimData?.contact?.abn
                              ? formatABN(paymentClaimData?.contact?.abn)
                              : "NA"}
                          </p>
                        )}
                        {paymentClaimData?.contact?.street && (
                          <p>
                            {formatedAddress(
                              paymentClaimData?.contact?.street,
                              paymentClaimData?.contact?.suburb?.name,
                              paymentClaimData?.contact?.state?.short_name,
                              paymentClaimData?.contact?.postal_code
                            )}
                          </p>
                        )}
                        {paymentClaimData?.contact?.phone && (
                          <p>
                            Phone:{" "}
                            {paymentClaimData?.contact?.phone
                              ? formatPhone(paymentClaimData?.contact?.phone)
                              : "NA"}
                          </p>
                        )}
                      </td>
                    </tr>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{
                      marginBottom: "45px",
                      border: "1px solid #ccc",
                      fontSize: "13px",
                    }}
                  >
                    <tr style={{ verticalAlign: "baseline" }}>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                          width: "18%",
                        }}
                      >
                        Payment Claim / Invoice #
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                          width: "20%",
                        }}
                      >
                        Payment Claim / Invoice Date
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                          width: "14%",
                        }}
                      >
                        Due Date
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Total Claimed Amount
                      </td>
                      <td style={{ textAlign: "right", padding: "5px" }}>
                        Scheduled Amount
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.invoice_number}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.invoice_date
                          ? DDMMYYYYFormat(
                              paymentClaimData?.invoice_date?.split("-")
                            )
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.due_date
                          ? DDMMYYYYFormat(
                              paymentClaimData?.due_date?.split("-")
                            )
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                          textAlign: "right",
                        }}
                      >
                        {paymentClaimData?.total_claimed_amount
                          ? "$" +
                            formatNumber(paymentClaimData?.total_claimed_amount)
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.total_scheduled_amount
                          ? "$" +
                            formatNumber(
                              paymentClaimData?.total_scheduled_amount
                            )
                          : "-"}
                      </td>
                    </tr>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{
                      marginBottom: "45px",
                      border: "1px solid #ccc",
                      fontSize: "13px",
                    }}
                  >
                    <tr style={{ verticalAlign: "baseline" }}>
                      <td
                        style={{
                          width: "44%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Description
                      </td>
                      <td
                        style={{
                          width: "15%",
                          textAlign: "right",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Claimed Amount
                      </td>
                      <td
                        style={{
                          width: "15%",
                          textAlign: "right",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Scheduled Amount
                      </td>
                      <td
                        style={{
                          width: "14%",
                          textAlign: "right",
                          padding: "5px",
                        }}
                      >
                        Amount withheld (if any)
                      </td>
                    </tr>
                    {paymentClaimData?.payment_claim_items?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item.description ? item.description : "-"}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "right",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item?.claimed_amount
                              ? "$" + formatNumber(item?.claimed_amount)
                              : "$" +
                                formatNumber(
                                  item?.scheduled_amount + item?.amount_withheld
                                )}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "right",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item?.scheduled_amount
                              ? "$" + formatNumber(item?.scheduled_amount)
                              : "-"}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "right",
                              borderTop: "1px solid #ccc",
                            }}
                          >
                            {item?.amount_withheld
                              ? "$" + formatNumber(item?.amount_withheld)
                              : "-"}
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.total_claimed_amount
                          ? "$" +
                            formatNumber(paymentClaimData?.total_claimed_amount)
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.total_scheduled_amount
                          ? "$" +
                            formatNumber(
                              paymentClaimData?.total_scheduled_amount
                            )
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                        }}
                      >
                        {paymentClaimData?.total_amount_withheld
                          ? "$" +
                            formatNumber(
                              paymentClaimData?.total_amount_withheld
                            )
                          : "-"}
                      </td>
                    </tr>
                  </table>
                  {paymentClaimData?.payment_claim_items?.filter(
                    (item) => Number(item?.amount_withheld) > 0
                  )?.length > 0 && (
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style={{
                        marginBottom: "45px",
                        fontSize: "13px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            width: "20%",
                            padding: "5px",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          Amount Withheld
                        </td>
                        <td style={{ width: "80%", padding: "5px" }}>
                          Reasons for withholding payment
                        </td>
                      </tr>
                      {paymentClaimData?.payment_claim_items?.map(
                        (item, index) =>
                          item?.amount_withheld ? (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "5px",
                                  borderTop: "1px solid #ccc",
                                  borderRight: "1px solid #ccc",
                                }}
                              >
                                {item?.amount_withheld
                                  ? "$" + formatNumber(item?.amount_withheld)
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  borderTop: "1px solid #ccc",
                                }}
                              >
                                {item?.withheld_description
                                  ? item?.withheld_description
                                  : "-"}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )
                      )}
                    </table>
                  )}

                  <p style={{ fontSize: "13px" }}>Attachments (if any)</p>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{
                      marginBottom: "45px",
                      border: "1px solid #ccc",
                      fontSize: "13px",
                    }}
                  >
                    <tr>
                      <td style={{ padding: "5px" }}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td
                        style={{ padding: "5px", borderTop: "1px solid #ccc" }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ padding: "5px", borderTop: "1px solid #ccc" }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ padding: "5px", borderTop: "1px solid #ccc" }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                  </table>

                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "45px" }}
                  >
                    <tr>
                      <td
                        style={{
                          padding: "5px",
                          width: "5%",
                          textAlign: "right",
                        }}
                      >
                        Sign:
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderBottom: "1px solid #ccc",
                          width: "45%",
                        }}
                      >
                        &nbsp;
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          width: "5%",
                          textAlign: "right",
                        }}
                      >
                        Date:
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderBottom: "1px solid #ccc",
                          width: "45%",
                        }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "5px", width: "5%" }}></td>
                      <td style={{ padding: "5px", textAlign: "center" }}>
                        (Signature)
                      </td>
                      <td style={{ padding: "5px", width: "5%" }}></td>
                      <td style={{ padding: "5px" }}></td>
                    </tr>
                  </table>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}

      {showDelete && (
        <Modal
          size="lg"
          show={showDelete}
          onHide={toggleDeleteModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Delete Payment Claim
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Are your sure you want to delete this payment claim?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handlePaymentClaimDelete();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PaymentClaimListActionButton;
