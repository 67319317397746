import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useProjectBar } from "../../pages/common/useProjectBar";

const ProjectHeaderNav = () => {
  const params = useParams();
  const projectId = params?.project_id;
  const role = localStorage.getItem("role");

  const location = useLocation();

  const { ProjectReadOnly } = useProjectBar();

  return (
    <ul className="header-nav">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li
              className={
                location.pathname === "/builders" ||
                (role === "Builder" && location.pathname === "/projects")
                  ? "active nav-item"
                  : "nav-item"
              }
            >
              {role === "Builder" ? (
                <Link
                  to={{
                    pathname: "/projects",
                  }}
                >
                  Projects
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: "/builders",
                  }}
                >
                  Builders
                </Link>
              )}
            </li>
            <li
              className={
                location.pathname === "/projects/" + projectId
                  ? "active nav-item"
                  : "nav-item"
              }
            >
              <Link
                to={{
                  pathname: "/projects/" + projectId,
                }}
              >
                Dashboard
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/sub-contractors")
                  ? "nav-item active"
                  : "nav-item "
              }
            >
              <Link
                to={{
                  pathname: `/projects/${projectId}/sub-contractors`,
                }}
              >
                Subcontractors
              </Link>
            </li>

            <li
              className={
                location.pathname.includes("/journal-entries") ||
                location.pathname.includes("/chart-of-accounts") ||
                location.pathname.includes("/opening-balance")
                  ? "active nav-item dropdown"
                  : "nav-item dropdown"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Accounting
              </a>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li
                  className={
                    location.pathname.includes("/project/journal-entries")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/project/journal-entries`}
                  >
                    Journal Entries For PTA
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/retention/journal-entries")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/retention/journal-entries`}
                  >
                    Journal Entries For RTA
                  </Dropdown.Item>
                </li>

                <li
                  className={
                    location.pathname.includes("/chart-of-accounts")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/chart-of-accounts`}
                  >
                    Chart of Accounts
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/retention/opening-balance")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/retention/opening-balance`}
                  >
                    Opening Balance For RTA
                  </Dropdown.Item>
                </li>
              </ul>
            </li>
            <li
              className={
                location.pathname.includes("/payment-claims") ||
                location.pathname.includes("/project/payment-schedules") ||
                location.pathname.includes("/project/payments") ||
                location.pathname.includes("/project/receipts") ||
                location.pathname.includes("/project/aba")
                  ? "active nav-item dropdown"
                  : "nav-item dropdown"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                PTA
              </a>
              <ul className="dropdown-menu">
                <li
                  className={
                    location.pathname.includes("/payment-claims")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/payment-claims`}
                  >
                    Payment Claims
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/project/payment-schedules")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/project/payment-schedules`}
                  >
                    Payment Schedule
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/project/payments")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/project/payments`}
                  >
                    Trust Payments (Upfront)
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/project/receipts")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/project/receipts`}
                  >
                    Trust Receipts
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/project/aba") ? "active" : ""
                  }
                >
                  <Dropdown.Item href={`/projects/${projectId}/project/aba`}>
                    Payment Instructions
                  </Dropdown.Item>
                </li>
              </ul>
            </li>
            <li
              className={
                location.pathname.includes("/retention/payments") ||
                location.pathname.includes("/retention/receipts")
                  ? "active nav-item dropdown"
                  : "nav-item dropdown"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                RTA
              </a>
              <ul className="dropdown-menu">
                <li
                  className={
                    location.pathname.includes("/retention/payments")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/retention/payments`}
                  >
                    Trust Payments
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/retention/receipts")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/retention/receipts`}
                  >
                    Trust Receipts
                  </Dropdown.Item>
                </li>
              </ul>
            </li>
            <li
              className={
                location.pathname.includes("/bas") ||
                location.pathname.includes("/transactions-report") ||
                (location.pathname.includes("/reconcile-transactions") &&
                  location.search?.includes(
                    "?tab=monthly-bank-reconciliation"
                  )) ||
                location.pathname.includes("/general-ledger") ||
                location.pathname.includes("/contractor-statement-report") ||
                location.pathname.includes("/trial-balance") ||
                location.pathname.includes("/financial-reports")
                  ? "active nav-item dropdown"
                  : "nav-item dropdown"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Reports
              </a>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li
                  className={
                    location.pathname.includes("/project/bas") ||
                    location.pathname.includes(
                      "/project/transactions-report"
                    ) ||
                    (location.pathname.includes(
                      "/project/reconcile-transactions"
                    ) &&
                      location.search?.includes(
                        "?tab=monthly-bank-reconciliation"
                      )) ||
                    location.pathname.includes("/project/general-ledger") ||
                    location.pathname.includes(
                      "/project/contractor-statement-report"
                    ) ||
                    location.pathname.includes("/project/trial-balance") ||
                    location.pathname.includes("/project/financial-reports")
                      ? "active child-menu"
                      : "child-menu"
                  }
                >
                  <Dropdown.Item>Project Trust</Dropdown.Item>
                  <ul className="dropdown-menu">
                    {/* <li
                      className={
                        location.pathname.includes("/project/bas")
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/project/bas`}
                      >
                        GST Report
                      </Dropdown.Item>
                    </li> */}
                    <li
                      className={
                        location.pathname.includes(
                          "/project/transactions-report"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/project/transactions-report`}
                      >
                        {/* Transactions Report */}
                        {/* Record of deposits and withdrawals */}
                        Cashbook
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes(
                          "/project/reconcile-transactions"
                        ) &&
                        location.search?.includes(
                          "?tab=monthly-bank-reconciliation"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      {/* <Dropdown.Item
                        href={`/projects/${projectId}/project/reconcile-transactions?tab=monthly-bank-reconciliation`}
                      >
                        Monthly Bank Reconciliation
                      </Dropdown.Item> */}
                    </li>
                    <li
                      className={
                        location.pathname.includes("/project/general-ledger")
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/project/general-ledger`}
                      >
                        General Ledger
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes(
                          "/project/contractor-statement-report"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/project/contractor-statement-report`}
                      >
                        Contractor Statements
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/project/trial-balance")
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/project/trial-balance`}
                      >
                        Trial Balance
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/project/financial-reports")
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/project/financial-reports`}
                      >
                        Financial Reports For PTA
                      </Dropdown.Item>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    location.pathname.includes("/retention/bas") ||
                    location.pathname.includes(
                      "/retention/transactions-report"
                    ) ||
                    (location.pathname.includes(
                      "/retention/reconcile-transactions"
                    ) &&
                      location.search?.includes(
                        "?tab=monthly-bank-reconciliation"
                      )) ||
                    location.pathname.includes("/retention/general-ledger") ||
                    location.pathname.includes(
                      "/retention/contractor-statement-report"
                    ) ||
                    location.pathname.includes("/retention/trial-balance") ||
                    location.pathname.includes("/retention/financial-reports")
                      ? "active child-menu"
                      : "child-menu"
                  }
                >
                  <Dropdown.Item>Retention Trust</Dropdown.Item>
                  <ul className="dropdown-menu">
                    <li
                      className={
                        location.pathname.includes(
                          "/retention/transactions-report"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/retention/transactions-report`}
                      >
                        Cashbook
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes(
                          "/retention/reconcile-transactions"
                        ) &&
                        location.search?.includes(
                          "?tab=monthly-bank-reconciliation"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      {/* <Dropdown.Item
                        href={`/projects/${projectId}/retention/reconcile-transactions?tab=monthly-bank-reconciliation`}
                      >
                        Monthly Bank Reconciliation
                      </Dropdown.Item> */}
                    </li>
                    <li
                      className={
                        location.pathname.includes("/retention/general-ledger")
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/retention/general-ledger`}
                      >
                        General Ledger
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes(
                          "/retention/contractor-statement-report"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/retention/contractor-statement-report`}
                      >
                        Contractor Statements
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/retention/trial-balance")
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/projects/${projectId}/retention/trial-balance`}
                      >
                        Trial Balance
                      </Dropdown.Item>
                    </li>
                    <li
                      className={
                        location.pathname.includes(
                          "/retention/financial-reports"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      {/* <Dropdown.Item
                        href={`/projects/${projectId}/retention/financial-reports`}
                      >
                        Financial Reports For RTA
                      </Dropdown.Item> */}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li
              className={
                (location.pathname.includes("/settings") &&
                  location.search?.includes("?tab=integrations")) ||
                location.pathname.includes("/terms") ||
                (location.pathname.includes("/reconcile-transactions") &&
                  location.search?.includes("?tab=bank-transactions")) ||
                (location.pathname.includes("/reconcile-transactions") &&
                  location.search?.includes("?tab=reconcile-transactions"))
                  ? "active nav-item dropdown"
                  : "nav-item dropdown"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Banking
              </a>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li
                  className={
                    location.pathname.includes("/settings") &&
                    location.search?.includes("?tab=integrations")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/settings?tab=integrations`}
                  >
                    Bank Accounts
                  </Dropdown.Item>
                </li>
                <li
                  className={
                    location.pathname.includes("/terms") ? "active" : ""
                  }
                >
                  <Dropdown.Item href={`/projects/${projectId}/terms`}>
                    Search Terms
                  </Dropdown.Item>
                </li>
                {!ProjectReadOnly && (
                  <li
                    className={
                      (location.pathname.includes(
                        "/project/reconcile-transactions"
                      ) &&
                        location.search?.includes("?tab=bank-transactions")) ||
                      (location.pathname.includes(
                        "/project/reconcile-transactions"
                      ) &&
                        location.search?.includes(
                          "?tab=reconcile-transactions"
                        ))
                        ? "active child-menu"
                        : "child-menu"
                    }
                  >
                    <Dropdown.Item>Project Trust</Dropdown.Item>
                    <ul className="dropdown-menu">
                      <li
                        className={
                          location.pathname.includes(
                            "/project/reconcile-transactions"
                          ) &&
                          location.search?.includes("?tab=bank-transactions")
                            ? "active"
                            : ""
                        }
                      >
                        <Dropdown.Item
                          href={`/projects/${projectId}/project/reconcile-transactions?tab=bank-transactions`}
                        >
                          View Transactions
                        </Dropdown.Item>
                      </li>
                      <li
                        className={
                          location.pathname.includes(
                            "/project/reconcile-transactions"
                          ) &&
                          location.search?.includes(
                            "?tab=reconcile-transactions"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Dropdown.Item
                          href={`/projects/${projectId}/project/reconcile-transactions`}
                        >
                          Reconcile Transactions
                        </Dropdown.Item>
                      </li>
                    </ul>
                  </li>
                )}

                {!ProjectReadOnly && (
                  <li
                    className={
                      (location.pathname.includes(
                        "/retention/reconcile-transactions"
                      ) &&
                        location.search?.includes("?tab=bank-transactions")) ||
                      (location.pathname.includes(
                        "/retention/reconcile-transactions"
                      ) &&
                        location.search?.includes(
                          "?tab=reconcile-transactions"
                        ))
                        ? "active child-menu"
                        : "child-menu"
                    }
                  >
                    <Dropdown.Item>Retention Trust</Dropdown.Item>
                    <ul className="dropdown-menu">
                      <li
                        className={
                          location.pathname.includes(
                            "/retention/reconcile-transactions"
                          ) &&
                          location.search?.includes("?tab=bank-transactions")
                            ? "active"
                            : ""
                        }
                      >
                        <Dropdown.Item
                          href={`/projects/${projectId}/retention/reconcile-transactions?tab=bank-transactions`}
                        >
                          View Transactions
                        </Dropdown.Item>
                      </li>
                      <li
                        className={
                          location.pathname.includes(
                            "/retention/reconcile-transactions"
                          ) &&
                          location.search?.includes(
                            "?tab=reconcile-transactions"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Dropdown.Item
                          href={`/projects/${projectId}/retention/reconcile-transactions?tab=reconcile-transactions`}
                        >
                          Reconcile Transactions
                        </Dropdown.Item>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </li>
            <li
              className={
                location.pathname.includes("/documents")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link
                to={{
                  pathname: "/projects/" + projectId + "/documents",
                }}
              >
                Docs
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/settings") &&
                location?.search?.includes("?tab=project-settings")
                  ? "nav-item dropdown active"
                  : "nav-item dropdown"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Settings
              </a>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li
                  className={
                    location.pathname.includes("/settings") &&
                    location?.search?.includes("?tab=project-settings")
                      ? "active"
                      : ""
                  }
                >
                  <Dropdown.Item
                    href={`/projects/${projectId}/settings?tab=project-settings`}
                  >
                    Settings
                  </Dropdown.Item>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </ul>
  );
};

export default ProjectHeaderNav;
