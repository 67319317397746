import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const HeaderNav = () => {
  const role = localStorage.getItem("role");
  const location = useLocation();

  return (
    <ul className="header-nav">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li
              className={
                location.pathname === "/builders" ||
                location.pathname === "/projects"
                  ? "active nav-item"
                  : "nav-item"
              }
            >
              {role && role === "Builder" ? (
                <Link
                  to={{
                    pathname: "/projects",
                  }}
                >
                  Projects
                </Link>
              ) : (
                role !== "Admin" && (
                  <Link
                    to={{
                      pathname: "/builders",
                    }}
                  >
                    Builders
                  </Link>
                )
              )}
            </li>
            {role && role === "Admin" && (
              <>
                <li
                  className={
                    location.pathname.includes("/admin-dashboard")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    to={{
                      pathname: "/admin-dashboard",
                    }}
                  >
                    Dashboard
                  </Link>
                </li>

                <li
                  className={
                    location.pathname.includes("/admin/registered-users")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    to={{
                      pathname: "/admin/registered-users",
                    }}
                  >
                    Registered Users
                  </Link>
                </li>
                <li
                  className={
                    location.pathname.includes("/admin/subscriptions")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    to={{
                      pathname: "/admin/subscriptions",
                    }}
                  >
                    Subscription
                  </Link>
                </li>
                <li
                  className={
                    location.pathname.includes("/admin/holidays")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    to={{
                      pathname: "/admin/holidays",
                    }}
                  >
                    Holidays
                  </Link>
                </li>
              </>
            )}

            {role && role !== "Builder" && role !== "Admin" && (
              <li
                className={
                  location.pathname.includes("/users")
                    ? "nav-item active"
                    : "nav-item "
                }
              >
                <Link
                  to={{
                    pathname: "/users",
                  }}
                >
                  Users
                </Link>
              </li>
            )}
            {role && role !== "Builder" && role !== "Admin" && (
              <li
                className={
                  location.pathname.includes("/offices")
                    ? "nav-item active"
                    : "nav-item "
                }
              >
                <Link
                  to={{
                    pathname: "/offices",
                  }}
                >
                  Offices
                </Link>
              </li>
            )}

            {role && role !== "Admin" ? (
              <li
                className={
                  (location.pathname.includes("/user-settings") &&
                    location?.search?.includes(
                      "?tab=invitations-from-client"
                    )) ||
                  location.pathname.includes("/general-settings")
                    ? "nav-item dropdown active"
                    : "nav-item dropdown"
                }
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Settings
                </a>

                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {role !== "Builder" && (
                    <li
                      className={
                        location.pathname.includes("/user-settings") &&
                        location?.search?.includes(
                          "?tab=invitations-from-client"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <Dropdown.Item
                        href={`/user-settings?tab=invitations-from-client`}
                      >
                        Invitations From Client
                      </Dropdown.Item>
                    </li>
                  )}
                  <li
                    className={
                      location.pathname.includes("/general-settings")
                        ? "active"
                        : ""
                    }
                  >
                    <Dropdown.Item href={`/general-settings`}>
                      General Settings
                    </Dropdown.Item>
                  </li>
                </ul>
              </li>
            ) : (
              <li
                className={
                  location.pathname.includes("/admin-settings")
                    ? "nav-item dropdown active"
                    : "nav-item dropdown"
                }
              >
                <Link>Settings</Link>
              </li>
            )}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Help
              </a>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li
                  className={
                    location.pathname.includes("/terms-of-use") ? "active" : ""
                  }
                >
                  <Dropdown.Item href={`/terms-of-use`}>
                    Terms Of Use
                  </Dropdown.Item>
                </li>
                {role !== "Builder" && (
                  <li
                    className={
                      location.pathname.includes("/partners-agreement")
                        ? "active"
                        : ""
                    }
                  >
                    <Dropdown.Item href={`/partners-agreement`}>
                      Partners Agreement
                    </Dropdown.Item>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </ul>
  );
};

export default HeaderNav;
