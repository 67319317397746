import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
// import Select from "react-select";
import InputMask from "react-input-mask";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import { Dropdown, DropdownButton } from "react-bootstrap";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  allBanks,
  allBankLoading,
  ProjectReadOnly,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    allBanks: PropTypes.any,
    allBankLoading: PropTypes.any,
    setLoading: PropTypes.any,
  };

  // function padWithLeadingZeros(num, totalLength) {
  //   return String(num).padStart(totalLength, '0');
  // }

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div
        className="entry-card full-card myprofile-card"
        style={{ display: "block" }}
      >
        <div className="form-box">
          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Entity Name
              <div className="apca-info info-text">
                <DropdownButton className="info-icon">
                  <Dropdown.Item>
                    <p>Operator of the Trust Account</p>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </label>
            <input
              type="text"
              name="entity_name"
              value={values?.entity_name ? values?.entity_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={ProjectReadOnly}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="entity_name" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={values?.email ? values?.email : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={ProjectReadOnly}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="email" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Phone No.</label>
            <InputMask
              type="text"
              name="phone"
              value={values?.phone ? values?.phone : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="+61 9 9999 9999"
              maskChar={null}
              disabled={ProjectReadOnly}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="phone" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Mobile No.</label>
            <InputMask
              type="text"
              name="mobile"
              value={values?.mobile ? values?.mobile : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={ProjectReadOnly}
              mask="+61 999 999 999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="mobile" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>ABN</label>
            <InputMask
              type="text"
              name="abn"
              value={values?.abn ? values?.abn : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={ProjectReadOnly}
              mask="99 999 999 999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="abn" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>License Number</label>
            <input
              type="text"
              name="qbcc_number"
              value={values?.qbcc_number ? values?.qbcc_number : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              disabled={ProjectReadOnly}
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="qbcc_number" />
            </span>
          </div>
          {/* <div className="project-bank-details-box">
            <h5>Trustee Bank Account</h5>
            <div className="form-group tetra-width">
              <label>Bank Account Name</label>
              <input
                type="text"
                name="trustee_account_name"
                value={
                  values?.trustee_account_name
                    ? values?.trustee_account_name
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_account_name" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>BSB</label>
              <InputMask
                type="text"
                name="trustee_bsb"
                value={values?.trustee_bsb ? values?.trustee_bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_bsb" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Account Number</label>
              <InputMask
                type="text"
                name="trustee_account_number"
                value={
                  values?.trustee_account_number
                    ? values?.trustee_account_number
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_account_number" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Bank Name</label>
              <Select
                name="trustee_bank_id"
                value={values?.trustee_bank_id ? values?.trustee_bank_id : ""}
                onChange={(e) => {
                  setFieldValue(`trustee_bank_id`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={allBanks}
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isLoading={allBankLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_bank_id" />
              </span>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="entry-card full-card"
        style={{ clear: "left", float: "left", margin: 0 }}
      >
        {!ProjectReadOnly && (
          <div className="action-box">
            <button type="submit">Save</button>
          </div>
        )}
      </div>
    </form>
  );
};

export default Form;
