import React, { useEffect, useState } from "react";
import BackButton from "../../components/Form/BackButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyProfile from "./MyProfile";
import ChangePassword from "./components/ChangePassword";
import Invitation from "./components/Invitation";
import SubscriptionAfterLogin from "./components/SubscriptionAfterLogin";
import Workinprogrees from "../../components/Workinprogrees";

const tabsKey = {
  "my-profile": 1,
  "change-password": 2,
  subscriptions: 3,
  "invitations-from-client": 4,
};

const UserSetting = () => {
  const location = useLocation();
  const role = localStorage.getItem("role");
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "my-profile";
  const defaultActiveKey = tabsKey[selectedTab.replace(/\?.*/, "")];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 4
      ? "invitations-from-client"
      : parseInt(defaultActiveKey) === 3
      ? "subscriptions"
      : parseInt(defaultActiveKey) === 2
      ? "change-password"
      : "my-profile"
  );
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/user-settings?tab=${activeTab}`, {
      replace: true,
    });
  }, [activeTab, navigate]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {activeTab === "my-profile"
                ? "My Profile"
                : activeTab === "change-password"
                ? "Change Password"
                : activeTab === "subscriptions"
                ? "Subscriptions"
                : "Invitations From Client"}
            </h1>
            <div className="content-details">
              {activeTab === "invitations-from-client" && <Workinprogrees />}
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(
                      event?.target?.innerText.replace(/ /g, "-").toLowerCase()
                    );
                  }}
                >
                  <li className={activeTab === "my-profile" ? "active" : ""}>
                    <Link key={1}>My Profile</Link>
                  </li>
                  <li
                    className={activeTab === "change-password" ? "active" : ""}
                  >
                    <Link key={2}>Change Password</Link>
                  </li>
                  {role && role !== "Admin" && (
                    <>
                      {/* <li
                        className={
                          activeTab === "subscriptions" ? "active" : ""
                        }
                      >
                        <Link key={3}>Subscriptions</Link>
                      </li> */}
                      {role !== "Builder" && (
                        <li
                          className={
                            activeTab === "invitations-from-client"
                              ? "active"
                              : ""
                          }
                        >
                          <Link key={4}>Invitations From Client</Link>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "my-profile" && <MyProfile />}
                {activeTab === "change-password" && <ChangePassword />}
                {activeTab === "subscriptions" && <SubscriptionAfterLogin />}
                {activeTab === "invitations-from-client" && <Invitation />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSetting;
