import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sitelogo from "../../components/Header/Logo";
import { Formik } from "formik";
import { ResetPasswordSchema } from "../common/Validation";
import { API, IV, post, SECRETKEY } from "../../config";
import { toast } from "react-toastify";
import Spin from "../common/Spin";
import SlideToggle from "react-slide-toggle";
import { useEffect } from "react";
import wFavicon from "../../assets/images/myna-white-favicon.png";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const emailStr = location.search.split("&").pop();
  const email = emailStr.split("=").pop();
  const [loading, setLoading] = useState(false);
  const [eye, seteye] = useState(true);
  const [eye1, seteye1] = useState(true);
  const [password, setpassword] = useState("password");
  const [password1, setpassword1] = useState("password");

  const userId = localStorage.getItem("userId");
  const EmailVerification = localStorage.getItem("EmailVerification");
  const hasToken = localStorage.getItem("bt-auth-token");
  const clientId = localStorage.getItem("clientId");

  var CryptoJS = require("crypto-js");

  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  // *API Call for Reset Password, Password Show and Hide - Start
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);

      const encPassword = CryptoJS.AES.encrypt(values?.password, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const finalEncryptedPassword = encPassword.ciphertext.toString(
        CryptoJS.enc.Base64
      );

      const encConfirmPassword = CryptoJS.AES.encrypt(
        values?.password_confirmation,
        fkey,
        {
          iv: fiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      const finalEncryptedConfirmPassword =
        encConfirmPassword.ciphertext.toString(CryptoJS.enc.Base64);

      const finalValue = {
        ...values,
        password: finalEncryptedPassword,
        password_confirmation: finalEncryptedConfirmPassword,
      };

      const { data } = await post(API.RESET_PASSWORD, {
        email: email,
        token: query.get("token"),
        ...finalValue,
      });
      if (data.status === 200) {
        setLoading(false);
        toast.success(data.message);
        navigate("/", { replace: true });
      } else {
        toast.error(data.errors?.bt_error[0]);
      }
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  const Eye1 = () => {
    if (password1 === "password") {
      setpassword1("text");
      seteye1(false);
    } else if (password1 === "text") {
      setpassword1("password");
      seteye1(true);
    }
  };

  useEffect(() => {
    userId &&
      EmailVerification &&
      hasToken &&
      // (clientId ? navigate("/businesses") : navigate("/clients"));
      (clientId ? navigate("/businesses") : navigate("/builders"));
  }, [userId, EmailVerification, hasToken, clientId, navigate]);
  // *API Call for Reset Password, Password Show and Hide - End

  return (
    <>
      <div className="login-screen">
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" />{" "}
              BuildTrust acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          <Formik
            initialValues={{
              email: email,
              password: "",
              password_confirmation: "",
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={ResetPasswordSchema}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                  <input
                    placeholder="Email Address"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.email}
                    autoComplete="off"
                    className="form-control"
                    required
                  />
                </div>
                <span className="text-danger">{errors?.email}</span>
                <div
                  className="form-group"
                  style={{ position: "relative", marginTop: "20px" }}
                >
                  <input
                    placeholder="New Password"
                    type={password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    value={values?.password}
                    className="form-control"
                  />
                  <i
                    onClick={Eye}
                    style={{
                      position: "absolute",
                      right: "20px",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      marginTop: "-2px",
                    }}
                    className={`icon ${
                      eye ? "icon-eye-close" : "icon-eye-open"
                    }`}
                  ></i>
                </div>
                <span className="text-danger">{errors?.password}</span>
                <div
                  className="form-group"
                  style={{ position: "relative", marginTop: "20px" }}
                >
                  <input
                    placeholder="Confirm Password"
                    type={password1}
                    name="password_confirmation"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    value={values?.password_confirmation}
                  />
                  <i
                    onClick={Eye1}
                    style={{
                      position: "absolute",
                      right: "20px",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      marginTop: "-2px",
                    }}
                    className={`icon ${
                      eye1 ? "icon-eye-close" : "icon-eye-open"
                    }`}
                  ></i>
                </div>
                <span className="text-danger">
                  {errors?.password_confirmation}
                </span>
                {loading && <Spin />}
                <button
                  type="submit"
                  className="button"
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn-nolink"
                  onClick={() => navigate("/")}
                >
                  Back to Login
                </button>
              </form>
            )}
          </Formik>

          <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a href="https://buildtrust.com.au/contact-us/" rel="noreferrer">
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="/terms-of-use" rel="noreferrer" target="_blank">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a
                href="https://buildtrust.com.au/privacy-policy/"
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <SlideToggle>
            {({ toggle, setCollapsibleElement }) => (
              <div className="my-collapsible">
                <i
                  className="fa fa-info my-collapsible__toggle"
                  onClick={toggle}
                ></i>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                >
                  <div className="my-collapsible__content-inner">
                    <p style={{ fontSize: "12px" }}>
                      <img
                        className="small-white-favicon"
                        src={wFavicon}
                        alt=""
                      />{" "}
                      BuildTrust acknowledges the Traditional Custodians of
                      country throughout Australia and their connections to
                      land, sea and community. We pay our respect to their
                      Elders past and present and extend that respect to all
                      Aboriginal and Torres Strait Islander peoples today.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
