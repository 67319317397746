import {
  faDownload,
  faEnvelope,
  faEye,
  faFilePdf,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "underscore";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  defaultLimitPagination,
  fileUpload,
  get,
  imageDefaultPrefixPath,
  // s3DefaultPath,
} from "../../../config";
import {
  formatABN,
  formatAccountNumber,
  formatBSBNumber,
  formatedAddress,
  formatName,
  formatNumber,
  formatPhone,
  formatSubcontractorName,
} from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import ExpandPayments from "./components/ExpandPayments";
import CalenderView from "../../common/CalenderView";
import { useProjectBar } from "../../common/useProjectBar";

const Payments = () => {
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");

  const [loading, setLoading] = useState(false);
  const [paymentsList, setPaymentsList] = useState([]);

  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search ||  "");

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  // const [id, setId] = useState([]);

  const [showPreview, setShowPreview] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [paymentId, setPaymentId] = useState();

  const [ABALoading, setABALoading] = useState(false);
  const [ABAReceiptData, setABAReceiptData] = useState();
  const [showCalender, setShowCalender] = useState(false);
  const [showUndo, setShowUndo] = useState(false);
  // const [pdfURL, setPdfURL] = useState(null);

  const { ProjectData, ProjectName, ProjectLogo, ProjectReadOnly } =
    useProjectBar();

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const fetchPayments = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${
          API.GET_PAYMENTS
        }?builder_project_id=${projectId}&trust_type=${trust_type}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const paymentsList = data.data.data;
      setPaymentsList(paymentsList);
      // let id = [];
      // data.data.data.map((item) => {
      //   id.push(item.id);
      // });
      // setId(id);
      return data.data;
    } catch (error) {
      setLoading(false);
      setPaymentsList([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField, projectId, trust_type]);

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/payments?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [
    navigate,
    limit,
    searchParam,
    page,
    sortField,
    sortOrder,
    projectId,
    trustType,
  ]);

  const fetchABAReceipt = async (paymentId, action) => {
    if (action === "preview") {
      try {
        setABALoading(true);
        const { data } = await get(
          `${API.PAYMENT_ABA_RECEIPT}/${paymentId}/${action}?trust_type=${trust_type}`
        );
        setABAReceiptData(data?.data);
        setABALoading(false);
      } catch (error) {
        setABALoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    } else {
      try {
        setABALoading(true);
        const { data } = await get(
          `${API.PAYMENT_ABA_RECEIPT}/${paymentId}/${action}`
        );
        data?.message && toast.success(data?.message);
        handleSendEmail();
        setABALoading(false);
      } catch (error) {
        setABALoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleShowPreview = (paymentId) => {
    setShowPreview(!showPreview);
    paymentId ? fetchABAReceipt(paymentId, "preview") : setABAReceiptData();
  };

  const handleSendEmail = (paymentId) => {
    setShowSendEmail(!showSendEmail);
    setPaymentId(paymentId);
  };

  const toggleUndoModal = (paymentId) => {
    setShowUndo(!showUndo);
    if (paymentId) {
      setPaymentId(paymentId);
    } else {
      setPaymentId();
    }
  };

  const handlePaymentUndo = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("module", "payment");
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append(`trust_type`, trust_type);
      formData.append("ids[0]", paymentId);
      const { data } = await fileUpload(
        `${API.JOURNAL_ENTRIES_UNDO}`,
        formData
      );
      setLoading(false);
      toast.success(data?.message);
      fetchPayments();
      toggleUndoModal();
    } catch (error) {
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const paymentsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: paymentsList[i]?.id,
        id: paymentsList[i]?.id,
        transaction_date: paymentsList[i]?.transaction_date
          ? paymentsList[i]?.transaction_date
          : "",
        // name: paymentsList[i]?.contact?.first_name
        //   ? paymentsList[i]?.contact?.account_name ===
        //     formatName(
        //       paymentsList[i]?.contact?.first_name,
        //       paymentsList[i]?.contact?.last_name
        //     )
        //     ? formatName(
        //         paymentsList[i]?.contact?.first_name,
        //         paymentsList[i]?.contact?.last_name
        //       )
        //     : `${formatName(
        //         paymentsList[i]?.contact?.first_name,
        //         paymentsList[i]?.contact?.last_name
        //       )} (${paymentsList[i]?.contact?.account_name})`
        //   : paymentsList[i]?.contact?.business_name
        //   ? paymentsList[i]?.contact?.account_name ===
        //     paymentsList[i]?.contact?.business_name
        //     ? paymentsList[i]?.contact?.business_name
        //     : `${paymentsList[i]?.contact?.business_name} (${paymentsList[i]?.contact?.account_name})`
        //   : paymentsList[i]?.contact?.account_name ===
        //     formatName(
        //       paymentsList[i]?.contact?.first_name,
        //       paymentsList[i]?.contact?.last_name
        //     )
        //   ? formatName(
        //       paymentsList[i]?.contact?.first_name,
        //       paymentsList[i]?.contact?.last_name
        //     )
        //   : `${formatName(
        //       paymentsList[i]?.contact?.first_name,
        //       paymentsList[i]?.contact?.last_name
        //     )} (${paymentsList[i]?.contact?.account_name})`,
        name: formatSubcontractorName(paymentsList[i]?.contact),
        total_amount: paymentsList[i]?.total_amount
          ? "$" + formatNumber(Number(paymentsList[i]?.total_amount))
          : `$0.00`,
        note: paymentsList[i]?.note ? paymentsList[i]?.note : "",
        file: paymentsList[i]?.aba_file?.file
          ? `${paymentsList[i]?.aba_file?.file?.split("/").slice(-1)}`
          : "",
        is_reversed: paymentsList[i]?.is_reversed,
        status: paymentsList[i]?.is_reversed === 1 ? "Reversed" : "-",
        receipt: (
          <>
            {paymentsList[i]?.receipt_file ? (
              <>
                <FontAwesomeIcon
                  icon={faEye}
                  size="1x"
                  onClick={() => {
                    handleShowPreview(paymentsList[i]?.id);
                  }}
                  title="Preview"
                />
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size="1x"
                  onClick={() => {
                    downloadLetter(paymentsList[i]?.receipt_file);
                  }}
                  title="Download Receipt"
                />
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                {paymentsList[i]?.contact?.email !== null ? (
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="1x"
                    onClick={() => {
                      handleSendEmail(paymentsList[i]?.id);
                    }}
                    title="Email"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="1x"
                    disabled
                    // style={{ pointerEvents: "none" }}
                    className="disabled-font-awesome"
                    onClick={() => {
                      handleSendEmail(paymentsList[i]?.id);
                    }}
                    title="Please Enter Email Address of Contractor"
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ),
        aba_file: (
          <>
            {paymentsList[i]?.aba_file?.file ? (
              <>
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  onClick={() => {
                    downloadLetter(paymentsList[i]?.aba_file?.file);
                  }}
                  title="Download ABA File"
                />
                {paymentsList[i]?.is_reversed === 0 && paymentsList[i]?.is_reconciled === 0 && !ProjectReadOnly && (
                  <>
                    <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                    <FontAwesomeIcon
                      icon={faUndo}
                      size="1x"
                      onClick={() => {
                        toggleUndoModal(paymentsList[i]?.id);
                      }}
                      title="Undo"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {paymentsList[i]?.is_reversed === 0 &&  paymentsList[i]?.is_reconciled === 0 && !ProjectReadOnly && (
                  <>
                    <FontAwesomeIcon
                      icon={faUndo}
                      size="1x"
                      onClick={() => {
                        toggleUndoModal(paymentsList[i]?.id);
                      }}
                      title="Undo"
                    />
                  </>
                )}
              </>
            )}
          </>
        ),
      });
    }
    return items;
  };
  const payments = paymentsGenerator(paymentsList?.length);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "transaction_date",
      text: "Transaction Date",
      style: { width: "13%" },
      // sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      style: { width: "15%" },
      // sort: true,
    },
    {
      dataField: "note",
      text: "Note",
      style: { width: "17%" },
      // sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      style: { width: "8%" },
    },
    {
      dataField: "file",
      text: "File",
      style: { width: "15%" },
      // sort: true,
    },
    {
      dataField: "receipt",
      text: "Receipt",
      align: "center",
      style: { width: "10%" },
      headerAlign: "center",
      // sort: true,
    },
    {
      dataField: "aba_file",
      text: "Action",
      align: "center",
      style: { width: "10%" },
      headerAlign: "center",
      // sort: true,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <>
        {" "}
        <ExpandPayments id={row?.id} />{" "}
      </>
    ),
    // onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    // expanded: id,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  const toggleCalenderView = () => {
    setShowCalender(!showCalender);
  };

  const rowClasses = (row) => {
    if (row?.is_reversed) {
      return "reversed_row";
    } else {
      return "";
    }
  };

  return (
    <>
      {" "}
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {trust_type === 1
                ? "Project Trust Payments (Upfront)"
                : "Retention Trust Payments"}

              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) => handleSearchChange(e)}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Payments"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <div className="table-btn" style={{ flexFlow: "nowrap" }}>
                  <Button variant="primary" onClick={toggleCalenderView}>
                    Calendar View
                  </Button>
                  {!ProjectReadOnly && (
                    <Button
                      variant="primary"
                      onClick={() =>
                        projectId &&
                        navigate(
                          `/projects/${projectId}/${trustType}/payments/add`
                        )
                      }
                    >
                      New Payments
                    </Button>
                  )}
                </div>
              </div>
              <div className="custom-table">
                {loading && <Spin />}
                <BootstrapTable
                  keyField="id"
                  remote
                  data={payments}
                  columns={columns}
                  onTableChange={handleTableChange}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCalender && (
        <Modal
          show={showCalender}
          onHide={toggleCalenderView}
          dialogClassName="small-review payment-schedule-calender-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Calender View
          </Modal.Header>
          <Modal.Body>
            <CalenderView projectData={ProjectData} />
          </Modal.Body>
        </Modal>
      )}
      <Modal
        size="lg"
        show={showSendEmail}
        onHide={() => {
          handleSendEmail();
        }}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Send E-mail
        </Modal.Header>
        <Modal.Body>
          {ABALoading && <Spin />}
          <div className="modal-body">
            Are you sure you want to email this receipt to the beneficiary for
            the payment made from PTA?
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                paymentId && fetchABAReceipt(paymentId, "email");
              }}
            >
              Yes
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                handleSendEmail();
              }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showPreview && (
        <Modal
          size="lg"
          show={showPreview}
          onHide={() => {
            handleShowPreview();
          }}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Receipt
          </Modal.Header>
          <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
            <React.Fragment>
              {ABALoading && <Spin />}
              {trust_type === 1 ? (
                <div
                  style={{
                    padding: "15px 15px 15px 15px",
                    height: "90vh",
                    overflow: "auto",
                    fontSize: "14px",
                  }}
                  className="payment-schedule-view"
                >
                  {/* Builder Details */}
                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td style={{ width: "50%" }}></td>
                      <td style={{ width: "50%", textAlign: "right" }}>
                        {ABAReceiptData?.builder_project?.builder?.logo ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              ABAReceiptData?.builder_project?.builder?.logo
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : ABAReceiptData?.builder_project?.builder
                            ?.builder_details?.profile_pic ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.profile_pic
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}

                        {/* <p>
                      <strong>
                        {ABAReceiptData?.builder_project?.builder
                          ?.builder_details
                          ? formatName(
                              ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.first_name,
                              ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.last_name
                            )
                          : "NA"}
                      </strong>
                    </p> */}
                        <p>
                          <strong>
                            {ABAReceiptData?.builder_project?.builder
                              ?.business_name
                              ? ABAReceiptData?.builder_project?.builder
                                  ?.business_name
                              : "NA"}
                          </strong>
                        </p>
                        <p>
                          ABN/ACN:{" "}
                          {ABAReceiptData?.builder_project?.builder?.abn
                            ? formatABN(
                                ABAReceiptData?.builder_project?.builder?.abn
                              )
                            : "NA"}
                        </p>
                        <p>
                          {formatedAddress(
                            ABAReceiptData?.builder_project?.builder?.street,
                            ABAReceiptData?.builder_project?.builder?.suburb
                              ?.name,
                            ABAReceiptData?.builder_project?.builder?.state
                              ?.short_name,
                            ABAReceiptData?.builder_project?.builder
                              ?.postal_code
                          )}
                        </p>
                        <p>
                          Phone:{" "}
                          {ABAReceiptData?.builder_project?.builder
                            ?.builder_details?.phone
                            ? formatPhone(
                                ABAReceiptData?.builder_project?.builder
                                  ?.builder_details?.phone
                              )
                            : "NA"}
                        </p>
                      </td>
                    </tr>
                  </table>
                  {/* --------------- */}
                  <div
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Payment Advice
                  </div>
                  <div
                    style={{
                      textTransform: "uppercase",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                  >
                    Withdrawal from project trust account
                  </div>
                  <p style={{ marginBottom: "10px" }}>
                    This is to advice that funds will be electronically
                    transferred to your account for payments as detailed below.
                  </p>

                  {/* Subcontractor Details */}
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "50px" }}
                  >
                    <tr>
                      <td style={{ width: "75%" }}>
                        <p>
                          <strong>
                            {ABAReceiptData?.contact
                              ? formatName(
                                  ABAReceiptData?.contact?.first_name,
                                  ABAReceiptData?.contact?.last_name
                                )
                              : "NA"}
                          </strong>
                        </p>
                        <p>
                          {ABAReceiptData?.contact?.business_name
                            ? ABAReceiptData?.contact?.business_name
                            : "NA"}
                        </p>
                        <p>
                          ABN/ACN:{" "}
                          {ABAReceiptData?.contact?.abn
                            ? formatABN(ABAReceiptData?.contact?.abn)
                            : "NA"}
                        </p>
                        <p>
                          {formatedAddress(
                            ABAReceiptData?.contact?.street,
                            ABAReceiptData?.contact?.suburb?.name,
                            ABAReceiptData?.contact?.state?.short_name,
                            ABAReceiptData?.contact?.postal_code
                          )}
                        </p>
                        <p>
                          Phone:{" "}
                          {ABAReceiptData?.contact?.phone
                            ? formatPhone(ABAReceiptData?.contact?.phone)
                            : "NA"}
                        </p>
                      </td>
                      <td style={{ width: "25%" }}></td>
                    </tr>
                  </table>
                  {/* ---------------------- */}

                  {/* Payment Details */}
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "50px" }}
                  >
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>Payment Date:</strong>{" "}
                        {ABAReceiptData?.payment?.transaction_date
                          ? ABAReceiptData?.payment?.transaction_date
                          : ""}
                      </td>
                      <td>
                        <strong>Total Amount Paid:</strong>{" "}
                        {ABAReceiptData?.total_paid_amount
                          ? "$" +
                            formatNumber(ABAReceiptData?.total_paid_amount)
                          : "$0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "60%",
                          paddingBottom: "15px",
                        }}
                      >
                        <strong>EFT Reference Number:</strong>{" "}
                        {ABAReceiptData?.eft_reference
                          ? ABAReceiptData?.eft_reference
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>Bank Account Name:</strong>{" "}
                        {ABAReceiptData?.contact?.account_name
                          ? ABAReceiptData?.contact?.account_name
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>BSB:</strong>{" "}
                        {ABAReceiptData?.contact?.bsb
                          ? formatBSBNumber(ABAReceiptData?.contact?.bsb)
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>Account Number:</strong>{" "}
                        {ABAReceiptData?.contact?.account_number
                          ? formatAccountNumber(
                              ABAReceiptData?.contact?.account_number
                            )
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                  </table>

                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "50px", marginTop: "50px" }}
                  >
                    <tr>
                      <td
                        style={{
                          width: "25%",
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        Payment Claim Date
                      </td>

                      <td
                        style={{
                          width: "25%",
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        Reference Number
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        Amount Paid
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        Cash Retention Withheld
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.payment?.transaction_date
                          ? ABAReceiptData?.payment?.transaction_date
                          : ""}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.payment?.payment_data.map((data) => {
                          return (
                            <>
                              {data?.retention_id &&
                                `${data?.payment_detail_id}(P)`}
                            </>
                          );
                        })}
                      </td>

                      <td
                        style={{
                          textAlign: "right",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.paid_amount
                          ? "$" + formatNumber(ABAReceiptData?.paid_amount)
                          : "$0.00"}
                        {/* {ABAReceiptData?.payment?.payment_data.map((data) => {
                      return (
                        <p>
                          {data?.amount
                            ? "$" + formatNumber(data?.amount)
                            : "$0.00"}
                        </p>
                      );
                    })} */}
                        {/* {ABAReceiptData?.payment?.payment_data.map((data) => {
                      return (
                        <>
                          {data?.retention_id
                            ? data?.amount
                              ? `$${formatNumber(data?.amount)}`
                              : "$0.00"
                            : ""}
                        </>
                      );
                    })} */}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.retained_amount
                          ? "$" + formatNumber(ABAReceiptData?.retained_amount)
                          : "$0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      ></td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      >
                        <strong>Total Amount:</strong>
                      </td>

                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      >
                        <strong>
                          {ABAReceiptData?.paid_amount
                            ? "$" + formatNumber(ABAReceiptData?.paid_amount)
                            : "$0.00"}
                        </strong>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      >
                        <strong>
                          {ABAReceiptData?.retained_amount
                            ? "$" +
                              formatNumber(ABAReceiptData?.retained_amount)
                            : "$0.00"}
                        </strong>
                      </td>
                    </tr>
                  </table>

                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td width="60%;">
                        <strong>Payment Date:</strong>{" "}
                        {ABAReceiptData?.payment?.transaction_date
                          ? ABAReceiptData?.payment?.transaction_date
                          : ""}
                        <br />
                        <span>
                          <strong>EFT Reference Number:</strong>{" "}
                          {ABAReceiptData?.eft_reference
                            ? ABAReceiptData?.eft_reference
                            : ""}
                        </span>
                        <br />
                        <br />
                        <strong>Financial Institute:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.bank
                            ? ABAReceiptData?.builder_project?.rta_project
                                ?.rta_contractor?.bank?.abbreviation
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor
                              ?.bank
                          ? ABAReceiptData?.builder_project?.rta_contractor
                              ?.bank?.abbreviation
                          : "NA"}
                        <br />
                        <strong>Bank Account Name:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.account_name
                            ? ABAReceiptData?.builder_project?.rta_project
                                ?.rta_contractor?.account_name
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor
                              ?.account_name
                          ? ABAReceiptData?.builder_project?.rta_contractor
                              ?.account_name
                          : "NA"}
                        <br />
                        <strong>BSB:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.bsb
                            ? formatBSBNumber(
                                ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.bsb
                              )
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor?.bsb
                          ? formatBSBNumber(
                              ABAReceiptData?.builder_project?.rta_contractor
                                ?.bsb
                            )
                          : "NA"}
                        <br />
                        <strong>Account Number:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.account_number
                            ? formatAccountNumber(
                                ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.account_number
                              )
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor
                              ?.account_number
                          ? formatAccountNumber(
                              ABAReceiptData?.builder_project?.rta_contractor
                                ?.account_number
                            )
                          : "NA"}
                      </td>
                      <td width="40%">
                        <strong>Total Retentions Deposited:</strong>{" "}
                        {ABAReceiptData?.retained_amount
                          ? "$" + formatNumber(ABAReceiptData?.retained_amount)
                          : "$0.00"}
                        <br />
                        <br />
                        <strong>
                          Total retentions withheld
                          <br />
                          for the beneficiary for the
                          <br />
                          contract after the deposit:
                        </strong>{" "}
                        {ABAReceiptData?.closing_balance_rta
                          ? "$" +
                            formatNumber(ABAReceiptData?.closing_balance_rta)
                          : "$0.00"}
                        {/* {"$" +
                      formatNumber(
                        ABAReceiptData?.total_retained_amount +
                          ABAReceiptData?.retained_amount
                      )} */}
                      </td>
                    </tr>
                  </table>
                  {/* --------------- */}
                </div>
              ) : (
                <>
                  <div
                    className="payment-schedule-view"
                    style={{ height: "90vh", overflow: "auto" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        // margin: "50px 30px",
                        margin: "15px",
                      }}
                    >
                      <table cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td style={{ width: "50%" }}></td>
                          <td style={{ width: "50%", textAlign: "right" }}>
                            {ABAReceiptData?.builder_project?.builder?.logo ? (
                              <img
                                src={
                                  imageDefaultPrefixPath +
                                  ABAReceiptData?.builder_project?.builder?.logo
                                }
                                style={{ height: "100px", width: "auto" }}
                                alt=""
                              />
                            ) : ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.profile_pic ? (
                              <img
                                src={
                                  imageDefaultPrefixPath +
                                  ABAReceiptData?.builder_project?.builder
                                    ?.builder_details?.profile_pic
                                }
                                style={{ height: "100px", width: "auto" }}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                            {/* <p>
                        <strong>
                          {record?.builder_project?.builder?.builder_details
                            ? formatName(
                                record?.builder_project?.builder
                                  ?.builder_details?.first_name,
                                record?.builder_project?.builder
                                  ?.builder_details?.last_name
                              )
                            : "NA"}
                        </strong>
                      </p> */}
                            <p>
                              <strong>
                                {ABAReceiptData?.builder_project?.builder
                                  ?.business_name
                                  ? ABAReceiptData?.builder_project?.builder
                                      ?.business_name
                                  : "-"}
                              </strong>
                            </p>
                            <p>
                              ABN/ACN:{" "}
                              {ABAReceiptData?.builder_project?.builder?.abn
                                ? formatABN(
                                    ABAReceiptData?.builder_project?.builder
                                      ?.abn
                                  )
                                : "-"}
                            </p>
                            <p>
                              {formatedAddress(
                                ABAReceiptData?.builder_project?.builder
                                  ?.street,
                                ABAReceiptData?.builder_project?.builder?.suburb
                                  ?.name,
                                ABAReceiptData?.builder_project?.builder?.state
                                  ?.short_name,
                                ABAReceiptData?.builder_project?.builder
                                  ?.postal_code
                              )}
                            </p>
                            <p>
                              Phone:{" "}
                              {ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.phone
                                ? formatPhone(
                                    ABAReceiptData?.builder_project?.builder
                                      ?.builder_details?.phone
                                  )
                                : "-"}
                            </p>
                          </td>
                        </tr>
                      </table>

                      <div
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                      >
                        Payment Advice
                      </div>
                      <div
                        style={{
                          textTransform: "uppercase",
                          marginTop: "10px",
                          marginBottom: "10px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Payment to advice beneficiary from retention trust
                        account
                      </div>

                      <p style={{ marginBottom: "10px" }}>
                        This is to advise that funds will be electronically
                        transferred into the retention trust account for
                        payments as detailed below.
                      </p>

                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style={{ marginBottom: "50px" }}
                      >
                        <tr>
                          <td style={{ width: "75%" }}>
                            <p>
                              <strong>
                                {" "}
                                {ABAReceiptData?.contact
                                  ? formatName(
                                      ABAReceiptData?.contact?.first_name,
                                      ABAReceiptData?.contact?.last_name
                                    )
                                  : "-"}
                              </strong>
                            </p>
                            <p>
                              {ABAReceiptData?.contact?.business_name
                                ? ABAReceiptData?.contact?.business_name
                                : ""}
                            </p>
                            <p>
                              ABN/ACN:{" "}
                              {ABAReceiptData?.contact?.abn
                                ? formatABN(ABAReceiptData?.contact?.abn)
                                : "-"}
                            </p>
                            <p>
                              {formatedAddress(
                                ABAReceiptData?.contact?.street,
                                ABAReceiptData?.contact?.suburb?.name,
                                ABAReceiptData?.contact?.state?.short_name,
                                ABAReceiptData?.contact?.postal_code
                              )}
                            </p>
                            <p>
                              Phone:{" "}
                              {ABAReceiptData?.contact?.phone
                                ? formatPhone(ABAReceiptData?.contact?.phone)
                                : "-"}
                            </p>
                          </td>
                          <td style={{ width: "25%" }}></td>
                        </tr>
                      </table>
                      <table cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td width="60%;">
                            {/* @if( !empty($project->rta_project_id) )
                              @php
                                  $bank_name = $project->rtaProject->rtaContractor->bank->abbreviation ?? '';
                                  $account_name = $project->rtaProject->rtaContractor->account_name ?? '';
                                  $bsb = $project->rtaProject->rtaContractor->bsb ?? '';
                                  $account_number = $project->rtaProject->rtaContractor->account_number ?? '';
                              @endphp
                          @else
                              @php
                                  $bank_name = $project->rtaContractor->bank->abbreviation ?? '';
                                  $account_name = $project->rtaContractor->account_name ?? '';
                                  $bsb = $project->rtaContractor->bsb ?? '';
                                  $account_number = $project->rtaContractor->account_number ?? '';
                              @endphp
                          @endif */}
                            <strong>Payment Date:</strong>{" "}
                            {ABAReceiptData?.payment?.transaction_date
                              ? ABAReceiptData?.payment?.transaction_date
                                  ?.split("-")
                                  ?.join("/")
                              : ""}
                            <br />
                            <span>
                              <strong>EFT Reference Number:</strong>{" "}
                              {ABAReceiptData?.eft_reference
                                ? ABAReceiptData?.eft_reference
                                : ""}
                            </span>
                            <br />
                            <br />
                            <strong>Financial Institute:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.bank?.abbreviation
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.bank?.abbreviation}
                            <br />
                            <strong>Bank Account Name:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.account_name
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.account_name}
                            <br />
                            <strong>BSB:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.bsb
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.bsb}
                            <br />
                            <strong>Account Number:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.account_number
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.account_number}
                          </td>
                          <td width="40%">
                            <strong>Total Retentions Released:</strong>{" "}
                            {"$" +
                              formatNumber(ABAReceiptData?.total_paid_amount)}
                            <br />
                            <br />
                            <strong>
                              Total retentions withheld
                              <br />
                              for the beneficiary for the
                              <br />
                              contract after the deposit:
                            </strong>{" "}
                            $
                            {ABAReceiptData?.closing_balance_rta
                              ? formatNumber(
                                  Number(ABAReceiptData?.closing_balance_rta)
                                )
                              : "0.00"}
                          </td>
                        </tr>
                      </table>
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style={{ marginTop: "50px" }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "25%",
                              fontWeight: "bold",
                              borderBottom: "#CCC 1px solid",
                              background: "#F0F0F0",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          >
                            Payment Claim Date
                          </td>
                          <td
                            style={{
                              width: "25%",
                              fontWeight: "bold",
                              borderBottom: "#CCC 1px solid",
                              background: "#F0F0F0",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                              textAlign: "right",
                            }}
                          >
                            Reference Number
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              borderBottom: "#CCC 1px solid",
                              background: "#F0F0F0",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                              textAlign: "right",
                            }}
                          >
                            Cash Retention Released
                          </td>
                        </tr>
                        {ABAReceiptData?.payment?.payment_data.map((item) => (
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                borderBottom: "#CCC 1px solid",
                                padding: "10px",
                              }}
                            >
                              {ABAReceiptData?.payment?.transaction_date
                                ? ABAReceiptData?.payment?.transaction_date
                                    ?.split("-")
                                    ?.join("/")
                                : "-"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderBottom: "#CCC 1px solid",
                                padding: "10px",
                              }}
                            >
                              {`${item?.payment_detail_id}(P)`}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderBottom: "#CCC 1px solid",
                                padding: "10px",
                              }}
                            >
                              $
                              {item?.amount
                                ? formatNumber(Number(item?.amount))
                                : "0.00"}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: "right",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          >
                            <strong>Total Amount:</strong>
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          >
                            <strong>
                              $
                              {ABAReceiptData?.total_paid_amount
                                ? formatNumber(
                                    Number(ABAReceiptData?.total_paid_amount)
                                  )
                                : "0.00"}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          </Modal.Body>
        </Modal>
      )}
      {showUndo && (
        <Modal
          size="lg"
          show={showUndo}
          onHide={toggleUndoModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Undo Payment
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Are your sure you want to undo this payment?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleUndoModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handlePaymentUndo();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Payments;
