/* eslint-disable react-hooks/exhaustive-deps */
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  defaultLimitPagination,
  get,
  imageDefaultPrefixPath,
} from "../../../config";
import { MultiSelect } from "primereact/multiselect";
// import ContractorSelectWithTA from "../../common/ContractorSelectWithTA";
import {
  ExGSTClaculate,
  formatName,
  formatNumber,
  formatSubcontractorName,
} from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import ExpandJournalEntry from "./ExpandJournalEntry";
import { useProjectBar } from "../../common/useProjectBar";
import { debounce } from "lodash";

const JournalEntries = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const search = query.get("search");
  const [lastBatchId, setLastBatchId] = useState();
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;

  const projectId = params?.project_id;

  const [projectLoading, setLoading] = useState(false);
  const [journalEntryList, setJournalEntryList] = useState(null);
  const [journalEntryData, setJournalEntryData] = useState(null);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || '');
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState("transaction_date");
  const [sortOrder, setSortOrder] = useState("DESC");

  const [selectedContacts, setSelectedContacts] = useState(null);
  const [selectedMainContacts, setSelectedMainContacts] = useState([]);
  const [contactListLoading, setContactListLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);

  const [expandedRow, setExpandedRow] = useState([]);

  // const [contractorDetails, setContractorDetails] = useState([]);

  const [show, setShow] = useState(false);
  const [detailsData, setDetailsData] = useState({});

  const { ProjectName, ProjectLogo, ProjectReadOnly } = useProjectBar();

  // const fetchJournalEntryData = useCallback(async (id) => {
  //   if (id) {
  //     try {
  //       const { data } = await get(`${API.JOURNAL_ENTRIES}/${id}`);
  //       setJournalEntryData(data?.data?.journal_entry_details);
  //     } catch (error) {
  //       if (error?.response?.data?.errors) {
  //         Object.keys(error?.response?.data?.errors).forEach((key) => {
  //           toast.error(error?.response?.data?.errors[key][0]);
  //         });
  //       } else {
  //         toast.error(error?.message);
  //       }
  //     }
  //   }
  // }, []);

  const handleContactList = useCallback(async () => {
    try {
      setContactListLoading(true);
      get(
        `${API.GET_CONTRACTORS}?builder_project_id=${
          projectId ? projectId : ""
        }&with_pta=1&limit=-1&sort_column=name&sort_order=ASC`
      ).then((response) => {
        const list =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.is_pta === 0
                  ? `${formatName(item?.first_name, item?.last_name)} (RTA)`
                  : item?.is_pta === 1
                  ? `${formatName(item?.first_name, item?.last_name)} (PTA)`
                  : item?.is_pta === 2
                  ? `${formatName(
                      item?.first_name,
                      item?.last_name
                    )} (Withdrawal)`
                  : item?.is_pta === 3
                  ? `${formatName(
                      item?.first_name,
                      item?.last_name
                    )} (Principal)`
                  : item?.is_pta === 4
                  ? `${formatName(item?.first_name, item?.last_name)} (Trustee)`
                  : formatName(item?.first_name, item?.last_name),
              value: item?.id,
            };
            return list;
          });
        setContactOptions(list);
        setContactListLoading(false);
      });
    } catch (e) {
      setContactListLoading(false);
      toast.error(e?.data?.message);
    }
  }, [projectId]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const fetchJournalEntries = useCallback(async () => {
    // const contacts =
    //   contractorDetails && contractorDetails?.length > 0
    //     ? contractorDetails?.map((item) => {
    //         return item?.id;
    //       })
    //     : [];

    // const allContacts = contacts.join(",") ? contacts.join(",") : "";

    try {
      setLoading(true);
      const { data } = await get(
        `${API.JOURNAL_ENTRIES}?builder_project_id=${projectId}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&contacts=${selectedMainContacts}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&trust_type=${trust_type}`
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const journalEntryList = data.data.data;
      let expanded = [];
      for (let i = 0; i < journalEntryList?.length; i++) {
        expanded.push(journalEntryList[i]?.id);
      }
      setExpandedRow(expanded);
      setJournalEntryList(journalEntryList);
      setLastBatchId(data?.last_batch_id + 1);
      return data.data;
    } catch (error) {
      setLoading(false);
      setJournalEntryList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [
    limit,
    page,
    projectId,
    sortField,
    sortOrder,
    trust_type,
    selectedMainContacts,
    searchParam
  ]);

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/journal-entries?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, page, sortField, sortOrder, projectId, trustType]);

  const toggleShowModal = (data) => {
    setShow(true);
    setDetailsData(data);
  };

  const toggleCloseModal = () => {
    setShow(false);
  };

  const journalEntryGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: journalEntryList[i]?.id,
        transaction_date: journalEntryList[i]?.transaction_date
          ? journalEntryList[i]?.transaction_date
          : "",
        period: journalEntryList[i]?.period ? journalEntryList[i]?.period : "-",
        batch_id: journalEntryList[i]?.batch_id
          ? journalEntryList[i]?.batch_id
          : "-",
        reference_number: journalEntryList[i]?.reference_number
          ? journalEntryList[i]?.reference_number
          : "-",
        // source: String(
        //   journalEntryList[i]?.journal_entry_details[0]
        //     ?.transaction_reference_number
        // ).includes("(R)")
        //   ? "Receipt"
        //   : String(
        //       journalEntryList[i]?.journal_entry_details[0]
        //         ?.transaction_reference_number
        //     ).includes("(P)")
        //   ? "Payments"
        //   : String(
        //       journalEntryList[i]?.journal_entry_details[0]
        //         ?.transaction_reference_number
        //     ).includes("(PS)")
        //   ? "Payment Schedule"
        //   : String(
        //       journalEntryList[i]?.journal_entry_details[0]
        //         ?.transaction_reference_number
        //     ).includes("(JE)")
        //   ? "Journal Entries"
        //   : "-",
        source: journalEntryList[i]?.receipt_id
          ? "Receipt"
          : String(
              journalEntryList[i]?.journal_entry_details[0]
                ?.transaction_reference_number
            ).includes("(PS)")
          ? "Payment Schedule"
          : journalEntryList[i]?.payment_id
          ? "Payments"
          : String(
              journalEntryList[i]?.journal_entry_details[0]
                ?.transaction_reference_number
            ).includes("(JE)")
          ? "Journal Entries"
          : "-",
        amount: journalEntryList[i]?.total_amount
          ? `$${formatNumber(journalEntryList[i]?.total_amount)}`
          : "-",
        gst_amount: journalEntryList[i]?.gst_amount
          ? `$${formatNumber(journalEntryList[i]?.gst_amount)}`
          : "-",
        net_amount: journalEntryList[i]?.net_amount
          ? `$${formatNumber(journalEntryList[i]?.net_amount)}`
          : "-",
        journal_entry_details: journalEntryList[i]?.journal_entry_details,
        action: (
          <>
            <FontAwesomeIcon
              onClick={() => {
                toggleShowModal(journalEntryList[i]);
                // fetchJournalEntryData(journalEntryList[i].id);
                setJournalEntryData(journalEntryList[i]?.journal_entry_details);
              }}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#businessDetail"
            />
            {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <Link to={`/projects/${projectId}/journal-entries/edit?id=${journalEntryList[i].id}`}>
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Link> */}
          </>
        ),
      });
    }
    return items;
  };
  const journalEntries = journalEntryGenerator(journalEntryList?.length);

  useEffect(() => {
    fetchJournalEntries();
  }, [fetchJournalEntries]);

  // useEffect(() => {
  //   fetchJournalEntryData();
  // }, [fetchJournalEntryData]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "transaction_date",
      text: "Transaction Date",
      sort: true,
      headerStyle: () => {
        return { width: "17%" };
      },
    },
    // {
    //   dataField: 'period',
    //   text: 'Period',
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: '8%' };
    //   }
    // },
    {
      dataField: "batch_id",
      text: "Batch Id ",
      sort: true,
      headerStyle: () => {
        return { width: "17%" };
      },
    },
    {
      dataField: "reference_number",
      text: "Reference/ID",
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "source",
      text: "Source",
      headerStyle: () => {
        return { width: "12%" };
      },
    },
    {
      dataField: "amount",
      text: "Total Amount",
      headerStyle: () => {
        return { width: "22%" };
      },
    },

    // {
    //   dataField: 'gst_amount',
    //   text: 'GST Amount',
    //   headerStyle: () => {
    //     return { width: '15%' };
    //   }
    // },
    // {
    //   dataField: 'net_amount',
    //   text: 'Net Amount',
    //   headerStyle: () => {
    //     return { width: '15%' };
    //   }
    // },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerStyle: {
        textAlign: "center",
      },
      // style: { width: '8%' }
    },
  ];

  // const handleContractorSelect = (contractor) => {
  //   setContractorDetails(contractor);
  // };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandJournalEntry
          id={row?.key}
          projectId={projectId}
          expandJournalData={row?.journal_entry_details}
        />
      </>
    ),
    expanded: expandedRow,
    onlyOneExpanding: false,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: [
      "Revenues",
      "totalRevenue",
      "Expenses",
      "totalExpense",
      "totalData",
      "blank-row",
    ],
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      if (
        rowKey === "Revenues" ||
        rowKey === "totalRevenue" ||
        rowKey === "Expenses" ||
        rowKey === "totalExpense" ||
        rowKey === "totalData" ||
        rowKey === "blank-row"
      ) {
        return <div className="not-expaned-cell-pl-report" />;
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const viewJournalDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: journalEntryData[i]?.id,
        transaction_date: detailsData?.transaction_date
          ? detailsData?.transaction_date
          : "",
        account: journalEntryData[i]?.chart_of_account
          ? (journalEntryData[i]?.chart_of_account?.code?.length < 4
              ? padWithLeadingZeros(
                  Number(journalEntryData[i]?.chart_of_account?.code),
                  4
                )
              : journalEntryData[i]?.chart_of_account?.code.toString()) +
            (journalEntryData[i]?.chart_of_account?.sub_code
              ? "." + journalEntryData[i]?.chart_of_account?.sub_code.toString()
              : "") +
            " - " +
            journalEntryData[i]?.chart_of_account?.name
          : "",
        // contact: journalEntryData[i]?.contact
        //   ? formatName(
        //       journalEntryData[i]?.contact?.first_name,
        //       journalEntryData[i]?.contact?.last_name
        //     ) +
        //     `${
        //       journalEntryData[i]?.contact?.is_pta === 0
        //         ? "(RTA)"
        //         : journalEntryData[i]?.contact?.is_pta === 1
        //         ? "(PTA)"
        //         : journalEntryData[i]?.contact?.is_pta === 2
        //         ? "(Withdrawal)"
        //         : journalEntryData[i]?.contact?.is_pta === 3
        //         ? "(Principal)"
        //         : journalEntryData[i]?.contact?.is_pta === 4
        //         ? "(Trustee)"
        //         : ""
        //     }`
        //   : "-",
        contact: journalEntryData[i]?.contact
          ? formatSubcontractorName(journalEntryData[i]?.contact) +
            `${
              journalEntryData[i]?.contact?.is_pta === 0
                ? " - RTA"
                : journalEntryData[i]?.contact?.is_pta === 1
                ? " - PTA"
                : journalEntryData[i]?.contact?.is_pta === 2
                ? " - Withdrawal"
                : journalEntryData[i]?.contact?.is_pta === 3
                ? " - Principal"
                : journalEntryData[i]?.contact?.is_pta === 4
                ? " - Trustee"
                : ""
            }`
          : "-",
        debit:
          journalEntryData[i]?.transaction_type === 0
            ? journalEntryData[i]?.amount
              ? "$" + formatNumber(Number(journalEntryData[i]?.amount))
              : "$0.00"
            : "-",
        credit:
          journalEntryData[i]?.transaction_type === 1
            ? journalEntryData[i]?.amount
              ? "$" + formatNumber(Number(journalEntryData[i]?.amount))
              : "$0.00"
            : "-",
        gst:
          journalEntryData[i]?.gst === 0
            ? "GST Excluded"
            : journalEntryData[i]?.gst === 1
            ? "GST 10%"
            : journalEntryData[i]?.gst === 2
            ? "BAS Excluded"
            : "GST on Imports",
        tax: journalEntryData[i]?.gst_amount
          ? "$" + formatNumber(Number(journalEntryData[i]?.gst_amount))
          : "-",
        ex_gst_amount: journalEntryData[i]?.amount
          ? "$" +
            ExGSTClaculate(
              journalEntryData[i]?.amount,
              journalEntryData[i]?.gst
            )
          : 0,
        description: journalEntryData[i]?.description
          ? journalEntryData[i]?.description
          : "-",
      });
    }
    return items;
  };
  const viewJournalData = viewJournalDataGenerator(journalEntryData?.length);

  const viewColumns = [
    // {
    //   dataField: 'transaction_date',
    //   text: 'Transaction Date',
    //   headerStyle: () => {
    //     return { width: '15%' };
    //   },
    // },
    {
      dataField: "contact",
      text: "Contact ",
      style: { width: "18%" },
    },
    {
      dataField: "account",
      text: "Account",
      style: { width: "24%" },
    },
    // {
    //   dataField: 'gst',
    //   text: 'GST',
    //   style: { width: '10%' }
    // },
    {
      dataField: "debit",
      text: "Debit",
      style: { width: "15%" },
    },
    {
      dataField: "credit",
      text: "Credit",
      style: { width: "15%" },
    },
    // {
    //   dataField: 'tax',
    //   text: 'Tax',
    //   style: { width: '11%' }
    // },
    // {
    //   dataField: 'ex_gst_amount',
    //   text: 'Ex. GST Amount',
    //   style: { width: '11%' }
    // },
    {
      dataField: "description",
      text: "Description",
    },
  ];

  // const [totalDeference, setTotalDeference] = useState(0);
  // const [totalDebit, setTotalDebit] = useState(0);
  // const [totalCredit, setTotalCredit] = useState(0);

  // useEffect(() => {
  //   let debitTotal = 0;
  //   let creditTotal = 0;
  //   journalEntryData &&
  //     journalEntryData?.length > 0 &&
  //     journalEntryData?.map((item) => {
  //       debitTotal += item?.transaction_type === 0 ? (item?.amount ? Number(item?.amount) : 0) : 0;
  //       creditTotal += item?.transaction_type === 1 ? (item?.amount ? Number(item?.amount) : 0) : 0;
  //       return 0;
  //     });
  //   setTotalDebit(formatNumber(Number(debitTotal)));
  //   setTotalCredit(formatNumber(Number(creditTotal)));
  //   let deference = debitTotal - creditTotal;
  //   setTotalDeference(formatNumber(Number(deference)));
  // }, [journalEntryData]);
  const filterContactInputRef = useRef();

  const handleContactChange = (e) => {
    setSelectedContacts(e.value);
  };

  const handleApplyFilter = () => {
    setSelectedMainContacts(selectedContacts);
    filterContactInputRef.current.hide();
  };

  const myContactResetFunction = () => {
    setSelectedContacts([]);
    setSelectedMainContacts([]);
    filterContactInputRef && filterContactInputRef.current.focus();
  };
  
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  return (
    <>
      <div className="cms-page journal-entry-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Journal Entries For {trust_type === 0 ? "RTA" : "PTA"}
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                {/* <div className="search-section">
                  <ContractorSelectWithTA
                    regionName={"0"}
                    projectId={projectId}
                    value={contractorDetails ? contractorDetails : null}
                    onChange={(contractor) => {
                      handleContractorSelect(contractor);
                    }}
                    className="contactname-select"
                    placeholder="Select Subcontractor"
                    isClearable={true}
                    isMulti={true}
                  />
                </div> */}
                <div className="search-section journal-entry-search-box">
                  <Form>
                    <FormControl
                      onChange={(e) => handleSearchChange(e)}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Journal Entries"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <MultiSelect
                  value={selectedContacts}
                  onChange={(e) => {
                    handleContactChange(e);
                  }}
                  options={contactOptions}
                  optionLabel="label"
                  filter
                  placeholder="Select Contact"
                  maxSelectedLabels={0}
                  selectedItemsLabel={`${selectedContacts?.length} Contact(s) Selected`}
                  onHide={() => {
                    setSelectedMainContacts(selectedContacts);
                  }}
                  style={{
                    fontSize: "13px",
                  }}
                  ref={filterContactInputRef}
                  panelFooterTemplate={
                    <>
                      <Button
                        className="multiSelect-button"
                        onClick={() => {
                          handleApplyFilter();
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        className="multiSelect-button"
                        onClick={() => {
                          myContactResetFunction();
                        }}
                      >
                        Reset
                      </Button>
                    </>
                  }
                />
                {!ProjectReadOnly && (
                  <div className="table-btn">
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(
                          `/projects/${projectId}/${trustType}/journal-entries/add`,
                          {
                            state: { lastBatchId: lastBatchId },
                          }
                        );
                      }}
                    >
                      Add New
                    </Button>
                  </div>
                )}
              </div>
              <div className="custom-table journal-main-table">
                {(projectLoading || contactListLoading) && <Spin />}

                <BootstrapTable
                  keyField="key"
                  remote
                  data={journalEntries || []}
                  columns={columns}
                  expandRow={expandRow}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        size="lg"
        show={show}
        onHide={toggleCloseModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header closeButton>Journal Entry Details</Modal.Header>
        <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
          <>
            <div
              style={{ padding: "15px", maxHeight: "90vh", overflow: "auto" }}
            >
              <p>
                <strong>Transaction Date:</strong>{" "}
                {detailsData?.transaction_date
                  ? detailsData?.transaction_date
                  : "-"}
              </p>
              {journalEntryData &&
                journalEntryData?.data?.map((item, index) => {
                  return (
                    <div className="modal-content card">
                      <div className="modal-content-details">
                        <p>
                          <strong>Account:</strong>{" "}
                          {item?.chart_of_account
                            ? item?.chart_of_account?.name +
                              (item?.chart_of_account?.account_type?.name
                                ? " (" +
                                  item?.chart_of_account?.account_type?.name +
                                  ")"
                                : "") +
                              (item?.chart_of_account?.code
                                ? " - " +
                                  item?.chart_of_account?.code.toString()
                                : "") +
                              (item?.chart_of_account?.sub_code
                                ? "." +
                                  item?.chart_of_account?.sub_code.toString()
                                : "")
                            : "-"}
                        </p>
                        <p>
                          <strong>Contact:</strong>{" "}
                          {item?.contact
                            ? formatName(
                                item?.contact?.first_name,
                                item?.contact?.last_name
                              )
                            : ""}
                        </p>
                        <p>
                          <strong>Description:</strong>{" "}
                          {item?.description ? item?.description : "-"}
                        </p>
                        {item?.transaction_type === 0 ? (
                          <p>
                            <strong>Debit:</strong>{" "}
                            {item?.amount
                              ? "$" + formatNumber(Number(item?.amount))
                              : "$0.00"}
                          </p>
                        ) : (
                          <p>
                            <strong>Credit:</strong>{" "}
                            {item?.amount
                              ? "$" + formatNumber(Number(item?.amount))
                              : "$0.00"}
                          </p>
                        )}
                        <p>
                          <strong>GST:</strong>{" "}
                          {item?.gst_type === 1 ? "10% GST" : "No GST"}
                        </p>
                        <p>
                          <strong>Tax:</strong>{" "}
                          {item?.gst_amount
                            ? "$" + formatNumber(Number(item?.gst_amount))
                            : "-"}
                        </p>
                        <p>
                          <strong>Ex. GST Amount:</strong>{" "}
                          {item?.amount
                            ? "$" + ExGSTClaculate(item?.amount, item?.gst_type)
                            : "-"}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        </Modal.Body>
      </Modal> */}
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={toggleCloseModal}
          dialogClassName="larg-popup journal-modal review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header closeButton>Journal Entry Details</Modal.Header>
          <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
            <>
              <div>
                {/* <div className="modal-content header"> */}
                {/* <div className="journal-entry-status" style={{ justifyContent: 'space-between' }}>
                <div className="journal-header-modal-content">
                  <p>
                    <span>Batch ID:</span> {detailsData?.batch_id ? detailsData?.batch_id : '-'}
                  </p>
                  <p>
                    <span>Reference No:</span>{' '}
                    {detailsData?.reference_number ? detailsData?.reference_number : '-'}
                  </p>
                  <p>
                    <span>Date:</span>{' '}
                    {detailsData?.transaction_date ? detailsData?.transaction_date : '-'}
                  </p>
                  <p>
                    <span>Period:</span> {detailsData?.period ? detailsData?.period : '-'}
                  </p>
                </div>
                <div className="journal-entry">
                  <p>
                    Total Debit
                    <i>{totalDebit ? totalDebit : 0.0}</i>
                  </p>
                  <p>
                    Total Credit<i>{totalCredit}</i>
                  </p>
                  <p>
                    Difference<i>{totalDeference}</i>
                  </p>
                </div>
              </div> */}
                <BootstrapTable
                  keyField="key"
                  data={viewJournalData}
                  columns={viewColumns}
                  noDataIndication="No Data Found"
                />
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default JournalEntries;
