import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import CancelButton from "../../../../components/Form/CancleButton";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import SuburbSelect from "../../../common/SuburbSelect";
import { entityTypes, imageDefaultPrefixPath } from "../../../../config";
import ImageCropModal from "../../../common/ImageCropModal";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  stateListing,
  stateLoading,
  builderID,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
  };

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const clientId = query.get("id");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    isChecked === true && setFieldValue("shipping_street", values?.street);
    isChecked === true && setFieldValue("shipping_state_id", values?.state_id);
    isChecked === true &&
      setFieldValue("shipping_suburb_id", values?.suburb_id);
    isChecked === true &&
      setFieldValue("shipping_postal_code", values?.postal_code);
  }, [
    isChecked,
    setFieldValue,
    values?.postal_code,
    values?.street,
    values?.state_id,
    values?.suburb_id,
  ]);

  // const imageInputRef = React.useRef();
  const profilePic = values?.profile_pic ? values?.profile_pic : "";

  const [selectedFile, setSelectedFile] = useState(profilePic);

  // const [imageType, setImageType] = useState();
  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    values?.profile_pic &&
      typeof values?.profile_pic === "string" &&
      setSelectedFile(values?.profile_pic);
  }, [values?.profile_pic]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  // const handleRemoveIcon = () => {
  //   setSelectedFile("");
  //   imageInputRef.current.value = "";
  // };

  const imageInputRef1 = React.useRef();
  const logo = values?.logo ? values?.logo : "";

  const [selectedFile1, setSelectedFile1] = useState(logo);
  const [imageType1, setImageType1] = useState();
  const [showModal1, setShowModal1] = useState(false);

  useEffect(() => {
    values?.logo &&
      typeof values?.logo === "string" &&
      setSelectedFile1(values?.logo);
  }, [values?.logo]);

  useEffect(() => {
    setSelectedFile1(selectedFile1);
  }, [selectedFile1]);

  const handleRemoveIcon1 = () => {
    setSelectedFile1("");
    imageInputRef1.current.value = "";
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card">
        <h3>Personal Details</h3>
        <div
          className="builder-firm-card"
          style={builderID ? { paddingTop: "20px" } : {}}
        >
          <div
            className="form-box builder-profile"
            style={builderID ? { padding: "0px 30px 30px 30px" } : {}}
          >
            {/* <div
              className="form-group half-width profile-photo logo"
              style={{ display: "block", textAlign: "center" }}
            >
              <input
                accept="image/*"
                type="file"
                name="profile_pic"
                className="form-control"
                id="file"
                ref={imageInputRef}
                onChange={(e) => {
                  e.currentTarget.files[0].type &&
                    setImageType(e.currentTarget.files[0].type);
                  e.currentTarget.files[0] && setShowModal(true);
                  setSelectedFile(
                    URL.createObjectURL(e.currentTarget.files[0])
                  );
                }}
                onBlur={handleBlur}
              />
              {selectedFile &&
                (selectedFile !== `${imageDefaultPrefixPath}null` ||
                  selectedFile !== `${imageDefaultPrefixPath}undefined` ||
                  selectedFile !== `${imageDefaultPrefixPath}`) && (
                  <div className="previewImage d-flex">
                    <img
                      className="display-image"
                      src={selectedFile}
                      alt="selectedFile"
                    />
                    <span
                      className="remove-image"
                      onClick={() => {
                        handleRemoveIcon(setFieldValue);
                        setFieldValue("profile_pic", null);
                      }}
                    >
                      Remove Profile
                      <i className="fa fa-times" aria-hidden="true" />
                    </span>
                  </div>
                )}
              {!selectedFile && (
                <span className="guide_leb">Add your Profile</span>
              )}
            </div> */}
            <div
              className="form-group half-width profile-photo logo"
              style={{ display: "block", textAlign: "center" }}
            >
              <input
                accept="image/*"
                type="file"
                name="logo"
                className="form-control"
                id="file"
                ref={imageInputRef1}
                onChange={(e) => {
                  e.currentTarget.files[0].type &&
                    setImageType1(e.currentTarget.files[0].type);
                  e.currentTarget.files[0] && setShowModal1(true);
                  setSelectedFile1(
                    URL.createObjectURL(e.currentTarget.files[0])
                  );
                }}
                onBlur={handleBlur}
              />
              {selectedFile1 &&
                (selectedFile1 !== `${imageDefaultPrefixPath}null` ||
                  selectedFile1 !== `${imageDefaultPrefixPath}undefined` ||
                  selectedFile1 !== `${imageDefaultPrefixPath}`) && (
                  <div className="previewImage d-flex">
                    <img
                      className="display-image"
                      src={selectedFile1}
                      alt="selectedFile1"
                    />
                    {/* <span
                      className="remove-image"
                      onClick={() => {
                        handleRemoveIcon1(setFieldValue);
                        setFieldValue("logo", null);
                      }}
                    >
                      Remove Logo
                      <i className="fa fa-times" aria-hidden="true" />
                    </span> */}
                    <div className="photo-upload-action d-flex">
                      <div className="edit-photo-input">
                        <input
                          accept="image/*"
                          type="file"
                          name="logo"
                          className="form-control"
                          id="file"
                          ref={imageInputRef1}
                          onChange={(e) => {
                            e.currentTarget.files[0].type &&
                              setImageType1(e.currentTarget.files[0].type);
                            e.currentTarget.files[0] && setShowModal1(true);
                            setSelectedFile1(
                              URL.createObjectURL(e.currentTarget.files[0])
                            );
                          }}
                          onBlur={handleBlur}
                        />
                        <FontAwesomeIcon icon={faEdit} />
                      </div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                          handleRemoveIcon1(setFieldValue);
                          setFieldValue("logo", null);
                        }}
                      />
                    </div>
                  </div>
                )}
              {!selectedFile1 && (
                <span className="guide_leb">Add your Logo</span>
              )}
            </div>
          </div>
          <div className="form-box builder-details">
            <div className="form-group half-width builder-firm">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                value={values?.first_name ? values?.first_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="first_name" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>
                <span className="required">*</span>Last Name
              </label>
              <input
                type="text"
                name="last_name"
                value={values?.last_name ? values?.last_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="last_name" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Phone No.</label>
              <InputMask
                type="text"
                name="phone"
                value={values?.phone ? values?.phone : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="+61 9 9999 9999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="phone" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Mobile No.</label>
              <InputMask
                type="text"
                name="mobile"
                value={values?.mobile ? values?.mobile : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="+61 999 999 999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="mobile" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>
                <span className="required">*</span>Email
              </label>
              <input
                type="text"
                name="email"
                value={values?.email ? values?.email : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="email" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Business Name</label>
              <input
                type="text"
                name="business_name"
                value={values?.business_name ? values?.business_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="business_name" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>ABN</label>
              <InputMask
                type="text"
                name="abn"
                value={values?.abn ? values?.abn : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="99 999 999 999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="abn" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Entity Type</label>
              <Select
                name="entity_type"
                value={values?.entity_type ? values?.entity_type : ""}
                onChange={(e) => {
                  setFieldValue(`entity_type`, e ? e : "");
                  // handleChange(e);
                }}
                onBlur={handleBlur}
                options={entityTypes}
                placeholder="Select Entity Type"
                classNamePrefix="selectbox"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="entity_type" />
              </span>
            </div>

            {clientId ? (
              <div className="form-group half-width builder-firm">
                <label>CRN</label>
                <input
                  type="text"
                  name="crn"
                  value={values?.crn ? values?.crn : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  disabled
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="crn" />
                </span>
              </div>
            ) : (
              <></>
            )}

            <div className="address-box">
              <h5>Postal Address</h5>
              <div className="form-group half-width builder-firm">
                <label>Address</label>
                <input
                  type="text"
                  name="street"
                  value={values?.street ? values?.street : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="street" />
                </span>
              </div>
              <div className="form-group half-width builder-firm">
                <label>Select State</label>
                <Select
                  name="state_id"
                  value={values?.state_id ? values?.state_id : ""}
                  onChange={(e) => {
                    setFieldValue(`state_id`, e ? e : "");
                    setFieldValue(`suburb_id`, "");
                  }}
                  onBlur={handleBlur}
                  options={stateListing}
                  placeholder="Select State"
                  classNamePrefix="selectbox"
                  isClearable
                  isLoading={stateLoading}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="state_id" />
                </span>
              </div>
              <div className="form-group half-width builder-firm">
                <label>Select Suburb</label>

                <SuburbSelect
                  name="suburb_id"
                  regionName={values?.state_id}
                  onChange={(e) => {
                    setFieldValue("suburb_id", e ? e : "");
                  }}
                  value={values?.suburb_id ? values?.suburb_id : null}
                  placeholder={"Select Suburb"}
                  isClearable={true}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="suburb_id" />
                </span>
              </div>
              <div className="form-group half-width builder-firm">
                <label>Postal Code</label>
                <InputMask
                  type="text"
                  name="postal_code"
                  value={values?.postal_code ? values?.postal_code : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mask="9999"
                  maskChar={null}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="postal_code" />
                </span>
              </div>
            </div>
            <div className="address-box">
              <h5>Street Address</h5>
              <div className="form-group" style={{ width: "100%", margin: 0 }}>
                <label
                  className="form-lable"
                  style={{
                    width: "auto",
                    display: "flex",
                    flexFlow: "wrap",
                    gap: "5px",
                    fontWeight: "bold",
                    maxWidth: "fit-content",
                  }}
                >
                  <input
                    type="checkbox"
                    name="same_address"
                    checked={isChecked}
                    onBlur={handleBlur}
                    onClick={(e) => {
                      setIsChecked(!isChecked);
                    }}
                    onChange={handleChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                    value="10"
                  />{" "}
                  Same as Postal Address
                </label>
              </div>

              <div className="form-group half-width builder-firm">
                <label>Address</label>
                <input
                  type="text"
                  name="shipping_street"
                  value={values?.shipping_street ? values?.shipping_street : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isChecked === true ? true : false}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_street" />
                </span>
              </div>
              <div className="form-group half-width builder-firm">
                <label>Select State</label>
                <Select
                  name="shipping_state_id"
                  value={
                    values?.shipping_state_id ? values?.shipping_state_id : ""
                  }
                  onChange={(e) => {
                    setFieldValue(`shipping_state_id`, e ? e : "");
                    setFieldValue(`shipping_suburb_id`, "");
                  }}
                  onBlur={handleBlur}
                  options={stateListing}
                  placeholder="Select State"
                  classNamePrefix="selectbox"
                  isClearable
                  isLoading={stateLoading}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  isDisabled={isChecked === true ? true : false}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_state_id" />
                </span>
              </div>
              <div className="form-group half-width builder-firm">
                <label>Select Suburb</label>

                <SuburbSelect
                  name="shipping_suburb_id"
                  regionName={values?.shipping_state_id}
                  onChange={(e) => {
                    setFieldValue("shipping_suburb_id", e ? e : "");
                  }}
                  value={
                    values?.shipping_suburb_id
                      ? values?.shipping_suburb_id
                      : null
                  }
                  placeholder={"Select Suburb"}
                  isClearable={true}
                  isDisabled={isChecked === true ? true : false}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_suburb_id" />
                </span>
              </div>
              <div className="form-group half-width builder-firm">
                <label>Postal Code</label>
                <InputMask
                  type="text"
                  name="shipping_postal_code"
                  value={
                    values?.shipping_postal_code
                      ? values?.shipping_postal_code
                      : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  disabled={isChecked === true ? true : false}
                  mask="9999"
                  maskChar={null}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_postal_code" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="action-box">
        <button type="submit">Save</button>
        <CancelButton />
      </div>
      {/* {showModal && (
        <ImageCropModal
          imageType={imageType}
          selectedFile={selectedFile}
          setShowModal={setShowModal}
          setSelectedFile={setSelectedFile}
          showModal={showModal}
          setFieldValue={setFieldValue}
        />
      )} */}
      {showModal1 && (
        <ImageCropModal
          name="image1"
          imageType={imageType1}
          selectedFile={selectedFile1}
          setShowModal={setShowModal1}
          setSelectedFile={setSelectedFile1}
          showModal={showModal1}
          setFieldValue={setFieldValue}
        />
      )}
    </form>
  );
};

export default Form;
