import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { imageDefaultPrefixPath } from "../../../../config";
import { useEffect } from "react";
import ImageCropModal from "../../../../pages/common/ImageCropModal";
import SuburbSelect from "../../../common/SuburbSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  stateListing,
  stateLoading,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
  };
  const [showModal, setShowModal] = useState(false);

  const defaultLogo = values?.logo ? values?.logo : "";
  const [selectedFile, setSelectedFile] = useState(defaultLogo);
  const [imageType, setImageType] = useState();
  const imageInputRef = React.useRef();

  useEffect(() => {
    values?.logo &&
      typeof values?.logo === "string" &&
      setSelectedFile(values?.logo);
  }, [values]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  const handleRemoveIcon = () => {
    setSelectedFile("");
    imageInputRef.current.value = "";
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card myprofile-card">
        <div className="form-box">
          <div
            className="form-group half-width profile-photo logo"
            style={{ display: "block", textAlign: "center" }}
          >
            <input
              accept="image/*"
              type="file"
              name="logo"
              className="form-control"
              id="file"
              ref={imageInputRef}
              onChange={(e) => {
                e.currentTarget.files[0].type &&
                  setImageType(e.currentTarget.files[0].type);
                e.currentTarget.files[0] && setShowModal(true);
                // setFieldValue(`logo`, e.currentTarget.files[0]);
                setSelectedFile(URL.createObjectURL(e.currentTarget.files[0]));
              }}
              onBlur={handleBlur}
            />
            {selectedFile &&
              (selectedFile !== `${imageDefaultPrefixPath}null` ||
                selectedFile !== `${imageDefaultPrefixPath}undefined` ||
                selectedFile !== `${imageDefaultPrefixPath}`) && (
                <div className="previewImage d-flex">
                  <img
                    className="display-image"
                    src={selectedFile}
                    alt="selectedFile"
                  />
                  {/* <span
                    className="remove-image"
                    onClick={() => {
                      handleRemoveIcon(setFieldValue);
                      setFieldValue("logo", null);
                    }}
                  >
                    Remove Logo
                    <i className="fa fa-times" aria-hidden="true" />
                  </span> */}
                  <div className="photo-upload-action d-flex">
                    <div className="edit-photo-input">
                      <input
                        accept="image/*"
                        type="file"
                        name="profile_pic"
                        className="form-control"
                        id="file"
                        ref={imageInputRef}
                        onChange={(e) => {
                          e.currentTarget.files[0].type &&
                            setImageType(e.currentTarget.files[0].type);
                          e.currentTarget.files[0] && setShowModal(true);
                          setSelectedFile(
                            URL.createObjectURL(e.currentTarget.files[0])
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        handleRemoveIcon(setFieldValue);
                        setFieldValue("logo", null);
                      }}
                    />
                  </div>
                </div>
              )}
            {!selectedFile && <span className="guide_leb">Add your Logo</span>}
            {/* <span className="guide_leb">Add your Logo</span> */}
          </div>
        </div>
        <div className="form-box" style={{ width: "100%" }}>
          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Business Name
            </label>
            <input
              type="text"
              name="business_name"
              value={values?.business_name ? values?.business_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="business_name" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>Firm Email</label>
            <input
              type="text"
              name="business_email"
              value={values?.business_email ? values?.business_email : ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="business_email" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>Phone No.</label>
            <InputMask
              type="text"
              name="phone"
              value={values?.phone ? values?.phone : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="+61 9 9999 9999"
              maskChar={null}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="phone" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>Street</label>
            <input
              type="text"
              name="street"
              value={values?.street ? values?.street : ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="street" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Select State
            </label>
            <Select
              name="state_id"
              value={values?.state_id ? values?.state_id : ""}
              onChange={(e) => {
                setFieldValue(`state_id`, e ? e : "");
                setFieldValue(`suburb_id`, "");
                // e && handleStateSelect(e.value);
              }}
              onBlur={handleBlur}
              options={stateListing}
              placeholder="Select State"
              classNamePrefix="selectbox"
              isClearable
              isLoading={stateLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />

            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="state_id" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Select Suburb
            </label>
            <SuburbSelect
              name="suburb_id"
              regionName={values?.state_id}
              onChange={(e) => {
                setFieldValue("suburb_id", e ? e : "");
              }}
              value={values?.suburb_id ? values?.suburb_id : null}
              placeholder={"Select Suburb"}
              isClearable={true}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="suburb_id" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Postal Code</label>
            <InputMask
              type="text"
              name="postal_code"
              value={values?.postal_code ? values?.postal_code : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="postal_code" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>Tax Agent Number</label>
            <input
              type="text"
              name="tan"
              value={values?.tan ? values?.tan : ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="tan" />
            </span>
          </div>
        </div>
      </div>
      <div
        className="entry-card full-card"
        style={{ clear: "left", float: "left", margin: 0 }}
      >
        <div className="action-box">
          <button type="submit">Save</button>
        </div>
      </div>
      {showModal && (
        <ImageCropModal
          imageType={imageType}
          selectedFile={selectedFile}
          setShowModal={setShowModal}
          setSelectedFile={setSelectedFile}
          showModal={showModal}
          setFieldValue={setFieldValue}
        />
      )}
    </form>
  );
};

export default Form;
