import React, { useState, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Sitelogo from "../../components/Header/Logo";
import wFavicon from "../../assets/images/myna-white-favicon.png";

import { API, get, post } from "../../config";
import Spin from "../common/Spin";
import { encrypt } from "../common/Misc";

const TwoFactor = () => {
  const navigate = useNavigate();
  const [twoFAMode, setTwoFAMode] = useState("google");
  const [message, setMessage] = useState({ success: "", error: "" });
  const [loading, setLoading] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const tokenExpiredEmail = localStorage.getItem("token-expired-email");
  const DefaultURL = localStorage.getItem("DefaultURL");

  // *Text Change Based on Mode - Start
  const text = useMemo(
    () =>
      twoFAMode === "google"
        ? "Enter the 6-digit code found in your authenticator app"
        : "We have send a token email Enter the 6-digit code found in your email",
    [twoFAMode]
  );
  // *Text Change Based on Mode - End

  // *API of 2FA Code and Email Code - Start
  const changeAuthenticationCode = (event) => {
    setVerifyCode(event.target.value);
  };

  const handleFinish = async (e, values) => {
    e.preventDefault();
    try {
      setMessage({});
      const email = localStorage.getItem("bt-auth-email");
      const apiURL =
        twoFAMode === "google"
          ? `${API.GOOGLE_2FA_VERIFY}?login=1`
          : API.VERIFY_EMAIL_AUTHENTICATION;
      const { data } = await post(apiURL, {
        email: email,
        code: verifyCode,
      });
      if (data.status === 200) {
        setLoading(true);
        const userDetails = await get(API.GET_USER_PROFILE);
        const { first_name, last_name, profile_pic, id, role } =
          userDetails?.data?.data;
        localStorage.setItem("Name", first_name ? first_name : last_name);
        localStorage.setItem("ProfilePhoto", profile_pic);
        localStorage.setItem("role", role?.name);
        localStorage.setItem("role-slug", role?.slug);
        localStorage.setItem("userId", id);
        localStorage.setItem("EmailVerification", 1);
        localStorage.setItem("bt-auth-tfa", 2);

        localStorage.setItem("bt-subscription", encrypt("1"));

        if (role?.name !== "Builder" && role?.name !== "Admin") {
          const accountingFirmData = await get(API.ACCOUNTING_FIRM_SETTINGS);
          localStorage.setItem(
            "bt-accounting-firm-logo",
            accountingFirmData?.data?.data?.accountingFirm?.logo
              ? accountingFirmData?.data?.data?.accountingFirm?.logo
              : ""
          );
        }
        if (role?.name !== "Admin") {
          try {
            let subdata = await get(API.GET_SUBSCRIPTION_DETAILS);
            if (subdata) {
              localStorage.setItem("bt-subscription", encrypt("1"));
            }
          } catch (error) {
            localStorage.setItem("bt-subscription", encrypt("0"));
          }
        } else {
          localStorage.setItem("bt-subscription", encrypt("1"));
        }
        setLoading(false);
        setTwoFAMode(null);
        tokenExpiredEmail === values?.email
          ? navigate(DefaultURL)
          : role?.name === "Builder"
          ? navigate("/projects")
          : role?.name === "Admin"
          ? navigate("/admin-dashboard")
          : navigate("/builders");
      } else {
        setLoading(false);
        setMessage({ error: data.errors?.bt_error[0] });
      }
    } catch (e) {
      setLoading(false);
      const { data } = e.response;
      if (data && data.errors) {
        setMessage({ error: data && data.errors["code"][0] });
      } else {
        setMessage({
          error:
            "There is some issue with request, please try after some time.",
        });
      }
    }
  };
  // *API of 2FA Code and Email Code - End

  // *OAuth Mode Change - Start
  const handle2FAModeChange = async () => {
    try {
      const email = localStorage.getItem("bt-auth-email");
      setMessage({});
      setLoading(true);
      setTwoFAMode((prevState) =>
        prevState === "google" ? "email" : "google"
      );
      if (twoFAMode === "google") {
        const { data } = await post(API.EMAIL_AUTHENTICATION, {
          email: email,
        });
        if (data.status === 200) {
          setMessage({ success: data.message });
        } else {
          setMessage({ error: data.errors });
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMessage({
        error: "There is some issue with request, please try after some time.",
      });
    }
  };
  // *OAuth Mode Change - End

  // *Back to Login Route Maintain - Start
  const handleBackToLogin = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };
  // *Back to Login Route Maintain - End

  return (
    <div className="login-screen">
      <>
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" />{" "}
              BuildTrust acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          <div className="login-form">
            {message.error && <p className="text-danger">{message.error}</p>}
            {message.success && (
              <p className="text-success">{message.success}</p>
            )}
            <Form name="2faForm" autoComplete="off" onSubmit={handleFinish}>
              {loading && <Spin />}
              <div className="auth-text auth-image">{text}</div>
              <input
                autoFocus
                placeholder="Authentication Code"
                bordered={false}
                onChange={changeAuthenticationCode}
                type="password"
              />
              <div className="form-group ant-form-item confirmButton">
                <Button type="submit">Confirm</Button>
                <Button
                  type="button"
                  onClick={handleBackToLogin}
                  className="linkButton"
                >
                  Back to Login
                </Button>
              </div>
            </Form>
            <div className="2faForm">
              <Button
                type="button"
                onClick={handle2FAModeChange}
                className="linkButton"
              >
                Use another authentication method
              </Button>
            </div>
          </div>
          <div className="footer-link">
            <p style={{ fontSize: "12px", marginBottom: "2px" }}>
              Trouble Logging In?{" "}
              <a
                href="https://buildtrust.com.au/contact-us/"
                rel="noreferrer"
                style={{ fontSize: "12px", textDecoration: "underline" }}
                target="_blank"
              >
                Contact Us
              </a>
            </p>
            <p style={{ fontSize: "12px" }}>
              By selecting Login, you agree to our{" "}
              <Link
                rel="noreferrer"
                style={{ fontSize: "12px", textDecoration: "underline" }}
                target="_blank"
              >
                Terms of Use
              </Link>{" "}
              and have read and acknowledge our{" "}
              <a
                href="https://buildtrust.com.au/privacy-policy/"
                rel="noreferrer"
                style={{ fontSize: "12px", textDecoration: "underline" }}
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </>
    </div>
  );
};

export default TwoFactor;
