import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";

const UploadFile = ({
  businessId,
  isLoading,
  handleUpload,
  setFiles,
  files,
  handleCancel,
  acceptFileFormat,
}) => {
  return (
    <div className="upload-div">
      <div className="modal-body">
        <Form>
          <div className="upload-file">
            <FormControl
              accept={acceptFileFormat}
              type="file"
              title=""
              name="chart_of_account"
              className="form-control"
              id="chart_of_account"
              onChange={(e) => {
                e.currentTarget.files[0]?.type === "text/csv" ||
                e.currentTarget.files[0]?.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                e.currentTarget.files[0]?.type === "application/vnd.ms-excel"
                  ? setFiles(e.currentTarget.files[0])
                  : toast.error("Please upload .csv, .xls or .xlsx only.") &&
                    setFiles();
              }}
            />
            <span>
              <p className="ant-upload-drag-icon">
                <FontAwesomeIcon title="" icon={faUpload} size="3x" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </span>
          </div>
          <div>
            <p>{files ? files?.name : ""}</p>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          variant="primary"
          disabled={files ? false : true}
          onClick={() => handleUpload()}
        >
          {isLoading ? "Loading..." : "Upload"}
        </Button>
        <Button variant="secondary" onClick={() => handleCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UploadFile;
