import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BuilderDetailsPopup from "./BuilderDetailsPopup";

const BuilderActionButton = ({ id }) => {
  const [showView, setShowView] = useState(false);

  const toggleShowModal = () => {
    setShowView(!showView);
  };
  return (
    <>
      <span>
        <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#clientDetail"
          // title="View Builder "
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <Link to={{ pathname: `/builders/edit/${id}` }}>
          <FontAwesomeIcon icon={faEdit} size="1x" title="Edit Builder" />
        </Link>
      </span>

      {showView && (
        <BuilderDetailsPopup
          showView={showView}
          builderId={id}
          toggleShowModal={toggleShowModal}
        />
      )}
    </>
  );
};

export default BuilderActionButton;
