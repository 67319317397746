import { Button, Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faEdit, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getProviderBankDetail,
  getYodleeAccessToken,
} from "./components/IntegrationsHelper";
import Spin from "../../../common/Spin";
import { YodleeConnectModal } from "./components/YodleeConnetctModal";
import {
  yodleeFastLink,
  yodleeConfigName,
  yodleeDefaultImg,
  fileUpload,
  API,
  get,
  // yodleeobAppName,
  // fileUpload,
  // API,
} from "../../../../config";
import DisconnectBank from "./components/DisconnectBankModal";
import { toast } from "react-toastify";
import ProCoreVerfication from "../../../general-settings/ProCoreVerfication";
// import { toast } from "react-toastify";

const Integrations = ({ProjectReadOnly}) => {
  const params = useParams();
  const projectId = params?.project_id;
  const role = localStorage.getItem("role");
  const [showModal, setShowModal] = useState(false);
  const [openYodlee, setOpenYodlee] = useState(false);
  const [openYodleeLoading, setOpenYodleeLoading] = useState(false);
  const [fastLinkData, setFastLinkData] = useState({});
  const [accountType, setAccountType] = useState();
  const [projectData, setProjectData] = useState();
  const navigate = useNavigate();

  const config = {
    params: { configName: "", obAppName: "" },
  };

  const handleYodleeControle = async (value) => {
    try {
      setAccountType(value);
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(projectId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params.configName = yodleeConfigName;
      // config.params.obAppName = yodleeobAppName;
      setOpenYodleeLoading(false);
      setOpenYodlee(true);
      setFastLinkData(config);
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeEditControl = async (value, providerID) => {
    try {
      let config = {};
      setAccountType(value);
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(projectId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: "edit",
        providerAccountId: providerID,
        configName: yodleeConfigName,
        // obAppName: yodleeobAppName,
      };
      setOpenYodleeLoading(false);
      setOpenYodlee(true);
      setFastLinkData(config);
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeRefreshControl = async (value, providerID) => {
    try {
      let config = {};
      setAccountType(value);
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(projectId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: "refresh",
        providerAccountId: providerID,
        configName: yodleeConfigName,
        // obAppName: yodleeobAppName,
      };
      setOpenYodleeLoading(false);
      setOpenYodlee(true);
      setFastLinkData(config);
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  //Providers(Bank)
  const [loading, setLoading] = useState(false);
  // const [providerBankData, setProviderBankData] = useState([]);

  const [providerProjectBankData, setProviderProjectBankData] = useState(null);
  const [providerRetentionBankData, setProviderRetentionBankData] =
    useState(null);

  const [disconnectModal, setDisconnectModal] = useState(false);
  const [providerId, setProviderId] = useState();

  const toggleDisconnectModal = () => {
    setDisconnectModal(false);
  };

  const providerBankData = () => {
    setProviderProjectBankData(null);
    setProviderRetentionBankData(null);
  };

  const getProviderBankData = useCallback(async () => {
    try {
      setLoading(true);
      providerBankData();
      const data = await getProviderBankDetail(projectId);
      data?.data && data?.data?.length > 0
        ? data?.data?.forEach((element) => {
            element?.trust_type === 1 && setProviderProjectBankData(element);
            // : setProviderProjectBankData(null);
            element?.trust_type === 0 && setProviderRetentionBankData(element);
            // : setProviderRetentionBankData(null);
          })
        : providerBankData();

      setLoading(false);
    } catch (e) {
      setProviderProjectBankData(null);
      setProviderRetentionBankData(null);
      setLoading(false);
    }
    navigate("?tab=integrations");
    // eslint-disable-next-line
  }, [projectId]);

  const handleProviderBank = () => {
    getProviderBankData();
    setDisconnectModal(false);
  };

  const handleClientConnectRequest = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);

      const response = await fileUpload(
        `${API.REQUEST_BUILDER_TO_CONNECT}`,
        formData
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        getProviderBankData();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };
  const toggleConfirmModal = () => {
    setShowModal(!showModal);
  };
  const getProjectData = async () => {
    try {
      const { data } = await get(`${API.PROJECT_DETAILS}/` + projectId);
      setProjectData(data?.data);
      return data.data;
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    getProjectData(projectId, setProjectData);
    getProviderBankData();
    // eslint-disable-next-line
  }, [getProviderBankData]);

  return (
    <>
      {loading && <Spin />}
      <div className="integration-card">
        <h3>Automatic Bank Feeds into BuildTrust</h3>

        <div className="card">
          {providerProjectBankData === null ? (
            <div className="card-body">
              <div className="crm-img">
                <img className="card-img-top" src={yodleeDefaultImg} alt="" />
              </div>
              {openYodleeLoading && <Spin />}
              {window.fastlink.close()}
              <p>Project Trust Account</p>
              <Button
                id="btn-fastlink"
                onClick={() => {
                  handleYodleeControle("project");
                }}
                disabled={ProjectReadOnly}
              >
                Connect a Bank Account
              </Button>
              {role && role !== "Builder" && role !== "Admin" && (
                <>
                  <span style={{ margin: "10px", display: "block" }}>OR</span>
                  <Button
                    id="btn-fastlink"
                    onClick={() => {
                      setShowModal(true);
                    }}
                    disabled={ProjectReadOnly}
                  >
                    Request a Builder to Connect
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className="card-body">
              <div className="crm-img">
                <img
                  className="card-img-top"
                  src={providerProjectBankData?.logo}
                  alt=""
                />
              </div>
              {openYodleeLoading && <Spin />}
              {window.fastlink.close()}
              <p>Project Trust Account</p>
              <div className="mb-2 yodlee card-icons">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="ml-2"
                  onClick={async () => {
                    handleYodleeEditControl(
                      "project",
                      providerProjectBankData?.providerAccountId
                    );
                  }}
                  disabled={ProjectReadOnly}
                />
                <FontAwesomeIcon
                  icon={faRefresh}
                  onClick={() => {
                    handleYodleeRefreshControl(
                      "project",
                      providerProjectBankData?.providerAccountId
                    );
                  }}
                  disabled={ProjectReadOnly}
                />
              </div>
              <Button
                data-toggle="modal"
                data-target="#disconnectBank"
                onClick={() => {
                  setDisconnectModal(true);
                  setProviderId(providerProjectBankData?.id);
                }}
                ProjectReadOnly={ProjectReadOnly}
              >
                Disconnect
                {/* {isYodleeConnect ? "Disconnect" : "Connect"} */}
              </Button>
            </div>
          )}
        </div>
        <div className="card">
          {providerRetentionBankData === null ? (
            <div className="card-body">
              <div className="crm-img">
                <img className="card-img-top" src={yodleeDefaultImg} alt="" />
              </div>
              {openYodleeLoading && <Spin />}
              {window.fastlink.close()}
              <p>Retention Trust Account</p>
              <Button
                id="btn-fastlink"
                onClick={() => {
                  handleYodleeControle("retention");
                }}
                disabled={ProjectReadOnly}
              >
                Connect a Bank Account
              </Button>
              {role && role !== "Builder" && role !== "Admin" && (
                <>
                  <span style={{ margin: "10px", display: "block" }}>OR</span>
                  <Button
                    id="btn-fastlink"
                    onClick={() => {
                      setShowModal(true);
                    }}
                    disabled={ProjectReadOnly}
                  >
                    Request a Builder to Connect
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className="card-body">
              <div className="crm-img">
                <img
                  className="card-img-top"
                  src={providerRetentionBankData?.logo}
                  alt=""
                />
              </div>
              {openYodleeLoading && <Spin />}
              {window.fastlink.close()}
              <p>Retention Trust Account</p>
              <div className="mb-2 yodlee card-icons">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="ml-2"
                  onClick={async () => {
                    handleYodleeEditControl(
                      "retention",
                      providerRetentionBankData?.providerAccountId
                    );
                  }}
                  disabled={ProjectReadOnly}
                />
                <FontAwesomeIcon
                  icon={faRefresh}
                  onClick={() => {
                    handleYodleeRefreshControl(
                      "retention",
                      providerRetentionBankData?.providerAccountId
                    );
                  }}
                  disabled={ProjectReadOnly}
                />
              </div>
              <Button
                data-toggle="modal"
                data-target="#disconnectBank"
                onClick={() => {
                  setDisconnectModal(true);
                  setProviderId(providerRetentionBankData?.id);
                }}
                disabled={ProjectReadOnly}
              >
                Disconnect
              </Button>
            </div>
          )}
        </div>
        <div style={{ width: "100%" }}>
          <ProCoreVerfication projectSection={true} ProjectReadOnly={ProjectReadOnly}/>
        </div>
        {openYodlee && <Modal
          size="lg"
          show={openYodlee}
          onHide={handleYodleeModal}
          dialogClassName="modal-50w medium-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Auto Bank Feeds Account Integration
          </Modal.Header>
          <Modal.Body>
            <YodleeConnectModal
              handleYodleeModal={handleYodleeModal}
              fastLinkData={fastLinkData}
              projectId={projectId}
              handleProviderBank={handleProviderBank}
              setOpenYodleeLoading={setLoading}
              accountType={accountType}
            />
          </Modal.Body>
        </Modal>}

        {disconnectModal && (
          <DisconnectBank
            projectId={projectId}
            providerId={providerId}
            toggleDisconnectModal={toggleDisconnectModal}
            handleDisconnect={handleProviderBank}
            setProviderBankLoading={setLoading}
            disconnectModal={disconnectModal}
          />
        )}
        {showModal && (
          <Modal
            size="lg"
            show={showModal}
            onHide={toggleConfirmModal}
            dialogClassName="modal-50w small-popup review-popup small-review"
            aria-labelledby="contained-modal-title-vcenter"
            className="project-section"
            centered
          >
            <Modal.Header className="mb-0" closeButton>
              REQUEST
            </Modal.Header>
            <Modal.Body>
              {loading && <Spin />}
              <div className="modal-body">
                Send Request to {projectData?.builder?.builder_details?.email}{" "}
                to connect {projectData?.project_name} with the bank(s).
              </div>
              <div className="modal-footer">
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={() => {
                    toggleConfirmModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={handleClientConnectRequest}
                >
                  OK
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Integrations;
