import BackButton from "../../../components/Form/BackButton";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import InputMask from "react-input-mask";
// import { DatePicker } from "@semcore/date-picker";
// import ChartOfAccountSelect from "../../common/ChartOfAccountSelect";
import {
  getContractorDetail,
  useBankList,
} from "../../Project/sub-contractors/components/SubContractorHelper";
import Spin from "../../common/Spin";
import {
  checkIfFileExists,
  formatName,
  formatNumber,
  MaxLimit,
} from "../../common/Misc";
import { getProjectData } from "../../Project/components/ProjectHelper";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
// import ContractorSelectWithTA from "../../common/ContractorSelectWithTA";
import ContractorSelectWithOutPTA from "../../common/ContractorSelectWithOutPTA";
import { Link, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { DatePicker } from "rsuite";

const defaulDescriptionForContractor =
  "To take up the payments net of retentions to contractors from the trust account - Payment Claim";
const defaulDescriptionForRetention =
  "To take up the payments of retentions to retention trust account (retained amount) - Payment Claim";

const RecordPayments = () => {
  const { state } = useLocation();
  const [fileNote, setFileNote] = useState("");
  const [descriptionErrors, setDescriptionErrors] = useState({});
  const [finalData, setFinalData] = useState([]);
  const [contractorDetails, setContractorDetails] = useState();
  // const [transactionDate, setTransactionDate] = useState(
  //   state?.transactionDate ? state?.transactionDate : new Date()
  // );
  const [transactionDate, setTransactionDate] = useState(
    state?.transactionDate
      ? moment(state?.transactionDate).format("DD-MM-YYYY")
      : moment().format("DD-MM-YYYY")
  );
  const [loading, setLoading] = useState(false);
  const [contractorData, setContractorData] = useState();
  const [amountErrors, setAmountErrors] = useState({});

  const [retentionData, setRetentionData] = useState({});
  const [subContractorData, setSubContractorData] = useState({});

  const [accountErrors, setAccountErrors] = useState({});
  const params = useParams();
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "project" ? 1 : 0;

  const navigate = useNavigate();

  const [finalSubminData, setFinalSubminData] = useState([]);
  const [show, setShow] = useState(false);

  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  const [setBuilderId] = useState();
  const [ProjectData, setProjectData] = useState();

  let today = new Date();
  const currentDate = new Date(today);
  currentDate.setDate(currentDate.getDate() + 1);

  useEffect(() => {
    getProjectData(
      projectId,
      setProjectName,
      setProjectLogo,
      setBuilderId,
      setProjectData
    );
  }, [projectId, setBuilderId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const handlePostDisabled = (finalData) => {
    if (finalData && finalData?.length > 0) {
      const descriptionError = Object.values(descriptionErrors);
      const amountError = Object.values(amountErrors);

      const checkDescriptionError = Object.values(
        descriptionError.filter(
          (item, index) => descriptionError.indexOf(item) === index
        )
      );

      const checkAmountError = Object.values(
        amountError.filter((item, index) => amountError.indexOf(item) === index)
      );
      const checkData = finalData?.map((element, index) => {
        if (
          (checkDescriptionError[index] === null ||
            checkDescriptionError[index] === undefined) &&
          (checkAmountError[index] === null ||
            checkAmountError[index] === undefined) &&
          element?.chart_of_account_id &&
          element?.amount &&
          element?.description &&
          element?.name &&
          element?.bank_id &&
          element?.bsb &&
          element?.account_number
        ) {
          return false;
        } else {
          return true;
        }
      });
      return checkData?.find((item) => item === true) ||
        !transactionDate ||
        !fileNote
        ? true
        : false;
    } else {
      return true;
    }
  };

  const handleConfirmModal = () => {
    setShow(false);
    setFinalSubminData([]);
  };

  const handlePost = async (finalData) => {
    setShow(true);
    setFinalSubminData(finalData);
  };

  const handleSubmitPost = async (finalData) => {
    const formData = new FormData();
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append("trust_type", trust_type);
    formData.append(
      `contact_id`,
      contractorDetails?.id ? contractorDetails?.id : ""
    );
    // formData.append(
    //   "transaction_date",
    //   transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : ""
    // );

    formData.append("transaction_date", transactionDate ? transactionDate : "");
    formData.append("note", fileNote ? fileNote : "");

    finalData?.forEach((item, index) => {
      if (item) {
        item.amount = Number(`${item?.amount}`.replace(/,/g, ""));

        item?.rta === 1
          ? formData.append(
              `payments[${index}][retention_id]`,
              item?.retention_id ? item?.retention_id : ""
            )
          : formData.append(
              `payments[${index}][contact_id]`,
              contractorDetails?.id ? contractorDetails?.id : ""
            );

        formData.append(
          `payments[${index}][chart_of_account_id]`,
          item?.chart_of_account_id?.id ? item?.chart_of_account_id?.id : ""
        );

        formData.append(
          `payments[${index}][amount]`,
          item?.amount ? item?.amount : 0
        );
        formData.append(
          `payments[${index}][description]`,
          item?.description ? item?.description : ""
        );
        formData.append(
          `payments[${index}][bank_id]`,
          item?.bank_id?.value
            ? item?.bank_id?.value
            : item?.bank_id
            ? item?.bank_id
            : ""
        );
        formData.append(
          `payments[${index}][account_name]`,
          item?.name ? item?.name : ""
        );
        formData.append(`payments[${index}][bsb]`, item?.bsb ? item?.bsb : "");
        formData.append(
          `payments[${index}][account_number]`,
          item?.account_number ? item?.account_number : ""
        );
      }
    });

    try {
      setLoading(true);
      const response = await fileUpload(`${API.CREATE_PAYMENTS}`, formData, {});
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        setFinalData([]);
        setTransactionDate(moment());
        setFileNote("");
        handleConfirmModal();
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errors = error?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const getContractor = async (contractorId) => {
    try {
      setLoading(true);
      const data = await getContractorDetail(contractorId);
      const contractorData = data ? data : {};
      if (contractorData?.id) {
        const contractorName = formatName(
          contractorData?.first_name,
          contractorData?.last_name
        );
        setContractorData(contractorData);

        if (
          contractorData?.account_name &&
          contractorData?.account_number &&
          contractorData?.bsb
        ) {
          const subContractorData = {
            chart_of_account_id: contractorData?.contact_type
              ?.chart_of_account_id
              ? contractorData?.contact_type?.chart_of_account
              : contractorData?.chart_of_account
              ? contractorData?.chart_of_account
              : "",
            name: contractorData?.account_name
              ? contractorData?.account_name === contractorName
                ? contractorData?.account_name
                : `${contractorData?.account_name}`
              : "",
            amount: contractorData?.amount ? contractorData?.amount : "",
            description: contractorData?.description
              ? contractorData?.description
              : defaulDescriptionForContractor,
            bank_id: contractorData?.bank_id ? contractorData?.bank_id : "",
            bsb: contractorData?.bsb ? contractorData?.bsb : "",
            account_number: contractorData?.account_number
              ? contractorData?.account_number
              : "",
          };
          const retentionData =
            (ProjectData?.rta_project?.rta_contractor?.bank_id &&
              ProjectData?.rta_project?.rta_contractor?.bsb !== "000-000") ||
            (ProjectData?.rta_contractor?.bank_id &&
              ProjectData?.rta_contractor?.bsb !== "000-000")
              ? {
                  rta: 1,
                  chart_of_account_id:
                    // ProjectData?.rta_project_id
                    //   ? ProjectData?.rta_project?.rta_contractor
                    //       ?.chart_of_account_rta
                    //     ? ProjectData?.rta_project?.rta_contractor
                    //         ?.chart_of_account_rta
                    //     : ""
                    //   :
                    ProjectData?.rta_contractor?.chart_of_account_rta
                      ? ProjectData?.rta_contractor?.chart_of_account_rta
                      : "",
                  name: ProjectData?.rta_project_id
                    ? ProjectData?.rta_project?.rta_contractor?.account_name
                      ? ProjectData?.rta_project?.rta_contractor
                          ?.account_name === contractorName
                        ? ProjectData?.rta_project?.rta_contractor?.account_name
                        : `${ProjectData?.rta_project?.rta_contractor?.account_name}`
                      : ""
                    : ProjectData?.rta_contractor?.account_name
                    ? ProjectData?.rta_contractor?.account_name ===
                      contractorName
                      ? ProjectData?.rta_contractor?.account_name
                      : `${ProjectData?.rta_contractor?.account_name}`
                    : "",
                  amount: "",
                  description: ProjectData?.rta_project_id
                    ? ProjectData?.rta_project?.rta_contractor?.description
                      ? ProjectData?.rta_project?.rta_contractor?.description
                      : defaulDescriptionForRetention
                    : ProjectData?.rta_contractor?.description
                    ? ProjectData?.rta_contractor?.description
                    : defaulDescriptionForRetention,

                  bank_id: ProjectData?.rta_project_id
                    ? ProjectData?.rta_project?.rta_contractor?.bank_id
                      ? ProjectData?.rta_project?.rta_contractor?.bank_id
                      : ""
                    : ProjectData?.rta_contractor?.bank_id
                    ? ProjectData?.rta_contractor?.bank_id
                    : "",
                  bsb: ProjectData?.rta_project_id
                    ? ProjectData?.rta_project?.rta_contractor?.bsb
                      ? ProjectData?.rta_project?.rta_contractor?.bsb
                      : ""
                    : ProjectData?.rta_contractor?.bsb
                    ? ProjectData?.rta_contractor?.bsb
                    : "",
                  account_number: ProjectData?.rta_project_id
                    ? ProjectData?.rta_project?.rta_contractor?.account_number
                      ? ProjectData?.rta_project?.rta_contractor?.account_number
                      : ""
                    : ProjectData?.rta_contractor?.account_number
                    ? ProjectData?.rta_contractor?.account_number
                    : "",
                  retention_id: ProjectData?.rta_project_id
                    ? ProjectData?.rta_project?.rta_contractor?.id
                      ? ProjectData?.rta_project?.rta_contractor?.id
                      : ""
                    : ProjectData?.rta_contractor?.id
                    ? ProjectData?.rta_contractor?.id
                    : "",
                }
              : "";

          const newArr =
            trust_type === 1 && retentionData
              ? [subContractorData, retentionData]
              : [subContractorData];
          setFinalData(newArr);
          setSubContractorData(subContractorData);
          setRetentionData(retentionData);
        } else {
          toast.error("Please add bank details of selected contractor.");
        }
      }
      setLoading(false);
    } catch (e) {
      setFinalData([]);
      toast.error("Something went wrong.");
    }
  };

  const handleContractorSelect = async (contractor) => {
    setFinalData([]);
    setContractorDetails(contractor);
    getContractor(contractor?.id);
  };

  // const handleNewAccountSelection = (e, index, finalData) => {
  //   const newArr = finalData.map((obj, i) => {
  //     if (i === index) {
  //       return { ...obj, chart_of_account_id: e };
  //     }
  //     return obj;
  //   });
  //   setFinalData(newArr);
  // };

  const handleName = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, name: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleAmount = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, amount: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleAmountBlur = (e, index) => {
    let errors = amountErrors;
    if (trust_type === 0) {
      if (
        parseFloat(Number(`${e.target.value}`.replace(/,/g, ""))) >=
        (contractorData?.closing_balance_rta
          ? contractorData?.closing_balance_rta
          : 0) +
          (contractorData?.opening_balance_rta
            ? contractorData?.opening_balance_rta
            : 0)
      ) {
        errors[index] = "Amount cannot be greater than closing balance";
      } else {
        errors[index] = null;
      }
    } else {
      if (parseFloat(Number(`${e.target.value}`.replace(/,/g, ""))) === 0) {
        errors[index] = "Amount cannot be zero";
      } else {
        errors[index] = null;
      }
    }
    setAmountErrors({ ...errors });
  };

  const handleDescription = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };
  const handleBlurDescription = (e, index) => {
    let errors = descriptionErrors;
    let regex = new RegExp(/^[^!@)(^%$<>][a-zA-Z\s\d.,#/&-]*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = "Enter valid Description.";
      } else {
        errors[index] = null;
      }
    } else {
      toast.error("Something went wrong.");
    }
    setDescriptionErrors({ ...errors });
  };

  const handleBSB = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, bsb: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleAccount = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, account_number: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };
  const handleBlurAccount = (e, index) => {
    let errors = accountErrors;
    let regex = new RegExp(/^\d{0,9}$/);

    if (e.target.value || e.target.value === 0) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = "Must be 9 digit number.";
      } else {
        let myFunc = (num) => Number(num);
        var intArr = Array.from(String(e.target.value), myFunc);
        const removeDuplicatesArr = removeDuplicates(intArr);
        if (intArr?.length > 1 && removeDuplicatesArr?.length === 1) {
          errors[index] =
            "Please enter valid Account Number. All digits of same number are not allowed.";
        } else {
          errors[index] = null;
        }
      }
    } else {
      return 0;
    }
    setAccountErrors({ ...errors });
  };

  const handleDelete = (finalData, index) => {
    finalData.splice(index, 1);
    setFinalData([...finalData]);
  };

  const handleBank = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, bank_id: e };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleAdd = (finalData) => {
    finalData[0]?.rta === 1
      ? setFinalData([subContractorData, ...finalData])
      : setFinalData([...finalData, retentionData]);
  };

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const bankListing = bankList
    ? [...bankList, { label: "Don't have a Retention Trust A/C", value: "" }]
    : [];

  return (
    <div className="cms-page">
      <div className="page-content-block">
        {loading && <Spin />}
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trust_type === 1
              ? "New Project Trust Payment"
              : "New Retention Trust Payment"}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details refund-process">
            <div className="table-top-btn">
              <div className="search-section">
                <div className="sub-contractor-dropdown">
                  <ContractorSelectWithOutPTA
                    regionName={"0"}
                    projectId={projectId}
                    value={contractorDetails ? contractorDetails : null}
                    onChange={(contractor) => {
                      handleContractorSelect(contractor);
                    }}
                    className="contactname-select"
                    placeholder="Select Subcontractor"
                    isClearable={false}
                  />
                  {contractorDetails &&
                    contractorData &&
                    !contractorData?.account_name &&
                    !contractorData?.account_number &&
                    !contractorData?.bsb && (
                      <div className="bank-details">
                        <p className="text-danger">
                          {/* Subcontractor does not have bank details */}
                          <Link
                            to={{
                              pathname: `/projects/${projectId}/sub-contractors/edit/${contractorDetails?.id}`,
                            }}
                            className="update-bank-text"
                          >
                            Update bank details
                          </Link>
                        </p>
                      </div>
                    )}
                </div>
              </div>
              {/* {clientData.closing_balance ? (
                <div className="client-account-balance">
                  <p>
                    Account balance for {FullNameGetterWithoutChartAt(clientData)} :{' '}
                    {clientData?.closing_balance
                      ? NegativeAmountConverter(clientData?.closing_balance)
                      : '$0.00'}
                  </p>
                  <p>
                    Balance after making this payment :{" "}
                    {finalAmount
                      ? clientData?.closing_balance &&
                        NegativeAmountConverter(clientData?.closing_balance - finalAmount)
                      : '$0.00'}
                  </p>
                </div>
              ) : (
                <></>
              )} */}
            </div>
            <div style={{ width: "100%", height: "89%" }}>
              <div id="grid-wrapper" style={{ width: "100%", height: "100%" }}>
                <div id="myGrid" className="ag-theme-alpine ato-grid">
                  <Form>
                    <div className="form-box">
                      <div className="refund-process-box">
                        <Container>
                          <Container>
                            <Row className="refund-top-details record-payment">
                              <Col
                                // xs={12}
                                md={2}
                                className="add-record-payment"
                              >
                                <strong>Transaction Date</strong>
                                <Form.Group className="datepicker">
                                  {/* <DatePicker
                                    name="transaction_date"
                                    value={
                                      transactionDate ? transactionDate : ""
                                    }
                                    onChange={(e) => {
                                      setTransactionDate(e);
                                    }}
                                    disabled={[[currentDate, false]]}
                                  /> */}
                                  <DatePicker
                                    cleanable={false}
                                    name="transaction_date"
                                    onChange={(e) =>
                                      setTransactionDate(
                                        moment(e, "DD-MM-YYYY").format(
                                          "DD-MM-YYYY"
                                        )
                                      )
                                    }
                                    format="dd-MM-yyyy"
                                    value={
                                      transactionDate
                                        ? String(transactionDate)?.includes("-")
                                          ? new Date(
                                              String(transactionDate)?.split(
                                                "-"
                                              )[2],
                                              String(transactionDate)?.split(
                                                "-"
                                              )[1] - 1,
                                              String(transactionDate)?.split(
                                                "-"
                                              )[0]
                                            )
                                          : new Date(transactionDate)
                                        : null
                                    }
                                    className="rs-date-body"
                                    placeholder="Select Date"
                                    placement="autoVerticalStart"
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                // xs={12}
                                md={3}
                                className="add-record-payment"
                              >
                                <strong>File Note</strong>
                                <Form.Control
                                  type="text"
                                  className="left-text-align"
                                  required
                                  maxLength={150}
                                  value={fileNote ? fileNote : ""}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                      ),
                                      message:
                                        "Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setFileNote(e.target.value);
                                  }}
                                ></Form.Control>
                              </Col>
                              <Col
                                // xs={12}
                                md={7}
                                className="add-record-payment text-right"
                              >
                                {trust_type === 0 && (
                                  <>
                                    <strong>
                                      Subcontractor Closing Balance
                                    </strong>
                                    <br />
                                    <span>
                                      {"$" +
                                        formatNumber(
                                          (contractorData?.closing_balance_rta
                                            ? contractorData?.closing_balance_rta
                                            : 0) +
                                            (contractorData?.opening_balance_rta
                                              ? contractorData?.opening_balance_rta
                                              : 0)
                                        )}
                                    </span>
                                  </>
                                )}
                              </Col>
                            </Row>
                            <Row className="refund-card-box record-payment">
                              <Col xs={6} md={12}>
                                <div className="refund-card">
                                  <label>Payment Details</label>
                                  <div className="refund-card-content">
                                    <div className="refund-card-content-label refund-card-title row col-md-12">
                                      <h6 className="col-md-2 center-align">
                                        Account Name
                                      </h6>
                                      {/* <h6 className="col-md-2 center-align">
                                        Account
                                      </h6> */}
                                      <h6 className="col-md-2 center-align">
                                        Amount
                                      </h6>
                                      <h6
                                        className={
                                          trust_type === 1
                                            ? "col-md-3 center-align"
                                            : "col-md-3 center-align"
                                        }
                                      >
                                        Description
                                      </h6>
                                      <h6 className="col-md-2 center-align">
                                        Bank Name
                                      </h6>
                                      <h6 className="col-md-1 center-align">
                                        BSB
                                      </h6>
                                      <h6 className="col-md-1 center-align">
                                        Account Number
                                      </h6>
                                      {/* {trust_type === 1 && (
                                        <h6 className="col-md-1 center-align"></h6>
                                      )} */}
                                    </div>
                                    <div className="refund-card-content-row">
                                      {finalData &&
                                        finalData?.length > 0 &&
                                        finalData?.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="refund-card-content-label row col-md-12"
                                            >
                                              <div className="col-md-2 refund-column">
                                                <Form.Group>
                                                  <Form.Control
                                                    // disabled
                                                    type="text"
                                                    name="name"
                                                    value={
                                                      item?.name
                                                        ? item?.name
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleName(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                    }}
                                                    style={{
                                                      border:
                                                        "1px solid #d9d9d9",
                                                      padding: "5px 10px",
                                                      fontSize: "14px",
                                                      width: "100%",
                                                      color: "#000",
                                                    }}
                                                  ></Form.Control>
                                                </Form.Group>
                                              </div>
                                              {/* <div className="col-md-2 refund-column">
                                                <ChartOfAccountSelect
                                                  name="chart_of_account_id"
                                                  onChange={(e) => {
                                                    handleNewAccountSelection(
                                                      e,
                                                      index,
                                                      finalData
                                                    );
                                                  }}
                                                  className="contactname-select"
                                                  projectId={projectId}
                                                  value={
                                                    item?.chart_of_account_id
                                                      ? item
                                                          ?.chart_of_account_id
                                                          ?.label
                                                        ? item?.chart_of_account_id
                                                        : {
                                                            id: item
                                                              ?.chart_of_account_id
                                                              ?.id
                                                              ? item
                                                                  ?.chart_of_account_id
                                                                  ?.id
                                                              : null,
                                                            label:
                                                              item
                                                                ?.chart_of_account_id
                                                                ?.name +
                                                              (item
                                                                ?.chart_of_account_id
                                                                ?.account_type
                                                                ?.name
                                                                ? " (" +
                                                                  item
                                                                    ?.chart_of_account_id
                                                                    ?.account_type
                                                                    ?.name +
                                                                  ")"
                                                                : "") +
                                                              (item
                                                                ?.chart_of_account_id
                                                                ?.code
                                                                ? " - " +
                                                                  item?.chart_of_account_id?.code.toString()
                                                                : "") +
                                                              (item
                                                                ?.chart_of_account_id
                                                                ?.sub_code
                                                                ? "." +
                                                                  item?.chart_of_account_id?.sub_code.toString()
                                                                : ""),
                                                            tax: item
                                                              ?.chart_of_account_id
                                                              ?.tax,
                                                          }
                                                      : null
                                                  }
                                                  defaultValue={
                                                    item?.chart_of_account_id
                                                      ? {
                                                          id: item
                                                            ?.chart_of_account_id
                                                            ?.id
                                                            ? item
                                                                ?.chart_of_account_id
                                                                ?.id
                                                            : null,
                                                          label:
                                                            item
                                                              ?.chart_of_account_id
                                                              ?.name +
                                                            (item
                                                              ?.chart_of_account_id
                                                              ?.account_type
                                                              ?.name
                                                              ? " (" +
                                                                item
                                                                  ?.chart_of_account_id
                                                                  ?.account_type
                                                                  ?.name +
                                                                ")"
                                                              : "") +
                                                            (item
                                                              ?.chart_of_account_id
                                                              ?.code
                                                              ? " - " +
                                                                item?.chart_of_account_id?.code.toString()
                                                              : "") +
                                                            (item
                                                              ?.chart_of_account_id
                                                              ?.sub_code
                                                              ? "." +
                                                                item?.chart_of_account_id?.sub_code.toString()
                                                              : ""),
                                                          tax: item
                                                            ?.chart_of_account_id
                                                            ?.tax,
                                                        }
                                                      : null
                                                  }
                                                  placeholder="Select Account"
                                                  isClearable={false}
                                                />
                                              </div> */}
                                              <div className="col-md-2 refund-column">
                                                <NumberFormat
                                                  type="text"
                                                  name="amount"
                                                  value={
                                                    item?.amount
                                                      ? item?.amount
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleAmount(
                                                      e,
                                                      index,
                                                      finalData
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    handleAmountBlur(e, index);
                                                  }}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "5px 10px",
                                                    fontSize: "14px",
                                                    width: "100%",
                                                    color: "#000",
                                                  }}
                                                  isAllowed={(values) =>
                                                    MaxLimit(values)
                                                  }
                                                  placeholder={`Amount to be ${
                                                    index === 0
                                                      ? "paid to subby"
                                                      : "withheld in RTA"
                                                  }`}
                                                  decimalScale={2}
                                                  allowNegative={false}
                                                  thousandSeparator={true}
                                                  autoComplete="off"
                                                  onKeyPress={(e) => {
                                                    e.which === 13 &&
                                                      e.preventDefault();
                                                  }}
                                                  className="ant-input record-payment"
                                                />
                                                <span className="text-danger">
                                                  {amountErrors[index]
                                                    ? amountErrors[index]
                                                    : ""}
                                                </span>
                                              </div>
                                              <div
                                                className={
                                                  trust_type === 1
                                                    ? "col-md-3 refund-column"
                                                    : "col-md-3 refund-column"
                                                }
                                              >
                                                <Form.Group>
                                                  <textarea
                                                    name="description"
                                                    placeholder="Add Description"
                                                    rows={2}
                                                    value={
                                                      item?.description
                                                        ? item?.description
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleDescription(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                      handleBlurDescription(
                                                        e,
                                                        index
                                                      );
                                                    }}
                                                  />
                                                  <span className="error">
                                                    {descriptionErrors[index]
                                                      ? descriptionErrors[index]
                                                      : ""}
                                                  </span>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-2 refund-column">
                                                <Form.Group>
                                                  <ReactSelect
                                                    name="bank_id"
                                                    value={
                                                      item?.bank_id
                                                        ? bankListing?.find(
                                                            (bankItem) =>
                                                              bankItem?.value ===
                                                              item?.bank_id
                                                          )
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleBank(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                    }}
                                                    options={bankListing}
                                                    placeholder="Select Bank Name"
                                                    className="bankdropdown"
                                                    classNamePrefix="selectbox"
                                                    isLoading={bankLoading}
                                                    autoComplete="off"
                                                    onKeyPress={(e) => {
                                                      e.which === 13 &&
                                                        e.preventDefault();
                                                    }}
                                                    style={{
                                                      padding: "0px",
                                                    }}
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "grey",
                                                      },
                                                    })}
                                                  />
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-1 refund-column">
                                                <Form.Group>
                                                  <InputMask
                                                    mask="999-999"
                                                    name="bsb"
                                                    value={
                                                      item?.bsb ? item?.bsb : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleBSB(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                    }}
                                                    placeholder="999-999"
                                                    className="ant-input record-payment"
                                                  />
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-1 refund-column">
                                                <Form.Group>
                                                  <InputMask
                                                    type="text"
                                                    name="account_number"
                                                    style={{
                                                      border:
                                                        "1px solid #d9d9d9",
                                                      padding: "5px 10px",
                                                      fontSize: "14px",
                                                      width: "100%",
                                                      color: "#000",
                                                    }}
                                                    onChange={(e) => {
                                                      handleAccount(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                      handleBlurAccount(
                                                        e,
                                                        index
                                                      );
                                                    }}
                                                    value={
                                                      item?.account_number
                                                        ? item?.account_number
                                                        : ""
                                                    }
                                                    mask="999999999"
                                                    maskChar={null}
                                                    autoComplete="off"
                                                    onKeyPress={(e) => {
                                                      e.which === 13 &&
                                                        e.preventDefault();
                                                    }}
                                                    className="ant-input record-payment"
                                                  />
                                                  {/* <Form.Control
                                                    required
                                                    type="text"
                                                    name="account_number"
                                                    style={{
                                                      border:
                                                        "1px solid #d9d9d9",
                                                      padding: "5px 10px",
                                                      fontSize: "14px",
                                                      width: "100%",
                                                      color: "#000",
                                                    }}
                                                    onChange={(e) => {
                                                      handleAccount(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                      handleBlurAccount(
                                                        e,
                                                        index
                                                      );
                                                    }}
                                                    value={
                                                      item?.account_number
                                                        ? item?.account_number
                                                        : ""
                                                    }
                                                  /> */}
                                                  <span className="error">
                                                    {accountErrors[index]
                                                      ? accountErrors[index]
                                                      : ""}
                                                  </span>
                                                </Form.Group>
                                              </div>
                                              {trust_type === 1 && (
                                                <div className="col-md-1  refund-column">
                                                  {finalData?.length !== 1 && (
                                                    <FontAwesomeIcon
                                                      icon={faTrash}
                                                      title="Delete"
                                                      onClick={() => {
                                                        handleDelete(
                                                          finalData,
                                                          index
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                  {finalData?.length === 1 &&
                                                    index === 0 &&
                                                    ((ProjectData?.rta_project
                                                      ?.rta_contractor
                                                      ?.bank_id &&
                                                      ProjectData?.rta_project
                                                        ?.rta_contractor
                                                        ?.bsb !== "000-000") ||
                                                      (ProjectData
                                                        ?.rta_contractor
                                                        ?.bank_id &&
                                                        ProjectData
                                                          ?.rta_contractor
                                                          ?.bsb !==
                                                          "000-000")) && (
                                                      <FontAwesomeIcon
                                                        icon={faPlus}
                                                        title="Add"
                                                        onClick={() => {
                                                          handleAdd(finalData);
                                                        }}
                                                      />
                                                    )}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                    </div>
                                    <div className="refund-card-content-btn-small">
                                      <Button
                                        className="btn-small"
                                        disabled={handlePostDisabled(finalData)}
                                        onClick={() => {
                                          handlePost(finalData);
                                        }}
                                      >
                                        Post
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Container>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={handleConfirmModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            New Trust Payment
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              <p>
                Are your sure you want to add this payment?
                <br />
                Once you confirm, you can't edit and delete this payment.
              </p>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleConfirmModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleSubmitPost(finalSubminData);
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default RecordPayments;
