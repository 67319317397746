import React from "react";
import { Navigate } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/Header/Index";

const AdminRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("bt-auth-token");
  const role = localStorage.getItem("role");

  if (token) {
    if (role === "Admin") {
      return (
        <>
          <Header />
          <Component {...rest} />
        </>
      );
    } else {
      return (
        <>
          <Header />
          <PageNotFound />
        </>
      );
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminRoute;
