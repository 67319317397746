import { faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { API, fileUpload } from "../../../../config";
import { toast } from "react-toastify";

const defaultOption = [
  { label: "Copies Of Contracts", value: 1 },
  { label: "Any Contract Variation/Amendment Documents", value: 2 },
  { label: "Payment Claims/ Schedules Given And Received", value: 3 },
  { label: "Supporting Documents", value: 4 },
  { label: "Others", value: 5 },
];

const newEntryData = { document_type: "", documents: [] };

const arrayRemove = (arr, value) => {
  if (value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }
};

const Upload = ({ setProjectLogo, setProjectName, getProjectData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [Option, setOption] = useState(defaultOption);
  const [finalData, setFinalData] = useState([newEntryData]);

  useEffect(() => {
    // navigate(`/projects/${projectId}/settings?tab=documents&child_tab=upload`, {
    //   replace: true,
    // });
    navigate(`/projects/${projectId}/documents?tab=documents&child_tab=list`, {
      replace: true,
    });
  }, [projectId, navigate]);

  useEffect(() => {
    finalData &&
      finalData?.length > 0 &&
      finalData?.map((item, index) => {
        const documentType = item?.document_type
          ? Option?.find((option) => option === item?.document_type)
          : "";
        documentType && setOption(arrayRemove(Option, documentType));
        return 0;
      });
  }, [finalData, Option]);

  const handleSelectChange = (finalData, finalIndex, e, documentType) => {
    const newArr = finalData.map((obj, i) => {
      if (i === finalIndex) {
        return {
          ...obj,
          document_type: e ? e : "",
        };
      }
      return obj;
    });

    documentType && setOption([...Option, documentType]);
    setFinalData(newArr);
  };

  const handleDocumentChange = (data, finalIndex, file) => {
    // const newDocuments = [...data[finalIndex].documents, file];
    let newData = [...data];

    if (finalIndex > -1) {
      newData[finalIndex] = {
        ...newData[finalIndex],
        documents: [file],
      };
      setFinalData([...newData]);
    }
  };

  const handleDocumentDelete = (finalData, finalIndex, index) => {
    finalData[finalIndex]?.documents.splice(index, 1);

    const newDocuments = [...finalData[finalIndex].documents];
    let newData = [...finalData];

    if (finalIndex > -1) {
      newData[finalIndex] = {
        ...newData[finalIndex],
        documents: newDocuments,
      };
      setFinalData([...newData]);
    }
  };

  const handleFinalDelete = (finalData, finalIndex, finalItem) => {
    finalItem?.document_type &&
      setOption([...Option, finalItem?.document_type]);
    finalData.splice(finalIndex, 1);
    setFinalData([...finalData]);
  };

  // const handleAdd = () => {
  //   setFinalData([...finalData, newEntryData]);
  // };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append(
        "document_type",
        finalData[0]?.document_type?.value
          ? finalData[0]?.document_type?.value
          : ""
      );
      formData.append(
        "document",
        finalData[0]?.documents ? finalData[0]?.documents[0] : ""
      );
      setLoading(true);
      const { data } = await fileUpload(`${API.UPLOAD_DOCUMENT}`, formData);
      if (data) {
        setLoading(false);
        setFinalData([newEntryData]);
        toast.success(data.message);
        navigate(
          `/projects/${projectId}/settings?tab=documents&child_tab=list`,
          {
            replace: true,
          }
        );
      }
    } catch (error) {
      //   setProjectData(value);
      // setShowAccount(false);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="documents-tab">
      <div className="documents-upload row">
        {finalData &&
          finalData?.length > 0 &&
          finalData?.map((finalItem, finalIndex) => {
            return (
              <>
                <div className="documents-upload-content col-md-3">
                  <div className="upload-header">
                    {finalData?.length > 1 && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                          handleFinalDelete(finalData, finalIndex, finalItem);
                        }}
                      />
                    )}
                  </div>
                  <Select
                    options={Option}
                    onChange={(e) => {
                      handleSelectChange(
                        finalData,
                        finalIndex,
                        e,
                        finalItem?.document_type
                      );
                    }}
                    value={
                      finalItem?.document_type ? finalItem?.document_type : ""
                    }
                    classNamePrefix="selectbox"
                  />
                  <div>
                    <div className="upload-div">
                      <div className="modal-body">
                        <Form>
                          <div className="upload-file">
                            <FormControl
                              accept={
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/jpeg, image/jpg, image/x-png, .doc, .docx, .pdf"
                              }
                              type="file"
                              title=""
                              name="documents"
                              className="form-control"
                              id="documents"
                              onChange={(e) => {
                                e.currentTarget.files[0]?.type ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                e.currentTarget.files[0]?.type ===
                                  "application/vnd.ms-excel" ||
                                e.currentTarget.files[0]?.type ===
                                  "image/jpeg" ||
                                e.currentTarget.files[0]?.type ===
                                  "image/jpg" ||
                                e.currentTarget.files[0]?.type ===
                                  "image/png" ||
                                e.currentTarget.files[0]?.type ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                e.currentTarget.files[0]?.type ===
                                  "application/msword" ||
                                e.currentTarget.files[0]?.type ===
                                  "application/pdf"
                                  ? handleDocumentChange(
                                      finalData,
                                      finalIndex,
                                      e.currentTarget.files[0]
                                    )
                                  : toast.error(
                                      "Please upload .pdf, .doc, docx, .xls, .xlsx, .jpeg, .jpg or   .png only."
                                    );
                              }}
                            />
                            <span>
                              <p className="ant-upload-drag-icon">
                                <FontAwesomeIcon
                                  title=""
                                  icon={faUpload}
                                  size="3x"
                                />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                            </span>
                          </div>
                          <div>
                            {finalItem?.documents &&
                              finalItem?.documents?.length > 0 &&
                              finalItem?.documents?.map((item, index) => {
                                return (
                                  <div className="file-names">
                                    <p>{`${item?.name}`}</p>
                                    <div className="remove">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        title="Remove"
                                        onClick={() => {
                                          handleDocumentDelete(
                                            finalData,
                                            finalIndex,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {/* {finalData && finalData?.length < 5 && (
          <div className="add-button">
            <div className="add-document-card">
              <FontAwesomeIcon
                icon={faPlus}
                size="9x"
                onClick={() => {
                  handleAdd();
                }}
              />
            </div>
          </div>
        )} */}
      </div>
      <div className="footer">
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </div>
  );
};
export default Upload;
