import {
  faDownload,
  faEnvelope,
  // faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "underscore";
import { API, defaultLimitPagination, fileUpload, get } from "../../../config";
import { formatNumber } from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";

import ABARefresh from "./ABARefresh";
import ExpandABATransactions from "./ExpandABATransactions";
// import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
import CalenderView from "../../common/CalenderView";
import { DatePicker } from "rsuite";

const ABATransactions = ({ projectData, ProjectReadOnly }) => {
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");

  const [loading, setLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [abaFilesList, setABAFilesList] = useState([]);

  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [showCalender, setShowCalender] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [itemId, setItemId] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [abaDate, setABADate] = useState();

  // *API Call for Fetching Download .ABA File List - Start
  const fetchABAFileList = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${
          API.GET_ABA_LIST_DOWNLOAD
        }?builder_project_id=${projectId}&trust_type=${trust_type}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&aba_file=0&search=${
          searchParam ? searchParam : ""
          // }&date=${abaDate ? moment(abaDate).format("DD-MM-YYYY") : ""}`
        }&date=${abaDate ? abaDate : ""}`
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const abaFilesList = data?.data?.data;
      setABAFilesList(abaFilesList);
      // let id = [];
      // data.data.data.map((item) => {
      //   id.push(item.id);
      // });
      // setId(id);
      return data.data;
    } catch (error) {
      setLoading(false);
      setABAFilesList([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [
    limit,
    page,
    searchParam,
    sortOrder,
    sortField,
    projectId,
    trust_type,
    abaDate,
  ]);

  useEffect(() => {
    fetchABAFileList();
  }, [fetchABAFileList]);
  // *API Call for Fetching Download .ABA File List - End

  // *API Call for Uploaded Status change .ABA File - Start
  const handleUploadedStatusABA = async (id, uploaded = 0) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("uploaded", uploaded === 0 ? 1 : 0);
      formData.append("_method", "PUT");
      const { data } = await fileUpload(
        `${API.GET_ABA_LIST_DOWNLOAD}/${id}`,
        formData
      );
      if (data) {
        toast.success(data.message);
      }
      setLoading(false);
      fetchABAFileList();
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  // *API Call for Uploaded Status change .ABA File - End

  // *API Call for Download .ABA File - Start
  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  // *API Call for Download .ABA File - End

  // *API Call for Refresh .ABA File - Start
  const handleRefreshButton = () => {
    fetchABAFileList();
  };

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/aba?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }&tab=aba-transactions`,
      {
        replace: true,
      }
    );
  }, [
    navigate,
    limit,
    searchParam,
    page,
    sortField,
    sortOrder,
    projectId,
    trustType,
  ]);
  // *API Call for Refresh .ABA File - End

  // *Download .ABA File List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const ABAFileGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: abaFilesList[i]?.id,
        id: abaFilesList[i]?.id,
        aba_file_id: abaFilesList[i]?.aba_file_id,
        created_at: abaFilesList[i]?.created_at
          ? abaFilesList[i]?.created_at?.split(" ")[0]
          : "",
        // bsb: abaFilesList[i]?.bsb ? abaFilesList[i]?.bsb : "-",
        bank_name: abaFilesList[i]?.bank_name
          ? abaFilesList[i]?.bank_name
          : "-",
        description: abaFilesList[i]?.description
          ? abaFilesList[i]?.description
          : "-",
        total_amount: abaFilesList[i]?.total_amount
          ? "$" + formatNumber(Number(abaFilesList[i]?.total_amount))
          : `$0.00`,
        // note: abaFilesList[i]?.note ? abaFilesList[i]?.note : "",
        file: abaFilesList[i]?.file
          ? `${abaFilesList[i]?.file?.split("/").slice(-1)}`
          : "",
        aba_file: (
          <>
            {abaFilesList[i]?.is_reversed !== 1 ? (
              <>
                {abaFilesList[i]?.file ? (
                  <>
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="1x"
                      onClick={() => {
                        downloadLetter(abaFilesList[i]?.file);
                      }}
                      title="Download ABA File"
                    />
                    {ProjectReadOnly ? (
                      <></>
                    ) : (
                      <>
                        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                        <ABARefresh
                          id={abaFilesList[i]?.id}
                          handleRefreshButton={handleRefreshButton}
                        />{" "}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {abaFilesList[i]?.contact?.email !== null ? (
                  <>
                    {abaFilesList[i]?.file && (
                      <>
                        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                      </>
                    )}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      onClick={() => {
                        handleSendEmail(abaFilesList[i]?.id);
                      }}
                      title="Email"
                    />
                  </>
                ) : (
                  <>
                    {abaFilesList[i]?.file && (
                      <>
                        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                      </>
                    )}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      disabled
                      // style={{ pointerEvents: "none" }}
                      className="disabled-font-awesome"
                      onClick={() => {
                        handleSendEmail();
                      }}
                      title="Please Enter Email Address of Contractor"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {abaFilesList[i]?.file ? (
                  <>
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="1x"
                      disabled
                      className="disabled-font-awesome"
                      title="Download ABA File"
                    />
                  </>
                ) : (
                  <></>
                )}
                {abaFilesList[i]?.contact?.email !== null ? (
                  <>
                    {abaFilesList[i]?.file && (
                      <>
                        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                      </>
                    )}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      disabled
                      className="disabled-font-awesome"
                      title="Email"
                    />
                  </>
                ) : (
                  <>
                    {abaFilesList[i]?.file && (
                      <>
                        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                      </>
                    )}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      disabled
                      className="disabled-font-awesome"
                      title="Please Enter Email Address of Contractor"
                    />
                  </>
                )}
              </>
            )}
          </>
        ),
        is_reversed: abaFilesList[i]?.is_reversed,
        uploaded: (
          <>
            {
              <div className="action-column">
                <input
                  type="checkbox"
                  onChange={() =>
                    handleUploadedStatusABA(
                      abaFilesList[i]?.id,
                      abaFilesList[i]?.uploaded
                    )
                  }
                  disabled={abaFilesList[i]?.is_reversed || ProjectReadOnly}
                  checked={abaFilesList[i]?.uploaded}
                />
              </div>
            }
          </>
        ),
      });
    }
    return items;
  };
  const abaFiles = ABAFileGenerator(abaFilesList?.length);

  const rowClasses = (row) => {
    if (row?.is_reversed) {
      return "reversed_row";
    } else {
      return "";
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Created Date",
      style: { width: "10%" },
    },
    // {
    //   dataField: "bsb",
    //   text: "Firm BSB",
    //   style: { width: "10%" },
    // },

    {
      dataField: "bank_name",
      text: "Bank Name",
      style: { width: "12%" },
    },
    {
      dataField: "description",
      text: "Description",
      // style: { width: "30%" },
    },
    {
      dataField: "total_amount",
      text: "Gross",
      style: { width: "15%" },
    },
    {
      dataField: "aba_file",
      text: ".ABA File",
      align: "center",
      style: { width: "10%" },
      headerAlign: "center",
    },
    {
      dataField: "uploaded",
      text: "Uploaded",
      headerAlign: "center",
      align: "center",
      style: { width: "8%" },
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <>
        {" "}
        <ExpandABATransactions id={row?.id} downloadLetter={downloadLetter} />
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  const toggleCalenderView = () => {
    setShowCalender(!showCalender);
  };
  // *Download .ABA File List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - End

  const handleSendEmail = (id) => {
    if (showSendEmail) {
      setItemId();
    } else {
      if (id) {
        setItemId(id);
      } else {
        setItemId();
      }
    }
    setShowSendEmail(!showSendEmail);
  };

  const fetchReEmail = async () => {
    try {
      setIsEmailLoading(true);
      const { data } = await get(`${API.ABA_EMAIL}/${itemId}`);
      data?.message && toast.success(data?.message);
      handleSendEmail();
      setIsEmailLoading(false);
    } catch (error) {
      setIsEmailLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };
  return (
    <>
      {" "}
      <div className="cms-page">
        <div className="page-content-block">
          <div className="">
            <div className="">
              <div className="table-top-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <div className="date-clear-body">
                      {/* <DatePicker
                        name="transaction_date"
                        value={abaDate ? abaDate : ""}
                        placeholder="Select Date to Generate ABA"
                        unclearbale={false}
                        onChange={(e) => {
                          setABADate(e);
                        }}
                      /> */}
                      <DatePicker
                        cleanable={true}
                        onClean={() => {
                          setABADate("");
                        }}
                        onChange={(e) => {
                          setABADate(
                            moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                          );
                        }}
                        format="dd-MM-yyyy"
                        value={
                          abaDate
                            ? String(abaDate)?.includes("-")
                              ? new Date(
                                  String(abaDate)?.split("-")[2],
                                  String(abaDate)?.split("-")[1] - 1,
                                  String(abaDate)?.split("-")[0]
                                )
                              : new Date(abaDate)
                            : null
                        }
                        className="rs-date-body"
                        placeholder="Select Date"
                        placement="autoVerticalStart"
                      />
                      {/* <FontAwesomeIcon
                        className="clear-button-selection"
                        icon={faXmark}
                        size="1x"
                        onClick={() => {
                          setABADate();
                        }}
                      /> */}
                    </div>
                    <div className="search-section">
                      <Form>
                        <FormControl
                          onChange={(e) => handleSearchChange(e)}
                          type="text"
                          value={searchParamData}
                          placeholder="Search Payments ABA"
                        />
                        <Button className="fa fa-search">Search</Button>
                      </Form>
                    </div>
                  </div>
                  <Button
                    style={{
                      padding: "10px 15px",
                      marginLeft: "15px",
                    }}
                    variant="primary"
                    onClick={toggleCalenderView}
                  >
                    Calendar View
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {loading && <Spin />}
                <BootstrapTable
                  keyField="id"
                  remote
                  data={abaFiles}
                  columns={columns}
                  rowClasses={rowClasses}
                  onTableChange={handleTableChange}
                  expandRow={expandRow}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCalender && (
        <Modal
          show={showCalender}
          onHide={toggleCalenderView}
          dialogClassName="small-review payment-schedule-calender-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Calender View
          </Modal.Header>
          <Modal.Body>
            <CalenderView projectData={projectData} />
          </Modal.Body>
        </Modal>
      )}
      {showSendEmail && (
        <Modal
          size="lg"
          show={showSendEmail}
          onHide={() => {
            handleSendEmail();
          }}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Send E-mail
          </Modal.Header>
          <Modal.Body>
            {isEmailLoading && <Spin />}
            <div className="modal-body">
              Are you sure you want to email this receipt?
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  fetchReEmail();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleSendEmail();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ABATransactions;
