import React, { useEffect, useState } from "react";
import logo from "../../assets/images/BuildTrust-white.png";
import { imageDefaultPrefixPath } from "../../config";
import { checkIfFileExists } from "../../pages/common/Misc";

const Sitelogo = () => {
  const role = localStorage.getItem("role");
  const [accountingFirmLogo, setAccountingFirmLogo] = useState("");
  const profile = localStorage.getItem("bt-accounting-firm-logo")
    ? localStorage.getItem("bt-accounting-firm-logo")
    : "";
  const token = localStorage.getItem("bt-auth-token");

  useEffect(() => {
    if (profile) {
      checkIfFileExists(`${imageDefaultPrefixPath}${profile}`, (exists) => {
        if (exists) {
          setAccountingFirmLogo(`${imageDefaultPrefixPath}${profile}`);
        } else {
          setAccountingFirmLogo();
        }
      });
    }
  }, [profile]);

  return (
    <div className="sitelogo">
      <img
        src={logo}
        alt="BuildTrust"
        style={{ maxWidth: "500px", filter: "brightness(100)" }}
      />
      {/* <span>Beta</span> */}
      {token &&
        role !== "Client" &&
        role !== "Admin" &&
        profile &&
        accountingFirmLogo && (
          <div className="ac-branding-logo">
            <img src={accountingFirmLogo} alt="" className="ac-firm-logo" />
          </div>
        )}
    </div>
  );
};

export default Sitelogo;
