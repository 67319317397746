/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  faCheck,
  faEllipsisV,
  faGrip,
  faListSquares,
  faMoneyBillTransfer,
  faPaperPlane,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
} from "react-bootstrap";
import Spin from "../../../pages/common/Spin";
import { API, defaultLimitPagination, fileUpload, get } from "../../../config";
import splitIcon from "../../../assets/images/split.svg";
import AddNewContactModal from "./components/AddNewContactModal";
import InfinityScrollComponent from "../../common/InfinityScrollComponent";
import ReconcileTransactionDelete from "./components/ReconcileTransactionDelete";
import {
  formatName,
  formatNumber,
  // formatName,
  // formatNumber,
  formatNumberWithoutComma,
  removeDuplicates,
} from "../../common/Misc";
import {
  getNextPageData,
  getUnderReviewedNextPageData,
  refreshBankTransactions,
} from "./components/TransactionsHelper";
import AddNewAccountModal from "./components/AddNewAccountModal";
import ChartOfAccountSelect from "../../common/ChartOfAccountSelect";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../common/Pagination";
import DateRangeDataPicker from "rsuite/DateRangePicker";
import SearchContractor from "./components/SearchContractor";
import SearchContractorForList from "./components/SearchContractorForList";
import NewExpandedRowForReconcileTransaction from "./components/NewExpandedRowForReconcileTransaction";
import ReactSelect from "react-select";

const GSTOptions = [
  { label: "GST 10%", value: 1 },
  { label: "GST Excluded", value: 0 },
  { label: "BAS Excluded", value: 2 },
  { label: "GST on Imports", value: 3 },
];

const NewReconcileTransactions = ({
  setBalanceInfo,
  isUnderReviewed,
  projectName,
  builderId,
  setValueRange,
  valueRange,
}) => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [data, setData] = useState([]);
  const [latestStatement, setLatestStatement] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [showSearchIndexs, setShowSearchIndexs] = useState([]);
  const [bankReconcilationId, setBankReconcilationId] = useState();
  const [searchRecord, setSearchRecord] = useState();
  const [showSearchIndexsForList, setShowSearchIndexsForList] = useState([]);
  const [showSearchPopUp, setShowSearchPopUp] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [lastElement, setLastElement] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [bankTransactionId, setBankTransactionId] = useState();
  const [readyToBeReconciled, setReadyToBeReconciled] = useState("");
  const [addNewContact, setAddNewContact] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();

  const [itemId, setItemId] = useState();
  const [splitAccountId, setSplitAccountId] = useState();
  const [splitGst, setSplitGst] = useState();
  const [splitDescription, setSplitDescription] = useState();
  const [split2Amount, setSplit2Amount] = useState();
  const [split1Amount, setSplit1Amount] = useState();

  const [amount1, setAmount1] = useState();
  const [amount2, setAmount2] = useState();

  const [addNewAccount, setAddNewAccount] = useState(false);
  const [splitAccountVal, setSplitAccountVal] = useState();
  const [splitGstVal, setSplitGstVal] = useState();

  const [splitContractor, setSplitContractor] = useState();

  const [dataForList, setDataForList] = useState([]);
  const [loadingForList, setLoadingForList] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  // eslint-disable-next-line no-unused-vars
  const [expand, setExpand] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [rowId, setRowId] = useState(null);

  const [view, setView] = useState("grid");
  const [showMemoriseModal, setShowMemoriseModal] = useState(false);
  const [memoriseDataList, setMemoriseDataList] = useState();

  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("ASC");

  const sortOptions = [
    {
      label: "Oldest First",
      value: "ASC",
      field: "date",
    },
    {
      label: "Latest First",
      value: "DESC",
      field: "date",
    },
  ];

  const handleReconcileDateRange = (e) => {
    setValueRange(e);
  };

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  }

  const dateFilterRange = [
    {
      label: "This Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Quarter",
      value: quarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Financial Year",
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Quarter",
      value: perviousQuarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Financial Year",
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
  ];

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  // const [builderId, setBuilderId] = useState();

  const [showRefresh, setShowRefresh] = useState(false);

  const defaultDateRange = [
    new Date(new Date().getFullYear(), 0, 1),
    new Date(),
  ];

  const { afterToday } = DateRangeDataPicker;

  const [dateValue, setDateValue] = useState(defaultDateRange);
  const [startDate, setStartDate] = useState(
    defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );

  const fetchReconcileTransaction = useCallback(async () => {
    if (builderId !== null && projectId !== null) {
      try {
        setLoading(true);
        let URL = `${
          API.BANK_RECONCILIATION_LIST
        }?builder_id=${builderId}&builder_project_id=${projectId}&trust_type=${trust_type}&sort_column=${
          sortField ? sortField : ""
        }&from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&limit=${
          currentPage * 10
        }&page=${1}`;
        if (isUnderReviewed) {
          URL = `${
            API.BANK_RECONCILIATION_LIST
          }?builder_id=${builderId}&builder_project_id=${projectId}&trust_type=${trust_type}&sort_column=${
            sortField ? sortField : ""
          }&from=${
            valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
          }&to=${
            valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
          }&sort_order=${sortOrder ? sortOrder : ""}&limit=${
            currentPage * 10
          }&page=${1}&under_review=1`;
        }
        get(URL).then((response) => {
          setLoading(false);
          setBalanceInfo(response?.data?.accounts);

          if (response.data.data.per_page <= 10) {
            setCurrentPage(response.data.data.current_page);
          }

          setData(response.data.data);
          // setBuilderId(response?.data?.client_business?.builder_id);

          const arrayForReadyToBeReconciled =
            response.data.data.data &&
            response.data.data.data.length > 0 &&
            response.data.data.data.map((item) => {
              const value = item?.bank_reconciliation?.map((data, i) => {
                const main =
                  data?.chart_of_account_id &&
                  (data?.gst == 0 || data?.gst) &&
                  data?.contact_id &&
                  data?.description &&
                  data?.amount
                    ? true
                    : false;
                return main;
              });
              const finalValue =
                value?.find((val) => val === false) === false ? true : false;

              return finalValue ? 0 : 1;
            });

          let count = 0;
          if (arrayForReadyToBeReconciled) {
            arrayForReadyToBeReconciled.forEach((element) => {
              if (element === 1) {
                count += 1;
              }
            });
            setReadyToBeReconciled(count);
          }
          const isMoreDataExist =
            response.data.data.data.length < response.data.data.total &&
            response.data.data?.next_page_url !== "" &&
            response.data.data?.next_page_url !== null;
          setHasMore(isMoreDataExist);
          setLatestStatement(response?.data?.last_import_date);
          var matchedRecords = [];
          isUnderReviewed === true
            ? response.data.data.data.forEach((element) => {
                element.bank_reconciliation.forEach((item) => {
                  matchedRecords.push(element.id);
                });
              })
            : response.data.data.data.forEach((element) => {
                element.bank_reconciliation.forEach((item) => {
                  if (
                    item?.chart_of_account_id &&
                    item?.contact_id &&
                    (item?.gst || item?.gst == 0) &&
                    item?.description
                  ) {
                    matchedRecords.push(element.id);
                  }
                });
              });

          let uniqueChars = [];
          matchedRecords.forEach((c) => {
            if (!uniqueChars.includes(c)) {
              uniqueChars.push(c);
            }
          });

          // setSplitGst("");
          // setSplitAccountId("");
          // setSplitAccountVal("");
          // setSplitGstVal("");
          setSelectedItems(uniqueChars);
          setLoading(false);
        });
      } catch (e) {
        setData([]);
        setSplitGstVal("");
        setSplitGst("");
        setSplitAccountId("");
        setSplitAccountVal("");
        setBalanceInfo([]);
        setLoading(false);
        const errorMessage = e.response?.data?.errors?.bt_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    }
  }, [
    projectId,
    isUnderReviewed,
    currentPage,
    setBalanceInfo,
    trust_type,
    builderId,
    sortField,
    sortOrder,
    valueRange,
  ]);
  const fetchReconcileTransactionForList = useCallback(async () => {
    if (builderId !== null && projectId !== null) {
      try {
        setLoadingForList(true);
        let URL = `${
          API.BANK_RECONCILIATION_LIST
        }?builder_id=${builderId}&builder_project_id=${projectId}&trust_type=${trust_type}&sort_column=${
          sortField ? sortField : ""
        }&from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}`;
        if (isUnderReviewed) {
          URL = `${
            API.BANK_RECONCILIATION_LIST
          }?builder_id=${builderId}&builder_project_id=${projectId}&trust_type=${trust_type}&sort_column=${
            sortField ? sortField : ""
          }&from=${
            valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
          }&to=${
            valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
          }&sort_order=${sortOrder ? sortOrder : ""}&limit=${
            limit ? limit : defaultLimitPagination
          }&page=${page ? page : 1}&under_review=1`;
        }
        get(URL).then((response) => {
          setLoadingForList(false);
          setBalanceInfo(response?.data?.accounts);
          setLimit(response.data.data?.per_page);
          setPage(response.data.data?.current_page);
          setTotal(response.data.data?.total);
          setFrom(response.data.data?.from);
          setTo(response.data.data?.to);

          setDataForList(response.data.data);
          const arrayForReadyToBeReconciled =
            response.data.data.data &&
            response.data.data.data.length > 0 &&
            response.data.data.data.map((item) => {
              const value = item?.bank_reconciliation?.map((data, i) => {
                const main =
                  data?.chart_of_account_id &&
                  (data?.gst == 0 || data?.gst) &&
                  data?.contact_id &&
                  data?.description &&
                  data?.amount
                    ? true
                    : false;
                return main;
              });
              const finalValue =
                value?.find((val) => val === false) === false ? true : false;

              return finalValue ? 0 : 1;
            });

          let count = 0;
          if (arrayForReadyToBeReconciled) {
            arrayForReadyToBeReconciled.forEach((element) => {
              if (element === 1) {
                count += 1;
              }
            });
            setReadyToBeReconciled(count);
          }
          setLatestStatement(response?.data?.last_import_date);

          var matchedRecords = [];
          isUnderReviewed === true
            ? response.data.data.data.forEach((element) => {
                element.bank_reconciliation.forEach((item) => {
                  matchedRecords.push(element.id);
                });
              })
            : response.data.data.data.forEach((element) => {
                element.bank_reconciliation.forEach((item) => {
                  if (
                    item?.chart_of_account_id &&
                    item?.contact_id &&
                    (item?.gst || item?.gst == 0) &&
                    item?.description
                  ) {
                    matchedRecords.push(element.id);
                  }
                });
              });

          let uniqueChars = [];
          matchedRecords.forEach((c) => {
            if (!uniqueChars.includes(c)) {
              uniqueChars.push(c);
            }
          });
          setSelectedItems(uniqueChars);
        });
      } catch (e) {
        setDataForList([]);
        setBalanceInfo([]);
        setLoadingForList(false);
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    }
  }, [
    projectId,
    isUnderReviewed,
    setBalanceInfo,
    page,
    limit,
    trust_type,
    builderId,
    sortField,
    sortOrder,
    valueRange,
  ]);

  const handleDelete = () => {
    // fetchReconcileTransaction();
    view === "grid" && fetchReconcileTransaction();
    view === "list" && fetchReconcileTransactionForList();
    setShowDelete(false);
  };

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=${
        isUnderReviewed ? "under-review" : "reconcile-transactions"
      }`
    );
  }, [navigate, isUnderReviewed, projectId, trustType]);

  const handleAllCheckBox = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    if (itemName === "checkall") {
      setAllChecked(checked);
      if (checked) {
        const allData = [];

        isUnderReviewed === true
          ? data.data.forEach((item) => {
              allData.push(item.id);
            })
          : data.data.forEach((item) => {
              if (!handleReconcileDisable(item)) {
                allData.push(item.id);
              }
            });
        setSelectedItems(allData);
      } else {
        setSelectedItems([]);
      }
    }
  };

  const handleAccountSelection = async (
    account,
    ItemID,
    item,
    transactionData
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("chart_of_account_id", account?.id);
      formData.append("gst", account?.tax);
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleContractorSelection = async (
    contractor,
    ItemID,
    item,
    transactionData
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("contact_id", contractor?.id);
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleContactNameSelection = async (
    contractor,
    ItemID,
    item,
    baseType,
    bankTransaction
  ) => {
    if (bankTransaction?.bank_reconciliation?.length === 1) {
      try {
        setLoading(true);
        // setIsLoadingCustomerDetail(true);
        const formData = new FormData();
        formData.append("contact_id", contractor?.id);
        formData.append(
          "description",
          bankTransaction?.bank_reconciliation[0]?.description
            ? bankTransaction?.bank_reconciliation[0]?.description
            : bankTransaction?.description
        );
        formData.append("_method", "put");
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankTransaction?.bank_reconciliation[0]?.id}`,
          formData,
          {}
        );
        if (response.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }

    if (bankTransaction?.bank_reconciliation?.length === 2) {
      try {
        setLoading(true);
        // setIsLoadingCustomerDetail(true);
        const formData = new FormData();
        formData.append("contact_id", contractor?.id);
        formData.append(
          "description",
          bankTransaction?.bank_reconciliation[0]?.description
            ? bankTransaction?.bank_reconciliation[0]?.description
            : bankTransaction?.description
        );
        formData.append("_method", "put");

        const formData2 = new FormData();
        formData2.append("contact_id", contractor?.id);
        formData2.append(
          "description",
          bankTransaction?.bank_reconciliation[1]?.description
            ? bankTransaction?.bank_reconciliation[1]?.description
            : bankTransaction?.description
        );
        formData2.append("_method", "put");

        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankTransaction?.bank_reconciliation[0]?.id}`,
          formData,
          {}
        );

        const response2 = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankTransaction?.bank_reconciliation[1]?.id}`,
          formData2,
          {}
        );
        if (response.status === 200 && response2.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
    // }
  };

  const handleBlurDescription = async (
    ItemID,
    item,
    transactionData,
    // chartOfAccountId,
    chartOfAccount
  ) => {
    const GST = item?.gst
      ? item?.gst
      : chartOfAccount
      ? chartOfAccount?.tax
      : "";
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("gst", GST);
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleGSTSelection = async (item, ItemID) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("gst", item);
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleReconcile = async (ItemID) => {
    if (ItemID !== null) {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append("builder_project_id", projectId);
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/reconcile/${ItemID}`,
          formData
        );
        if (response.status === 200) {
          toast.success(response.data.message);
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleReconcileAll = () => {
    if (selectedItems.length === 0) {
      toast.warning("Please select atleast one bank statement reconcilation");
    } else {
      if (selectedItems.length > 0) {
        const formData = new FormData();
        formData.append("builder_project_id ", projectId);
        selectedItems.map((itemData) => {
          const selectedData = data?.data?.find(
            (item) => item?.id === itemData
          );

          const value = selectedData?.bank_reconciliation?.map((data, i) => {
            const main =
              data?.chart_of_account_id &&
              (data?.gst == "0" || data?.gst) &&
              data?.contact_id &&
              data?.description &&
              data?.amount &&
              selectedData?.id;
            return main;
          });
          removeDuplicates(value)[0] &&
            formData.append(
              "bank_transaction_ids[]",
              removeDuplicates(value)[0]
            );

          return formData;
        });

        if ([...formData.entries()].length) {
          fileUpload(API.BANK_RECONCILIATION_BULK, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success(response.data.message);
                view === "grid" && fetchReconcileTransaction();
                view === "list" && fetchReconcileTransactionForList();
              }
            })
            .catch((error) => {
              if (error?.response?.data?.errors) {
                Object.keys(error?.response?.data?.errors).forEach((key) => {
                  toast.error(error?.response?.data?.errors[key][0]);
                });
              } else {
                toast.error(error?.message);
              }
            });
        }
      }
    }
  };

  const sendReview = async (ItemID, Item) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/send-for-review/${ItemID}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  const handleDescription = (e, Item) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex(
      (el) =>
        el?.bank_reconciliation[0]?.id === id ||
        el?.bank_reconciliation[1]?.id === id
    );
    if (Item?.bank_reconciliation[0]?.id === id) {
      Item.bank_reconciliation[0].description = value;
    }
    if (Item?.bank_reconciliation[1]?.id === id) {
      Item.bank_reconciliation[1].description = value;
    }
    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: Item?.bank_reconciliation,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleCheckBox = (id) => {
    const oldIndex = [...selectedItems];

    if (selectedItems.indexOf(id) === -1) {
      oldIndex.push(id);
    } else {
      oldIndex.splice(selectedItems.indexOf(id), 1);
    }
    if (isUnderReviewed === true) {
      if (oldIndex?.length === data?.data?.length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
      // setAllChecked(true);
    } else {
      if (oldIndex.length === readyToBeReconciled) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }

    setSelectedItems(oldIndex);
  };

  const handleReconcileDisable = (item) => {
    const value = item?.bank_reconciliation?.map((data, i) => {
      const main =
        data?.chart_of_account_id &&
        (data?.gst === "0" || data?.gst) &&
        data?.contact_id &&
        data?.description &&
        data?.amount
          ? true
          : false;
      return main;
    });
    const finalValue =
      value?.find((val) => val === false) === false ? true : false;
    // return !item?.chart_of_account_id || !item?.contact_id;
    return finalValue;
  };

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageData = isUnderReviewed
      ? await getUnderReviewedNextPageData(
          currentPage + 1,
          projectId,
          trust_type,
          sortField,
          sortOrder
        )
      : await getNextPageData(
          currentPage + 1,
          projectId,
          trust_type,
          sortField,
          sortOrder
        );

    const newObj = {
      ...nextPageData,
      data: [...data.data, ...nextPageData.data],
    };

    setData(newObj);
    setCurrentPage(newObj.current_page);
    setIsLoading(false);
    const isMoreDataExist =
      newObj.data.length < newObj.total &&
      nextPageData?.next_page_url !== "" &&
      nextPageData?.next_page_url !== null;
    setHasMore(isMoreDataExist);
  };

  const toggleRefreshModal = () => {
    setShowRefresh(!showRefresh);
  };

  const handleDateRefreshRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  const handleRefresh = async () => {
    if (projectId) {
      setLoading(true);
      if (startDate && endDate) {
        const getTransaction = await refreshBankTransactions(
          projectId,
          trust_type,
          startDate,
          endDate
        );
        setLoading(false);
        if (getTransaction?.status === 200) {
          toast.success(getTransaction?.message);
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        toggleRefreshModal();
      } else {
        toast.error("Start Date and End Date both are required");
      }
    }
  };

  const handleSplitDisable = (item) => {
    return item?.bank_reconciliation?.length === 1 ? false : true;
  };

  const handleSubmitSplit = async (
    ItemId,
    bankReconcilationId,
    contactId,
    description,
    accountType,
    index
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const formData1 = new FormData();
      formData.append("bank_transaction_id", ItemId ? ItemId : "");
      formData.append(
        "chart_of_account_id",
        splitAccountVal ? splitAccountVal : ""
      );
      formData.append("gst", splitGst ? splitGst : splitAccountId?.tax);
      formData.append(
        "contact_id",
        splitContractor?.id ? splitContractor?.id : ""
      );
      formData.append("description", splitDescription ? splitDescription : "");
      formData.append("amount", split2Amount ? split2Amount : "");
      trust_type === 0
        ? formData.append(
            "builder_project_id",
            selectedProjectId
              ? selectedProjectId
              : dataForList?.data[index]?.bank_reconciliation[0]
                  ?.builder_project_id
          )
        : formData.append("builder_project_id", projectId ? projectId : "");

      formData1.append("amount", split1Amount ? split1Amount : "");
      formData1.append("description", description ? description : "");
      formData1.append("contact_id", contactId ? contactId : "");
      formData1.append("chart_of_account_id", accountType ? accountType : "");
      formData1.append("_method", "put");

      const response1 = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${bankReconcilationId}`,
        formData1,
        {}
      );

      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}`,
        formData,
        {}
      );
      if (response.status === 200 && response1.status === 200) {
        setItemId("");
        setSplitAccountId("");
        setSplitGst("");
        setSplitDescription("");
        setSplit2Amount("");
        setSplit1Amount("");
        setSelectedProjectId("");
        // toast.success(response.data.message);
        // fetchReconcileTransaction();
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleAmount = (e, Item) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex(
      (el) =>
        el?.bank_reconciliation[0]?.id === id ||
        el?.bank_reconciliation[1]?.id === id
    );

    if (Item?.bank_reconciliation[0]?.id === id) {
      /^\d*(\.\d{0,2})?$/.test(value) === true
        ? parseFloat(value) > parseFloat(Item?.amount)
          ? setAmount1(Item?.amount)
          : setAmount1(value)
        : setAmount1(amount1);

      Item.bank_reconciliation[0].amount =
        /^\d*(\.\d{0,2})?$/.test(value) === true
          ? parseFloat(value) > parseFloat(Item?.amount)
            ? Item?.amount
            : value
          : amount1;
    }

    if (Item?.bank_reconciliation[1]?.id === id) {
      /^\d*(\.\d{0,2})?$/.test(value) === true
        ? parseFloat(value) > parseFloat(Item?.amount)
          ? setAmount2(Item?.amount)
          : setAmount2(value)
        : setAmount2(amount2);

      Item.bank_reconciliation[1].amount =
        /^\d*(\.\d{0,2})?$/.test(value) === true
          ? parseFloat(value) > parseFloat(Item?.amount)
            ? Item?.amount
            : value
          : amount2;
    }

    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: Item?.bank_reconciliation,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleBlurAmount = async (
    ItemID,
    item,
    transactionData,
    chartOfAccount
  ) => {
    const GST1 = item?.gst
      ? item?.gst
      : chartOfAccount
      ? chartOfAccount?.tax
      : "";
    const GST2 = transactionData?.bank_reconciliation?.find(
      (val) => val?.id !== ItemID
    )?.gst
      ? transactionData?.bank_reconciliation?.find((val) => val?.id !== ItemID)
          ?.gst
      : transactionData?.bank_reconciliation?.find((val) => val?.id !== ItemID)
          ?.chart_of_account_id
      ? transactionData?.chart_of_account?.tax
      : "";
    try {
      setLoading(true);
      const formData = new FormData();
      const formData2 = new FormData();
      const amount1 = item?.amount
        ? item?.amount > transactionData?.amount
          ? transactionData?.amount
          : item?.amount
        : 0.0;

      const amount2 = transactionData?.amount - amount1;
      const itemId1 = ItemID;
      const itemId2 = transactionData?.bank_reconciliation?.find(
        (val) => val?.id !== ItemID
      )?.id;

      formData.append("amount", formatNumberWithoutComma(amount1));
      formData.append("_method", "put");
      formData.append("gst", GST1);

      formData2.append("amount", formatNumberWithoutComma(amount2));
      formData2.append("_method", "put");
      formData2.append("gst", GST2);

      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${itemId1}`,
        formData,
        {}
      );
      const response2 = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${itemId2}`,
        formData2,
        {}
      );
      if (response.status === 200 && response2.status === 200) {
        // toast.success(response.data.message);
        // fetchReconcileTransaction();
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };
  const handleEmailForClarificationAll = () => {
    if (selectedItems.length === 0) {
      toast.warning(
        "Please select atleast one bank statement for clarification"
      );
    } else {
      if (selectedItems.length > 0) {
        setLoading(true);
        const formData = new FormData();
        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trust_type);
        formData.append("project_url", window.location.href);
        selectedItems.map((itemData) => {
          formData.append("bank_transaction_ids[]", itemData);
          return formData;
        });

        if ([...formData.entries()].length) {
          fileUpload(API.EMAIL_FOR_CLARIFICATION, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              if (response.status === 200) {
                setLoading(false);
                toast.success(response.data.message);
                // fetchReconcileTransaction();
                view === "grid" && fetchReconcileTransaction();
                view === "list" && fetchReconcileTransactionForList();
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error?.response?.data?.errors) {
                Object.keys(error?.response?.data?.errors).forEach((key) => {
                  toast.error(error?.response?.data?.errors[key][0]);
                });
              } else {
                toast.error(error?.message);
              }
            });
        }
      }
    }
  };

  const handleSplitOkDisable = (item) => {
    const isDisable =
      item?.bank_reconciliation[0]?.id &&
      item?.bank_reconciliation[0]?.chart_of_account_id &&
      (parseInt(item?.bank_reconciliation[0]?.gst) === 1 ||
        parseInt(item?.bank_reconciliation[0]?.gst) === 0) &&
      item?.bank_reconciliation[0]?.contact_id &&
      item?.bank_reconciliation[0]?.description &&
      split1Amount &&
      split2Amount &&
      (splitAccountVal || splitAccountId) &&
      (parseInt(splitGst) ||
        parseInt(splitGst) === 0 ||
        parseInt(splitGstVal) ||
        parseInt(splitGstVal) === 0) &&
      splitContractor &&
      splitDescription
        ? false
        : true;

    return isDisable;
  };

  const toggleMemoriseModal = () => {
    setShowMemoriseModal(!showMemoriseModal);
  };

  const handleLeftMemorise = async (
    accouintId,
    description,
    contactId,
    descriptionMemorise
  ) => {
    const formData = new FormData();
    formData.append("builder_project_id", projectId);
    formData.append("term", description);
    formData.append("chart_of_account_id", accouintId);
    formData.append("description", descriptionMemorise);
    contactId && formData.append("contact_id", contactId ? contactId : "");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_TERM}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleLeftListMemorise = async (
    accouintId,
    description,
    contactId,
    descriptionMemorise
  ) => {
    const formData = new FormData();
    formData.append("builder_project_id", projectId);
    formData.append("term", description);
    formData.append("chart_of_account_id", accouintId);
    formData.append("description", descriptionMemorise);
    contactId && formData.append("contact_id", contactId ? contactId : "");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_TERM}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        setMemoriseDataList();
        toggleMemoriseModal();
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleMemoriseDescription = (e, Item) => {
    const { id, value } = e.target;

    let newData = [...data.data];
    let index = newData.findIndex(
      (el) =>
        el?.bank_reconciliation[0]?.id === id ||
        el?.bank_reconciliation[1]?.id === id
    );
    if (Item?.bank_reconciliation[0]?.id === id) {
      Item.bank_reconciliation[0].term = value;
    }
    if (Item?.bank_reconciliation[1]?.id === id) {
      Item.bank_reconciliation[1].term = value;
    }
    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: Item?.bank_reconciliation,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleBlurComment = async (itemId, Item, comment) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("comment", comment ? comment : "");
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_TRANSACTION}/${itemId}`,
        formData,
        {}
      );
      if (response.status === 200) {
        // toast.success(response.data.message);
        // fetchReconcileTransaction();
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleAddNewAccount = () => {
    setAddNewAccount(!addNewAccount);
  };

  const handleComment = (e, Item) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex((el) => el?.id === id);

    if (index > -1) {
      newData[index] = {
        ...newData[index],
        comment: value,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleSplitAddAccount = (account) => {
    setSplitAccountVal(account?.id);
    setSplitAccountId(account);
    setSplitGst(account?.tax);
    setSplitGstVal(account?.tax);
  };

  const handleSplitAddContractor = (account) => {
    setSplitContractor(account);
  };

  const handleSearchCloseForList = (isUpdate = false) => {
    setSplitContractor("");
    setBankReconcilationId();
    setShowSearchPopUp(false);
    view === "list" && setShowSearchIndexsForList([-1]);
    if (isUpdate) {
      view === "list" && fetchReconcileTransactionForList();
    }
  };

  const listViewDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: dataForList?.data[i]?.id,

        check: (
          <div className="reconcile-item-list-data refund-checkbox">
            <Form.Check
              type="checkbox"
              disabled={
                isUnderReviewed === true
                  ? false
                  : handleReconcileDisable(dataForList?.data[i])
              }
              checked={
                isUnderReviewed === true
                  ? selectedItems.indexOf(dataForList?.data[i].id) >= 0
                  : handleReconcileDisable(dataForList?.data[i]) === false
                  ? selectedItems.indexOf(dataForList?.data[i].id) >= 0
                  : false
              }
              onChange={() => {
                handleCheckBox(dataForList?.data[i].id);
              }}
            ></Form.Check>
          </div>
        ),

        date: dataForList?.data[i]?.transactionDate
          ? moment(dataForList?.data[i]?.transactionDate, "DD-MM-YYYY").format(
              "DD-MM-YYYY"
            )
          : "",
        description: dataForList?.data[i]?.description
          ? dataForList?.data[i]?.description
          : "",
        debit:
          dataForList?.data[i]?.baseType === 1
            ? ""
            : `$${formatNumber(Number(dataForList?.data[i]?.amount))}`,
        credit:
          dataForList?.data[i]?.baseType === 1
            ? `$${formatNumber(Number(dataForList?.data[i]?.amount))}`
            : "",

        review_notes: isUnderReviewed ? (
          <Form.Control
            required
            type="text"
            id={dataForList?.data[i].id}
            placeholder="Add Comment"
            value={
              dataForList?.data[i].comment ? dataForList?.data[i].comment : ""
            }
            onChange={(e) => {
              handleComment(e, dataForList?.data[i]);
            }}
            onBlur={() =>
              handleBlurComment(
                dataForList?.data[i]?.id,
                dataForList?.data[i],
                dataForList?.data[i]?.comment
              )
            }
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  "Comment can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
              },
            ]}
            style={{
              border: "1px solid #d9d9d9",
              padding: "5px 10px",
              fontSize: "14px",
            }}
          />
        ) : (
          ""
        ),
        account: (
          <ChartOfAccountSelect
            regionName={i.toString()}
            name="chart_of_account_id"
            onChange={(account) =>
              handleAccountSelection(
                account,
                dataForList?.data[i]?.bank_reconciliation[0]?.id,
                dataForList?.data[i]?.bank_reconciliation[0],
                dataForList?.data[i]
              )
            }
            className={"contactname-select"}
            projectId={
              dataForList?.data[i]?.bank_reconciliation[0]?.builder_project_id
            }
            value={
              dataForList?.data[i]?.bank_reconciliation[0]?.chart_of_account
                ? {
                    id: dataForList?.data[i]?.bank_reconciliation[0]
                      ?.chart_of_account?.id
                      ? dataForList?.data[i]?.bank_reconciliation[0]
                          ?.chart_of_account?.id
                      : null,
                    label:
                      dataForList?.data[i]?.bank_reconciliation[0]
                        ?.chart_of_account?.name +
                      (dataForList?.data[i]?.bank_reconciliation[0]
                        ?.chart_of_account?.account_type?.name
                        ? " (" +
                          dataForList?.data[i]?.bank_reconciliation[0]
                            ?.chart_of_account?.account_type?.name +
                          ")"
                        : "") +
                      (dataForList?.data[i]?.bank_reconciliation[0]
                        ?.chart_of_account?.code
                        ? " - " +
                          dataForList?.data[
                            i
                          ]?.bank_reconciliation[0]?.chart_of_account?.code.toString()
                        : "") +
                      (dataForList?.data[i]?.bank_reconciliation[0]
                        ?.chart_of_account?.sub_code
                        ? "." +
                          dataForList?.data[
                            i
                          ]?.bank_reconciliation[0]?.chart_of_account?.sub_code.toString()
                        : ""),
                    tax: dataForList?.data[i]?.bank_reconciliation[0]
                      ?.chart_of_account?.tax,
                  }
                : null
            }
            placeholder={"Select Account"}
            isClearable={false}
          />
        ),
        contact_name: (
          <input
            regionName={i.toString()}
            placeholder="Search Contractor"
            className={"contactname-select"}
            readOnly
            style={{
              border: "1px solid #d9d9d9",
              padding: "5px 10px",
              fontSize: "14px",
              width: "calc(100%)",
              borderRadius: "6px",
            }}
            onClick={() => {
              const oldIndex = [...showSearchIndexsForList];
              if (
                showSearchIndexsForList.indexOf(dataForList?.data[i].id) === -1
              ) {
                oldIndex.push(dataForList?.data[i].id);
              } else {
                oldIndex.splice(
                  showSearchIndexsForList.indexOf(dataForList?.data[i].id),
                  1
                );
              }
              setShowSearchIndexsForList(oldIndex);
              setSearchRecord(dataForList?.data[i]);
              setShowSearchPopUp(true);
              setBankReconcilationId(
                dataForList?.data[i]?.bank_reconciliation[0]?.id
              );
            }}
            defaultValue={
              dataForList?.data[i]?.bank_reconciliation[0]?.contact
                ? dataForList?.data[i]?.bank_reconciliation[0]?.contact
                    ?.is_pta === 0
                  ? `${formatName(
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.first_name,
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.last_name
                    )} (RTA)`
                  : dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.is_pta === 1
                  ? `${formatName(
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.first_name,
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.last_name
                    )} (PTA)`
                  : dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.is_pta === 2
                  ? `${formatName(
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.first_name,
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.last_name
                    )} (Trustee's Business/Withdrawal)`
                  : dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.is_pta === 3
                  ? `${formatName(
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.first_name,
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.last_name
                    )} (Principal)`
                  : formatName(
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.first_name,
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact
                        ?.last_name
                    )
                : null
            }
            value={
              dataForList?.data[i]?.bank_reconciliation[0]?.contact?.is_pta ===
              0
                ? `${formatName(
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.first_name,
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.last_name
                  )} (RTA)`
                : dataForList?.data[i]?.bank_reconciliation[0]?.contact
                    ?.is_pta === 1
                ? `${formatName(
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.first_name,
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.last_name
                  )} (PTA)`
                : dataForList?.data[i]?.bank_reconciliation[0]?.contact
                    ?.is_pta === 2
                ? `${formatName(
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.first_name,
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.last_name
                  )} (Trustee's Business/Withdrawal)`
                : dataForList?.data[i]?.bank_reconciliation[0]?.contact
                    ?.is_pta === 3
                ? `${formatName(
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.first_name,
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.last_name
                  )} (Principal)`
                : formatName(
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.first_name,
                    dataForList?.data[i]?.bank_reconciliation[0]?.contact
                      ?.last_name
                  )
            }
          />
        ),
        comment: (
          <Form.Control
            required
            type="text"
            id={dataForList?.data[i]?.bank_reconciliation[0].id}
            placeholder="Add Description"
            value={
              dataForList?.data[i]?.bank_reconciliation[0].description
                ? dataForList?.data[i]?.bank_reconciliation[0].description
                : ""
            }
            onChange={(e) => {
              handleDescription(e, dataForList?.data[i]);
            }}
            onBlur={() =>
              handleBlurDescription(
                dataForList?.data[i]?.bank_reconciliation[0].id,
                dataForList?.data[i]?.bank_reconciliation[0],
                dataForList?.data[i],
                dataForList?.data[i]?.bank_reconciliation[0]?.chart_of_account
              )
            }
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
              },
            ]}
            style={{
              border: "1px solid #d9d9d9",
              padding: "5px 10px",
              fontSize: "14px",
            }}
          />
        ),
        amount: (
          <Form.Control
            required
            type="text"
            id={dataForList?.data[i]?.bank_reconciliation[0].id}
            placeholder="Add Amount"
            value={
              dataForList?.data[i]?.bank_reconciliation[0].amount
                ? dataForList?.data[i]?.bank_reconciliation[0].amount
                : ""
            }
            onChange={(e) => {
              handleAmount(e, dataForList?.data[i]);
            }}
            onBlur={() =>
              handleBlurAmount(
                dataForList?.data[i]?.bank_reconciliation[0].id,
                dataForList?.data[i]?.bank_reconciliation[0],
                dataForList?.data[i],
                dataForList?.data[i]?.bank_reconciliation[0]?.chart_of_account
              )
            }
            rules={[
              {
                pattern: new RegExp(/^([0-9]+$)([.]?)/),
                message: "Amount can contain numbers & dot(.)",
              },
            ]}
            style={{
              border: "1px solid #d9d9d9",
              padding: "5px 10px",
              fontSize: "14px",
            }}
          />
        ),
        action: (
          <>
            <div className="table-action-btn">
              {handleReconcileDisable(dataForList?.data[i]) === true ? (
                <>
                  <FontAwesomeIcon
                    title="Reconcile"
                    className="disabled-font-awesome"
                    icon={faMoneyBillTransfer}
                    size="1x"
                  />
                </>
              ) : (
                <FontAwesomeIcon
                  title="Reconcile"
                  onClick={() => {
                    handleReconcile(dataForList?.data[i].id);
                  }}
                  icon={faMoneyBillTransfer}
                  size="1x"
                />
              )}
              <span className="icon-divider">
                <b>/</b>
              </span>
              <FontAwesomeIcon
                title="Search"
                onClick={() => {
                  const oldIndex = [...showSearchIndexsForList];
                  if (
                    showSearchIndexsForList.indexOf(dataForList?.data[i].id) ===
                    -1
                  ) {
                    oldIndex.push(dataForList?.data[i].id);
                  } else {
                    oldIndex.splice(
                      showSearchIndexsForList.indexOf(dataForList?.data[i].id),
                      1
                    );
                  }
                  setShowSearchIndexsForList(oldIndex);
                  setSearchRecord(dataForList?.data[i]);
                  setShowSearchPopUp(true);
                  setBankReconcilationId(
                    dataForList?.data[i]?.bank_reconciliation[0]?.id
                  );
                }}
                icon={faSearch}
              />
              <span className="icon-divider">
                <b>/</b>
              </span>
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="1x"
                title="Send For Review"
                onClick={() => {
                  sendReview(dataForList?.data[i]?.id, dataForList?.data[i]);
                }}
              />
              <span className="icon-divider">
                <b>/</b>
              </span>
              <FontAwesomeIcon
                icon={faTrash}
                size="1x"
                title="Delete"
                onClick={() => {
                  toggleDeleteModal();
                  setBankTransactionId(dataForList?.data[i]?.id);
                }}
              />
              <span className="icon-divider">
                <b>/</b>
              </span>
            </div>
          </>
        ),
        bank_transaction_data: dataForList?.data[i],
      });
    }
    return items;
  };

  const listViewData = listViewDataGenerator(dataForList?.data?.length);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  //retention-list-view
  const columns = isUnderReviewed
    ? trust_type === 0
      ? [
          {
            dataField: "check",
            text: "",
          },
          {
            dataField: "date",
            text: "Date",
          },
          {
            dataField: "description",
            text: "Description",
          },
          {
            dataField: "debit",
            text: "Debit",
          },
          {
            dataField: "credit",
            text: "Credit",
          },
          {
            dataField: "review_notes",
            text: "Review Notes",
          },
          {
            dataField: "contact_name",
            text: "Subcontractor/Beneficiary",
          },
          // {
          //   dataField: "account",
          //   text: "Account",
          // },
          {
            dataField: "comment",
            text: "Comment",
          },
          {
            dataField: "amount",
            text: "Amount",
          },
          {
            dataField: "action",
            text: "Action",
          },
        ]
      : [
          {
            dataField: "check",
            text: "",
          },
          {
            dataField: "date",
            text: "Date",
          },
          {
            dataField: "description",
            text: "Description",
          },
          {
            dataField: "debit",
            text: "Debit",
          },
          {
            dataField: "credit",
            text: "Credit",
          },
          {
            dataField: "review_notes",
            text: "Review Notes",
          },
          {
            dataField: "contact_name",
            text: "Subcontractor/Beneficiary",
          },
          // {
          //   dataField: "account",
          //   text: "Account",
          // },
          {
            dataField: "comment",
            text: "Comment",
          },
          {
            dataField: "amount",
            text: "Amount",
          },
          {
            dataField: "action",
            text: "Action",
          },
        ]
    : trust_type === 0
    ? [
        {
          dataField: "check",
          text: "",
        },
        {
          dataField: "date",
          text: "Date",
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "debit",
          text: "Debit",
        },
        {
          dataField: "credit",
          text: "Credit",
        },
        {
          dataField: "contact_name",
          text: "Subcontractor/Beneficiary",
        },
        // {
        //   dataField: "account",
        //   text: "Account",
        // },
        {
          dataField: "comment",
          text: "Comment",
        },
        {
          dataField: "amount",
          text: "Amount",
        },
        {
          dataField: "action",
          text: "Action",
        },
      ]
    : [
        {
          dataField: "check",
          text: "",
        },
        {
          dataField: "date",
          text: "Date",
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "debit",
          text: "Debit",
        },
        {
          dataField: "credit",
          text: "Credit",
        },
        {
          dataField: "contact_name",
          text: "Subcontractor/Beneficiary",
        },
        // {
        //   dataField: "account",
        //   text: "Account",
        // },
        {
          dataField: "comment",
          text: "Comment",
        },
        {
          dataField: "amount",
          text: "Amount",
        },
        {
          dataField: "action",
          text: "Action",
        },
      ];

  useEffect(() => {
    view === "grid" && fetchReconcileTransaction();
    view === "list" && fetchReconcileTransactionForList();
  }, [fetchReconcileTransaction, fetchReconcileTransactionForList, view]);

  const handleSearchClose = (isUpdate = false) => {
    setSplitContractor("");
    setBankReconcilationId();
    view === "grid" && setShowSearchIndexs([-1]);
    if (isUpdate) {
      view === "grid" && fetchReconcileTransaction();
    }
  };

  const expandRow = {
    renderer: (row) => (
      <div className="expand-reconcile-transaction">
        <NewExpandedRowForReconcileTransaction
          projectId={projectId}
          data={row}
          handleAccountSelection={handleAccountSelection}
          GSTOptions={GSTOptions}
          handleGSTSelection={handleGSTSelection}
          handleContactNameSelection={handleContactNameSelection}
          handleDescription={handleDescription}
          handleBlurDescription={handleBlurDescription}
          handleAmount={handleAmount}
          handleBlurAmount={handleBlurAmount}
          handleSplitAddAccount={handleSplitAddAccount}
          splitGstVal={splitGstVal}
          setSplitGst={setSplitGst}
          setSplitDescription={setSplitDescription}
          setSplit1Amount={setSplit1Amount}
          setSplit2Amount={setSplit2Amount}
          handleSplitOkDisable={handleSplitOkDisable}
          handleSubmitSplit={handleSubmitSplit}
          split1Amount={split1Amount}
          split2Amount={split2Amount}
          handleReconcileDisable={handleReconcileDisable}
          handleSplitDisable={handleSplitDisable}
          splitIcon={splitIcon}
          itemId={itemId}
          isUnderReviewed={isUnderReviewed}
          selectedItems={selectedItems}
          handleCheckBox={handleCheckBox}
          splitDescription={splitDescription}
          setSplitGstVal={setSplitGstVal}
          trust_type={trust_type}
          handleSplitAddContractor={handleSplitAddContractor}
          splitContractor={splitContractor}
          dataForList={dataForList}
          showSearchIndexsForList={showSearchIndexsForList}
          searchRecord={searchRecord}
          setSearchRecord={setSearchRecord}
          setBankReconcilationId={setBankReconcilationId}
          setShowSearchIndexsForList={setShowSearchIndexsForList}
          bankReconcilationId={bankReconcilationId}
          handleSearchCloseForList={handleSearchCloseForList}
          fetchReconcileTransactionForList={fetchReconcileTransactionForList}
          setShowSearchPopUp={setShowSearchPopUp}
        />
      </div>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "right",
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      setExpand(expanded);
      setRowId(rowKey);
      return (
        <div className="table-action-btn">
          <b>
            <span
              variant="secondary"
              className="reconcile-item-list-split"
              size="sm"
              title="Expand"
            >
              <img src={splitIcon} alt="" style={{ height: "16px" }} />
            </span>
          </b>
        </div>
      );
    },
  };

  const handleSorting = (item) => {
    setSortField(item?.field);
    setSortOrder(item?.value);
  };

  return (
    <div className="cms-page audit-statement new-reconcilation-page">
      {loading && <Spin />}
      <InfinityScrollComponent
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMore}
        loadOnMount={true}
        lastItemRef={lastElement}
      >
        <div className="cms-page">
          <div className="page-content-block">
            <div className="refund-process">
              <div className="table-top-btn" style={{ margin: "0px" }}>
                <div>
                  <h2>
                    Total Transactions ready to be reconciled:{" "}
                    {data?.data ? data?.data?.length : 0}
                  </h2>
                  <p>
                    Your last bank transaction import occurred on{" "}
                    {latestStatement?.created_at
                      ? moment(
                          latestStatement?.created_at,
                          "DD-MM-YYYY HH:mm:ss"
                        ).format("Do MMM YYYY")
                      : ""}
                  </p>
                </div>
                <div className="table-btn">
                  <Button
                    variant="primary"
                    onClick={() => {
                      toggleRefreshModal();
                    }}
                  >
                    Refresh
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!data.data?.length}
                    onClick={() => {
                      handleReconcileAll();
                    }}
                  >
                    Reconcile
                  </Button>
                  {isUnderReviewed && role === "Manager" && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleEmailForClarificationAll();
                      }}
                    >
                      Email For Clarification
                    </Button>
                  )}
                </div>
              </div>
              <div style={{ width: "100%", height: "89%" }}>
                <div
                  id="grid-wrapper"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div
                    id="myGrid"
                    className="ag-theme-alpine ato-grid bank-reconcile-grid"
                  >
                    {loading && <Spin />}
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            label={
                              "Select All " +
                              (selectedItems.length > 0
                                ? `(${selectedItems.length})`
                                : "")
                            }
                            name="checkall"
                            id="checkall"
                            checked={allChecked}
                            onChange={(e) => handleAllCheckBox(e)}
                          ></Form.Check>
                          <div style={{ marginBottom: "20px" }}>
                            <ReactSelect
                              placeholder="Select Sort"
                              defaultValue={
                                sortOrder === "DESC"
                                  ? sortOptions[1]
                                  : sortOptions[0]
                              }
                              onChange={(item) => {
                                handleSorting(item);
                              }}
                              style={{ zIndex: "5", borderRadius: "10px" }}
                              classNamePrefix="contactname-select__control"
                              options={sortOptions}
                              theme={(theme) => ({
                                ...theme,
                                zIndex: "5",
                                borderRadius: "10px",
                                colors: {
                                  ...theme.colors,
                                  primary: "grey",
                                },
                              })}
                            />
                          </div>
                          <Form style={{ marginBottom: "20px" }}>
                            <DateRangeDataPicker
                              placeholder="Select Date Range"
                              onChange={(e) => handleReconcileDateRange(e)}
                              format="dd-MM-yyyy"
                              disabledDate={afterToday()}
                              placement="bottomEnd"
                              value={valueRange}
                              ranges={dateFilterRange}
                              locale={{ ok: "Apply" }}
                            />
                          </Form>
                        </div>
                        <div className="view-tab ">
                          <div
                            className={
                              view === "grid" ? "selected-grid" : "grid"
                            }
                          >
                            <FontAwesomeIcon
                              icon={faGrip}
                              onClick={() => {
                                setView("grid");
                              }}
                              title="Grid View"
                              size="1x"
                            />
                          </div>{" "}
                          <div
                            className={
                              view === "list" ? "selected-list" : "list"
                            }
                          >
                            <FontAwesomeIcon
                              icon={faListSquares}
                              onClick={() => {
                                setView("list");
                              }}
                              title="List View"
                              size="1x"
                            />
                          </div>
                        </div>
                      </div>
                      {view === "grid" && (
                        <div className="form-box">
                          {data?.data?.map((item, index) => {
                            return (
                              <div
                                id={item.id}
                                ref={setLastElement}
                                key={index}
                                className={
                                  handleReconcileDisable(item)
                                    ? "refund-process-box"
                                    : "refund-process-box reconciled-green-border"
                                }
                              >
                                <Container>
                                  <Container>
                                    <Row className="refund-card-box">
                                      <Col
                                        xs={6}
                                        md={4}
                                        style={{ display: "flex" }}
                                      >
                                        <div className="refund-checkbox">
                                          <Form.Check
                                            type="checkbox"
                                            disabled={handleReconcileDisable(
                                              item
                                            )}
                                            checked={
                                              handleReconcileDisable(item) ===
                                              false
                                                ? selectedItems.indexOf(
                                                    item.id
                                                  ) >= 0
                                                : false
                                            }
                                            onChange={() => {
                                              handleCheckBox(item.id);
                                            }}
                                          ></Form.Check>
                                        </div>
                                        <div
                                          className="refund-card"
                                          style={{
                                            width: "calc(100% - 25px)",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          {item && (
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <FontAwesomeIcon
                                                  icon={faEllipsisV}
                                                  size="1x"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    toggleDeleteModal();
                                                    setBankTransactionId(
                                                      item?.id
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#deleteReconcileTransaction"
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    handleSearchClose();
                                                    const oldIndex = [
                                                      ...showSearchIndexs,
                                                    ];
                                                    if (
                                                      showSearchIndexs.indexOf(
                                                        item.id
                                                      ) === -1
                                                    ) {
                                                      oldIndex.push(item.id);
                                                    } else {
                                                      oldIndex.splice(
                                                        showSearchIndexs.indexOf(
                                                          item.id
                                                        ),
                                                        1
                                                      );
                                                    }
                                                    setShowSearchIndexs(
                                                      oldIndex
                                                    );
                                                  }}
                                                >
                                                  Search
                                                </Dropdown.Item>
                                                {item && (
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      sendReview(item.id, item);
                                                    }}
                                                  >
                                                    Send For Review
                                                  </Dropdown.Item>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                          <label>Bank Transaction</label>
                                          <div
                                            className={"refund-card-content"}
                                          >
                                            <div className="left-block">
                                              <p
                                                className="name"
                                                style={{ flexDirection: "row" }}
                                              >
                                                <strong>Date:</strong>{" "}
                                                {moment(
                                                  item?.transactionDate,
                                                  "DD-MM-YYYY"
                                                ).format("DD-MM-YYYY")}
                                              </p>
                                              <p>
                                                <strong
                                                  style={{
                                                    minWidth: "inherit",
                                                  }}
                                                >
                                                  Description:
                                                </strong>{" "}
                                                {item?.description
                                                  ? item?.description
                                                  : ""}
                                              </p>
                                            </div>
                                            {isUnderReviewed && (
                                              <Form.Group
                                                style={{ width: "100%" }}
                                              >
                                                <p
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexFlow: "wrap",
                                                    marginBottom: "0px",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <strong>Comment:</strong>
                                                  <Form.Control
                                                    className="memorise-description"
                                                    required
                                                    type="text"
                                                    id={item.id}
                                                    placeholder="Add Comment"
                                                    value={
                                                      item.comment
                                                        ? item.comment
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleComment(e, item);
                                                    }}
                                                    onBlur={() =>
                                                      handleBlurComment(
                                                        item?.id,
                                                        item,
                                                        item?.comment
                                                      )
                                                    }
                                                    rules={[
                                                      {
                                                        pattern: new RegExp(
                                                          /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                        ),
                                                        message:
                                                          "Comment can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                      },
                                                    ]}
                                                    style={{
                                                      border:
                                                        "1px solid #d9d9d9",
                                                      padding: "5px 10px",
                                                      fontSize: "14px",
                                                      width:
                                                        "calc(100% - 140px)",
                                                    }}
                                                  />
                                                </p>
                                              </Form.Group>
                                            )}
                                            <div
                                              className="right-block"
                                              style={{
                                                position: "absolute",
                                                right: "20px",
                                              }}
                                            >
                                              <p className="rightalign">
                                                {item?.baseType === 1 ? (
                                                  <p>
                                                    <strong>Credit:</strong>{" "}
                                                  </p>
                                                ) : (
                                                  <p>
                                                    <strong>Debit:</strong>{" "}
                                                  </p>
                                                )}
                                                $
                                                {`${item?.amount.toLocaleString(
                                                  "en-US",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}`}
                                              </p>
                                            </div>

                                            {((itemId !== item?.id &&
                                              item?.bank_reconciliation
                                                ?.length === 0) ||
                                              (itemId !== item?.id &&
                                                item?.bank_reconciliation
                                                  ?.length === 0)) && (
                                              <div
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                <hr />
                                                <div
                                                  style={{
                                                    display: "inline-flex",
                                                    gap: "15px",
                                                    position: "relative",
                                                  }}
                                                >
                                                  {item?.bank_reconciliation &&
                                                    item?.bank_reconciliation
                                                      ?.length === 1 &&
                                                    item?.bank_reconciliation?.map(
                                                      (data, index) => {
                                                        return (
                                                          <Fragment key={index}>
                                                            <Form.Control
                                                              className={
                                                                itemId ===
                                                                item?.id
                                                                  ? "split"
                                                                  : "memorise-description"
                                                              }
                                                              required
                                                              type="text"
                                                              id={data.id}
                                                              placeholder="Term To Memorise"
                                                              value={
                                                                item
                                                                  ?.bank_reconciliation[0]
                                                                  ?.term
                                                                  ? item
                                                                      ?.bank_reconciliation[0]
                                                                      ?.term
                                                                  : item?.term
                                                                      ?.term
                                                                  ? item?.term
                                                                      ?.term
                                                                  : ""
                                                              }
                                                              onChange={(e) => {
                                                                handleMemoriseDescription(
                                                                  e,
                                                                  item
                                                                );
                                                              }}
                                                              disabled={
                                                                !item?.term_id
                                                                  ? false
                                                                  : true
                                                              }
                                                              rules={[
                                                                {
                                                                  pattern:
                                                                    new RegExp(
                                                                      /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                                    ),
                                                                  message:
                                                                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                                },
                                                              ]}
                                                              style={{
                                                                border:
                                                                  "1px solid #d9d9d9",
                                                                padding:
                                                                  "5px 10px",
                                                                fontSize:
                                                                  "14px",
                                                                paddingRight:
                                                                  "34px",
                                                                width:
                                                                  "100% !important",
                                                              }}
                                                            />
                                                            <Button
                                                              variant="secondary"
                                                              className="matching-btn memorise-btn tick-btn"
                                                              disabled={
                                                                item
                                                                  ?.bank_reconciliation[0]
                                                                  ?.chart_of_account_id &&
                                                                item
                                                                  ?.bank_reconciliation[0]
                                                                  ?.contact_id &&
                                                                item
                                                                  ?.bank_reconciliation[0]
                                                                  ?.term &&
                                                                !item?.term_id
                                                                  ? false
                                                                  : true
                                                              }
                                                              size="sm"
                                                              onClick={() => {
                                                                handleLeftMemorise(
                                                                  item
                                                                    ?.bank_reconciliation[0]
                                                                    ?.chart_of_account_id,
                                                                  item
                                                                    ?.bank_reconciliation[0]
                                                                    ?.term,
                                                                  item
                                                                    ?.bank_reconciliation[0]
                                                                    ?.contact_id,
                                                                  item
                                                                    ?.bank_reconciliation[0]
                                                                    ?.description
                                                                );
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                title="Memorise"
                                                                icon={faCheck}
                                                              />
                                                            </Button>
                                                          </Fragment>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={6}
                                        md={1}
                                        className="matching-btn-section"
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Button
                                          variant="secondary"
                                          className={
                                            handleReconcileDisable(item)
                                              ? "matching-btn"
                                              : "matching-btn reconciled-blue-btn"
                                          }
                                          disabled={handleReconcileDisable(
                                            item
                                          )}
                                          size="sm"
                                          onClick={() => {
                                            handleReconcile(item.id);
                                          }}
                                        >
                                          Reconcile
                                        </Button>
                                      </Col>
                                      <Col xs={6} md={7}>
                                        <div className="refund-card">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <FontAwesomeIcon
                                                icon={faEllipsisV}
                                                size="1x"
                                              />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  handleSearchClose();
                                                  const oldIndex = [
                                                    ...showSearchIndexs,
                                                  ];
                                                  if (
                                                    showSearchIndexs.indexOf(
                                                      item.id
                                                    ) === -1
                                                  ) {
                                                    oldIndex.push(item.id);
                                                  } else {
                                                    oldIndex.splice(
                                                      showSearchIndexs.indexOf(
                                                        item.id
                                                      ),
                                                      1
                                                    );
                                                  }
                                                  setShowSearchIndexs(oldIndex);
                                                }}
                                              >
                                                Search
                                              </Dropdown.Item>
                                              {item && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    sendReview(item.id, item);
                                                  }}
                                                >
                                                  Send For Review
                                                </Dropdown.Item>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>

                                          <label>Allocation to Accounts</label>

                                          <div
                                            className={"refund-card-content"}
                                          >
                                            <h6 className={"col-md-4"}>
                                              Subcontractor/Beneficiary
                                            </h6>
                                            {/* <h6 className={"col-md-3"}>
                                              Account
                                            </h6> */}
                                            {item?.bank_reconciliation?.length >
                                              0 && (
                                              <h6 className="col-md-3">
                                                Amount
                                              </h6>
                                            )}
                                            <h6 className="col-md-5">
                                              Description
                                            </h6>

                                            <div className="refund-card-content-row">
                                              {item?.bank_reconciliation
                                                ?.length > 0 &&
                                                item?.bank_reconciliation?.map(
                                                  (bankData, bankIndex) => {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="refund-card-content-label row col-md-12"
                                                      >
                                                        {/* Contractor */}
                                                        <div
                                                          className={
                                                            "col-md-4 refund-column"
                                                          }
                                                        >
                                                          <input
                                                            readOnly
                                                            regionName={index.toString()}
                                                            placeholder="Search Contractor"
                                                            className={
                                                              "contactname-select"
                                                            }
                                                            style={{
                                                              border:
                                                                "1px solid #d9d9d9",
                                                              padding:
                                                                "5px 10px",
                                                              fontSize: "14px",
                                                              width:
                                                                "calc(100%)",
                                                              borderRadius:
                                                                "6px",
                                                            }}
                                                            onClick={() => {
                                                              handleSearchClose();
                                                              const oldIndex = [
                                                                ...showSearchIndexs,
                                                              ];
                                                              if (
                                                                showSearchIndexs.indexOf(
                                                                  item.id
                                                                ) === -1
                                                              ) {
                                                                oldIndex.push(
                                                                  item.id
                                                                );
                                                              } else {
                                                                oldIndex.splice(
                                                                  showSearchIndexs.indexOf(
                                                                    item.id
                                                                  ),
                                                                  1
                                                                );
                                                              }
                                                              setBankReconcilationId(
                                                                bankData?.id
                                                              );

                                                              setShowSearchIndexs(
                                                                oldIndex
                                                              );
                                                            }}
                                                            defaultValue={
                                                              bankData?.contact
                                                                ? bankData
                                                                    ?.contact
                                                                    ?.is_pta ===
                                                                  0
                                                                  ? `${formatName(
                                                                      bankData
                                                                        ?.contact
                                                                        ?.first_name,
                                                                      bankData
                                                                        ?.contact
                                                                        ?.last_name
                                                                    )} (RTA)`
                                                                  : bankData
                                                                      ?.contact
                                                                      ?.is_pta ===
                                                                    1
                                                                  ? `${formatName(
                                                                      bankData
                                                                        ?.contact
                                                                        ?.first_name,
                                                                      bankData
                                                                        ?.contact
                                                                        ?.last_name
                                                                    )} (PTA)`
                                                                  : bankData
                                                                      ?.contact
                                                                      ?.is_pta ===
                                                                    2
                                                                  ? `${formatName(
                                                                      bankData
                                                                        ?.contact
                                                                        ?.first_name,
                                                                      bankData
                                                                        ?.contact
                                                                        ?.last_name
                                                                    )} (Trustee's Business/Withdrawal)`
                                                                  : bankData
                                                                      ?.contact
                                                                      ?.is_pta ===
                                                                    3
                                                                  ? `${formatName(
                                                                      bankData
                                                                        ?.contact
                                                                        ?.first_name,
                                                                      bankData
                                                                        ?.contact
                                                                        ?.last_name
                                                                    )} (Principal)`
                                                                  : formatName(
                                                                      bankData
                                                                        ?.contact
                                                                        ?.first_name,
                                                                      bankData
                                                                        ?.contact
                                                                        ?.last_name
                                                                    )
                                                                : null
                                                            }
                                                            value={
                                                              bankData?.contact
                                                                ?.is_pta === 0
                                                                ? `${formatName(
                                                                    bankData
                                                                      ?.contact
                                                                      ?.first_name,
                                                                    bankData
                                                                      ?.contact
                                                                      ?.last_name
                                                                  )} (RTA)`
                                                                : bankData
                                                                    ?.contact
                                                                    ?.is_pta ===
                                                                  1
                                                                ? `${formatName(
                                                                    bankData
                                                                      ?.contact
                                                                      ?.first_name,
                                                                    bankData
                                                                      ?.contact
                                                                      ?.last_name
                                                                  )} (PTA)`
                                                                : bankData
                                                                    ?.contact
                                                                    ?.is_pta ===
                                                                  2
                                                                ? `${formatName(
                                                                    bankData
                                                                      ?.contact
                                                                      ?.first_name,
                                                                    bankData
                                                                      ?.contact
                                                                      ?.last_name
                                                                  )} (Trustee's Business/Withdrawal)`
                                                                : bankData
                                                                    ?.contact
                                                                    ?.is_pta ===
                                                                  3
                                                                ? `${formatName(
                                                                    bankData
                                                                      ?.contact
                                                                      ?.first_name,
                                                                    bankData
                                                                      ?.contact
                                                                      ?.last_name
                                                                  )} (Principal)`
                                                                : formatName(
                                                                    bankData
                                                                      ?.contact
                                                                      ?.first_name,
                                                                    bankData
                                                                      ?.contact
                                                                      ?.last_name
                                                                  )
                                                            }
                                                          />
                                                        </div>

                                                        {/* Account */}
                                                        {/* <div
                                                          className={
                                                            "col-md-3 refund-column"
                                                          }
                                                        >
                                                          <ChartOfAccountSelect
                                                            regionName={index.toString()}
                                                            name="chart_of_account_id"
                                                            onChange={(
                                                              account
                                                            ) =>
                                                              handleAccountSelection(
                                                                account,
                                                                bankData.id,
                                                                bankData,
                                                                item
                                                              )
                                                            }
                                                            className={
                                                              "contactname-select"
                                                            }
                                                            projectId={
                                                              projectId
                                                            }
                                                            defaultValue={
                                                              bankData?.chart_of_account
                                                                ? {
                                                                    id: bankData
                                                                      ?.chart_of_account
                                                                      ?.id
                                                                      ? bankData
                                                                          ?.chart_of_account
                                                                          ?.id
                                                                      : null,
                                                                    label:
                                                                      bankData
                                                                        ?.chart_of_account
                                                                        ?.name +
                                                                      (bankData
                                                                        ?.chart_of_account
                                                                        ?.account_type
                                                                        ?.name
                                                                        ? " (" +
                                                                          bankData
                                                                            ?.chart_of_account
                                                                            ?.account_type
                                                                            ?.name +
                                                                          ")"
                                                                        : "") +
                                                                      (bankData
                                                                        ?.chart_of_account
                                                                        ?.code
                                                                        ? " - " +
                                                                          bankData?.chart_of_account?.code.toString()
                                                                        : "") +
                                                                      (bankData
                                                                        ?.chart_of_account
                                                                        ?.sub_code
                                                                        ? "." +
                                                                          bankData?.chart_of_account?.sub_code.toString()
                                                                        : ""),
                                                                    tax: bankData
                                                                      ?.chart_of_account
                                                                      ?.tax,
                                                                  }
                                                                : null
                                                            }
                                                            value={null}
                                                            placeholder={
                                                              "Select Account"
                                                            }
                                                            isClearable={false}
                                                          />
                                                        </div> */}

                                                        {/* Amount */}
                                                        {item
                                                          ?.bank_reconciliation
                                                          ?.length > 0 && (
                                                          <div className="col-md-3 refund-column">
                                                            <Form.Group>
                                                              <Form.Control
                                                                required
                                                                type="text"
                                                                id={bankData.id}
                                                                placeholder="Add Amount"
                                                                value={
                                                                  bankData.amount
                                                                    ? bankData.amount
                                                                    : ""
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAmount(
                                                                    e,
                                                                    item
                                                                  );
                                                                }}
                                                                onBlur={() =>
                                                                  handleBlurAmount(
                                                                    bankData.id,
                                                                    bankData,
                                                                    item,
                                                                    bankData?.chart_of_account
                                                                  )
                                                                }
                                                                rules={[
                                                                  {
                                                                    pattern:
                                                                      new RegExp(
                                                                        /^([0-9]+$)([.]?)/
                                                                      ),
                                                                    message:
                                                                      "Amount can contain numbers & dot(.)",
                                                                  },
                                                                ]}
                                                                style={{
                                                                  border:
                                                                    "1px solid #d9d9d9",
                                                                  padding:
                                                                    "5px 10px",
                                                                  fontSize:
                                                                    "14px",
                                                                  width: "100%",
                                                                }}
                                                              />
                                                            </Form.Group>
                                                          </div>
                                                        )}

                                                        {/* Description */}
                                                        <div
                                                          className={
                                                            "col-md-5 refund-column"
                                                          }
                                                        >
                                                          <Form.Group>
                                                            <Form.Control
                                                              className={
                                                                "memorise-description"
                                                              }
                                                              required
                                                              type="text"
                                                              id={bankData.id}
                                                              placeholder="Add Description"
                                                              value={
                                                                bankData.description
                                                                  ? bankData.description
                                                                  : ""
                                                              }
                                                              onChange={(e) => {
                                                                handleDescription(
                                                                  e,
                                                                  item
                                                                );
                                                              }}
                                                              onBlur={() =>
                                                                handleBlurDescription(
                                                                  bankData.id,
                                                                  bankData,
                                                                  item,
                                                                  bankData?.chart_of_account
                                                                )
                                                              }
                                                              rules={[
                                                                {
                                                                  pattern:
                                                                    new RegExp(
                                                                      /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                                    ),
                                                                  message:
                                                                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                                },
                                                              ]}
                                                              style={{
                                                                border:
                                                                  "1px solid #d9d9d9",
                                                                padding:
                                                                  "5px 10px",
                                                                fontSize:
                                                                  "14px",
                                                                width: "100%",
                                                              }}
                                                            />
                                                          </Form.Group>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>

                                            {itemId === item?.id && (
                                              <>
                                                <hr />
                                                <Form.Group
                                                  style={{
                                                    display: "flex",
                                                    flexFlow: "wrap",
                                                    alignItems: "center",
                                                    gridGap: "10px",
                                                    marginLeft: "auto",
                                                  }}
                                                  className="action-box"
                                                >
                                                  <Button
                                                    onClick={() => {
                                                      handleSubmitSplit(
                                                        item?.id,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.id,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.contact_id,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.description,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.chart_of_account_id,
                                                        index
                                                      );
                                                    }}
                                                    disabled={handleSplitOkDisable(
                                                      item
                                                    )}
                                                  >
                                                    Ok
                                                  </Button>
                                                  <Button
                                                    className="reset btn btn-primary"
                                                    onClick={() => {
                                                      setItemId("");
                                                      setSplitAccountId("");
                                                      setSplitGst("");
                                                      setSplitGstVal("");
                                                      setSplitDescription("");
                                                      setSplit2Amount("");
                                                      setSplit1Amount("");
                                                      setSplitContractor("");
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </Form.Group>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {showSearchIndexs.indexOf(item.id) !==
                                    -1 ? (
                                      <SearchContractor
                                        searchParams={Number(item?.amount)}
                                        transaction_type={item?.baseType}
                                        ItemId={item.id}
                                        Item={item}
                                        close={handleSearchClose}
                                        getBankStatementReconcileData={
                                          fetchReconcileTransaction
                                        }
                                        bankReconcilationId={
                                          item?.bank_reconciliation[0]?.id
                                        }
                                        projectId={projectId}
                                        handleContractorSelection={
                                          handleContractorSelection
                                        }
                                        trustType={trust_type}
                                        splitContractor={splitContractor}
                                        setSplitContractor={setSplitContractor}
                                      />
                                    ) : null}
                                  </Container>
                                </Container>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {view === "list" && (
                        <div
                          //retention-list-view
                          className={
                            isUnderReviewed
                              ? trust_type === 0
                                ? "reconcile-transactions-list under-reviewed retention"
                                : "reconcile-transactions-list under-reviewed"
                              : trust_type === 0
                              ? "reconcile-transactions-list retention"
                              : "reconcile-transactions-list"
                          }
                        >
                          {loadingForList && <Spin />}
                          <BootstrapTable
                            keyField="key"
                            remote
                            data={listViewData || []}
                            columns={columns}
                            expandRow={expandRow}
                            noDataIndication="No Data Found"
                          />

                          <Pagination
                            total={total}
                            limit={parseInt(limit)}
                            currentPage={page}
                            updateLimit={handleUpdateLimit}
                            updatePage={handleChangePage}
                            from={from}
                            to={to}
                          />
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InfinityScrollComponent>
      {showDelete && (
        <ReconcileTransactionDelete
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          itemId={bankTransactionId}
          showDelete={showDelete}
        />
      )}
      {/* Modal of the Refresh */}
      <Modal
        size="lg"
        show={showRefresh}
        onHide={toggleRefreshModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section fetch-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Select Period To Fetch Transactions
        </Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div className="modal-body">
            <div className="date-picker-refresh">
              <>
                <Form>
                  <DateRangeDataPicker
                    placeholder="Select Date Range"
                    onChange={(e) => handleDateRefreshRange(e)}
                    format="dd-MM-yyyy"
                    value={dateValue}
                    placement="bottomEnd"
                    shouldDisableDate={afterToday()}
                    ranges={[]}
                    // showOneCalendar
                  />
                </Form>
              </>
              {/* <h5>Select Date Range</h5> */}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                handleRefresh();
              }}
            >
              Fetch
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleRefreshModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {addNewContact && (
        <AddNewContactModal
          addNewContact={addNewContact}
          handleAddNewContact={handleAddNewContact}
          projectId={projectId}
        />
      )}
      {addNewAccount && (
        <AddNewAccountModal
          addNewAccount={addNewAccount}
          handleAddNewAccount={handleAddNewAccount}
          projectId={projectId}
          setSplitAccountId={setSplitAccountVal}
          setSplitGst={setSplitGstVal}
          setSplitAccountId2={setSplitAccountId}
          setSplitGst2={setSplitGst}
        />
      )}
      {showMemoriseModal && (
        <Modal
          size="lg"
          show={showMemoriseModal}
          onHide={toggleMemoriseModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Term To Memorise
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              <Form.Control
                required
                type="text"
                id={memoriseDataList?.bank_reconciliation[0].id}
                placeholder="Term To Memorise"
                value={
                  memoriseDataList?.bank_reconciliation[0]?.term
                    ? memoriseDataList?.bank_reconciliation[0]?.term
                    : memoriseDataList?.term?.term
                    ? memoriseDataList?.term?.term
                    : ""
                }
                onChange={(e) => {
                  handleMemoriseDescription(e, memoriseDataList);
                }}
                disabled={!memoriseDataList?.term_id ? false : true}
                rules={[
                  {
                    pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                    message:
                      "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                  },
                ]}
                style={{
                  border: "1px solid #d9d9d9",
                  padding: "8px 10px",
                  fontSize: "14px",
                }}
              />
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={
                  memoriseDataList?.bank_reconciliation[0]
                    ?.chart_of_account_id &&
                  memoriseDataList?.bank_reconciliation[0]?.term &&
                  memoriseDataList?.bank_reconciliation[0]?.contact_id &&
                  !memoriseDataList?.term_id
                    ? false
                    : true
                }
                onClick={() => {
                  handleLeftListMemorise(
                    memoriseDataList?.bank_reconciliation[0]
                      ?.chart_of_account_id,
                    memoriseDataList?.bank_reconciliation[0]?.term,
                    memoriseDataList?.bank_reconciliation[0]?.contact_id,
                    memoriseDataList?.bank_reconciliation[0]?.description
                  );
                }}
              >
                Memorise
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {searchRecord &&
      showSearchIndexsForList.indexOf(searchRecord.id) !== -1 ? (
        <SearchContractorForList
          searchParams={Number(searchRecord?.amount)}
          transaction_type={searchRecord?.baseType}
          ItemId={searchRecord.id}
          Item={searchRecord}
          showSearchIndexsForList={showSearchIndexsForList}
          close={handleSearchCloseForList}
          getBankStatementReconcileData={fetchReconcileTransactionForList}
          bankReconcilationId={searchRecord?.bank_reconciliation[0]?.id}
          projectId={projectId}
          trustType={trust_type}
          showSearchPopUp={showSearchPopUp}
          splitContractor={splitContractor}
          setSplitContractor={setSplitContractor}
        />
      ) : null}
    </div>
  );
};

export default NewReconcileTransactions;
