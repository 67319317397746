import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { API, defaultLimitPagination, fileUpload, get } from "../../../config";
import Spin from "../../common/Spin";
import moment from "moment";
import Pagination from "../../common/Pagination";
import BankTransactionActionButton from "./components/BankTransactionActionButton";
import { refreshBankTransactions } from "./components/TransactionsHelper";
import { toast } from "react-toastify";
import AddBankTransaction from "./AddBankTransaction";
import { formatNumber } from "../../common/Misc";
// import { DateRangePicker } from "@semcore/date-picker";
import DateRangeDataPicker from "rsuite/DateRangePicker";
import UploadFile from "../../common/UploadFile";

const BankTransactions = ({
  setBalanceInfo,
  valueRange,
  setValueRange,
  ProjectReadOnly,
}) => {
  const [bankTransactionData, setBankTransactionData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  // const defaultFrom = query.get("from");
  // const defaultTo = query.get("to");
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState([]);

  // const [valueRange, setValueRange] = useState(
  //   defaultFrom && defaultTo
  //     ? [
  //         new Date(moment(defaultFrom, "DD-MM-YYYY")),
  //         new Date(moment(defaultTo, "DD-MM-YYYY")),
  //       ]
  //     : pastFinancialdate
  // );
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [showBank, setShowBank] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const [disabledReferesh, setDisabledReferesh] = useState(true);
  const [showRefresh, setShowRefresh] = useState(false);

  const defaultDateRange = [
    new Date(new Date().getFullYear(), 0, 1),
    new Date(),
  ];

  const handleDownload = () => {
    setIsLoadingDownload(true);
    get(API.DOWNLOAD_BANK_STATEMENT_TEMPLATE)
      .then((result) => {
        if (result.status === 200) {
          const { data } = result.data;
          var a = document.createElement("a");
          a.href = data.file;
          a.download = "bank-statement-sample.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        }
        setIsLoadingDownload(false);
      })
      .catch((error) => {
        const { data } = error.response;
        setIsLoadingDownload(false);
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      });
  };

  const { afterToday } = DateRangeDataPicker;

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  }

  const dateFilterRange = [
    {
      label: "This Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Quarter",
      value: quarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Financial Year",
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Quarter",
      value: perviousQuarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Financial Year",
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
  ];

  const [dateValue, setDateValue] = useState(defaultDateRange);
  const [startDate, setStartDate] = useState(
    defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );

  // *API Call for Fetching Bank Transactions and Account List - Start
  const getAccounts = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_ACCOUNTS}?builder_project_id=${
          projectId ? projectId : ""
        }&trust_type=${trust_type}`
      );
      const disabled = data?.data && data?.data?.length > 0 ? false : true;
      setDisabledReferesh(disabled);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [projectId, trust_type]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const fetchBankTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_LIST}?from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&builder_project_id=${
          projectId ? projectId : ""
        }&trust_type=${trust_type}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setBalanceInfo(data?.accounts);

      setBankTransactionData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [
    projectId,
    valueRange,
    limit,
    page,
    setBalanceInfo,
    sortField,
    sortOrder,
    trust_type,
  ]);

  useEffect(() => {
    fetchBankTransactions();
  }, [fetchBankTransactions]);
  // *API Call for Fetching Bank Transactions and Account List - End

  const handleDelete = () => {
    fetchBankTransactions();
  };

  // *Bank Transaction List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const bankTransactionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: bankTransactionData[i]?.id,
        transactionDate: bankTransactionData[i]?.date
          ? moment(bankTransactionData[i]?.date, "DD-MM-YYYY").format(
              "DD-MM-YYYY"
            )
          : "-",
        description: bankTransactionData[i]?.description
          ? bankTransactionData[i]?.description
          : "-",
        credit:
          bankTransactionData[i]?.baseType === 1 ? (
            <span className="rag-green">
              {"$" + formatNumber(Number(bankTransactionData[i]?.amount))}
            </span>
          ) : (
            "-"
          ),
        debit:
          bankTransactionData[i]?.baseType === 0 ? (
            <span className="rag-red">
              {"$" + formatNumber(Number(bankTransactionData[i]?.amount))}
            </span>
          ) : (
            "-"
          ),
        bank_transaction_source: bankTransactionData[i]?.source
          ? bankTransactionData[i]?.source
          : "-",
        action: bankTransactionData[i]?.deleted_at ? (
          ""
        ) : (
          <BankTransactionActionButton
            id={bankTransactionData[i]?.id}
            handleDelete={handleDelete}
            reconciledStatus={bankTransactionData[i]?.bank_reconciled_status}
          />
        ),
        archived: bankTransactionData[i]?.deleted_at ? (
          <span title="Archived" color={"red"} key={"red"}>
            {String.fromCodePoint("0x0001F6AB")}
          </span>
        ) : (
          ""
        ),
      });
    }
    return items;
  };

  const bankTransactions = bankTransactionGenerator(
    bankTransactionData?.length
  );

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=bank-transactions&limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&from=${
        valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
      }&to=${valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""}`
    );
  }, [navigate, projectId, page, limit, trustType, valueRange]);

  const handleDateRange = (e) => {
    setValueRange(e);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleRefresh = async () => {
    if (projectId) {
      setLoading(true);
      if (startDate && endDate) {
        const getTransaction = await refreshBankTransactions(
          projectId,
          trust_type,
          startDate,
          endDate
        );
        if (getTransaction?.status === 200) {
          toast.success(getTransaction?.message);
          fetchBankTransactions();
        }
        toggleRefreshModal();
      } else {
        toast.error("Start Date and End Date both are required");
      }
      setLoading(false);
    }
  };

  const handleExport = useCallback(
    async (format) => {
      try {
        setExportLoading(true);
        const { data } = await get(
          `${API.BANK_TRANSACTION_LIST}?from=${
            valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
          }&to=${
            valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
          }&builder_project_id=${projectId ? projectId : ""}&format=${
            format ? format : ""
          }}&trust_type=${trust_type}&export=1`
        );
        const export_report_path = data?.data?.file;
        setExportLoading(false);
        if (export_report_path) {
          var a = document.createElement("a");
          a.href = export_report_path;
          var file = export_report_path.split("/");
          a.setAttribute(
            "download",
            file[file.length - 1] || "bank_transaction.pdf"
          );
          a.setAttribute("target", "_blank");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          setExportLoading(false);
        }
      } catch (e) {
        setExportLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    },
    [projectId, valueRange, trust_type]
  );

  const columns = ProjectReadOnly
    ? [
        {
          dataField: "transactionDate",
          text: "Date",
          sort: true,
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "credit",
          text: "Credit",
          sort: true,
        },
        {
          dataField: "debit",
          text: "Debit",
          sort: true,
        },
        {
          dataField: "bank_transaction_source",
          text: "Bank Transaction Source",
        },
      ]
    : [
        {
          dataField: "transactionDate",
          text: "Date",
          sort: true,
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "credit",
          text: "Credit",
          sort: true,
        },
        {
          dataField: "debit",
          text: "Debit",
          sort: true,
        },
        {
          dataField: "bank_transaction_source",
          text: "Bank Transaction Source",
        },
        {
          dataField: "action",
          text: "Action",
          className: "text-center",
          style: { width: "127px", textAlign: "center" },
        },
      ];

  const bankModal = () => {
    fetchBankTransactions();
  };

  const toggleBankModal = () => {
    setShowBank(!showBank);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(
        sortField === "credit" || sortField === "debit" ? "amount" : sortField
      );
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };
  // *Bank Transaction List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start

  // *API Call for Refresh Bank Transaction List - Start
  const toggleRefreshModal = () => {
    setShowRefresh(!showRefresh);
  };

  const handleDateRefreshRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };
  // *API Call for Refresh Bank Transaction List - End

  const handleImportModal = async () => {
    setShowImport(true);
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles([]);
  };

  const handleUpload = async () => {
    if (files) {
      setIsImportLoading(true);
      const formData = new FormData();

      formData.append("statement_file", files);
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append("trust_type", trust_type);

      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      fileUpload(API.BANK_STATEMENT_IMPORT, formData, config)
        .then((res) => {
          if (res && res.status === 200) {
            setIsImportLoading(false);
            toast.success(res.data.message);
            fetchBankTransactions();
            handleImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsImportLoading(false);
          toast.error(data?.message);
          toast.error(
            data?.errors &&
              data.errors.statement_file &&
              data.errors.statement_file[0]
          );
          toast.error(
            data?.errors && data.errors.myna_error && data.errors.myna_error[0]
          );
        });
    }
  };

  return (
    <>
      <div className="cms-page">
        <div className="table-top-btn">
          <div className="search-section">
            <Form>
              <Form.Group>
                {/* <p>Selected Period</p> */}
                <DateRangeDataPicker
                  placeholder="Select Date Range"
                  onChange={(e) => handleDateRange(e)}
                  format="dd-MM-yyyy"
                  value={valueRange}
                  disabledDate={afterToday()}
                  ranges={dateFilterRange}
                  locale={{ ok: "Apply" }}
                />
              </Form.Group>
            </Form>
          </div>
          <div className="table-btn">
            {!ProjectReadOnly && (
              <>
                <Button
                  onClick={() => {
                    navigate(
                      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=reconcile-transactions`
                    );
                  }}
                >
                  Reconcile Transactions
                </Button>
                <Button
                  onClick={() => toggleBankModal()}
                  data-toggle="modal"
                  data-target="#bankTransaction"
                >
                  Insert New
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    toggleRefreshModal();
                  }}
                  disabled={disabledReferesh}
                >
                  Refresh
                </Button>
                <div className="border-btn">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p>Date - Required (DD-MM-YYYY)</p>
                      <p>Narrative / Description - Required</p>
                      <p>Debit Amount - Required</p>
                      <p>Credit Amount - Required</p>
                      <p>Balance - Optional</p>
                    </Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="link"
                    disabled={isLoadingDownload}
                    onClick={() => handleDownload()}
                    className="download-template"
                  >
                    Download Template
                  </Button>
                  <Button variant="primary" onClick={handleImportModal}>
                    Import
                  </Button>
                </div>{" "}
              </>
            )}
            <Button onClick={() => handleExport("pdf")}>
              {exportLoading ? "Loading..." : "Export"}
            </Button>
          </div>
        </div>
        <div className="custom-table">
          {loading && <Spin />}
          <BootstrapTable
            keyField="key"
            remote
            data={bankTransactions}
            columns={columns}
            onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
      {showBank && (
        <AddBankTransaction
          projectId={projectId}
          showBank={showBank}
          trust_type={trust_type}
          toggleBankModal={toggleBankModal}
          bankModal={bankModal}
        />
      )}
      {/* Modal of the Refresh */}
      <Modal
        size="lg"
        show={showRefresh}
        onHide={toggleRefreshModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section fetch-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Select Period To Fetch Transactions
        </Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div className="modal-body">
            <div className="date-picker-refresh">
              <>
                <Form>
                  <DateRangeDataPicker
                    placeholder="Select Date Range"
                    onChange={(e) => handleDateRefreshRange(e)}
                    format="dd-MM-yyyy"
                    value={dateValue}
                    placement="bottomEnd"
                    shouldDisableDate={afterToday()}
                    ranges={[]}
                  />
                </Form>
              </>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                handleRefresh();
              }}
            >
              Fetch
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleRefreshModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showImport && (
        <Modal
          show={showImport}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          arial-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>Import Statement</Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <UploadFile
                isMultiple={true}
                projectId={projectId}
                isLoading={isImportLoading}
                setFiles={setFiles}
                files={files}
                handleUpload={handleUpload}
                handleCancel={handleImportClose}
                acceptFileFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              {/* {basiqData?.connection_status == 0 ? (
              <>
                <p className="or">OR</p>
                <Button
                  variant="primary"
                  onClick={() => navigate('/settings?tab=integrations', { replace: true })}
                >
                  Connect to import automatic bank feeds
                </Button>
              </>
            ) : (
              <>
                <p className="or">OR</p>
                <Button variant="primary" onClick={handleBSModal}>
                  Import Bank Feeds
                </Button>
              </>
            )} */}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default BankTransactions;
