/* eslint-disable eqeqeq */
import { API, get } from "../../../config";

export const getProjectData = async (
  projectId,
  setProjectName,
  setProjectLogo,
  setBuilderId,
  setProjectData,
  setProjectReadOnly,
  setProjectAccess
) => {
  try {
    const { data } = await get(`${API.PROJECT_DETAILS}/` + projectId);
    setProjectName(data?.data?.project_name);
    setProjectLogo(data?.data?.logo);
    setProjectReadOnly(
      data?.data?.subscription?.status == 0 || data?.data?.projectStatus == 1 || !data?.data?.subscription
        ? true
        : false
    );
    setProjectAccess(data?.data?.subscription ? true : false);
    setBuilderId && setBuilderId(data?.data?.builder_id);
    setProjectData && setProjectData(data?.data);
    return data.data;
  } catch (e) {
    return null;
  }
};
