import * as Yup from "yup";

const PASSWORD_VALIDATOR =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

const COMMON_VALIDATOR_FOR_STRING = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-']*$/;

// const NUMBER_VALIDATOR = /^\d*\.?\d*$/;

/**
 * Email Validation
 */
const email = Yup.string()
  .required("Email address is required.")
  .email("Please enter a valid email address. For eg. example@example.com")
  .nullable();

const notRequiredEmail = Yup.string()
.email("Please enter a valid email address. For eg. example@example.com")
.nullable();

/**
 * First Name Validation
 */
const FIRSTNAME_VALIDATOR = /^[a-zA-Z\s]*$/;
const FIRSTNAME_MESSAGE = "First name can contain letters & spaces.";
const requiredFirstName = Yup.string()
  .required("First name is required.")
  .matches(FIRSTNAME_VALIDATOR, FIRSTNAME_MESSAGE)
  .nullable();
const firstName = Yup.string()
  .matches(FIRSTNAME_VALIDATOR, FIRSTNAME_MESSAGE)
  .nullable();

// const NAME_VALIDATOR = /^[a-zA-Z\s]*$/;
// const NAME_MESSAGE = "Name can contain letters & spaces.";
// const requiredName = Yup.string()
//   .required("Name is required.")
//   .matches(NAME_VALIDATOR, NAME_MESSAGE)
//   .nullable();
// const name = Yup.string().matches(NAME_VALIDATOR, NAME_MESSAGE).nullable();

/**
 * Last Name Validation
 */
const LASTNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&']*$/;
const LASTNAME_MESSAGE =
  "Last name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe(') & spaces.";
const requiredLastName = Yup.string()
  .required("Last name is required.")
  .matches(LASTNAME_VALIDATOR, LASTNAME_MESSAGE)
  .nullable();

/**
 * Business Name Validation
 */
const BUSINESSNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-']*$/;
const BUSINESSNAME_MESSAGE =
  "Business name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-) & spaces.";
const requiredBusinessName = Yup.string()
  .required("Business Name is required.")
  .max(200, "Maximum 200 Character")
  .matches(BUSINESSNAME_VALIDATOR, BUSINESSNAME_MESSAGE)
  .nullable();
const businessName = Yup.string()
  .max(200, "Maximum 200 Character")
  .matches(BUSINESSNAME_VALIDATOR, BUSINESSNAME_MESSAGE)
  .nullable();

/**
 * Project Name Validation
 */
const PROJECTNAME_VALIDATOR = /^[^!@#^%$<>][a-zA-Z\s\d.)(,\-&']*$/;
const PROJECTNAME_MESSAGE =
  "Project name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe(') hyphen(-) & spaces.";
const requiredProjectName = Yup.string()
  .required("Project Name is required.")
  .max(200, "Maximum 200 Character")
  .matches(PROJECTNAME_VALIDATOR, PROJECTNAME_MESSAGE)
  .nullable();
const PAYERTNAME_MESSAGE =
  "Payer name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), Parentheses(()) & spaces.";
const requiredPayerName = Yup.string()
  .required("Payer Name is required.")
  .max(200, "Maximum 200 Character")
  .matches(PROJECTNAME_VALIDATOR, PAYERTNAME_MESSAGE)
  .nullable();
// const projectName = Yup.string()
//   .max(200, "Maximum 200 Character")
//   .matches(PROJECTNAME_VALIDATOR, PROJECTNAME_MESSAGE)
//   .nullable();

/**
 * Entity Name Validation
 */
const ENTITYNAME_VALIDATOR = /^[^!@#^%$<>][a-zA-Z\s\d.)(,\-&']*$/;
const ENTITYNAME_MESSAGE =
  "Entity name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe(') hyphen(-) & spaces.";
const requiredEntityName = Yup.string()
  .required("Entity Name is required.")
  .max(200, "Maximum 200 Character")
  .matches(ENTITYNAME_VALIDATOR, ENTITYNAME_MESSAGE)
  .nullable();

/**
 * Last Name Validation
 */
const PRINCIPALNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&']*$/;
const PRINCIPALNAME_MESSAGE =
  "Name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe(') & spaces.";
const requiredPrincipalName = Yup.string()
  .required("Principal Name is required.")
  .matches(PRINCIPALNAME_VALIDATOR, PRINCIPALNAME_MESSAGE)
  .nullable();

/**
 * Address Validation
 */
const ADDRESS_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-']*$/;
const ADDRESS_MESSAGE =
  "Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/), apostrophe(') & spaces.";
const requiredAddress = Yup.string()
  .required("Address is required.")
  .max(200, "Maximum 200 Character")
  .matches(ADDRESS_VALIDATOR, ADDRESS_MESSAGE)
  .nullable();
const address = Yup.string()
  .max(200, "Maximum 200 Character")
  .matches(ADDRESS_VALIDATOR, ADDRESS_MESSAGE)
  .nullable();
const bankAccountName = Yup.string()
  .max(200, "Must be less than 200 character")
  .matches(
    /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/,
    `Account name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.`
  );
const requiredBankAccountName = Yup.string()
  .required("Account name is required.")
  .max(200, "Must be less than 200 character")
  .matches(
    /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-']*$/,
    `Account name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-) & spaces.`
  );

const requiredBankAccountNamewithTrust = Yup.string()
  .required("Account name is required.")
  .max(200, "Must be less than 200 character")
  .matches(
    /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-']*$/,
    `Account name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-) & spaces.`
  )
  .lowercase()
  .matches("trust", "Must include Trust key-word");

/**
 * Chart Of Account Name Validation
 */
const CHARTOFACCOUNTNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-']*$/;
const CHARTOFACCOUNTNAME_MESSAGE =
  "Name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-), slash(/) & spaces.";
const requiredChartOfAccountName = Yup.string()
  .required("Name is required.")
  .matches(CHARTOFACCOUNTNAME_VALIDATOR, CHARTOFACCOUNTNAME_MESSAGE)
  .nullable();

/**
 * Account Number Validation
 */
const ACCOUNTNUMBER_VALIDATOR = /^(\d)(?!\1+$)\d{0,8}$/;
const ACCOUNTNUMBER_MESSAGE =
  "Please enter valid Account Number. All digits of same number are not allowed.";
const requiredAccountNumber = Yup.string()
  .required("Account Number is required.")
  .max(9, "Account Number must be less than 10 digit number.")
  .matches(ACCOUNTNUMBER_VALIDATOR, ACCOUNTNUMBER_MESSAGE)
  .nullable();
const accountNumber = Yup.string()
  .max(9, "Account Number must be less than 10 digit number.")
  .matches(ACCOUNTNUMBER_VALIDATOR, ACCOUNTNUMBER_MESSAGE)
  .nullable();

const requiredBSB = Yup.string().required("BSB is required.").nullable();

const requiredBankName = Yup.object()
  .required("Bank name is required.")
  .nullable();

const requiredProjectStatus = Yup.object()
  .required("Project status is required.")
  .nullable();

// const entityType = Yup.object().required("Entity type is required.").nullable();

export const LoginSchema = Yup.object().shape({
  email: email,
  password: Yup.string().required("Password is required.").nullable(),
});

export const FirmSignupSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  phone: Yup.string().required("Phone Number is required."),
  email: email,
  password: Yup.string()
    .required("Password is required.")
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  password_confirmation: Yup.string()
    .required("Re-type Password is required.")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
  business_name: requiredBusinessName,
  business_email: email,
  street: requiredAddress,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  postal_code: Yup.string().required("Postal Code is required."),
});

export const AddProcoreImportSchema = Yup.object().shape({
  procore_company_id: Yup.object().required("Company is required.").nullable(),
  procore_project_id: Yup.object().required("Project is required.").nullable(),
});

export const FirmSignupAccountingFirmSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  phone: Yup.string().required("Phone Number is required."),
  email: email,
  password: Yup.string()
    .required("Password is required.")
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  password_confirmation: Yup.string()
    .required("Re-type Password is required.")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
  business_name: Yup.string()
    .required("Name of the Practice is required.")
    .max(200, "Maximum 200 Character")
    .matches(
      BUSINESSNAME_VALIDATOR,
      "Name of the Practice can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces."
    )
    .nullable(),
  business_email: email,
  street: requiredAddress,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  postal_code: Yup.string().required("Postal Code is required."),
});

export const EmailVerificationSchema = Yup.object().shape({
  email: email,
});

export const StripePaymentModalSchema = Yup.object().shape({
  quantity: Yup.number()
    .required("Quantity is required.")
    .max(5000, "Maximum Quantity is 5000.")
    .min(1, "Minimum Quantity is 1.")
    .nullable(),
  // name: Yup.string().required("Name is required.").nullable(),
  email: Yup.string().when("bank", {
    is: (val) => val && val === true,
    then: Yup.string()
      .required("Email address is required.")
      .email("Please enter a valid email address. For eg. example@example.com")
      .nullable(),
  }),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: email,
});

export const ResetPasswordSchema = Yup.object().shape({
  email: email,
  password: Yup.string()
    .required("New Password is required.")
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  password_confirmation: Yup.string()
    .required("Confirm Password is required.")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
});

export const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .required("Current Password is required.")
    .nullable(),
  new_password: Yup.string()
    .required("New Password is required.")
    .when("old_password", {
      is: (val) => !!val,
      then: Yup.string().notOneOf(
        [Yup.ref("old_password")],
        "Current and New Password should not be the same."
      ),
    })
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  confirm_password: Yup.string()
    .required("Confirm Password is required.")
    .when("new_password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
});

export const MyProfileSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  email: email,
});

export const AddBuilderSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  email: email,
  // entity_type: entityType,
  business_name: businessName,
  street: address,
  shipping_street: address,
});

export const AddPrincipalSchema = Yup.object().shape({
  name: requiredPrincipalName,
  email: notRequiredEmail,
  business_name: requiredBusinessName,
  street: address,
  account_name: bankAccountName,
  account_number: accountNumber,
});

export const AddProjectSchema = Yup.object().shape({
  project_name: requiredProjectName,
  email: email,
  street: address,
  // trustee_account_name: requiredBankAccountName,
  // trustee_account_number: requiredAccountNumber,
  // trustee_bsb: requiredBSB,
  // trustee_bank_id: requiredBankName,
  // trustee_opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),
  project_account_name: requiredBankAccountNamewithTrust,
  project_account_number: requiredAccountNumber,
  project_bsb: requiredBSB,
  project_bank_id: requiredBankName,
  project_apca_id: Yup.string().required("APCA ID is required.").nullable(),
  // project_opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),
  retention_account_name: requiredBankAccountNamewithTrust,
  retention_account_number: requiredAccountNumber,
  retention_bsb: requiredBSB,
  retention_bank_id: requiredBankName,
  estimated_cost: Yup.string().required("Project cost is required.").nullable(),
  retention_apca_id: Yup.string().required("APCA ID is required.").nullable(),
  // retention_opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),
  project_status: requiredProjectStatus,
  builder: Yup.string().required("PLease Select Builder.").nullable(),
});

export const AddProjectSettingSchema = Yup.object().shape({
  project_name: requiredProjectName,
  email: email,
  street: address,
  // trustee_account_name: requiredBankAccountName,
  // trustee_account_number: requiredAccountNumber,
  // trustee_bsb: requiredBSB,
  // trustee_bank_id: requiredBankName,
  // trustee_apca_id: Yup.string()
  // .required("APCA ID is required.")
  // .nullable(),
  // trustee_opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),
  project_account_name: requiredBankAccountNamewithTrust,
  project_account_number: requiredAccountNumber,
  project_bsb: requiredBSB,
  project_bank_id: requiredBankName,
  project_apca_id: Yup.string().required("APCA ID is required.").nullable(),
  // project_opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),
  retention_account_name: requiredBankAccountNamewithTrust,
  retention_account_number: requiredAccountNumber,
  retention_bsb: requiredBSB,
  retention_bank_id: requiredBankName,
  estimated_cost: Yup.string().required("Project cost is required.").nullable(),
  retention_apca_id: Yup.string().required("APCA ID is required.").nullable(),
  // retention_opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),
  project_status: requiredProjectStatus,
});

export const AddWithdrawalSchema = Yup.object().shape({
  // email: email,
  withdrawal_account_name: requiredBankAccountName,
  withdrawal_account_number: requiredAccountNumber,
  withdrawal_bsb: requiredBSB,
  withdrawal_bank_id: requiredBankName,
  // withdrawal_apca_id: Yup.string()
  // .required("APCA ID is required.")
  // .nullable(),
});

export const AddTrusteeSchema = Yup.object().shape({
  entity_name: requiredEntityName,
  // email: email,
  // trustee_account_name: requiredBankAccountName,
  // trustee_account_number: requiredAccountNumber,
  // trustee_bsb: requiredBSB,
  // trustee_bank_id: requiredBankName
  // trustee_apca_id: Yup.string()
  // .required("APCA ID is required.")
  // .nullable(),
});

export const AddContractorSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: requiredBusinessName,
  // entity_type: entityType,
  abn: Yup.string().required("ABN is required.").nullable(),
  // email: email,
  street: address,
  shipping_street: address,
  account_name: requiredBankAccountName,
  account_number: requiredAccountNumber,
  bsb: requiredBSB,
  // bank_id: requiredBankName,
  // opening_balance: Yup.string()
  //   .required("Opening balance is required.")
  //   .nullable(),

  //bank_id: requiredBankName,
});

export const AddCustomerSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
});

export const AddSupplierSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
});

export const AddContactSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
  contact_type: Yup.object().required("Contact type is required.").nullable(),
});

export const AddChartOfAccountSchema = Yup.object().shape({
  account_type_id: Yup.object()
    .required("Account type is required.")
    .nullable(),
  type: Yup.object().required("Type is required.").nullable(),
  tax: Yup.object().required("Tax is required.").nullable(),
  code: Yup.mixed().required("Code is required.").nullable(),
  name: requiredChartOfAccountName,
});

/**
 * Contractor Type Validation
 */
// const CONTRACTORTYPENAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-']*$/;
// const CONTRACTORTYPENAME_MESSAGE =
//   "Contractor Type can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-) & spaces.";
const requiredCONTRACTORTYPEName = Yup.string()
  .required("Contractor Type is required.")
  .max(200, "Maximum 200 Character")
  // .matches(CONTRACTORTYPENAME_VALIDATOR, CONTRACTORTYPENAME_MESSAGE)
  .nullable();
// const CONTRACTORTYPENAME = Yup.string()
//   .max(200, "Maximum 200 Character")
//   .matches(CONTRACTORTYPENAME_VALIDATOR, CONTRACTORTYPENAME_MESSAGE)
//   .nullable();

export const AddContractorTypeSchema = Yup.object().shape({
  contractor_type: requiredCONTRACTORTYPEName,
  description: Yup.string().max(150, "Maximum 150 Character").nullable(),
  chart_of_account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
});

export const AddSearchTermSchema = Yup.object().shape({
  chart_of_account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
  term: Yup.string().required("Term is required.").nullable(),
});

export const AddBankTransactionSchema = Yup.object().shape({
  // account: Yup.object().required("Account is required.").nullable(),
  transactionDate: Yup.string()
    .required("Transaction Date is required.")
    .nullable(),
  amount: Yup.string().required("Amount is required.").nullable(),
  baseType: Yup.object().required("Transaction Type is required.").nullable(),
  description: Yup.string()
    .required("Description is required.")
    .max(150, "Maximum 150 Character")
    .nullable(),
});

export const AddOfficeSchema = Yup.object().shape({
  street: requiredAddress,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  postal_code: Yup.string().required("Postal Code is required."),
});

export const AddUserSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  email: email,
  role_id: Yup.object().required("Role is required.").nullable(),
  office_id: Yup.object().required("Office is required.").nullable(),
});

export const InviteAccountingFirmSchema = Yup.object().shape({
  email: email,
});

export const FirmSettingSchema = Yup.object().shape({
  business_name: requiredBusinessName,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  // tan: Yup.number()
  //   .typeError("Tax Agent Number can contain numbers.")
  //   .nullable(),
  tan: Yup.string()
    .matches(
      /^\d{0,8}$/,
      "Tax Agent Number can contain numbers and must be maximum 8 digit number."
    )
    .nullable(),
});

export const AddContactForRecolocileTransactionSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  contact_type: Yup.object().required("Contact type is required.").nullable(),
});

export const AddContactForJournalEntrySchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  contact_type: Yup.object().required("Contact type is required.").nullable(),
});

export const AddReceiptSchema = Yup.object().shape({
  transaction_date: Yup.date()
    .required("Transaction Date is required.")
    .nullable(),
  note: Yup.string()
    .required("Note is required.")
    .matches(
      COMMON_VALIDATOR_FOR_STRING,
      "Note can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-) & spaces."
    )
    .nullable(),
  chart_of_account_id: Yup.object().required("Account is required.").nullable(),
  contact_id: Yup.object().required("Contractor is required.").nullable(),
  payer_name: requiredPayerName,
  amount: Yup.string().required("Amount is required.").nullable(),
  description: Yup.string()
    .required("Description is required.")
    .matches(
      COMMON_VALIDATOR_FOR_STRING,
      "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-) & spaces."
    )
    // .max(150, "Maximum 150 Character")
    .nullable(),
});

export const TrailReallocteItemSchema = Yup.object().shape({
  chart_of_account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
  gst: Yup.object().required("GST Type is required.").nullable(),
});

export const AddHolidaysSchema = Yup.object().shape({
  date: Yup.string()
    .required("Date is required.")
    .nullable(),
  name: Yup.string().required("Name is required.").nullable(),
  // description: Yup.string()
  //   .required("Description is required.")
  //   .max(150, "Maximum 150 Character")
  //   .nullable(),
});
