import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EditOffice from "../EditOffice";
import OfficeDelete from "./OfficeDelete";

const OfficeActionButton = ({ id, officeModal }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showOffice, setShowOffice] = useState(false);

  const toggleOfficeModal = () => {
    showOffice && officeModal();

    setShowOffice(!showOffice);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  return (
    <>
      <span>
        {/* <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#clientDetail"
        /> */}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp; */}
        {/* <Link to={{ pathname: "/edit-client?id=" + id }}> */}
        <FontAwesomeIcon icon={faEdit} size="1x" onClick={toggleOfficeModal} />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteClient"
        />
      </span>

      {showDelete && (
        <OfficeDelete
          officeId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={officeModal}
          showDelete={showDelete}
        />
      )}

      {showOffice && (
        <EditOffice
          officeId={id}
          showOffice={showOffice}
          toggleOfficeModal={toggleOfficeModal}
        />
      )}

      {/* {showView && (
        <OfficeDetailPopup
          showView={showView}
          officeId={id}
          toggleShowModal={toggleShowModal}
        />
      )} */}
    </>
  );
};

export default OfficeActionButton;
