import axios from "axios";
import { toast } from "react-toastify";

export function defaultBaseUrl() {
  return "https://api.buildtrust.com.au";
}

// const baseURL = "https://api.buildtrust.com.au/api/v1";
const baseURL = defaultBaseUrl() + "/api/v1";

//set config when creating the instance
const instance = axios.create({
  baseURL: baseURL,
});

//add a request interceptor

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("bt-auth-token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["timeout"] = 5000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const refreshAPI = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  // const token = localStorage.getItem("bt-auth-token");
  const token = localStorage.getItem("bt-refresh-token");
  headers["Authorization"] = "Bearer " + token;
  await axios
    .post(`${baseURL}/refresh`, "", { headers: headers })
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem("bt-auth-token", res.data.access_token);
        localStorage.setItem("bt-refresh-token", res.data.refresh_token);
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setTimeout(() => {
        localStorage.setItem("DefaultURL", window.location.pathname);
        localStorage.setItem(
          "token-expired-email",
          localStorage.getItem("bt-auth-email")
        );
        localStorage.removeItem("bt-auth-token");
        // localStorage.removeItem("bt-auth-email");
        localStorage.removeItem("EmailVerification");
        localStorage.removeItem("userId");
        localStorage.removeItem("ProfilePhoto");
        localStorage.removeItem("Name");
        localStorage.removeItem("role");
        localStorage.removeItem("builderId");
        localStorage.removeItem("AccountingFirmLogo");
        localStorage.removeItem("BusinessName");
        // localStorage.clear();
        window.location.href = "/";
      }, 2000);
    });
};

// Add a response interceptor

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.status === "Token Expired. Please try again.") {
        localStorage.setItem("DefaultURL", window.location.pathname);
        localStorage.setItem(
          "token-expired-email",
          localStorage.getItem("bt-auth-email")
        );
        localStorage.removeItem("bt-auth-token");
        // localStorage.removeItem("bt-auth-email");
        localStorage.removeItem("EmailVerification");
        localStorage.removeItem("userId");
        localStorage.removeItem("ProfilePhoto");
        localStorage.removeItem("Name");
        localStorage.removeItem("role");
        localStorage.removeItem("builderId");
        localStorage.removeItem("AccountingFirmLogo");
        localStorage.removeItem("BusinessName");
        // localStorage.clear();
        window.location.href = "/";
      }
      if (response.data.status === 401) {
        localStorage.clear();
        // window.location.href = "/";
      }
    }
    return response;
  },

  function (error) {
    const originalRequest = error.config;

    const headers = {
      "Content-Type": "application/json",
    };
    // const token = localStorage.getItem("bt-auth-token");
    const token = localStorage.getItem("bt-refresh-token");
    headers["Authorization"] = "Bearer " + token;

    //token expired
    if (
      error.response.status === 419 &&
      originalRequest.url !== "/reset-password"
    ) {
      return axios
        .post(`${baseURL}/refresh`, "", { headers: headers })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("bt-auth-token", res.data.access_token);
            localStorage.setItem("bt-refresh-token", res.data.refresh_token);
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setTimeout(() => {
            localStorage.setItem("DefaultURL", window.location.pathname);
            localStorage.setItem(
              "token-expired-email",
              localStorage.getItem("bt-auth-email")
            );
            localStorage.removeItem("bt-auth-token");
            // localStorage.removeItem("bt-auth-email");
            localStorage.removeItem("EmailVerification");
            localStorage.removeItem("userId");
            localStorage.removeItem("ProfilePhoto");
            localStorage.removeItem("Name");
            localStorage.removeItem("role");
            localStorage.removeItem("builderId");
            localStorage.removeItem("AccountingFirmLogo");
            localStorage.removeItem("BusinessName");
            // localStorage.clear();
            window.location.href = "/";
          }, 2000);
        });
    }

    //if user unauthorised then redirect to login
    if (error.response.status === 401) {
      if (
        error?.response?.data?.errors?.bt_error[0] ===
        "Email or password may be wrong."
      ) {
        toast.error(error.response.data.errors.bt_error[0]);
        localStorage.clear();
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
    //if user has no permission then redirect to dashboard
    // if (error.response.status === 403) {
    //   window.location.href = "/";
    // }

    return Promise.reject(error);
  }
);

export default instance;
