import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sitelogo from "../../components/Header/Logo";
import { API, get, IV, post, SECRETKEY } from "../../config";
import Spin from "../common/Spin";
import { LoginSchema } from "../common/Validation";
import SlideToggle from "react-slide-toggle";
// import { useEffect } from "react";
import wFavicon from "../../assets/images/myna-white-favicon.png";
import { encrypt } from "../common/Misc";

const Login = (props) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({ error: "" });
  const [loading, setLoading] = useState(false);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const builderId = localStorage.getItem("builderId");

  const userId = localStorage.getItem("userId");
  const EmailVerification = localStorage.getItem("EmailVerification");
  const hasToken = localStorage.getItem("bt-auth-token");
  const defaultEmail = localStorage.getItem("invite-accountingFirmEmail");
  const tokenExpiredEmail = localStorage.getItem("token-expired-email");
  const DefaultURL = localStorage.getItem("DefaultURL");
  // const tfaCheck = localStorage.getItem("bt-auth-tfa");

  var CryptoJS = require("crypto-js");

  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  // *API Call for Login, Password Show and Hide - Start
  const handleFormSubmit = async (values) => {
    try {
      setMessage({});
      if (values?.email?.trim() === "" || values?.password?.trim() === "") {
        setMessage({
          error: "Enter valid email and password",
        });
      } else {
        setLoading(true);

        const encPassword = CryptoJS.AES.encrypt(values?.password, fkey, {
          iv: fiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const finalEncryptedPassword = encPassword.ciphertext.toString(
          CryptoJS.enc.Base64
        );
        const finalValue = { ...values, password: finalEncryptedPassword };

        const { data } = await post(API.LOGIN, finalValue);
        if (data?.status === 200 && data.access_token !== undefined) {
          const token = data.access_token;
          const refreshToken = data.refresh_token;
          props.authenticate(token, values.email, data["2fa"], refreshToken);
          setLoading(false);
          data.role === "Builder" &&
            localStorage.setItem("builderId", data?.builder_id);
          if (data["2fa"] === -1) {
            setLoading(true);
            const userDetails = await get(API.GET_USER_PROFILE);
            setLoading(false);
            const { first_name, last_name, profile_pic, id } =
              userDetails?.data?.data;
            localStorage.setItem("Name", first_name ? first_name : last_name);
            localStorage.setItem("ProfilePhoto", profile_pic);
            localStorage.setItem("role", data?.role);
            localStorage.setItem("role-slug", data?.slug);
            localStorage.setItem("userId", id);
            localStorage.setItem("EmailVerification", 1);

            if (data?.role !== "Admin") {
              try {
                setLoading(true);
                let subdata = await get(API.GET_SUBSCRIPTION_DETAILS);
                setLoading(false);
                if (subdata) {
                  localStorage.setItem("bt-subscription", encrypt("1"));
                }
              } catch (error) {
                localStorage.setItem("bt-subscription", encrypt("0"));
              }
            } else {
              localStorage.setItem("bt-subscription", encrypt("1"));
            }
            if (data?.role !== "Builder" && data?.role !== "Admin") {
              setLoading(true);
              const accountingFirmData = await get(
                API.ACCOUNTING_FIRM_SETTINGS
              );
              setLoading(false);
              localStorage.setItem(
                "bt-accounting-firm-logo",
                accountingFirmData?.data?.data?.accountingFirm?.logo
                  ? accountingFirmData?.data?.data?.accountingFirm?.logo
                  : ""
              );
            }

            if (data?.role === "Builder") {
              setLoading(true);
              const businessData = await get(`${API.BUILDER_DETAILS}/${id}`);
              setLoading(false);
              localStorage.setItem(
                "BusinessName",
                businessData?.data?.data?.builder?.business_name
                  ? businessData?.data?.data?.builder?.business_name
                  : ""
              );
            }

            tokenExpiredEmail === values?.email
              ? navigate(DefaultURL)
              : data.role === "Builder"
              ? navigate("/projects")
              : data.role === "Admin"
              ? navigate("/admin-dashboard")
              : navigate("/builders");
          } else {
            if (data["2fa"] !== null && data["2fa"] !== 0) {
              localStorage.setItem("role", data?.role);
              navigate("/2fa", { replace: true });
            } else {
              localStorage.setItem("role", data?.role);
              navigate("/2fa/register", { replace: true });
            }
          }

          window.setTimeout(function () {
            localStorage.removeItem("DefaultURL");
            localStorage.removeItem("token-expired-email");
          }, 9000);
        }
      }
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.bt_error[0];
      setMessage({
        error:
          errorMessage ||
          "There is some issue with request, please try after some time.",
      });
      setLoading(false);
    }
  };

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  useEffect(() => {
    EmailVerification === "1" &&
      hasToken &&
      userId &&
      (builderId ? navigate("/projects") : navigate("/builders"));
  }, [navigate, EmailVerification, userId, hasToken, builderId]);
  // *API Call for Login, Password Show and Hide - End

  return (
    <>
      <div className="login-screen">
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" />{" "}
              BuildTrust acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          {message.error && <p className="text-danger">{message.error}</p>}
          <Formik
            initialValues={{
              email: defaultEmail ? defaultEmail : "",
              password: "",
            }}
            enableReinitialize
            validationSchema={LoginSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                  <div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        id="yourUsername"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        value={values.email}
                        required
                      />
                    </div>
                    <span className="text-danger">{errors?.email}</span>
                  </div>

                  <div style={{ position: "relative" }}>
                    <input
                      type={password}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      value={values.password}
                      id="yourPassword"
                      required
                    />
                    <i
                      onClick={Eye}
                      style={{
                        position: "absolute",
                        right: "13px",
                        cursor: "pointer",
                        top: "23px",
                        transform: "translateY(-50%)",
                        marginTop: "0px",
                      }}
                      className={`icon ${
                        eye ? "icon-eye-close" : "icon-eye-open"
                      }`}
                    ></i>
                    <span className="text-danger">{errors?.password}</span>
                  </div>

                  {loading && <Spin />}
                  <div>
                    <button className="button lg-btn" type="submit">
                      Login
                    </button>
                  </div>
                  <div>
                    <p>
                      <button
                        type="button"
                        className="btn-nolink"
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot Password?
                      </button>
                    </p>
                    <p>
                      Not registered yet?{" "}
                      <a
                        href={`/signup`}
                        rel="noreferrer"
                        className="btn-nolink"
                      >
                        Signup Now
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a href="https://buildtrust.com.au/contact-us/" rel="noreferrer">
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="/terms-of-use" rel="noreferrer" target="_blank">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a
                href="https://buildtrust.com.au/privacy-policy/"
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <SlideToggle>
            {({ toggle, setCollapsibleElement }) => (
              <div className="my-collapsible">
                <i
                  className="fa fa-info my-collapsible__toggle"
                  onClick={toggle}
                ></i>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                >
                  <div className="my-collapsible__content-inner">
                    <p style={{ fontSize: "12px" }}>
                      <img
                        className="small-white-favicon"
                        src={wFavicon}
                        alt=""
                      />{" "}
                      BuildTrust acknowledges the Traditional Custodians of
                      country throughout Australia and their connections to
                      land, sea and community. We pay our respect to their
                      Elders past and present and extend that respect to all
                      Aboriginal and Torres Strait Islander peoples today.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        </div>
      </div>
    </>
  );
};
export default Login;
