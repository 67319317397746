import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const tfaCheck = localStorage.getItem("bt-auth-tfa");
  const token = localStorage.getItem("bt-auth-token");
  const role = localStorage.getItem("role");

  const pathname = window.location.pathname;
  let search = window.location.search;

  if (token) {
    if (
      tfaCheck === "2" &&
      pathname !== "/2fa/change" &&
      pathname !== "/2fa/disable" &&
      search !== "?page=back"
    ) {
      return role === "Builder" ? (
        <Navigate to="/projects" />
      ) : (
        <Navigate to="/builders" />
      );
    } else if (search !== "?page=back" && pathname === "/2fa/register") {
      return (
        <>
          <Component {...rest} />
        </>
      );
    } else if (tfaCheck === "1" && pathname === "/2fa/register") {
      return <Navigate to="/2fa" />;
    } else if (tfaCheck === "0" && pathname === "/2fa") {
      return <Navigate to="/2fa/register" />;
    } else {
      return (
        <>
          <Component {...rest} />
        </>
      );
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
