import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  entityTypes,
  fileUpload,
  imageDefaultPrefixPath,
} from "../../../config";
import { formatedPostalCode, formatName } from "../../common/Misc";
import Spin from "../../common/Spin";
import { AddBuilderSchema } from "../../common/Validation";
import { getBuilderDetail, useStateList } from "./components/BuilderHelper";
import Form from "./components/Form";

const EditBuilder = ({ builderID }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [bilderData, setBuilderData] = useState();
  const [loading, setLoading] = useState(false);
  const builderId = builderID ? builderID : id;
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  async function fetchData() {
    try {
      setLoading(true);
      const data = await getBuilderDetail(builderId);
      setBuilderData(data);
      setLoading(false);
    } catch (e) {
      setBuilderData(null);
    }
  }
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderId]);

  // Submit Form
  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.profile_pic = value?.image
      ? value?.image
      : value?.profile_pic
      ? value?.profile_pic
      : "";
    value.logo = value?.image1 ? value?.image1 : value?.logo ? value?.logo : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.id;

    value.entity_type = value?.entity_type?.value;

    value.profile_pic =
      value?.profile_pic && typeof value?.profile_pic === "string"
        ? value?.profile_pic - imageDefaultPrefixPath
        : value?.profile_pic;

    value.logo =
      value?.logo && typeof value?.logo === "string"
        ? value?.logo - imageDefaultPrefixPath
        : value?.logo;

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone != null ? value?.phone : "");
    formData.append("mobile", value?.mobile != null ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append("abn", value?.abn != null ? value?.abn : "");

    formData.append("crn", value?.crn ? value?.crn : "");
    formData.append("_method", "put");

    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );
    formData.append(
      "entity_type",
      value?.entity_type ? value?.entity_type : ""
    );
    value?.profile_pic &&
      formData.append(
        "profile_pic",
        value?.profile_pic ? value?.profile_pic : ""
      );
    value?.logo && formData.append("logo", value?.logo ? value?.logo : "");

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.BUILDER_ADD}/${builderId}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        if (builderID) {
          fetchData();
        } else {
          navigate("/builders");
        }
      }
      return data?.data;
    } catch (error) {
      setBuilderData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) =>
      item?.value ===
      (bilderData?.state_id
        ? bilderData?.state_id
        : bilderData?.builder?.state_id)
  );

  // ShippingState
  const shippingStateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const shippingState = shippingStateListing?.find(
    (item) =>
      item?.value ===
      (bilderData?.shipping_state_id
        ? bilderData?.shipping_state_id
        : bilderData?.builder?.shipping_state_id)
  );

  return builderID ? (
    <>
      <section>
        <Formik
          initialValues={{
            profile_pic: bilderData?.profile_pic
              ? imageDefaultPrefixPath + bilderData?.profile_pic
              : "",
            logo: bilderData?.builder?.logo
              ? imageDefaultPrefixPath + bilderData?.builder?.logo
              : "",
            first_name: bilderData?.first_name ? bilderData?.first_name : "",
            last_name: bilderData?.last_name ? bilderData?.last_name : "",
            phone: bilderData?.phone ? bilderData?.phone : "",
            mobile: bilderData?.mobile ? bilderData?.mobile : "",
            email: bilderData?.email ? bilderData?.email : "",
            business_name: bilderData?.builder?.business_name
              ? bilderData?.builder?.business_name
              : bilderData?.builder?.client?.business_name,
            abn: bilderData?.builder?.abn
              ? bilderData?.builder?.abn
              : bilderData?.builder?.client?.abn,

            crn: bilderData?.builder?.crn
              ? bilderData?.builder?.crn
              : bilderData?.builder?.client?.crn,

            street: bilderData?.builder?.street
              ? bilderData?.builder?.street
              : bilderData?.builder?.client?.street,
            state_id: state,
            suburb_id: bilderData?.builder?.suburb,
            postal_code: bilderData?.builder?.postal_code
              ? formatedPostalCode(bilderData?.builder?.postal_code)
              : formatedPostalCode(bilderData?.builder?.client?.postal_code),

            shipping_street: bilderData?.builder?.shipping_street
              ? bilderData?.builder?.shipping_street
              : bilderData?.builder?.client?.shipping_street,
            shipping_state_id: shippingState,
            shipping_suburb_id: bilderData?.builder?.shipping_suburb,
            shipping_postal_code: bilderData?.builder?.shipping_postal_code
              ? formatedPostalCode(bilderData?.builder?.shipping_postal_code)
              : formatedPostalCode(
                  bilderData?.builder?.client?.shipping_postal_code
                ),

            entity_type: entityTypes?.find(
              (item) => item?.value === bilderData?.builder?.entity_type
            ),
          }}
          enableReinitialize
          innerRef={formValues}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={AddBuilderSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            setErrors,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                {loading && <Spin />}
                <Form
                  values={values}
                  errors={errors}
                  setErrors={setErrors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  formValues={formValues}
                  overwrittenFields={overwrittenFields}
                  existingValues={existingValues}
                  stateListing={stateListing}
                  stateLoading={stateLoading}
                  builderID={builderID}
                />
              </>
            );
          }}
        </Formik>
      </section>
    </>
  ) : (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {formatName(bilderData?.first_name, bilderData?.last_name)}
          </h1>
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  profile_pic: bilderData?.profile_pic
                    ? imageDefaultPrefixPath + bilderData?.profile_pic
                    : "",
                  logo: bilderData?.builder?.logo
                    ? imageDefaultPrefixPath + bilderData?.builder?.logo
                    : "",
                  first_name: bilderData?.first_name
                    ? bilderData?.first_name
                    : "",
                  last_name: bilderData?.last_name ? bilderData?.last_name : "",
                  phone: bilderData?.phone ? bilderData?.phone : "",
                  mobile: bilderData?.mobile ? bilderData?.mobile : "",
                  email: bilderData?.email ? bilderData?.email : "",
                  business_name: bilderData?.builder?.business_name
                    ? bilderData?.builder?.business_name
                    : bilderData?.builder?.client?.business_name,
                  abn: bilderData?.builder?.abn
                    ? bilderData?.builder?.abn
                    : bilderData?.builder?.client?.abn,

                  crn: bilderData?.builder?.crn
                    ? bilderData?.builder?.crn
                    : bilderData?.builder?.client?.crn,

                  street: bilderData?.builder?.street
                    ? bilderData?.builder?.street
                    : bilderData?.builder?.client?.street,
                  state_id: state,
                  suburb_id: bilderData?.builder?.suburb,
                  postal_code: bilderData?.builder?.postal_code
                    ? formatedPostalCode(bilderData?.builder?.postal_code)
                    : formatedPostalCode(
                        bilderData?.builder?.client?.postal_code
                      ),

                  shipping_street: bilderData?.builder?.shipping_street
                    ? bilderData?.builder?.shipping_street
                    : bilderData?.builder?.client?.shipping_street,
                  shipping_state_id: shippingState,
                  shipping_suburb_id: bilderData?.builder?.shipping_suburb,
                  shipping_postal_code: bilderData?.builder
                    ?.shipping_postal_code
                    ? formatedPostalCode(
                        bilderData?.builder?.shipping_postal_code
                      )
                    : formatedPostalCode(
                        bilderData?.builder?.client?.shipping_postal_code
                      ),

                  entity_type: entityTypes?.find(
                    (item) => item?.value === bilderData?.builder?.entity_type
                  ),
                }}
                enableReinitialize
                innerRef={formValues}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddBuilderSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBuilder;
