import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import customericon from "../../assets/images/customers.png";
import payments from "../../assets/images/payment.png";
// import receipts from "../../assets/images/receipt.png";
import paymentSchedule from "../../assets/images/payment-schedule.png";
// import projecticon from "../../assets/images/projects.png";
// import RTA from "../../assets/images/RTA.png";
// import PTA from "../../assets/images/PTA.png";
import abaicon from "../../assets/images/aba.png";
import docsImage from "../../assets/images/journal-entries.png";
import { defaultLimitPagination } from "../../config";
import { useProjectBar } from "../../pages/common/useProjectBar";
// import { Dropdown } from "react-bootstrap";
// import { Dropdown } from "react-bootstrap";

const SidebarNav = () => {
  const [addClass, setAddClass] = useState(false);
  const params = useParams();
  const projectId = params?.project_id;
  const role = localStorage.getItem("role");

  const location = useLocation();

  const toggle = () => {
    setAddClass(!addClass);
  };
  let boxClass = ["sidebar-nav"];
  if (addClass) {
    boxClass.push("open");
  }

  const { ProjectReadOnly } = useProjectBar();

  return (
    <div className={boxClass.join(" ")}>
      <i className="sidebtn" onClick={toggle}></i>
      <ul>
        <li
          className={
            role !== "Builder" && location.pathname.includes("/sub-contractors")
              ? "active"
              : ""
          }
        >
          <Link
            to={{
              pathname: `/projects/${projectId}/sub-contractors`,
            }}
            data-title="Subcontractors"
          >
            <img src={customericon} alt="SubContractors" />
            Subcontractors
          </Link>
        </li>
        <li
          className={
            location.pathname.includes("/payment-claims") ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: `/projects/${projectId}/payment-claims`,
            }}
            data-title="Payment Claims"
          >
            <img src={docsImage} alt="Payment Claims" />
            Payment Claims
          </Link>
        </li>
        <li
          className={
            location.pathname.includes("/project/payment-schedules")
              ? "active"
              : ""
          }
        >
          <Link
            to={{
              pathname: `/projects/${projectId}/project/payment-schedules`,
            }}
            data-title="Payment Schedule"
          >
            <img src={paymentSchedule} alt="Payment Schedule" />
            Payment Schedule
          </Link>
        </li>

        {/* <li className="child-menu">
          <Dropdown.Item>
            <img src={PTA} alt="PTA Banking" />
          </Dropdown.Item>
          <ul className="dropdown-menu">
            <li>
              <Dropdown.Item href={`/projects/${projectId}/project/payments`}>
                Trust Payments
              </Dropdown.Item>
            </li> */}
        {/* <li>
              <Dropdown.Item href={`/projects/${projectId}/project/receipts`}>
                Trust Receipts
              </Dropdown.Item>
            </li> */}
        {/* <li>
              <Dropdown.Item
                href={`/projects/${projectId}/project/transactions`}
              >
                Export Transactions
              </Dropdown.Item>
            </li> */}
        {/* </ul>
        </li> */}
        {/* 
        <li className="child-menu">
          <Dropdown.Item>
            <img src={RTA} alt="RTA Banking" />
            Retention Trust Account
          </Dropdown.Item>
          <ul className="dropdown-menu">
            <li>
              <Dropdown.Item href={`/projects/${projectId}/retention/payments`}>
                Trust Payments
              </Dropdown.Item>
            </li>
            <li>
              <Dropdown.Item href={`/projects/${projectId}/retention/receipts`}>
                Trust Receipts
              </Dropdown.Item>
            </li>
          </ul>
        </li> */}
        <li
          className={location.pathname.includes("/project/aba") ? "active" : ""}
        >
          <Link
            to={{
              pathname: `/projects/${projectId}/project/aba`,
              limit: defaultLimitPagination,
              page: 1,
              tab: "generate-aba",
              aba_file: 1,
            }}
            data-title="Payment Instructions"
          >
            <img src={abaicon} alt="Payment Instructions" />
            Payment Instructions
          </Link>
        </li>
        {!ProjectReadOnly && (
          <li
            className={
              location.pathname.includes("/project/reconcile-transactions") &&
              location.search?.includes("?tab=reconcile-transactions")
                ? "active"
                : ""
            }
          >
            <Link
              to={{
                pathname: `/projects/${projectId}/project/reconcile-transactions`,
              }}
              data-title="PTA Bank Reconciliation"
            >
              <img src={payments} alt="PTA Bank Reconciliation" />
              PTA Bank Reconciliation
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SidebarNav;
