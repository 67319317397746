import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import SuburbSelect from "../../../../common/SuburbSelect";
const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  stateListing,
  stateLoading,
  allBanks,
  allBankLoading,
  ProjectReadOnly,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    allBanks: PropTypes.any,
    allBankLoading: PropTypes.any,
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card myprofile-card">
        <div className="form-box" style={{ width: "100%" }}>
          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Principal Name
            </label>
            <input
              type="text"
              name="name"
              value={values?.name ? values?.name : ""}
              onChange={handleChange}
              onBlur={(e) => {
                if (!values?.business_name) {
                  setFieldValue("business_name", e.target.value);
                }
              }}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={ProjectReadOnly}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="name" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>
              Email
            </label>
            <input
              type="text"
              name="email"
              value={values?.email ? values?.email : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={ProjectReadOnly}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="email" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Phone No.</label>
            <InputMask
              type="text"
              name="phone"
              value={values?.phone ? values?.phone : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="+61 9 9999 9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={ProjectReadOnly}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="phone" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Business Name
            </label>
            <input
              type="text"
              name="business_name"
              value={values?.business_name ? values?.business_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={ProjectReadOnly}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="business_name" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>ABN</label>
            <InputMask
              type="text"
              name="abn"
              value={values?.abn ? values?.abn : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 999 999 999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={ProjectReadOnly}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="abn" />
            </span>
          </div>

          <div className="address-box">
            <h5>Address</h5>
            <div className="form-group tetra-width">
              <label>Address</label>
              <input
                type="text"
                name="street"
                value={values?.street ? values?.street : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={ProjectReadOnly}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="street" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Select State</label>
              <Select
                name="state_id"
                value={values?.state_id ? values?.state_id : ""}
                onChange={(e) => {
                  setFieldValue(`state_id`, e ? e : "");
                  setFieldValue(`suburb_id`, "");
                }}
                onBlur={handleBlur}
                options={stateListing}
                placeholder="Select State"
                classNamePrefix="selectbox"
                isClearable
                isLoading={stateLoading}
                autoComplete="off"
                isDisabled={ProjectReadOnly}
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="state_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Select Suburb</label>

              <SuburbSelect
                name="suburb_id"
                regionName={values?.state_id}
                onChange={(e) => {
                  setFieldValue("suburb_id", e ? e : "");
                }}
                value={values?.suburb_id ? values?.suburb_id : null}
                placeholder={"Select Suburb"}
                isClearable={true}
                isDisabled={ProjectReadOnly}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="suburb_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Postal Code</label>
              <InputMask
                type="text"
                name="postal_code"
                value={values?.postal_code ? values?.postal_code : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="9999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={ProjectReadOnly}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="postal_code" />
              </span>
            </div>
          </div>
          {/* <div className="project-bank-details-box">
            <h5>Bank Details</h5>
            <div className="form-group tetra-width">
              <label>Bank Name</label>
              <Select
                name="bank_id"
                value={values?.bank_id ? values?.bank_id : ""}
                onChange={(e) => {
                  setFieldValue(`bank_id`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={allBanks}
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isLoading={allBankLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />
            </div>
            <div className="form-group tetra-width">
              <label>Bank Account Name</label>
              <input
                type="text"
                name="account_name"
                value={values?.account_name ? values?.account_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="account_name" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>BSB</label>
              <InputMask
                type="text"
                name="bsb"
                value={values?.bsb ? values?.bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="bsb" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Account Number</label>
              <InputMask
                type="text"
                name="account_number"
                value={values?.account_number ? values?.account_number : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="account_number" />
              </span>
            </div>
          </div> */}
          <div className="form-group tetra-width full-width">
            <label>Description</label>
            <textarea
              type="text"
              name="description"
              value={values?.description ? values?.description : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              style={{
                borderRadius: "10px",
                margin: "0 0 15px",
                minHeight: "70px",
              }}
              disabled={ProjectReadOnly}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="description" />
            </span>
          </div>
        </div>
      </div>
      <div
        className="entry-card full-card"
        style={{ clear: "left", float: "left", margin: 0 }}
      >
        {!ProjectReadOnly && (
          <div className="action-box">
            <button type="submit">Save</button>
          </div>
        )}
      </div>
    </form>
  );
};

export default Form;
