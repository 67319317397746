import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import StripePaymentModal from "../../components/Modals/StripePaymentModal";
import { API, fileUpload, get, STRIPE_PUBLISHABLE_KEY } from "../../config";
import "./firm.module.css";
import Spin from "../common/Spin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { encrypt, formatNumber } from "../common/Misc";
import BuilderSelect from "../common/BuilderSelect";
import ProjectSelect from "../common/ProjectSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const stripeTestPromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Subscription = ({ login = false, project = false }) => {
  const role = localStorage.getItem("role");

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tabName = query.get("tab");

  const [isLoading, setIsLoading] = useState(true);
  const [isSubLoading, setIsSubLoading] = useState(true);
  const [selectsubscription, selectsubscriptionplan] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [defaultPromoCode, setDefaultPromoCode] = useState("");
  const [serviceChecked, setServiceChecked] = useState(false);
  const [supportPlan, setSupportPlan] = useState([]);
  const [subscriptionSupportDetail, setSubscriptionSupportDetail] = useState();
  const [showBuilderProjectSelectModal, setShowBuilderProjectSelectModal] =
    useState(false);
  const [updateSubscription, setUpdateSubscription] = useState(false);

  const [builderId, setBuilderId] = useState(
    localStorage.getItem("builderId") ? localStorage.getItem("builderId") : ""
  );
  const [projectId, setProjectId] = useState("");

  const [paymentData, setPaymentData] = useState();

  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const userId = localStorage.getItem("userId");
  const EmailVerification = localStorage.getItem("EmailVerification");
  const isToken = localStorage.getItem("bt-auth-token");

  const [setsubscription, setsubscriptionlist] = useState([]);

  const [selectedPeriodPlan, setSelectedPeriodPlan] = useState("month");

  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState(false);

  const toggleBuilderProjectSelectModal = (data) => {
    if (data) {
      setPaymentData(data);
    }
    setShowBuilderProjectSelectModal(!showBuilderProjectSelectModal);
  };

  const fetchSubscriptionDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await get(
        `${API.GET_SUBSCRIPTION_DETAILS}${
          project
            ? "?builder_project_id=" +
              (project ? params?.project_id : projectId)
            : ""
        }`
      );
      setIsLoading(false);
      localStorage.setItem("bt-subscription", encrypt("1"));
      setSubscriptionDetail(
        data?.data?.subscriptions?.length > 0
          ? data?.data?.subscriptions?.find(
              (item) => item?.status !== "canceled"
            )
          : {}
      );
      setSubscriptionSupportDetail(data?.data?.supportPlan);
      return data.data;
    } catch (error) {
      setSubscriptionDetail(null);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setIsLoading(false);
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    }
  }, [params?.project_id, project, projectId]);

  useEffect(() => {
    if (login) {
      fetchSubscriptionDetails();
    }
  }, [fetchSubscriptionDetails, login]);

  const setsubscriptiondata = useCallback(async () => {
    try {
      setIsSubLoading(true);
      const { data } = await get(API.SUBSCRIPTION);
      let array = login
        ? [
            { type: "supportPlan", plan: [] },
            { type: "month", plan: [] },
            // { type: "year", plan: [] },
          ]
        : [
            { type: "month", plan: [] },
            // { type: "year", plan: [] },
          ];
      data.data.map((item) => {
        if (item?.prices?.data && item?.prices?.data?.length > 0) {
          item?.prices?.data?.map((pricePlan) => {
            if (
              pricePlan?.recurring?.interval === "month" &&
              pricePlan?.active === true
            ) {
              const nameObj = { name: item?.name, unitLabel: item?.unit_label };
              login
                ? array[1].plan.push({ ...pricePlan, ...nameObj })
                : array[0].plan.push({ ...pricePlan, ...nameObj });
            }
            if (
              pricePlan?.recurring?.interval === "year" &&
              pricePlan?.active === true
            ) {
              const nameObj = { name: item?.name, unitLabel: item?.unit_label };
              login
                ? array[2].plan.push({ ...pricePlan, ...nameObj })
                : array[1].plan.push({ ...pricePlan, ...nameObj });
            }
            return 0;
          });
        }
        return 0;
      });
      if (login) {
        array[0]?.plan?.sort(
          (a, b) => Number(a?.unit_amount) - Number(b?.unit_amount)
        );
        array[1]?.plan?.sort(
          (a, b) => Number(a?.unit_amount) - Number(b?.unit_amount)
        );
      } else {
        array[0]?.plan?.sort(
          (a, b) => Number(a?.unit_amount) - Number(b?.unit_amount)
        );
      }

      if (login && data?.supportPlans?.metadata?.type === "support_plan") {
        data?.supportPlans?.prices?.data?.map((pricePlan) => {
          if (pricePlan?.active === true) {
            const nameObj = {
              name: data?.supportPlans?.name,
              unitLabel: data?.supportPlans?.unit_label,
            };
            array[0].plan.push({ ...pricePlan, ...nameObj });
          }
          return 0;
        });
      }
      setSelectedPeriodPlan(login ? array[1] : array[0]);
      setsubscriptionlist(array);
      setSupportPlan(data?.supportPlans);

      setPromoCode(data.coupons);
      data.coupons &&
        data.coupons?.length > 0 &&
        data.coupons?.map((el) => {
          el?.id === "FIRST50" && setDefaultPromoCode(el?.id);
          return 0;
        });

      setIsSubLoading(false);
    } catch (error) {
      setIsSubLoading(false);
    }
  }, [login]);

  const ProceedPayment = async (selectedPlan, update) => {
    if (update) {
      setUpdateSubscription(update);
    }
    if (selectedPlan) {
      selectsubscriptionplan(selectedPlan);
      setShowPaymentModal(true);
    } else {
      toast.error("Please select subscription plan");
    }
  };

  useEffect(() => {
    setsubscriptiondata();
  }, [setsubscriptiondata]);

  const handleStripePaymentmodalclose = () => {
    setShowPaymentModal(false);
    if (updateSubscription) {
      fetchSubscriptionDetails();
    }
  };

  useEffect(() => {
    (!userId || EmailVerification !== "1") && navigate("/");
  }, [userId, EmailVerification, navigate]);

  const cancelSubscription = async () => {
    setCancelSubscriptionLoading(true);
    const formData = new FormData();
    formData.append(
      "builder_project_id",
      project ? params?.project_id : projectId
    );
    await fileUpload(API.CANCEL_SUBSCRIPTION, formData)
      .then(() => {
        setShowModal(false);
        setCancelSubscriptionLoading(false);
        fetchSubscriptionDetails();
      })
      .catch(() => {
        setShowModal(false);
        setCancelSubscriptionLoading(false);
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <div
        className={
          tabName === "subscriptions"
            ? "container-fluid subscription-plan"
            : "container-fluid subscription-plan"
        }
        style={{ overflow: "auto", paddingBottom: "30px" }}
      >
        {/* {isLoading ? (
          <Spin />
        ) : ( */}
        <div className="p-5">
          {isLoading || isSubLoading ? (
            <Spin />
          ) : (
            <div className="row custom-tabs pricetabs">
              {tabName === "subscriptions" || isToken ? (
                <> </>
              ) : (
                <>
                  <h2
                    style={{
                      textAlign: "center",
                      margin: "0 0 15px",
                      fontWeight: "700",
                      color: "#FFF",
                    }}
                  >
                    The Best Build Trust Solution at the Best Price!
                  </h2>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0 0 30px",
                      fontWeight: "700",
                      color: "#FFF",
                    }}
                  ></p>
                </>
              )}

              <div className="pricetab-block">
                <div className="price-tabs-block">
                  {setsubscription &&
                    setsubscription.length > 0 &&
                    setsubscription.map((el) => {
                      return (
                        <>
                          <h6
                            className={`${
                              el.type === "month"
                                ? "monthly-tab"
                                : el.type === "year"
                                ? "yearly-tab"
                                : login && "support-tab"
                            } ${
                              selectedPeriodPlan.type === el.type && "active"
                            }`}
                            onClick={() => setSelectedPeriodPlan(el)}
                          >
                            {el.type === "month"
                              ? "Monthly"
                              : el.type === "year"
                              ? "Yearly"
                              : login && "Onboarding"}
                          </h6>
                        </>
                      );
                    })}
                </div>
                <div className="pricetab-table">
                  <div
                    className={
                      selectedPeriodPlan.type === "month"
                        ? "monthly active"
                        : selectedPeriodPlan?.type === "year"
                        ? "yearly active"
                        : "support active"
                    }
                  >
                    {selectedPeriodPlan?.plan &&
                      selectedPeriodPlan?.plan?.length > 0 &&
                      selectedPeriodPlan?.plan?.map((data) => {
                        return (
                          <>
                            {data?.recurring?.interval ===
                              selectedPeriodPlan?.type}
                            <div className="col-lg-6 col-md-12 mb-4 price-tabs">
                              <div className="card-body">
                                <div className="text-center">
                                  <h5
                                    className="card-title"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "19px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {/* {data?.name ? data?.name : ""} */}
                                    {data?.nickname ? data?.nickname : "" }
                                  </h5>
                                  <h2
                                    className="h2"
                                    style={
                                      login
                                        ? { margin: "0" }
                                        : { margin: "0", fontSize: "25px" }
                                    }
                                  >
                                    <sup>$</sup>
                                    <NumberFormat
                                      thousandsGroupStyle="thousand"
                                      value={
                                        data?.unit_amount
                                          ? (data?.unit_amount / 100).toFixed(2)
                                          : ""
                                      }
                                      decimalSeparator="."
                                      displayType="text"
                                      type="text"
                                      thousandSeparator={true}
                                      allowNegative={true}
                                    />
                                    <sub>
                                      <small>
                                        {selectedPeriodPlan.type === "month"
                                          ? data?.tax_behavior === "inclusive"
                                            ? "/pm Inc. GST"
                                            : "/pm Ex. GST"
                                          : selectedPeriodPlan.type === "year"
                                          ? data?.tax_behavior === "inclusive"
                                            ? "/pm Inc. GST"
                                            : "/pm Ex. GST"
                                          : data?.tax_behavior === "inclusive"
                                          ? " Inc. GST"
                                          : " Ex. GST"}
                                      </small>
                                    </sub>
                                  </h2>
                                  {selectedPeriodPlan.type !==
                                    "supportPlan" && (
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        textTransform: "uppercase",
                                        opacity: "0.7",
                                        margin: 0,
                                        padding: "10px",
                                      }}
                                    >
                                      Per{" "}
                                      {data?.unitLabel ? data?.unitLabel : ""}
                                    </p>
                                  )}
                                  {data?.metadata?.best_for && (
                                    <div className="plan-description">
                                      {data?.metadata?.best_for}
                                    </div>
                                  )}

                                  {selectedPeriodPlan.type === "supportPlan" ? (
                                    <button
                                      style={{ marginTop: "15px" }}
                                      // onClick={() => ProceedPayment(data)}
                                      onClick={() => {
                                        project
                                          ? project &&
                                            subscriptionDetail?.plan_time &&
                                            subscriptionDetail?.plan_time ===
                                              data?.recurring?.interval &&
                                            subscriptionDetail?.price_id &&
                                            subscriptionDetail?.price_id ===
                                              data.id &&
                                            subscriptionDetail?.status !==
                                              "canceled"
                                            ? setShowModal(true)
                                            : ProceedPayment(data, true)
                                          : toggleBuilderProjectSelectModal(
                                              data
                                            );
                                      }}
                                      className={`btn ${
                                        data?.prices?.data?.[0]?.["id"] ===
                                        selectsubscription
                                          ? "btn-disable"
                                          : ""
                                      }`}
                                      disabled={
                                        subscriptionSupportDetail?.support_plan
                                          ? true
                                          : false
                                      }
                                    >
                                      {subscriptionSupportDetail?.support_plan
                                        ? "Active"
                                        : "Get Started"}
                                    </button>
                                  ) : (
                                    <button
                                      // onClick={() => ProceedPayment(data)}
                                      onClick={() => {
                                        project
                                          ? project &&
                                            subscriptionDetail?.plan_time &&
                                            subscriptionDetail?.plan_time ===
                                              data?.recurring?.interval &&
                                            subscriptionDetail?.price_id &&
                                            subscriptionDetail?.price_id ===
                                              data.id &&
                                            subscriptionDetail?.status !==
                                              "canceled"
                                            ? setShowModal(true)
                                            : ProceedPayment(data, true)
                                          : toggleBuilderProjectSelectModal(
                                              data
                                            );
                                      }}
                                      className={`btn ${
                                        data?.prices?.data?.[0]["id"] ===
                                        selectsubscription
                                          ? "btn-disable"
                                          : ""
                                      }`}
                                    >
                                      {project &&
                                      subscriptionDetail?.plan_time &&
                                      subscriptionDetail?.plan_time ===
                                        data?.recurring?.interval &&
                                      subscriptionDetail?.price_id &&
                                      subscriptionDetail?.price_id ===
                                        data.id &&
                                      subscriptionDetail?.status !== "canceled"
                                        ? "Cancel"
                                        : data?.unit_amount / 100 >
                                            subscriptionDetail?.price &&
                                          subscriptionDetail?.status
                                        ? "Upgrade"
                                        : data?.unit_amount / 100 <
                                            subscriptionDetail?.price &&
                                          subscriptionDetail?.status
                                        ? "Downgrade"
                                        : "Get Started"}
                                      {/* Get Started */}
                                    </button>
                                  )}
                                </div>
                                <div className="text-center">
                                  <ul className="list-group list-group-flush h-100 ">
                                    {selectedPeriodPlan.type ===
                                    "supportPlan" ? (
                                      <>
                                        <div
                                          style={{
                                            listStyle: "none",
                                            textAlign: "left",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              marginBottom: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            A. Setup of :
                                          </p>

                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Project Trust and/or Retention Trust
                                            Accounts
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Receipts, Documents
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Integration for direct Bank Feeds
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Sub-Contractors
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Payment Schdules
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>{" "}
                                            Connection with Accounting Firm
                                          </li>

                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            B. Training on :
                                          </p>

                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Import Sub contractors lists
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Import Payment Schedules
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Dashboard
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            .ABA file management
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            Bank Reconciliation
                                          </li>
                                          <li className="list-group-item">
                                            <span className="rag-green">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                            How to produce reports for auditors
                                          </li>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          One Project Trust Account (PTA) with
                                          Unlimited Bank Feeds
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          One Retention Trust Account (RTA) with
                                          Unlimited Bank Feeds
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          {/* Trust Specific Chart of Accounts */}
                                          No Hidden Transactions Fee
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Manage Unlimited Subbies
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Invite Accounting Firm
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Up to {data?.metadata?.users} Users
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Reports as per QBCC Trust Guidelines
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Upload Project Documents
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Unlimited .aba files
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Automatic Receipts for amounts
                                          retained in RTA
                                        </li>
                                        <li className="list-group-item">
                                          <span className="rag-green">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                          Most Advanced OCR to extract invoice
                                          data
                                        </li>{" "}
                                      </>
                                    )}
                                    {!login && (
                                      <li className="list-group-item">
                                        <div className="service-plan-card">
                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              White Glove Onboarding Service{" "}
                                              <span className="integration-card-info service-card-info">
                                                <DropdownButton className="info-icon">
                                                  <Dropdown.Item>
                                                    <div>
                                                      <p
                                                        style={{
                                                          fontWeight: "bold",
                                                          marginBottom: "15px",
                                                          color: "white",
                                                        }}
                                                      >
                                                        A. Setup of :
                                                      </p>
                                                      <ol>
                                                        <li>
                                                          Project Trust and/or
                                                          Retention Trust
                                                          Accounts
                                                        </li>
                                                        <li>
                                                          Receipts, Documents
                                                        </li>
                                                        <li>
                                                          Integration for direct
                                                          Bank Feeds
                                                        </li>
                                                        <li>Sub-Contractors</li>
                                                        <li>
                                                          Payment Schdules
                                                        </li>
                                                        <li>
                                                          {" "}
                                                          Connection with
                                                          Accounting Firm
                                                        </li>
                                                      </ol>
                                                      <br />
                                                      <p
                                                        style={{
                                                          fontWeight: "bold",
                                                          marginBottom: "15px",
                                                          color: "white",
                                                        }}
                                                      >
                                                        B. Training on :
                                                      </p>
                                                      <ol>
                                                        <li>
                                                          Import Sub contractors
                                                          lists
                                                        </li>
                                                        <li>
                                                          Import Payment
                                                          Schedules
                                                        </li>
                                                        <li>Dashboard</li>
                                                        <li>
                                                          .ABA file management
                                                        </li>
                                                        <li>
                                                          Bank Reconciliation
                                                        </li>
                                                        <li>
                                                          How to produce reports
                                                          for auditors
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </Dropdown.Item>
                                                </DropdownButton>
                                              </span>
                                            </div>
                                            <div>
                                              <>
                                                <span className="service-price">
                                                  $
                                                  {formatNumber(
                                                    supportPlan?.prices?.data?.find(
                                                      (item) =>
                                                        item.active === true
                                                    )?.unit_amount / 100
                                                  )}
                                                  {supportPlan?.prices?.data?.find(
                                                    (item) =>
                                                      item.active === true
                                                  )?.tax_behavior ===
                                                  "exclusive"
                                                    ? " Ex. GST"
                                                    : " Inc. GST"}
                                                </span>
                                              </>
                                            </div>
                                          </div>
                                          <input
                                            type="checkbox"
                                            name="service"
                                            onChange={(e) =>
                                              setServiceChecked(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>

              {showPaymentModal && (
                <Elements stripe={stripeTestPromise}>
                  <StripePaymentModal
                    showModal={showPaymentModal}
                    handleModalClose={handleStripePaymentmodalclose}
                    id={userId}
                    planDetail={selectsubscription}
                    promoCode={promoCode}
                    defaultPromocode={defaultPromoCode}
                    planType={selectedPeriodPlan?.type}
                    supportPlan={!login && serviceChecked && supportPlan}
                    subscriptionDetail={subscriptionDetail}
                    login={login}
                    builderId={builderId}
                    projectId={project ? params?.project_id : projectId}
                    updateSubscription={updateSubscription}
                    // handleSucessProjectSub={handleSucessProjectSub}
                  />
                </Elements>
              )}
              {showModal && (
                <Modal
                  size="lg"
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  dialogClassName="modal-50w small-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  {cancelSubscriptionLoading && <Spin />}
                  <Modal.Header className="mb-0" closeButton>
                    Cancel Subscription
                  </Modal.Header>
                  <Modal.Body>
                    <div className="modal-body">
                      Are you sure you want to cancel subscription?
                    </div>
                    <div className="modal-footer">
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        No
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => cancelSubscription()}
                      >
                        Yes
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {showBuilderProjectSelectModal && (
                <Modal
                  show={showBuilderProjectSelectModal}
                  onHide={toggleBuilderProjectSelectModal}
                  size="lg"
                  dialogClassName="modal-50w small-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  className="project-section"
                  centered
                >
                  <Modal.Header closeButton style={{ marginBottom: "10px" }}>
                    Project Subscription Selection
                  </Modal.Header>
                  <Modal.Body>
                    <div className="modal-body">
                      <div
                        className="form-box"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {role === "Builder" ? (
                          <></>
                        ) : (
                          <div className="form-group half-width">
                            <label>
                              <span className="required">*</span>Builder Select
                            </label>
                            <BuilderSelect
                              regionName={"0"}
                              onChange={(e) => {
                                setBuilderId(e?.builder?.id);
                              }}
                              name="builder"
                              placeholder={"Select Builder"}
                              isClearable={false}
                            />
                          </div>
                        )}
                        <div className="form-group half-width">
                          <label>
                            <span className="required">*</span>Project Select
                          </label>
                          <ProjectSelect
                            regionName={builderId ? builderId : "0"}
                            onChange={(e) => {
                              setProjectId(e?.id);
                            }}
                            placeholder={"Select Project"}
                            builderId={builderId}
                            isClearable={false}
                            rta_project={0}
                            isNotSubscribe={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        onClick={() => {
                          toggleBuilderProjectSelectModal();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => {
                          ProceedPayment(paymentData);
                        }}
                        disabled={projectId && builderId ? false : true}
                      >
                        OK
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          )}
        </div>
        {/* )} */}
      </div>
    </>
  );
};
export default Subscription;
