import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router-dom";
// import SearchTermDelete from "./SearchTermDelete";

const SearchTermActionButton = ({ id, handleDelete }) => {
  // const [showDelete, setShowDelete] = useState(false);

  // const toggleDeleteModal = () => {
  //   setShowDelete(!showDelete);
  // };

  const params = useParams();
  const projectId = params?.project_id;
  return (
    <>
      <span>
        <a href={`/projects/${projectId}/terms/edit/${id}`}>
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </a>
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteSearchTerm"
        /> */}
      </span>

      {/* {showDelete && (
        <SearchTermDelete
          searchTermId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )} */}
    </>
  );
};

export default SearchTermActionButton;
