import qs from "qs";
import instance, { defaultBaseUrl } from "./APIConfig/Interceptor";

export const API = {
  // login and authentication
  LOGIN: "/login",
  LOGOUT: "/logout",
  REFRESH: "/refresh",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  UPDATE_PROFILE: "/profile",
  GOOGLE_2FA_REGISTER: "/google-2fa-register",
  GOOGLE_2FA_VERIFY: "/google-2fa-verify",
  VERIFY_EMAIL_AUTHENTICATION: "/verify-email-authentication",
  EMAIL_AUTHENTICATION: "/email-authentication",
  VERIFY_BACKUP_EMAIL: "/verify-backup-email",
  ADD_BACKUP_EMAIL: "/add-backup-email",
  DELETE_2FA: "/disable-2fa",

  //Register
  FIRM_SIGNUP: "/register",
  EMAIL_VERIFICATION: "/email-verification",

  //Subsciption
  SUBSCRIPTION: "/stripe/products",
  FIRM_SUBSCRIPTION: "/stripe/subscription",
  GET_SUBSCRIPTION_DETAILS: "/stripe/subscription-details",
  GET_SUBSCRIPTION_LIST_DETAILS: "/stripe/subscription-details",
  CANCEL_SUBSCRIPTION: "/stripe/cancel-subscription",
  GET_UPCOMING_INVOICE: "/stripe/upcoming-invoice",
  UPDATE_SUBSCRIPTION: "/stripe/update-subscription",

  //User Settings
  GET_USER_PROFILE: "/profile",

  // Clients
  GET_CLIENTS: "/clients",
  CLIENT_DETAILS: "/clients",
  CLIENT_ADD: "/clients",
  CLIENT_DELETE: "/clients",

  // Builders
  GET_BUILDERS: "/builders",
  BUILDER_DETAILS: "/builders",
  BUILDER_ADD: "/builders",
  BUILDER_DELETE: "/builders",

  // Projects
  GET_PROJECT: "/projects",
  PROJECT_DELETE: "/projects",
  PROJECT_DETAILS: "/projects",
  PROJECT_ADD: "/projects",

  // Business
  GET_BUSINESS: "/businesses",
  BUSINESS_DELETE: "/businesses",
  BUSINESS_DETAILS: "/businesses",
  BUSINESS_ADD: "/businesses",

  // Customer
  GET_CUSTOMERS: "/customers",
  CUSTOMER_DELETE: "/customers",
  CUSTOMER_DETAILS: "/customers",
  CUSTOMER_Add: "/customers",

  // Contractor
  GET_CONTRACTORS: "/contractors",
  CONTRACTOR_DELETE: "/contractors",
  CONTRACTOR_DETAILS: "/contractors",
  CONTRACTOR_Add: "/contractors",
  IMPORT_CONTRACTOR: "/contractors/import",
  DOWNLOAD_CONTRACTOR_TEMPLATE: "/contractors/download-sample",

  // Document list
  GET_DOCUMENT_LIST: "/documents",
  UPLOAD_DOCUMENT: "/documents",
  DELETE_DOCUMENT: "/documents",

  // Contractor-Types
  GET_CONTRACTOR_TYPE: "/contractor-types",
  CONTRACTOR_TYPE_DELETE: "/contractor-types",
  CONTRACTOR_TYPE_DETAILS: "/contractor-types",
  CONTRACTOR_TYPE_Add: "/contractor-types",
  CONTRACTOR_TYPE_SYNC: "/contractor-types/sync",

  // Supplier
  GET_SUPPLIERS: "/suppliers",
  SUPPLIER_DELETE: "/suppliers",
  SUPPLIER_DETAILS: "/suppliers",
  SUPPLIER_Add: "/suppliers",

  // Contacts
  GET_CONTACTS: "/contacts",
  CONTACT_DELETE: "/contacts",
  CONTACT_DETAILS: "/contacts",
  CONTACT_Add: "/contacts",

  //Accounting
  GET_ACCOUNT_TYPES: "/account-types",
  GET_ACCOUNT_DATA: "/chart-of-accounts",
  ACCOUNT_DELETE: "/chart-of-accounts",
  ACCOUNT_DETAILS: "/chart-of-accounts",
  ACCOUNT_ADD: "/chart-of-accounts",
  IMPORT_ACCOUNT: "/chart-of-accounts/import",
  DOWNLOAD_ACCOUNT_TEMPLATE: "/chart-of-accounts/get-template",

  // Common
  GET_STATE_LIST: "/states",
  GET_SUBURB_LIST: "/suburbs",
  GET_OFFICE_LIST: "/offices",
  GET_STAFF_MANAGER_LIST: "/users",

  //Basiq integration
  BASIQ_GET_USER: "/basiq/get-user",
  BASIQ_CREATE_USER: "basiq/create-user",
  BASIQ_DISCONNECT: "basiq/disconnect",

  //Yodlee integration
  YODLEE_GET_TOKEN: "/yodlee/get-token",
  SAVE_ACCOUNTS: "/yodlee/accounts",
  SAVE_PROVIDERS_ACCOUNTS: "/yodlee/providers",
  REQUEST_CLIENT_TO_CONNECT: "/yodlee/request-client-to-connect",

  REQUEST_BUILDER_TO_CONNECT: "/yodlee/request-builder-to-connect",

  //Providers(Bank)
  GET_PROVIDERS_BANK: "/yodlee/providers",
  PROVIDER_BANK_DISCONNECT: "/yodlee/providers/disconnect",

  //Bank
  GET_BANK_LIST: "/banks",

  //Search terms
  GET_TERMS: "/terms",
  TERM_DELETE: "/terms",
  ADD_TERM: "/terms",
  EDIT_TERM: "/terms",

  //Banking
  BANK_TRANSACTION_LIST: "/bank-transactions",
  BANK_TRANSACTION_BATCHES: "/bank-transaction-batches",
  TRANSACTION_DELETE: "/bank-transactions",
  BANK_TRANSACTION_BY_BATCHES: "/bank-transaction-batches",
  REFRESH_BANK_TRANSACTION: "/yodlee/transactions",
  BANK_RECONCILIATION_LIST: "/bank-reconciliations",
  UPDATE_BANK_RECONCILIATION: "/bank-reconciliations",
  BANK_RECONCILIATION_BULK: "/bank-reconciliations/bulk",
  EMAIL_FOR_CLARIFICATION: "/bank-reconciliations/email-for-clarification",
  GET_ACCOUNTS: "/get-accounts",
  ADD_BANK_TRANSACTION: "/bank-transactions",
  UPDATE_BANK_TRANSACTION: "/bank-transactions",
  GET_PROJECT_FOR_BANK_RECONCILIATION: "/bank-reconciliations/get-projects",
  PERMANETALY_DELETE_BANK_STATEMENT: "/bank-transactions/delete-permanent",
  DOWNLOAD_BANK_STATEMENT_TEMPLATE: "/bank-transactions/download-template",
  BANK_STATEMENT_IMPORT: "/bank-transactions/import",

  //payments
  GET_PAYMENTS: "/payments",
  CREATE_PAYMENTS: "/payments",
  PAYMENT_ABA_RECEIPT: "/payments",

  //Journal Entry
  JOURNAL_ENTRIES: "/journal-entries",
  JOURNAL_ENTRIES_UNDO: "/journal-entries/undo",

  //Payment Schedule
  PAYMENT_SCHEDULES: "/payment-schedules",
  DOWNLOAD_PAYMENT_SCHEDULE_TEMPLATE: "/payment-schedules/download-sample",
  IMPORT_PAYMENT_SCHEDULE: "/payment-schedules/import",
  DOWNLOAD_ADJUSTMENT_TEMPLATE: "/payment-schedules/get-adjustments",
  IMPORT_ADJUSTMENT: "/payment-schedules/import-adjustments",
  PAYMENT_SCHEDULE_APPROVE: "/payment-schedules/approve",
  PAYMENT_SCHEDULE_VERIFY: "/payment-schedules/verify",
  PAYMENT_SCHEDULES_VARIATION: "/payment-schedules/variation",

  // Payment Claim
  GET_PAYMENT_CLAIM_LIST: "/payment-claims",
  UPLOAD_PAYMENT_CLAIM: "/payment-claims",
  DELETE_PAYMENT_CLAIM: "/payment-claims",
  DOWNLOAD_PAYMENT_CLAIM_TEMPLATE: "/payment-claims/download-sample",
  IMPORT_PAYMENT_CLAIM: "/payment-claims/import",

  //Receipt
  GET_RECEIPT: "/receipts",
  CREATE_RECIEPT: "/receipts",

  //Report
  GET_TRIAL_BALANCE_DATA: "/reports/trial-balance-report",
  GET_TRIAL_BALANCE_ENTRIES_DATA: "/reports/trial-balance-entries-report",
  BANK_RECONCILED_TRANSACTION_BULK_REALLOCATE:
    "/bank-reconciliations/bulk-reallocate",

  LEDGER_ENTRY_REPORT: "/reports/ledger-entries-report",
  CONTRACTOR_STATEMENT_REPORT: "reports/contractor-statement-report",
  TRANSACTION_REPORT: "/reports/transaction-report",
  GENERAL_LEDGER_REPORT: "/reports/general-ledger-report",
  MONTHLY_BANK_RECONCILIATION_REPORT:
    "/reports/monthly-bank-reconciliation-report",
  MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES:
    "/reports/monthly-bank-reconciliation-entries-report",

  //Users
  GET_USER: "/users",
  USER: "/users",

  //Office
  GET_OFFICE: "/offices",
  OFFICE: "/offices",

  //Role
  GET_ROLE_LIST: "/roles",

  //Business Dashboard
  GET_BUSINESS_DASHBOARD: "/business-dashboard",
  GET_BANK_CONCILEDATA: "/dashboard-reconciled-data",

  //Project DashBoard
  GET_PROJECT_DASHBOARD: "/project-dashboard",
  GET_PROJECT_DASHBOARD_GRAPH: "/dashboard-graph-data",

  //Principal
  GET_PRINCIPAL_DETAILS: "/principals",
  PRINCIPAL: "/principals",

  //Withdrawal
  GET_WITHDRAWAL_DETAILS: "/contractors",
  WITHDRAWAL: "/contractors",

  //Invite Accounting Firm
  INVITE_ACCOUNTING_FIRM: "/invite-accounting-firm",
  GET_INVITATIONS: "/invitations",
  INVITATIONS: "/invitations",

  //General Setting
  ACCOUNTING_FIRM_SETTINGS: "/accounting-firms",
  GENERAL_SETTING_INTEGRATION: "/general-setting-integration",
  PROCORE_OAUTH: "/procore/oauthredirect",
  PROCORE_COMPANY_LIST: "/procore/get-companies",
  PROCORE_PROJECT_LIST: "/procore/get-projects",
  PROCORE_DISCONNECT: "/procore/remove-connections",
  PROCORE_IMPORT_SUBCONTRACTOR: "/procore/import-subcontractors",

  //BAS
  SUMMARY_REPORT: "/reports/gst-summary-bas",
  GST_TRANSACTION: "/reports/gst-transactions-bas",
  GST_TRANSACTION_DETAILS: "/reports/gst-transactions-details-bas",

  //Admin
  GET_SUBSCRIPTION_FOR_ADMIN: "/admin/subscriptions",
  GET_REGISTERED_USER_FOR_ADMIN: "/admin/registered-users",

  // Public Holiday
  GET_HOLIDAY: "/admin/public-holidays",
  HOLIDAY: "/admin/public-holidays",

  GET_PAYMENTMETHOD_LIST: "/stripe/payment-methods",

  // ABA
  GET_ABA_LIST_DOWNLOAD: "/aba-list",
  GET_ABA_EXPAND_LIST: "/aba-payments",
  GET_ABA_LIST: "/payments",
  GENERATE_ABA: "/generate-aba",
  UNDO_ABA: "/aba/undo",
  ABA_FILE_LIST: "/aba-file-list",
  DOWNLOAD_ABA_FILE: "/download-aba-file",
  REGENERATE_ABA: "/regenerate-aba",
  ABA_TRANSACTION_FILES: "/aba-file-customer",
  ABA_EMAIL: "/aba-receipts/send-mail",

  //COA Financial Report
  GET_COA_TRIAL_BALANCE_DATA: "/reports/ca-trial-balance-report",
  GET_COA_TRIAL_BALANCE_ENTRIES_DATA:
    "/reports/ca-trial-balance-entries-report",
  COA_BANK_RECONCILED_TRANSACTION_BULK_REALLOCATE:
    "/bank-reconciliations/bulk-reallocate",

  COA_GENERAL_LEDGER_REPORT: "/reports/ca-general-ledger-report",
  COA_LEDGER_ENTRY_REPORT: "/reports/ca-ledger-entries-report",
  PROFIT_AND_LOSS_REPORT: "/reports/profit-and-loss-report",
  GET_PROFIT_AND_LOSS_ENTRIES_DATA: "/reports/profit-and-loss-entries-report",
  GET_PROFIT_AND_LOSS_CHART_DATA: "/reports/profit-and-loss-data",
  BALANCE_SHEET_REPORT: "/reports/balance-sheet-report",
  GET_BALANCE_SHEET_ENTRIES_DATA: "/reports/balance-sheet-entries-report",

  //Opening Balance
  OPENING_BALANCE: "/opening-balance",
};

// Idle Time in Minutes
export const idelTime = 30;

// Default Limit
export const defaultLimitPagination = 25;

export function get(endPoint, params = {}) {
  return instance.get(endPoint, params);
}

export function deleteCall(endPoint) {
  return instance.delete(endPoint);
}

export function putCall(endPoint, params) {
  return instance.put(endPoint, params);
}

export function post(endPoint, params, isStringify = true) {
  return instance.post(
    endPoint,
    params && (isStringify ? JSON.stringify(params) : qs.stringify(params))
  );
}

export function fileUpload(endPoint, params, config) {
  return instance.post(endPoint, params, config);
}

export function fileUploadPut(endPoint, params, config) {
  return instance.put(endPoint, params, config);
}

export const STRIPE_PUBLISHABLE_KEY =
  // "pk_test_51MP0AgJjNVe04DpkEN3th8xWA1Ccw0cqMVXNJxZuMdoDC8sTJdQlOonBkiUMWdkfquVdBcuyY35T05iRKUneEFAV002WYvOSik";
  "pk_live_51MP0AgJjNVe04Dpkyjcc4Lee844PKozYTg4r6TwDGqEzkTp42XXnMhVhunqzQEsu7tSVHMZoCDwsRi4MEX7CnQmt00mUGQdHX3";

export const s3DefaultPath =
  // "https://trust-easy-file-storage.s3.ap-southeast-2.amazonaws.com/public/buildTrust/";
  "https://build-trust-file-storage.s3.ap-southeast-2.amazonaws.com/public/";

export const imageDefaultPrefixPath = defaultBaseUrl() + "/public/uploads/";

// export const imageDefaultPrefixPath =
// "https://api.buildtrust.com.au/public/uploads/";

//Yodlee
export const yodleeFastLink =
  "https://fl4.prod.yodlee.com.au/authenticate/ANZDevexProd-248/fastlink?channelAppName=anzdevexprod";
export const yodleeConfigName = "BT_Aggregation";
// export const yodleeobAppName = "BuildTrust";
export const yodleeDefaultImg =
  "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/plus-512.png";

export const IV = "68r6Sp3r6h26gfdr";
export const SECRETKEY = "VahH9D2f3ethWo5UKwux7Ww6eDQcJVaP";

export const entityTypes = [
  { label: "Company", value: "Company" },
  // { label: "Global", value: "Global" },
  { label: "Partnership", value: "Partnership" },
  { label: "Sole Trader", value: "Sole Trader" },
  { label: "Superfund", value: "Superfund" },
  { label: "Trust", value: "Trust" },
  { label: "Other", value: "Other" },
];

export const ProjectStatus = [
  { label: "Not Started", value: 0 },
  { label: "In Progress", value: 2 },
  { label: "On Hold", value: 3 },
  { label: "Completed", value: 1 },
];
