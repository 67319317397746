import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./components/Form";
import {
  API,
  fileUpload,
  imageDefaultPrefixPath,
  ProjectStatus,
} from "../../../../config";
import {
  getProjectDetailWithHeadProject,
  useStateList,
  useBankList,
  useAllBankList,
} from "../../../Builders/Projects/components/ProjectsHelper";
import { AddProjectSettingSchema } from "../../../common/Validation";
import Spin from "../../../common/Spin";
import { useCallback } from "react";
// import moment from "moment";

const ProjectSetting = ({
  setProjectLogo,
  setProjectName,
  getProjectData,
  ProjectReadOnly,
}) => {
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [builderId, setBuilderId] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const params = useParams();
  const projectId = params?.project_id;
  const navigate = useNavigate();

  const fetchProjectData = useCallback(
    async (projectID) => {
      try {
        setLoading(true);
        const data = await getProjectDetailWithHeadProject(projectID);
        setBuilderId(data?.data?.builder_id);
        setProjectData(data?.data);
        navigate(`/projects/${projectID}/settings?tab=project-settings`, {
          replace: true,
        });
        setLoading(false);
      } catch (e) {
        setProjectData(null);
      }
    },
    [navigate]
  );

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.logo = value?.image ? value?.image : value?.logo ? value?.logo : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";

    value.trustee_opening_balance = Number(
      `${value?.trustee_opening_balance}`.replace(/,/g, "")
    );
    value.project_opening_balance = Number(
      `${value?.project_opening_balance}`.replace(/,/g, "")
    );
    value.retention_opening_balance = Number(
      `${value?.retention_opening_balance}`.replace(/,/g, "")
    );
    value.estimated_cost = Number(`${value?.estimated_cost}`.replace(/,/g, ""));

    value.logo =
      value?.logo && typeof value?.logo === "string" ? null : value?.logo;

    formData.append("builder_id", builderId ? builderId : "");
    value?.logo && formData.append("logo", value?.logo ? value?.logo : "");
    formData.append(
      "project_name",
      value?.project_name ? value?.project_name : ""
    );
    formData.append("email", value?.email ? value?.email : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("abn", value?.abn ? value?.abn : "");
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id?.value : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id?.id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );

    // formData.append(
    //   "trustee_account_name",
    //   value?.trustee_account_name ? value?.trustee_account_name : ""
    // );
    // formData.append(
    //   "trustee_bsb",
    //   value?.trustee_bsb ? value?.trustee_bsb : ""
    // );
    // formData.append(
    //   "trustee_account_number",
    //   value?.trustee_account_number ? value?.trustee_account_number : ""
    // );
    // formData.append(
    //   "trustee_bank_id",
    //   value?.trustee_bank_id ? value?.trustee_bank_id?.value : ""
    // );
    // formData.append(
    //   "trustee_apca_id",
    //   value?.trustee_apca_id ? value?.trustee_apca_id : ""
    // );
    // formData.append(
    //   "trustee_opening_balance",
    //   value?.trustee_opening_balance ? value?.trustee_opening_balance : 0
    // );

    formData.append(
      "project_account_name",
      value?.project_account_name ? value?.project_account_name : ""
    );
    formData.append(
      "project_bsb",
      value?.project_bsb ? value?.project_bsb : ""
    );
    formData.append(
      "project_account_number",
      value?.project_account_number ? value?.project_account_number : ""
    );
    formData.append(
      "project_bank_id",
      value?.project_bank_id ? value?.project_bank_id?.value : ""
    );
    formData.append(
      "project_apca_id",
      value?.project_apca_id ? value?.project_apca_id : ""
    );
    formData.append(
      "project_opening_balance",
      value?.project_opening_balance ? value?.project_opening_balance : 0
    );
    // formData.append(
    //   "project_transaction_start_date",
    //   value?.project_transaction_start_date
    //     ? moment(value?.project_transaction_start_date, "DD-MM-YYYY").format(
    //         "DD-MM-YYYY"
    //       )
    //     : ""
    // );
    formData.append(
      "project_transaction_start_date",
      value?.project_transaction_start_date
        ? value?.project_transaction_start_date
        : ""
    );
    value?.rta_project &&
      formData.append("rta_project_id", value?.rta_project?.id);

    // if (!value?.rta_project) {
    formData.append(
      "retention_account_name",
      value?.retention_account_name ? value?.retention_account_name : ""
    );
    formData.append(
      "retention_bsb",
      value?.retention_bsb ? value?.retention_bsb : ""
    );
    formData.append(
      "retention_account_number",
      value?.retention_account_number ? value?.retention_account_number : ""
    );
    formData.append(
      "retention_bank_id",
      value?.retention_bank_id ? value?.retention_bank_id?.value : ""
    );
    formData.append(
      "retention_apca_id",
      value?.retention_apca_id ? value?.retention_apca_id : ""
    );
    // formData.append(
    //   "retention_transaction_start_date",
    //   value?.retention_transaction_start_date
    //     ? moment(
    //         value?.retention_transaction_start_date,
    //         "DD-MM-YYYY"
    //       ).format("DD-MM-YYYY")
    //     : ""
    // );
    formData.append(
      "retention_transaction_start_date",
      value?.retention_transaction_start_date
        ? value?.retention_transaction_start_date
        : ""
    );
    formData.append(
      "retention_opening_balance",
      value?.retention_opening_balance ? value?.retention_opening_balance : 0
    );
    // }

    // formData.append(
    //   "start_date",
    //   value?.start_date
    //     ? moment(value?.start_date, "DD-MM-YYYY").format("DD-MM-YYYY")
    //     : ""
    // );
    // formData.append(
    //   "end_date",
    //   value?.end_date
    //     ? moment(value?.end_date, "DD-MM-YYYY").format("DD-MM-YYYY")
    //     : ""
    // );
    formData.append(
      "start_date",
      value?.start_date
        ? value?.start_date
        : ""
    );
    formData.append(
      "end_date",
      value?.end_date
        ? value?.end_date
        : ""
    );
    formData.append(
      "estimated_cost",
      value?.estimated_cost ? value?.estimated_cost : ""
    );
    formData.append(
      "project_status",
      value?.project_status ? value?.project_status?.value : null
    );

    formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.PROJECT_ADD}/${projectId}`,
        formData
      );
      toast.success(data?.message);

      setLoading(false);
      if (data) {
        if (projectId) {
          setProjectData(null);
          // navigate(`/projects/${projectId}/settings?tab=project-settings`);
          fetchProjectData(projectId);
          getProjectData(projectId, setProjectName, setProjectLogo);
        }
      }
      return data?.data;
    } catch (error) {
      setProjectData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProjectData(projectId);
  }, [projectId, fetchProjectData]);

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === projectData?.state_id
  );

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const { allBankLoading, allBankList = [] } = useAllBankList();
  // const bankListing = bankList?.map((item) => {
  //   const { name: label, id: value, ...rest } = item;
  //   return { label, value, ...rest };
  // });
  const bankListing = bankList
    ? [...bankList, { label: "Don't have a Retention Trust A/C", value: "" }]
    : [];
  const allBanks = allBankList ? allBankList : [];
  const projectBank = bankListing?.find(
    (item) => item?.value === projectData?.pta_contractor?.bank_id
  );

  // const trusteeBank = allBanks?.find(
  //   (item) => item?.value === projectData?.trustee_contractor?.bank_id
  // );

  const retentionBank = bankListing?.find(
    (item) =>
      item?.value ===
      (projectData?.rta_project_id
        ? projectData?.rta_project?.rta_contractor?.bank_id
        : projectData?.rta_contractor?.bank_id)
  );

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  return (
    <>
      <Formik
        initialValues={{
          project_name: projectData?.project_name
            ? projectData?.project_name
            : "",
          abn: projectData?.abn ? projectData?.abn : "",
          mobile: projectData?.mobile ? projectData?.mobile : "",
          phone: projectData?.phone ? projectData?.phone : "",
          email: projectData?.email ? projectData?.email : "",
          street: projectData?.street ? projectData?.street : "",
          postal_code: projectData?.postal_code ? projectData?.postal_code : "",
          state_id: state ? state : "",
          suburb_id: projectData?.suburb ? projectData?.suburb : null,
          logo: projectData?.logo
            ? imageDefaultPrefixPath + projectData?.logo
            : "",

          // trustee_account_name: projectData?.trustee_contractor?.account_name
          //   ? projectData?.trustee_contractor?.account_name
          //   : "",
          // trustee_bsb: projectData?.trustee_contractor?.bsb
          //   ? projectData?.trustee_contractor?.bsb
          //   : "",
          // trustee_account_number: projectData?.trustee_contractor
          //   ?.account_number
          //   ? projectData?.trustee_contractor?.account_number
          //   : "",
          // trustee_bank_id: trusteeBank ? trusteeBank : "",
          // trustee_apca_id: projectData?.trustee_contractor?.apca_id ? projectData?.trustee_contractor?.apca_id : "",
          // trustee_opening_balance: projectData?.trustee_contractor
          //   ?.opening_balance
          //   ? projectData?.trustee_contractor?.opening_balance
          //   : 0,

          project_account_name: projectData?.pta_contractor?.account_name
            ? projectData?.pta_contractor?.account_name
            : "",
          project_bsb: projectData?.pta_contractor?.bsb
            ? projectData?.pta_contractor?.bsb
            : "",
          project_transaction_start_date: projectData?.pta_contractor
            ?.transaction_start_date
            ? projectData?.pta_contractor?.transaction_start_date
            : "",
          project_account_number: projectData?.pta_contractor?.account_number
            ? projectData?.pta_contractor?.account_number
            : "",
          project_bank_id: projectBank ? projectBank : "",
          project_apca_id: projectData?.pta_contractor?.apca_id
            ? padWithLeadingZeros(projectData?.pta_contractor?.apca_id, 6)
            : projectData?.pta_contractor?.apca_id === 0
            ? padWithLeadingZeros(projectData?.pta_contractor?.apca_id, 6)
            : "",
          project_opening_balance: projectData?.pta_contractor?.opening_balance
            ? projectData?.pta_contractor?.opening_balance
            : 0,

          retention_account_name: projectData?.rta_project_id
            ? projectData?.rta_project?.rta_contractor?.account_name
              ? projectData?.rta_project?.rta_contractor?.account_name
              : "Retention Trust Account"
            : projectData?.rta_contractor?.account_name
            ? projectData?.rta_contractor?.account_name
            : "Retention Trust Account",
          retention_bsb: projectData?.rta_project_id
            ? projectData?.rta_project?.rta_contractor?.bsb
              ? projectData?.rta_project?.rta_contractor?.bsb
              : "000000"
            : projectData?.rta_contractor?.bsb
            ? projectData?.rta_contractor?.bsb
            : "000000",
          retention_account_number: projectData?.rta_project_id
            ? projectData?.rta_project?.rta_contractor?.account_number
              ? projectData?.rta_project?.rta_contractor?.account_number
              : "000000001"
            : projectData?.rta_contractor?.account_number
            ? projectData?.rta_contractor?.account_number
            : "000000001",
          retention_bank_id: retentionBank
            ? retentionBank
            : bankListing?.find((i) => i.value === ""),
          retention_apca_id: projectData?.rta_contractor?.apca_id
            ? padWithLeadingZeros(projectData?.rta_contractor?.apca_id, 6)
            : "000000",
          retention_opening_balance: projectData?.rta_project_id
            ? projectData?.rta_project?.rta_contractor?.opening_balance
              ? projectData?.rta_project?.rta_contractor?.opening_balance
              : 0
            : projectData?.rta_contractor?.opening_balance
            ? projectData?.rta_contractor?.opening_balance
            : 0,
          retention_transaction_start_date: projectData?.rta_contractor
            ?.transaction_start_date
            ? projectData?.rta_contractor?.transaction_start_date
            : "",
          // start_date: projectData?.start_date
          //   ? new Date(moment(projectData?.start_date, "DD-MM-YYYY"))
          //   : "",
          // end_date: projectData?.end_date
          //   ? new Date(moment(projectData?.end_date, "DD-MM-YYYY"))
          //   : "",
          start_date: projectData?.start_date ? projectData?.start_date : "",
          end_date: projectData?.end_date ? projectData?.end_date : "",
          estimated_cost: projectData?.estimated_cost
            ? projectData?.estimated_cost
            : 0,

          rta_project: projectData?.rta_project
            ? projectData?.rta_project
            : null,
          project_status:
            projectData?.project_status !== null
              ? ProjectStatus?.find(
                  (item) => item?.value === Number(projectData?.project_status)
                )
              : null,
        }}
        enableReinitialize
        innerRef={formValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={AddProjectSettingSchema}
      >
        {({
          values,
          setErrors,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <>
              {loading && <Spin />}
              <Form
                values={values}
                errors={errors}
                setErrors={setErrors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                stateListing={stateListing}
                stateLoading={stateLoading}
                bankListing={bankListing}
                allBanks={allBanks}
                bankLoading={bankLoading}
                allBankLoading={allBankLoading}
                projectData={projectData}
                builderId={builderId}
                projectId={projectId}
                setLoading={setLoading}
                ProjectReadOnly={ProjectReadOnly}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ProjectSetting;
