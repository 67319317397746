import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatAccountNumber,
  formatBSBNumber,
  formatedAddress,
  formatMobile,
  formatName,
  formatNumber,
  // formatNumber,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getContractorPopupDetail } from "./SubContractorHelper";
import { useNavigate, useParams } from "react-router-dom";

const SubContractorDetailPopup = ({
  contractorId,
  toggleShowModal,
  showView,
}) => {
  const [contractorDetail, setContractorDetail] = useState(null);
  const [contractorLoading, setContractorLoading] = useState(true);
  const params = useParams();
  const projectId = params?.project_id;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const contractorDetailData = await getContractorPopupDetail(
        contractorId,
        true
      );
      setContractorDetail(contractorDetailData);
      setContractorLoading(false);
    }
    fetchData();
  }, [contractorId, setContractorDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="project-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Subcontractor(Beneficiaries) Details
      </Modal.Header>
      <Modal.Body>
        {contractorLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {contractorDetail?.profile_pic === null ||
                contractorDetail?.profile_pic === "" ||
                contractorDetail?.profile_pic === undefined ? (
                  <span>
                    {contractorDetail?.first_name
                      ? contractorDetail?.first_name?.charAt(0)
                      : contractorDetail?.business_name
                      ? contractorDetail?.business_name?.charAt(0)
                      : contractorDetail?.last_name?.charAt(0)}
                  </span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${contractorDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {formatName(
                    contractorDetail?.first_name,
                    contractorDetail?.last_name
                  )}
                </h5>
                <p>
                  {contractorDetail?.contact_type?.name
                    ? contractorDetail?.contact_type?.name
                    : ""}
                </p>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Name:</strong>{" "}
                  {contractorDetail?.first_name
                    ? contractorDetail?.first_name
                    : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong>{" "}
                  {contractorDetail?.last_name
                    ? contractorDetail?.last_name
                    : "-"}
                </p>

                <p>
                  <strong>Email Address:</strong>{" "}
                  {contractorDetail?.email ? contractorDetail?.email : "-"}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {contractorDetail?.phone
                    ? formatPhone(contractorDetail?.phone)
                    : "-"}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {contractorDetail?.mobile
                    ? formatMobile(contractorDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {contractorDetail?.business_name
                    ? contractorDetail?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {contractorDetail?.abn
                    ? formatABN(contractorDetail?.abn)
                    : "-"}
                </p>
                {/* <p>
                  <strong>Contractor Type:</strong>{" "}
                  {contractorDetail?.contact_type?.name
                    ? contractorDetail?.contact_type?.name
                    : "-"}
                </p> */}
                <p>
                  <strong>License Number:</strong>{" "}
                  {contractorDetail?.qbcc_number
                    ? contractorDetail?.qbcc_number
                    : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    contractorDetail?.street,
                    contractorDetail?.suburb?.name,
                    contractorDetail?.state?.name,
                    contractorDetail?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    contractorDetail?.shipping_street,
                    contractorDetail?.shipping_suburb?.name,
                    contractorDetail?.shipping_state?.name,
                    contractorDetail?.shipping_postal_code
                  )}
                </p>
              </div>
            </div>
            <div className="modal-content">
              <h3>Bank Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Bank Name:</strong>
                  {contractorDetail?.bank
                    ? contractorDetail?.bank?.abbreviation +
                      " - " +
                      contractorDetail?.bank?.name
                    : "-"}
                </p>
                <p>
                  <strong>Account Name:</strong>{" "}
                  {contractorDetail?.account_name
                    ? contractorDetail?.account_name
                    : "-"}
                </p>
                <p>
                  <strong>BSB:</strong>{" "}
                  {contractorDetail?.bsb
                    ? formatBSBNumber(contractorDetail?.bsb)
                    : "-"}
                </p>
                <p>
                  <strong>Account Number:</strong>{" "}
                  {contractorDetail?.account_number
                    ? formatAccountNumber(contractorDetail?.account_number)
                    : "-"}
                </p>
                {/* <p>
                  <strong>Opening Balance:</strong>{" "}
                  {contractorDetail?.opening_balance
                    ? "$" +
                      formatNumber(Number(contractorDetail?.opening_balance))
                    : "$0.00"}
                </p> */}
              </div>
            </div>
            <div className="modal-content">
              <h3>Statements</h3>
              <div
                className="modal-content-details"
                style={{ paddingBottom: "15px" }}
              >
                <Button
                  variant="primary"
                  style={{
                    marginLeft: "0px",
                  }}
                  onClick={() => {
                    navigate(
                      `/projects/${projectId}/project/transactions-report`,
                      {
                        state: { contractorData: contractorDetail },
                      }
                    );
                  }}
                >
                  PTA Statements
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate(
                      `/projects/${projectId}/retention/transactions-report`,
                      {
                        state: { contractorData: contractorDetail },
                      }
                    );
                  }}
                >
                  RTA Statements
                </Button>
              </div>
            </div>
            <div className="modal-content">
              <h3>Contract Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Contract Value:</strong>
                  {contractorDetail?.contract_value
                    ? `$${formatNumber(contractorDetail?.contract_value)}`
                    : "-"}
                </p>
                <p>
                  <strong>Retention Percentage:</strong>{" "}
                  {contractorDetail?.retention_percentage
                    ? `${contractorDetail?.retention_percentage}%`
                    : "-"}
                </p>
                <p>
                  <strong>Retention Amount:</strong>{" "}
                  {contractorDetail?.retention_amount
                    ? `$${formatNumber(contractorDetail?.retention_amount)}`
                    : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SubContractorDetailPopup;
