import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  entityTypes,
  fileUpload,
  imageDefaultPrefixPath,
} from "../../../config";
import Spin from "../../common/Spin";
import { AddBuilderSchema } from "../../common/Validation";
import { useStateList } from "./components/BuilderHelper";
import Form from "./components/Form";

const AddBuilder = () => {
  const navigate = useNavigate();
  const [builderData, setBuilderData] = useState();

  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.profile_pic = value?.image
      ? value?.image
      : value?.profile_pic
      ? value?.profile_pic
      : "";

    value.logo = value?.image1 ? value?.image1 : value?.logo ? value?.logo : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.id;

    value.staff_id = value?.staff_id?.value;
    value.manager_id = value?.manager_id?.value;
    value.office_id = value?.office_id?.value;

    value.entity_type = value?.entity_type?.value;

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append("abn", value?.abn ? value?.abn : "");

    formData.append("crn", value?.crn ? value?.crn : "");
    value?.profile_pic &&
      formData.append(
        "profile_pic",
        value?.profile_pic ? value?.profile_pic : ""
      );
    value?.logo && formData.append("logo", value?.logo ? value?.logo : "");
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );
    formData.append("staff_id", value?.staff_id ? value?.staff_id : "");
    formData.append("manager_id", value?.manager_id ? value?.manager_id : "");
    formData.append("office_id", value?.office_id ? value?.office_id : "");

    formData.append(
      "entity_type",
      value?.entity_type ? value?.entity_type : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BUILDER_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate("/builders");
      }
      return data?.data;
    } catch (error) {
      setBuilderData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) =>
      item?.value ===
      (builderData?.state_id
        ? builderData?.state_id
        : builderData?.client?.state_id)
  );
  const shippingState = stateListing?.find(
    (item) =>
      item?.value ===
      (builderData?.shipping_state_id
        ? builderData?.shipping_state_id
        : builderData?.client?.shipping_state_id)
  );

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            New Builder
          </h1>
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  first_name: builderData?.first_name,
                  last_name: builderData?.last_name,
                  phone: builderData?.phone,
                  mobile: builderData?.mobile,
                  email: builderData?.email,
                  business_name: builderData?.business_name
                    ? builderData?.business_name
                    : builderData?.client?.business_name,
                  abn: builderData?.abn
                    ? builderData?.abn
                    : builderData?.client?.abn,

                  crn: builderData?.crn
                    ? builderData?.crn
                    : builderData?.client?.crn,
                  profile_pic: builderData?.profile_pic
                    ? imageDefaultPrefixPath + builderData?.profile_pic
                    : "",
                  logo: builderData?.logo
                    ? imageDefaultPrefixPath + builderData?.logo
                    : "",
                  street: builderData?.street
                    ? builderData?.street
                    : builderData?.client?.street,
                  state_id: state,
                  suburb_id: "",
                  postal_code: builderData?.postal_code
                    ? builderData?.postal_code
                    : builderData?.client?.postal_code,

                  shipping_street: builderData?.shipping_street
                    ? builderData?.shipping_street
                    : builderData?.client?.shipping_street,
                  shipping_state_id: shippingState,
                  shipping_suburb_id: "",
                  shipping_postal_code: builderData?.shipping_postal_code
                    ? builderData?.shipping_postal_code
                    : builderData?.client?.shipping_postal_code,

                  entity_type: entityTypes?.find(
                    (item) => item?.value === builderData?.entity_type
                  ),
                }}
                enableReinitialize={true}
                innerRef={formValues}
                onSubmit={(value) => {
                  handleFormSubmit(value);
                }}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddBuilderSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBuilder;
