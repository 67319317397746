import React, { useState } from "react";
import { toast } from "react-toastify";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API, get } from "../../../config";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../common/Spin";
import { useParams } from "react-router-dom";

const ABARefresh = ({ id, handleRefreshButton }) => {
  const params = useParams();
  const projectId = params?.project_id;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.REGENERATE_ABA}/${id}?builder_project_id=${projectId}`
      );
      toast.success(data.message);
      toggleShowModal();
      setLoading(false);
      handleRefreshButton();
    } catch (e) {
      toast.error(
        "There is some issue with request, please try after some time."
      );
      setLoading(false);
    }
  };
  return (
    <>
      <FontAwesomeIcon
        icon={faRefresh}
        size="1x"
        onClick={() => {
          toggleShowModal();
        }}
        title="Regenerate ABA File"
      />
      {showModal && (
        <Modal
          size="lg"
          show={showModal}
          onHide={toggleShowModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Regenerate ABA File
          </Modal.Header>
          <div className="modal-body">
            <p>Are your sure you want to regenerate the .ABA File?</p>
          </div>
          <div className="modal-footer">
            <Button
              disabled={loading}
              className="cancel"
              onClick={() => toggleShowModal()}
            >
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleRefresh}>
              OK
            </Button>
          </div>
          {loading && <Spin />}
        </Modal>
      )}
    </>
  );
};

export default ABARefresh;
