import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/Form/BackButton";
import SubscriptionAfterLogin from "../user-setting/components/SubscriptionAfterLogin";
import FirmSetting from "./FirmSetting/FirmSetting";
import ProCoreVerfication from "./ProCoreVerfication";
import EditBuilder from "../Builders/Builders/EditBuilder";

const tabsKey = {
  "firm-settings": 1,
  subscriptions: 2,
  integrations: 3,
  "builder-settings": 4,
};

const GeneralSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const role = localStorage.getItem("role");
  const selectedTab =
    query.get("tab") ||
    (role !== "Builder" ? "firm-settings" : "builder-settings");
  const getCode = query.get("code") || "";
  const selectedChildTab = query.get("childTab") || "current-subscription";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 4
      ? "builder-settings"
      : parseInt(defaultActiveKey) === 3
      ? "integrations"
      : parseInt(defaultActiveKey) === 2
      ? "subscriptions"
      : "firm-settings"
  );

  useEffect(() => {
    if (activeTab === "subscriptions") {
      navigate(
        `/general-settings?tab=subscriptions&childTab=${selectedChildTab}`,
        {
          replace: true,
        }
      );
    } else if (activeTab === "integrations") {
      navigate(`/general-settings?tab=integrations?code=${getCode}`, {
        replace: true,
      });
    } else {
      navigate(`/general-settings?tab=${activeTab}`, {
        replace: true,
      });
    }
  }, [activeTab, navigate, selectedChildTab, getCode]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {activeTab === "builder-settings"
                ? "Builder Settings"
                : activeTab === "firm-settings"
                ? "Firm Settings"
                : activeTab === "subscriptions"
                ? "Subscriptions"
                : "Integrations"}
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(
                      event?.target?.innerText.replace(/ /g, "-").toLowerCase()
                    );
                  }}
                >
                  {role !== "Builder" && (
                    <li
                      className={activeTab === "firm-settings" ? "active" : ""}
                    >
                      <Link key={1}>Firm Settings</Link>
                    </li>
                  )}
                  {role === "Builder" && (
                    <li
                      className={
                        activeTab === "builder-settings" ? "active" : ""
                      }
                    >
                      <Link key={5}>Builder Settings</Link>
                    </li>
                  )}
                  {/* <li className={activeTab === "subscriptions" ? "active" : ""}>
                    <Link key={2}>Subscriptions</Link>
                  </li> */}
                  <li className={activeTab === "integrations" ? "active" : ""}>
                    <Link key={3}>Integrations</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "firm-settings" && <FirmSetting />}
                {activeTab === "subscriptions" && (
                  <SubscriptionAfterLogin builder={true} />
                )}
                {activeTab === "integrations" && <ProCoreVerfication />}
                {activeTab === "builder-settings" && (
                  <EditBuilder builderID={localStorage?.getItem("userId")} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSetting;
