import React from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, defaultLimitPagination, get } from "../../config";
import { formatName } from "./Misc";

const ContractorSelectWithOutPTA = (props) => {

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const search = searchQuery?.length >= 3 ? searchQuery : "";
    const response = await get(
      `${API.GET_CONTRACTORS}?builder_project_id=${
        props?.projectId ? props?.projectId : ""
      }&with_pta=1&limit=${defaultLimitPagination}&page=${page && !search ? page : 1}&search=${
        search ? search : ""
      }&sort_column=name&sort_order=ASC`
    );
    
    const responseJSON = response?.data?.data?.data
      ? response?.data?.data?.data
      : [];

    const displayResponseJSON = response?.data?.data?.data
    ? response?.data?.data?.data?.filter((item) => item?.is_pta !== 1)
    : [];

    const totalLength = loadedOptions?.length
      ? loadedOptions?.length + responseJSON?.length
      : responseJSON?.length;

    return {
      options: displayResponseJSON,
      hasMore: totalLength < response?.data?.data?.total,
      additional: {
        page: search ? 2 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      //change in key value triggers re-redering of this component
      // key={regionName !== null ? JSON.stringify(regionName) : 0}
      key={props.projectId}
      value={props.value || props.defaultValue}
      loadOptions={loadOptions}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) =>
        option?.is_pta === 0
          ? `${formatName(option?.first_name, option?.last_name)} (RTA)`
          : option?.is_pta === 1
          ? `${formatName(option?.first_name, option?.last_name)} (PTA)`
          : option?.is_pta === 2
          ? `${formatName(
              option?.first_name,
              option?.last_name
            )} (Withdrawal)`
          : option?.is_pta === 3
          ? `${formatName(option?.first_name, option?.last_name)} (Principal)`
          : option?.is_pta === 4
          ? `${formatName(option?.first_name, option?.last_name)} (Trustee)`
          : formatName(option?.first_name, option?.last_name)
      }
      classNamePrefix={props?.className || "selectbox"}
      onChange={onChange}
      isSearchable={true}
      placeholder={props?.placeholder || "Select Contractor"}
      additional={{
        page: 1,
      }}
      isClearable={props?.isClearable}
      isMulti={props?.isMulti ? props?.isMulti : false}
    />
  );
};

ContractorSelectWithOutPTA.propTypes = {
  regionName: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default ContractorSelectWithOutPTA;
