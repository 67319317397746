import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import PaymentClaimList from "./PaymentClaimList";
import Upload from "./Upload";
import BackButton from "../../../components/Form/BackButton";
import { getProjectData } from "../components/ProjectHelper";
import { imageDefaultPrefixPath } from "../../../config";
// import Workinprogrees from "../../../components/Workinprogrees";

const tabsKey = {
  list: 1,
  upload: 2,
};

const PaymnetClaims = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "list";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1 ? "List" : "Upload"
  );
  const params = useParams();
  const projectId = params?.project_id;
  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    setActiveTab(parseInt(defaultActiveKey) === 1 ? "List" : "Upload")
  },[defaultActiveKey])

  return (
    <>
      <div className="cms-page">
        {/* <Workinprogrees /> */}
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Payment Claims
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>

            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li className={activeTab === "List" ? "active" : ""}>
                    <Link key={1}>List</Link>
                  </li>
                  <li className={activeTab === "Upload" ? "active" : ""}>
                    <Link key={2}>Upload</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "List" && <PaymentClaimList />}
                {activeTab === "Upload" && (
                  <Upload
                    getProjectData={getProjectData}
                    setProjectName={setProjectName}
                    setProjectLogo={setProjectLogo}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymnetClaims;
