import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "underscore";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  defaultLimitPagination,
  get,
  imageDefaultPrefixPath,
} from "../../../config";
import {
  chartOfAccountFormat,
  // formatName,
  formatNumber,
  formatSubcontractorName,
} from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import ReceiptActionButton from "./components/ReceiptActionButton";
import { useProjectBar } from "../../common/useProjectBar";
// import UserActionButton from "./components/UserActionButton";

const Receipts = () => {
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");

  const [loading, setLoading] = useState(false);
  const [receiptsList, setReceiptsList] = useState([]);

  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { ProjectName, ProjectLogo, ProjectReadOnly } = useProjectBar();

  const fetchReceipts = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${
          API.GET_RECEIPT
        }?builder_project_id=${projectId}&trust_type=${trust_type}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const receiptsList = data.data.data;
      setReceiptsList(receiptsList);
      return data.data;
    } catch (error) {
      setLoading(false);
      setReceiptsList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField, projectId, trust_type]);

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/receipts?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [
    navigate,
    limit,
    searchParam,
    page,
    sortField,
    sortOrder,
    projectId,
    trustType,
  ]);

  const receiptsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: receiptsList[i]?.id,

        id: receiptsList[i]?.id,
        transaction_date: receiptsList[i]?.transaction_date
          ? receiptsList[i]?.transaction_date
          : "",
        amount: receiptsList[i]?.amount
          ? "$" + formatNumber(Number(receiptsList[i]?.amount))
          : `$0.00`,
        contact_name:
          receiptsList[i]?.contact?.is_pta === 0
            ? `${formatSubcontractorName(receiptsList[i]?.contact)} - RTA`
            : receiptsList[i]?.contact?.is_pta === 1
            ? `${formatSubcontractorName(receiptsList[i]?.contact)} - PTA`
            : receiptsList[i]?.contact?.is_pta === 2
            ? `${formatSubcontractorName(
                receiptsList[i]?.contact
              )} - Trustee's Business/Withdrawal`
            : receiptsList[i]?.contact?.is_pta === 3
            ? `${formatSubcontractorName(receiptsList[i]?.contact)} - Principal`
            : receiptsList[i]?.contact?.is_pta === 4
            ? `${formatSubcontractorName(receiptsList[i]?.contact)} - Trustee`
            : formatSubcontractorName(receiptsList[i]?.contact),
        payer_name: receiptsList[i]?.payer_name
          ? receiptsList[i]?.payer_name
          : "",
        // invoice_claim_number: receiptsList[i]?.invoice_claim_number ? receiptsList[i]?.invoice_claim_number : "-",
        note: receiptsList[i]?.note ? receiptsList[i]?.note : "",
        account: receiptsList[i]?.chart_of_account?.name
          ? chartOfAccountFormat(receiptsList[i]?.chart_of_account)
          : "",
        is_reversed: receiptsList[i]?.is_reversed,
        status: receiptsList[i]?.is_reversed === 1 ? "Reversed" : "-",
        action: (
          <>
            <ReceiptActionButton
              record={receiptsList[i]}
              fetchReceipts={fetchReceipts}
              projectId={projectId}
              trust_type={trust_type}
              ProjectName={ProjectName}
              ProjectReadOnly={ProjectReadOnly}
              id={receiptsList[i]?.id}
              file={receiptsList[i]?.payment_advice_file}
            />
          </>
        ),
      });
    }
    return items;
  };
  const receipts = receiptsGenerator(receiptsList?.length);

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };


  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "transaction_date",
      text: "Transaction Date",
      style: { width: "13%" },
      // sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      style: { width: "10%" },
      // sort: true,
    },
    {
      dataField: "contact_name",
      text: "Contact Name",
      style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "payer_name",
      text: "Payer Name",
      style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "note",
      text: "Note",
      style: { width: "10%" },
      // sort: true,
    },
    // {
    //   dataField: "invoice_claim_number",
    //   text: "Invoice Claim Number",
    //   style: { width: "10%" },
    //   // sort: true,
    // },
    {
      dataField: "account",
      text: "Account",
      style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      style: { width: "8%" },
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "7%" },
    },
  ];

  const rowClasses = (row) => {
    if (row?.is_reversed) {
      return "reversed_row";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {trust_type === 1
                ? "Project Trust Receipts"
                : "Retention Trust Receipts"}

              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) => handleSearchChange(e)}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Receipt"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <div className="table-btn">
                  {!ProjectReadOnly && (
                    <Button
                      variant="primary"
                      onClick={() =>
                        projectId &&
                        navigate(
                          `/projects/${projectId}/${trustType}/receipts/add`
                        )
                      }
                    >
                      Add New
                    </Button>
                  )}
                </div>
              </div>
              <div className="custom-table">
                {loading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  data={receipts}
                  columns={columns}
                  onTableChange={handleTableChange}
                  // expandRow={expandRow}
                  rowClasses={rowClasses}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showUser && (
        <AddUser
          toggleUserModal={toggleUserModal}
          userModal={userModal}
          showUser={showUser}
        />
      )} */}
    </>
  );
};

export default Receipts;
