import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { API, defaultLimitPagination, get } from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import Pagination from "../../common/Pagination";
import moment from "moment";

import ReconcileItemActionButton from "./components/ReconcileItemActionButton";
import { DateRangePicker } from "rsuite";
// import { DateRangePicker } from "@semcore/date-picker";

const ReconcileItem = ({ setBalanceInfo, setValueRange, valueRange }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [reconcileItemList, setReconcileItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [exportLoading, setExportLoading] = useState(false);

  const { afterToday } = DateRangePicker;
  
  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  }

  const dateFilterRange = [
    {
      label: "This Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Quarter",
      value: quarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Financial Year",
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Quarter",
      value: perviousQuarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Financial Year",
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
  ];

  const fetchReconcileItem = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${
          API.BANK_RECONCILIATION_LIST
        }?builder_project_id=${projectId}&trust_type=${trust_type}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&processed=1`
      );

      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setBalanceInfo(data?.accounts);
      setReconcileItemList(data?.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setReconcileItemList([]);
      setBalanceInfo([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
    navigate(
      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=reconcile-item&page=${
        page ? page : 1
      }&limit=${limit ? limit : defaultLimitPagination}&from=${
        valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
      }&to=${valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""}`
    );
  }, [
    navigate,
    projectId,
    limit,
    page,
    setBalanceInfo,
    valueRange,
    trustType,
    trust_type,
  ]);

  const handleUndo = () => {
    fetchReconcileItem();
  };

  const handleExport = async (format) => {
    try {
      setExportLoading(true);
      const { data } = await get(
        `${
          API.BANK_RECONCILIATION_LIST
        }?builder_project_id=${projectId}&trust_type=${trust_type}&from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&export=1&processed=1&format=${format}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "bank_reconciled_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
      setExportLoading(false);
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const reconcileItemGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      for (
        let j = 0;
        j < reconcileItemList[i].bank_reconciliation.length;
        j++
      ) {
        items.push({
          key: reconcileItemList[i]?.bank_reconciliation[j].id,
          date: reconcileItemList[i]?.transactionDate
            ? moment(
                reconcileItemList[i]?.transactionDate,
                "DD-MM-YYYY"
              ).format("DD-MM-YYYY")
            : "-",
          description: reconcileItemList[i]?.bank_reconciliation[j]?.description
            ? reconcileItemList[i]?.bank_reconciliation[j]?.description
            : "",
          credit:
            reconcileItemList[i]?.baseType === 1 ? (
              <span className="rag-green">
                $
                {reconcileItemList[i]?.bank_reconciliation[
                  j
                ]?.amount?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : (
              "-"
            ),
          debit:
            reconcileItemList[i]?.baseType === 0 ? (
              <span className="rag-red">
                $
                {reconcileItemList[i]?.bank_reconciliation[
                  j
                ]?.amount?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : (
              "-"
            ),
          balance: reconcileItemList[i]?.balance
            ? reconcileItemList[i]?.balance
            : "-",
          bank_transaction_source: reconcileItemList[i]?.source
            ? reconcileItemList[i]?.source
            : "-",
          chart_of_account: reconcileItemList[i]?.bank_reconciliation[j]
            ?.chart_of_account?.name
            ? reconcileItemList[i]?.bank_reconciliation[j]?.chart_of_account
                ?.name
            : "-",
          action: (
            <ReconcileItemActionButton
              handleUndo={handleUndo}
              id={reconcileItemList[i]?.id}
              projectId={projectId}
            />
          ),
        });
      }
    }
    return items;
  };

  const reconcileItem = reconcileItemGenerator(reconcileItemList?.length);

  useEffect(() => {
    fetchReconcileItem();
  }, [fetchReconcileItem]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      // sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      // sort: true,
    },
    {
      dataField: "debit",
      text: "Debit",
      // sort: true,
    },

    {
      dataField: "bank_transaction_source",
      text: "Bank Transaction Source",
      // sort: true,
    },
    {
      dataField: "chart_of_account",
      text: "Chart of Account",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleDateRange = (e) => {
    setValueRange(e);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="cms-page">
        <div className="table-top-btn">
          <div className="table-btn">
            <Form>
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={(e) => handleDateRange(e)}
                format="dd-MM-yyyy"
                placement="bottomEnd"
                disabledDate={afterToday()}
                value={valueRange}
                ranges={dateFilterRange}
                locale={{ ok: "Apply" }}
              />
            </Form>
            <Button onClick={() => handleExport("pdf")}>
              {exportLoading ? "Loading…" : "Export"}
            </Button>
          </div>
        </div>
        {/* <p
          style={{
            textAlign: "right",
            fontSize: "12px",
            margin: "0px 0 20px",
          }}
        >
          <a
            href="https://trust-easy-file-storage.s3.ap-southeast-2.amazonaws.com/public/common/docs/BankFeedsintoHL.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000" }}
          >
            Instructions on HandiLedger Import
          </a>
        </p> */}
        <div className="custom-table">
          {loading && <Spin />}
          <BootstrapTable
            keyField="key"
            remote
            data={reconcileItem}
            columns={columns}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default ReconcileItem;
