import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/newstyle.css";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import AdminDashboard from "./pages/Admin/dashboard/Index";
import ProtectedRoute from "./ProtectedRoute";
import { useCallback, useEffect, useState } from "react";
// import { API, post } from "./config";

import Builders from "./pages/Builders/Builders/Builders";

import Tabs from "./tabs";

import Settings from "./pages/Project/settings/Settings";
import UserSetting from "./pages/user-setting/UserSetting";

// import ImportTransaction from "./pages/Project/Banking/ImportTransaction";
import ReconcileTransactionsTab from "./pages/Project/Banking/ReconcileTransactionTab";
import SearchTerms from "./pages/Project/settings/search-terms/SearchTerms";
import AddSearchTerm from "./pages/Project/settings/search-terms/AddSearchTerm";
import EditSearchTerm from "./pages/Project/settings/search-terms/EditSearchTerm";
import GeneralLedgerReport from "./pages/Project/reports/GeneralLedgerReport";
import ContactStatements from "./pages/Project/reports/ContractorStatements";
import FirmSignup from "./pages/firm/FirmSignup";
import TermsOfUse from "./pages/TermsOfUse";
import TermsAndConditions from "./pages/TermsAndConditions";
import Subscription from "./pages/firm/Subscription";
import Congratulation from "./pages/firm/Congratulation";
import EmailVerification from "./pages/firm/EmailVerification";
import ClientDashboard from "./pages/Settings/ClientDashboard";
import Users from "./pages/Users/Users";
import Offices from "./pages/Offices/Offices";
import InviteAccountingFirm from "./pages/InviteAccountingFirm/InviteAccountingFirm";
import GeneralSetting from "./pages/general-settings/GeneralSetting";
import Bas from "./pages/Project/BAS/Bas";
import ConnectBank from "./pages/Project/settings/integrations/ConnectBank";
import SubscriptionsForAdmin from "./pages/Admin/SubscriptionsForAdmin";
import RegisteredUsersForAdmin from "./pages/Admin/RegisteredUsresForAdmin";
import JournalEntries from "./pages/Project/journal-entries/JournalEntries";
import AddJournalEntry from "./pages/Project/journal-entries/AddJournalEntry";
import AddBuilder from "./pages/Builders/Builders/AddBuilder";
import EditBuilder from "./pages/Builders/Builders/EditBuilder";
import ProjectForBuilder from "./pages/Builders/Projects/ProjectsForBuilder";
import ProjectDashboard from "./pages/Project/ProjectDashboard";
import SubContractors from "./pages/Project/sub-contractors/SubContractors";
import AddSubContractor from "./pages/Project/sub-contractors/AddSubContractor";
import EditSubContractor from "./pages/Project/sub-contractors/EditSubContractor";
import ChartOfAccounts from "./pages/Project/accounting/ChartOfAccounts";
import MonthlyBankReconciliation from "./pages/Project/Banking/MonthlyBankReconciliation";
import RecordPayments from "./pages/Project/Payments/RecordPayments";
import Payments from "./pages/Project/Payments/Payments";
import TrialBalanceReport from "./pages/Project/reports/TrialBalanceReport";
import RecordReceipt from "./pages/Project/Receipts/RecordReceipt";
import Receipts from "./pages/Project/Receipts/Receipts";
import TransactionsReport from "./pages/Project/reports/TransactionsReport";
import PageNotFound from "./pages/PageNotFound";
import TwoFactorRegister from "./pages/auth/TwoFactorRegister";
import TwoFactorChange from "./pages/auth/TwoFactorChange";
import TwoFactorDisable from "./pages/auth/TwoFactorDisable";
import AuthRoute from "./AuthRoute";
import TwoFactor from "./pages/auth/TwoFactor";
import PaymentSchedule from "./pages/Project/payment-schedule/PaymentSchedule";
import AddPaymentSchedule from "./pages/Project/payment-schedule/AddPaymentSchedule";
import Documents from "./pages/Project/settings/documents/Documents";
import ABATabs from "./pages/Project/ABA/ABATabs";
import FinancialReportTab from "./pages/Project/financial-reports/FinancialReportTab";
import OpeningBalance from "./pages/Project/opening-balance/OpeningBalance";
import PaymnetClaims from "./pages/Project/payment-claim/PaymentClaims";
import { refreshAPI } from "./APIConfig/Interceptor";
import AdminRoute from "./AdminRoute";
import Holidays from "./pages/Admin/Holidays/Holidays";
import PartnerAgreement from "./pages/PartnerAgreement";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tfa, setTfa] = useState(0);
  const halfHour = 30 * 60000;
  const role = localStorage.getItem("role");

  const authenticate = useCallback(
    (token, email, tfaCheck = 0, refreshToken) => {
      localStorage.setItem("bt-auth-tfa", tfaCheck);

      setIsAuthenticated(true);
      setTfa(tfa);

      localStorage.setItem("bt-auth-token", token);
      localStorage.setItem("bt-refresh-token", refreshToken);
      setInterval(() => {
        refreshAPI();
      }, [halfHour]);
      localStorage.setItem("bt-auth-email", email);
    },
    [tfa, halfHour]
  );

  useEffect(() => {
    const lsToken = localStorage.getItem("bt-auth-token");
    const email = localStorage.getItem("bt-auth-email");
    const tfaCheck = localStorage.getItem("bt-auth-tfa");
    const reToken = localStorage.getItem("bt-refresh-token");

    if (lsToken || reToken) {
      authenticate(lsToken, email, tfaCheck, reToken);
    }
  }, [authenticate]);

  // const refresh = async () => {
  //   try {
  //     const response = await post(API.REFRESH, "");
  //     if (response?.data?.status === 200) {
  //       localStorage.setItem("bt-auth-token", response?.data?.access_token);
  //       localStorage.setItem("bt-refresh-token", response?.data?.refresh_token);
  //     }
  //   } catch (error) {
  //     const { data } = error.response;
  //     toast.error(data.message);
  //     setTimeout(() => {
  //       localStorage.clear();
  //     }, 3000);
  //   }
  // };

  return (
    <>
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Login
                authenticate={authenticate}
                isAuthenticated={isAuthenticated}
                tfa={tfa}
              />
            }
          />
          <Route
            exact
            path="/2fa"
            element={<AuthRoute component={TwoFactor}></AuthRoute>}
          />

          <Route
            exact
            path="/2fa/register"
            element={<AuthRoute component={TwoFactorRegister}></AuthRoute>}
          />
          <Route
            exact
            path="/2fa/change"
            element={<AuthRoute component={TwoFactorChange}></AuthRoute>}
          />
          <Route
            exact
            path="/2fa/disable"
            element={<AuthRoute component={TwoFactorDisable}></AuthRoute>}
          />
          <Route exact path="/subscription" element={<Subscription />} />
          <Route exact path="/congratulations" element={<Congratulation />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/signup" element={<FirmSignup />} />
          <Route
            exact
            path="/email-verification"
            element={<EmailVerification />}
          />
          <Route exact path="/terms-of-use" element={<TermsOfUse />} />
          <Route exact path="/partners-agreement" element={<PartnerAgreement />} />
          <Route
            exact
            path="/terms-and-condition"
            element={<TermsAndConditions />}
          />
          <Route exact path="/tabs" element={<Tabs />} />

          <Route
            path="/builders"
            element={<ProtectedRoute notSubscription={true} component={Builders}></ProtectedRoute>}
          />
          <Route
            path="/builders/add"
            element={<ProtectedRoute notSubscription={true} component={AddBuilder}></ProtectedRoute>}
          />
          <Route
            path="/builders/edit/:id"
            element={<ProtectedRoute notSubscription={true} component={EditBuilder}></ProtectedRoute>}
          />

          <Route
            path="/projects"
            element={
              <ProtectedRoute notSubscription={true} component={ProjectForBuilder}></ProtectedRoute>
            }
          />

          <Route
            path="/projects/:project_id"
            element={
              <ProtectedRoute component={ProjectDashboard}></ProtectedRoute>
            }
          />

          <Route
            path="projects/:project_id/sub-contractors"
            element={
              <ProtectedRoute component={SubContractors}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/sub-contractors/add"
            element={
              <ProtectedRoute component={AddSubContractor}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/sub-contractors/edit/:sub_contractor_id"
            element={
              <ProtectedRoute component={EditSubContractor}></ProtectedRoute>
            }
          />

          <Route
            path="projects/:project_id/chart-of-accounts"
            element={
              <ProtectedRoute component={ChartOfAccounts}></ProtectedRoute>
            }
          />

          <Route
            path="/accounting-firm-invitation"
            element={<InviteAccountingFirm />}
          />

          {role !== "Builder" && role !== "Admin" && (
            <Route
              path="/users"
              element={<ProtectedRoute notSubscription={true} component={Users}></ProtectedRoute>}
            />
          )}

          {role !== "Builder" && role !== "Admin" && (
            <Route
              path="/offices"
              element={<ProtectedRoute notSubscription={true} component={Offices}></ProtectedRoute>}
            />
          )}

          {role !== "Builder" && role !== "Admin" && (
            <Route
              path="/client-dashboard/:client_id"
              element={
                <ProtectedRoute component={ClientDashboard}></ProtectedRoute>
              }
            />
          )}

          <Route
            path="user-settings"
            element={
              <ProtectedRoute
                component={UserSetting}
                notSubscription={true}
              ></ProtectedRoute>
            }
          />

          <Route
            path="general-settings"
            element={
              <ProtectedRoute
                component={GeneralSetting}
                notSubscription={true}
              ></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/opening-balance"
            element={
              <ProtectedRoute component={OpeningBalance}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/bas"
            element={<ProtectedRoute component={Bas}></ProtectedRoute>}
          />
          <Route
            path="projects/:project_id/:trust_type/journal-entries"
            element={
              <ProtectedRoute component={JournalEntries}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/journal-entries/add"
            element={
              <ProtectedRoute component={AddJournalEntry}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/payment-schedules"
            element={
              <ProtectedRoute component={PaymentSchedule}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/payment-schedules/add"
            element={
              <ProtectedRoute component={AddPaymentSchedule}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/settings"
            element={<ProtectedRoute component={Settings}></ProtectedRoute>}
          />
          <Route
            path="projects/:project_id/documents"
            element={<ProtectedRoute component={Documents}></ProtectedRoute>}
          />
           <Route
            path="projects/:project_id/payment-claims"
            element={<ProtectedRoute component={PaymnetClaims}></ProtectedRoute>}
          />
          <Route
            path="projects/:project_id/terms"
            element={<ProtectedRoute component={SearchTerms}></ProtectedRoute>}
          />
          <Route
            path="projects/:project_id/terms/add"
            element={
              <ProtectedRoute component={AddSearchTerm}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/terms/edit/:term_id"
            element={
              <ProtectedRoute component={EditSearchTerm}></ProtectedRoute>
            }
          />

          {/* <Route
            path="projects/:project_id/:trust_type/transactions"
            element={
              <ProtectedRoute component={ImportTransaction}></ProtectedRoute>
            }
          /> */}
          <Route
            path="projects/:project_id/:trust_type/reconcile-transactions"
            element={
              <ProtectedRoute
                component={ReconcileTransactionsTab}
              ></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/financial-reports"
            element={
              <ProtectedRoute component={FinancialReportTab}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/transactions-report"
            element={
              <ProtectedRoute component={TransactionsReport}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/trial-balance"
            element={
              <ProtectedRoute component={TrialBalanceReport}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/general-ledger"
            element={
              <ProtectedRoute component={GeneralLedgerReport}></ProtectedRoute>
            }
          />
          <Route
            path="projects/:project_id/:trust_type/contractor-statement-report"
            element={
              <ProtectedRoute component={ContactStatements}></ProtectedRoute>
            }
          />
          <Route
            path="/monthly-bank-reconciliation"
            element={
              <ProtectedRoute
                component={MonthlyBankReconciliation}
              ></ProtectedRoute>
            }
          />
          <Route
            path="/projects/:project_id/:trust_type/receipts"
            element={<ProtectedRoute component={Receipts}></ProtectedRoute>}
          />
          <Route
            path="/projects/:project_id/:trust_type/receipts/add"
            element={
              <ProtectedRoute component={RecordReceipt}></ProtectedRoute>
            }
          />
          <Route
            path="/projects/:project_id/:trust_type/payments/add"
            element={
              <ProtectedRoute component={RecordPayments}></ProtectedRoute>
            }
          />
          <Route
            path="/projects/:project_id/:trust_type/payments"
            element={<ProtectedRoute component={Payments}></ProtectedRoute>}
          />
          <Route
            path="/projects/:project_id/:trust_type/aba"
            element={<ProtectedRoute component={ABATabs}></ProtectedRoute>}
          />
          <Route
            exact
            path="/connect-to-bank/:project_id"
            element={<ConnectBank />}
          />

          {/* Admin Routes */}

          <Route
            path="/admin-dashboard"
            element={
              <AdminRoute component={AdminDashboard}></AdminRoute>
            }
          />
          <Route
            path="/admin/subscriptions"
            element={
              <AdminRoute
                component={SubscriptionsForAdmin}
              ></AdminRoute>
            }
          />
          <Route
            path="/admin/registered-users"
            element={
              <AdminRoute
                component={RegisteredUsersForAdmin}
              ></AdminRoute>
            }
          />
          <Route
            path="/admin/holidays"
            element={
              <AdminRoute
                component={Holidays}
              ></AdminRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
