import React from "react";
import { API, fileUpload } from "../../../config";
import { toast } from "react-toastify";
import { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Button } from "react-bootstrap";
import { MaxLimit, formatNumber } from "../../common/Misc";
import NumberFormat from "react-number-format";
import ContractorSelectWithTA from "../../common/ContractorSelectWithTA";
import { useParams } from "react-router-dom";
import { getContractorDetail } from "../sub-contractors/components/SubContractorHelper";
import Spin from "../../common/Spin";

const AddEditOpeningBalance = ({ data, onClose, type }) => {
  const params = useParams();
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [customerId, setCustomerId] = useState(data?.id ? data?.id : "");
  // eslint-disable-next-line no-unused-vars
  const [contractorDetails, setContractorDetails] = useState();
  const [disableState, setDisableState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      values.opening_balance = Number(
        `${values?.opening_balance}`.replace(/,/g, "")
      );
      const formData = new FormData();
      formData.append("trust_type", trust_type);
      formData.append("sub_contractor_id", customerId);
      formData.append("builder_project_id", projectId ? projectId : "");
      if (trust_type) {
        formData.append(
          "opening_balance",
          values?.opening_balance ? values?.opening_balance : ""
        );
      } else {
        formData.append(
          "opening_balance_rta",
          values?.opening_balance ? values?.opening_balance : ""
        );
      }

      formData.append("_method", "PUT");

      setSaveLoading(true);
      const { data } = await fileUpload(`${API.OPENING_BALANCE}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSaveLoading(false);
      toast.success(data.message);
      onClose();
    } catch (e) {
      setSaveLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleSubmit1 = async (values) => {
    try {
      values.opening_balance = Number(
        `${values?.opening_balance}`.replace(/,/g, "")
      );
      const formData = new FormData();
      formData.append("trust_type", trust_type);
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append(
        "opening_balance",
        values?.opening_balance ? values?.opening_balance : ""
      );
      formData.append("_method", "PUT");

      setSaveLoading(true);
      const data1 = await fileUpload(`${API.OPENING_BALANCE}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSaveLoading(false);
      data1?.data.message && toast.success(data1?.data.message);
      onClose();
    } catch (e) {
      setSaveLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const getContractor = async (contractorId) => {
    try {
      setLoading(true);
      const data = await getContractorDetail(contractorId);
      const contractorData = data ? data : {};
      setCustomerId(contractorData?.id);
      if (contractorData?.id) {
        if (
          contractorData?.account_name &&
          contractorData?.account_number &&
          contractorData?.bsb
        ) {
          setDisableState(false);
        } else {
          setDisableState(true);
          toast.error("Please add bank details of selected contractor.");
        }
      }
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong.");
    }
  };

  const handleContractorSelect = async (contractor) => {
    setContractorDetails(contractor);
    getContractor(contractor?.id, contractor);
  };

  return (
    <div>
      {saveLoading && <Spin />}
      {type === "firm" ? (
        <Formik
          initialValues={{
            // business_name: data?.business_name ? data?.business_name : "",
            opening_balance: data?.opening_balance
              ? data?.opening_balance
              : "0.00",
          }}
          name="supplierForm"
          enableReinitialize={true}
          onSubmit={(value) => {
            handleSubmit1(value);
          }}
          validateOnBlur={true}
          validateOnChange={true}
          // validationSchema={AddBuilderSchema}
        >
          {({
            values,
            setErrors,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                {loading && <Spin />}

                <form
                  onSubmit={handleSubmit}
                  noValidate
                  className="entry-section"
                >
                  <div className="form-box">
                    {/* <div className="form-group half-width builder-firm">
                      <label>Business Name</label>
                      <input
                        type="text"
                        name="business_name"
                        value={
                          values?.business_name ? values?.business_name : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="business_name" />
                      </span>
                    </div> */}

                    <div className="form-group full-width">
                      <label>
                        {/* <span className="required">*</span> */}
                        Opening Balance($)
                      </label>
                      <NumberFormat
                        type="text"
                        name="opening_balance"
                        value={values?.opening_balance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator={true}
                        isAllowed={(values) => MaxLimit(values)}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="opening_balance" />
                      </span>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      disabled={disableState}
                    >
                      Save
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            contractor_id: contractorDetails
              ? contractorDetails
              : data
              ? data
              : "",
            opening_balance: trust_type
              ? data?.opening_balance
                ? formatNumber(data?.opening_balance)
                : ""
              : data?.opening_balance_rta
              ? formatNumber(data?.opening_balance_rta)
              : "",
          }}
          name="supplierForm"
          enableReinitialize={true}
          onSubmit={(value) => {
            handleSubmit(value);
          }}
          validateOnBlur={true}
          validateOnChange={true}
          // validationSchema={AddBuilderSchema}
        >
          {({
            values,
            setErrors,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                {loading && <Spin />}
                <form
                  onSubmit={handleSubmit}
                  noValidate
                  className="entry-section"
                >
                  <div className="form-box">
                    <div className="form-group half-width builder-firm">
                      <label>
                        <span className="required">*</span>Sub Contactor
                      </label>
                      <ContractorSelectWithTA
                        regionName={"0"}
                        projectId={projectId}
                        value={
                          values?.contractor_id ? values?.contractor_id : null
                        }
                        onChange={(contractor) => {
                          handleContractorSelect(contractor, setFieldValue);
                        }}
                        className="contactname-select"
                        placeholder="Select Subcontractor"
                        isClearable={false}
                        isDisabled={data?.id ? true : false}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="business_name" />
                      </span>
                    </div>

                    <div className="form-group half-width">
                      <label>
                        {/* <span className="required">*</span> */}
                        Opening Balance($)
                      </label>
                      <NumberFormat
                        type="text"
                        name="opening_balance"
                        value={values?.opening_balance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator={true}
                        isAllowed={(values) => MaxLimit(values)}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="opening_balance" />
                      </span>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      disabled={disableState}
                    >
                      Save
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
export default AddEditOpeningBalance;
