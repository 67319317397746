/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  defaultLimitPagination,
  fileUpload,
  get,
  imageDefaultPrefixPath,
} from "../../../config";
import {
  DDMMYYYYFormat,
  ExcelDateFormat,
  // checkIfFileExists,
  formatNumber,
  formatSubcontractorName,
  lastTwoMonth,
} from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
// import { getProjectData } from "../components/ProjectHelper";
import UploadFile from "../../common/UploadFile";
import PaymentSchduleActionButton from "./PaymentSchduleActionButton";
import ExpandedPaymentScheduled from "./ExpandedPaymentScheduled";
import * as XLSX from "xlsx";
import ExcelEditorPaymentSchedule from "./ExcelEditorPaymentSchedule";
import { Type } from "react-bootstrap-table2-editor";
import moment from "moment";
import CalenderView from "../../common/CalenderView";
import { DragIndicatorOutlined } from "@material-ui/icons";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { useProjectBar } from "../../common/useProjectBar";
import ExcelEditorAdjustment from "./ExcelEditorAdjustment";
import PaymentScheduledApprovalModal from "./PaymentScheduledApprovalModal";
import ReactSelect from "react-select";
import { debounce } from "lodash";
import { MonthRangePicker } from "@semcore/date-picker";

const PaymentSchedule = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");

  const projectId = params?.project_id;
  // const [projectName, setProjectName] = useState();
  // const [projectLogo, setProjectLogo] = useState();

  const [projectLoading, setLoading] = useState(false);
  const [paymentScheduleList, setPaymentScheduleListList] = useState(null);

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [sortByStatus, setSortByStatus] = useState("");

  const SortStatus = [
    { label: "Approved First", value: "approved" },
    { label: "Unapproved First", value: "unapproved" },
    { label: "Email Sent", value: "email-sent" },
    { label: "Email Yet To Sent", value: "email-not-sent" },
  ];

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [showCalender, setShowCalender] = useState(false);

  const [showImportBulk, setShowImportBulk] = useState(false);
  const [isImportBulkLoading, setIsImportBulkLoading] = useState(false);
  const [filesBulk, setFilesBulk] = useState();
  const [showExcelBulkModal, setShowExcelBulkModal] = useState(false);
  const [excelBulkData, setExcelBulkData] = useState();

  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelData, setExcelData] = useState();

  const [mainSelectedRow, setMainSelectedRow] = useState([]);
  const [mainNotSelectedRow, setMainNotSelectedRow] = useState([]);
  const [approvalModal, setApprovalModal] = useState(false);
  const [valueRange, setValueRange] = useState(lastTwoMonth);

  const { ProjectData, ProjectLogo, ProjectName, ProjectReadOnly } =
    useProjectBar();

  const fetchPaymentSchedules = useCallback(async () => {
    setMainNotSelectedRow();
    try {
      setLoading(true);
      const { data } = await get(
        `${API.PAYMENT_SCHEDULES}?builder_project_id=${projectId}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&trust_type=${trust_type}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }&sort_by_status=${sortByStatus ? sortByStatus : ""}&from=${
          valueRange[0]
            ? moment(valueRange[0]).format("MM-YYYY")
            : ""
        }&to=${
          valueRange[1]
            ? moment(valueRange[1]).format("MM-YYYY")
            : ""
        }`
      );

      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const paymentScheduleList = data.data.data;
      let notSelected = [];
      for (let k = 0; k < data?.data?.data?.length; k++) {
        if (
          data?.data?.data[k]?.approved_date ||
          data?.data?.data[k]?.is_reversed
        ) {
          notSelected.push(data?.data?.data[k]?.id);
        }
      }
      setMainNotSelectedRow(notSelected);
      setPaymentScheduleListList(paymentScheduleList);
      return data.data;
    } catch (error) {
      setLoading(false);
      setPaymentScheduleListList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [
    projectId,
    limit,
    page,
    trust_type,
    searchParam,
    sortField,
    sortOrder,
    sortByStatus,
    valueRange,
  ]);

  const handleDelete = () => {
    fetchPaymentSchedules();
  };

  // *Approve Payment Scheduled - Start
  const handleApprove = async (id, email) => {
    if (!mainSelectedRow.length) {
      toast.error("Please select rows");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);
      formData.append("send_email", email);

      if (mainSelectedRow?.length > 0) {
        for (let i = 0; i < mainSelectedRow?.length; i++) {
          formData.append(`ids[${i}]`, mainSelectedRow[i]);
        }
      }

      const { data } = await fileUpload(
        API.PAYMENT_SCHEDULE_APPROVE,
        formData,
        {}
      );

      setApprovalModal(false);

      setLoading(false);
      if (data) {
        setMainSelectedRow([]);
        toast.success(data.message);
        fetchPaymentSchedules();
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const toggleApprovalModal = async () => {
    if (!mainSelectedRow.length) {
      toast.error("Please select rows");
      return;
    }
    setApprovalModal(true);
  };
  const handleModalClose = () => {
    setApprovalModal(!approvalModal);
  };
  // *Approve Payment Scheduled - End

  const paymentScheduleGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: paymentScheduleList[i]?.id,
        expandData: paymentScheduleList[i]?.payment_schedule_items
          ? paymentScheduleList[i]?.payment_schedule_items
          : [],
        name: formatSubcontractorName(paymentScheduleList[i]?.contact),

        invoice_number: paymentScheduleList[i]?.invoice_number
          ? paymentScheduleList[i]?.invoice_number
          : "-",
        invoice_date: paymentScheduleList[i]?.invoice_date
          ? DDMMYYYYFormat(paymentScheduleList[i]?.invoice_date?.split("-"))
          : "",
        due_date: paymentScheduleList[i]?.due_date
          ? DDMMYYYYFormat(paymentScheduleList[i]?.due_date?.split("-"))
          : "",
        claimed_amount: paymentScheduleList[i]?.total_claimed_amount
          ? `$${formatNumber(
              Number(paymentScheduleList[i]?.total_claimed_amount)
            )}`
          : "$0.00",
        received_date: paymentScheduleList[i]?.received_date
          ? DDMMYYYYFormat(paymentScheduleList[i]?.received_date?.split("-"))
          : "-",
        approved_date: paymentScheduleList[i]?.approved_date
          ? DDMMYYYYFormat(paymentScheduleList[i]?.approved_date?.split("-"))
          : "-",
        approved_amount: paymentScheduleList[i]?.approved_amount
          ? `$${formatNumber(Number(paymentScheduleList[i]?.approved_amount))}`
          : "$0.00",
        scheduled_amount: paymentScheduleList[i]?.total_scheduled_amount
          ? `$${formatNumber(
              Number(paymentScheduleList[i]?.total_scheduled_amount)
            )}`
          : "$0.00",
        amount_withheld: paymentScheduleList[i]?.total_amount_withheld
          ? `$${formatNumber(
              Number(paymentScheduleList[i]?.total_amount_withheld)
            )}`
          : "$0.00",

        adjustment_date: paymentScheduleList[i]?.adjustment_date
          ? DDMMYYYYFormat(paymentScheduleList[i]?.adjustment_date?.split("-"))
          : "",
        adjustment_amount: paymentScheduleList[i]?.adjustment_amount ? (
          <span
            className={
              paymentScheduleList[i]?.adjustment_type ? "rag-green" : "rag-red"
            }
          >
            {`$${formatNumber(
              Number(paymentScheduleList[i]?.adjustment_amount)
            )}
            `}
          </span>
        ) : (
          "$0.00"
        ),
        adjustment_description: paymentScheduleList[i]?.adjustment_description
          ? paymentScheduleList[i]?.adjustment_description
          : "-",
        is_reversed: paymentScheduleList[i]?.is_reversed,
        status: paymentScheduleList[i]?.is_reversed === 1 ? "Reversed" : "-",
        mail_sent_date: paymentScheduleList[i]?.mail_sent_date
          ? DDMMYYYYFormat(paymentScheduleList[i]?.mail_sent_date?.split("-"))
          : "-",
        info: (
          <>
            <PaymentSchduleActionButton
              id={paymentScheduleList[i].id}
              handleDelete={handleDelete}
              info={true}
              file={paymentScheduleList[i].file}
              dataView={paymentScheduleList[i]}
              projectId={projectId}
              trust_type={trust_type}
              fetchPaymentSchedule={fetchPaymentSchedules}
              projectData={ProjectData}
              ProjectReadOnly={ProjectReadOnly}
              setMainSelectedRow={setMainSelectedRow}
            />
          </>
        ),
        action: (
          <>
            <PaymentSchduleActionButton
              id={paymentScheduleList[i].id}
              handleDelete={handleDelete}
              file={paymentScheduleList[i].file}
              dataView={paymentScheduleList[i]}
              projectId={projectId}
              info={false}
              trust_type={trust_type}
              fetchPaymentSchedule={fetchPaymentSchedules}
              projectData={ProjectData}
              ProjectReadOnly={ProjectReadOnly}
              setMainSelectedRow={setMainSelectedRow}
            />
          </>
        ),
        verify: (
          <>
            <PaymentSchduleActionButton
              id={paymentScheduleList[i].id}
              handleDelete={handleDelete}
              file={paymentScheduleList[i].file}
              dataView={paymentScheduleList[i]}
              projectId={projectId}
              info={false}
              verify={true}
              trust_type={trust_type}
              fetchPaymentSchedule={fetchPaymentSchedules}
              projectData={ProjectData}
              ProjectReadOnly={ProjectReadOnly}
              setMainSelectedRow={setMainSelectedRow}
            />
          </>
        ),
      });
    }
    return items;
  };
  const paymentSchedules = paymentScheduleGenerator(
    paymentScheduleList?.length
  );

  useEffect(() => {
    fetchPaymentSchedules();
  }, [fetchPaymentSchedules]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "received_date",
      text: "Received / Transaction Date",
      headerStyle: { width: "9%" },
      sort: true,
    },
    {
      dataField: "name",
      text: "Payment Schedule To ",
      headerStyle: { width: "12%" },
      sort: true,
    },
    {
      dataField: "invoice_number",
      text: "Invoice Number",
      headerStyle: { width: "9%" },
      sort: true,
    },
    {
      dataField: "invoice_date",
      text: "Invoice Date",
      headerStyle: { width: "8%" },
    },
    {
      dataField: "due_date",
      text: "Due Date",
      headerStyle: { width: "8%" },
      // sort: true,
    },
    {
      dataField: "claimed_amount",
      text: "Claimed Amount",
      headerStyle: { width: "8%" },
    },

    {
      dataField: "approved_date",
      text: "Approval Date",
      headerStyle: { width: "8%" },
    },
    {
      dataField: "approved_amount",
      text: "Approved Amount",
      headerStyle: { width: "10%" },
      sort: true,
    },
    // {
    //   dataField: "adjustment_date",
    //   text: "Variation Date",
    //   headerStyle: { width: "10%" },
    // },
    // {
    //   dataField: "adjustment_amount",
    //   text: "Variation Amount",
    //   headerStyle: { width: "10%" },
    // },
    // {
    //   dataField: "adjustment_description",
    //   text: "Variation Reason",
    //   headerStyle: { width: "10%" },
    // },

    // {
    //   dataField: "status",
    //   text: "Status",
    //   headerStyle: { width: "8%" },
    // },
    {
      dataField: "mail_sent_date",
      text: "Sent Date",
      headerStyle: { width: "8%" },
    },
    {
      dataField: "info",
      text: "Info",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "140px" },
    },
    {
      dataField: "action",
      text: "Action",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "105px" },
    },
    {
      dataField: "verify",
      text: "Verify",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "100px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleApprovedSort = (status) => {
    setPage(1);
    setSortByStatus(status?.value);
  };

  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(
        `${API.DOWNLOAD_PAYMENT_SCHEDULE_TEMPLATE}?builder_project_id=${projectId}&from=${
          valueRange[0]
            ? moment(valueRange[0]).format("MM-YYYY")
            : ""
        }&to=${
          valueRange[1]
            ? moment(valueRange[1]).format("MM-YYYY")
            : ""
        }&trust_type=${trust_type}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "payment-schedules.xls"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleImportModal = async () => {
    setShowImport(true);
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const rowClasses = (row) => {
    if (row?.is_reversed) {
      return "reversed_row";
    } else {
      return "";
    }
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandedPaymentScheduled paymentscheduleList={row?.expandData} />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  /**
   * Search
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  const toggleCalenderView = () => {
    setShowCalender(!showCalender);
  };

  // Excel
  const handleExcelClose = () => {
    setSelectedRow([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const ExcelContactColumns = [
    // {
    //   dataField: "order",
    //   text: "",
    //   editable: false,
    // },
    {
      dataField: "audit_no",
      text: "Reference/ID",
      editCellClasses: "edit-cell-excel-class",
      editable: false,
    },
    {
      dataField: "name",
      text: "Contractor Name",
      editCellClasses: "edit-cell-excel-class",
      editable: (data, row) => {
        // eslint-disable-next-line eqeqeq
        if (row?.schedule_group == 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      dataField: "company_name",
      text: (
        <div>
          <span className="required">*</span>Company Name
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      editable: (data, row) => {
        // eslint-disable-next-line eqeqeq
        if (row?.schedule_group == 0) {
          return true;
        } else {
          return false;
        }
      },
      // validator: (newValue) => {
      //   if (newValue === "" || newValue === undefined || newValue === null) {
      //     return {
      //       valid: false,
      //       message: "Company Name is Required",
      //     };
      //   }
      //   return true;
      // },
    },
    {
      dataField: "abn",
      text: (
        <div>
          <span className="required">*</span>ABN
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      editable: (data, row) => {
        // eslint-disable-next-line eqeqeq
        if (row?.schedule_group == 0) {
          return true;
        } else {
          return false;
        }
      },
      validator: (newValue) => {
        if (newValue) {
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if (
            !String(newValue)
              .split(" ")
              .join("")
              .match(/^[\d]{11}$/)
          ) {
            return {
              valid: false,
              message: "Enter 11 Digit ABN Number",
            };
          }
          if (newValue.split(" ").join("").length === 11) {
            let newNumber = newValue.split(" ").join("");
            let sum = 0;
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit;
            });
            if (sum % 89 !== 0) {
              return {
                valid: false,
                message: "Enter Valid ABN Number",
              };
            }
          }
        } else {
          return true;
        }
      },
      classes: (cell) => {
        if (cell) {
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if (cell.split(" ").join("").length !== 11) {
            // toast.error("Enter 11 Digit ABN Number")
            return "error-cell";
          }
          if (cell.split(" ").join("").length === 11) {
            let newNumber = cell.split(" ").join("");
            let sum = 0;
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit;
            });
            if (sum % 89 !== 0) {
              return "error-cell";
            }
          }
        }
      },
    },
    {
      dataField: "email",
      text: (
        <div>
          <span className="required">*</span>Email
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Email is Required",
          };
        }
        if (newValue) {
          if (
            !newValue
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            return {
              valid: false,
              message: "Please enter a valid email address",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (
            !String(cell)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "invoice_number",
      text: (
        <div>
          <span className="required">*</span>Claim/Invoice Number
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Invoice Number is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "invoice_number";
        }
      },
    },
    {
      dataField: "invoice_date",
      text: (
        <div>
          <span className="required">*</span>Claim/Invoice Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-") && cell) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object" && cell) {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        if (cell) {
          return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
            "0" +
            (dateObj?.getUTCMonth() + 1)
          ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
        }
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Invoice Date is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "invoice_date";
        }
      },
    },
    {
      dataField: "due_date",
      text: (
        <div>
          <span className="required">*</span>Due Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-") && cell) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object" && cell) {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        if (cell) {
          return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
            "0" +
            (dateObj?.getUTCMonth() + 1)
          ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
        }
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Due Date is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "due_date";
        }
      },
    },
    {
      dataField: "claimed_amount",
      text: (
        <div>
          <span className="required">*</span>Claimed Amount
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue, row) => {
        if (
          (newValue === "" || newValue === undefined || newValue === null) &&
          row?.schedule_group === 0
        ) {
          return {
            valid: false,
            message: "Claimed Amount is Required",
          };
        }
        return true;
      },
      editable: (data, row) => {
        // eslint-disable-next-line eqeqeq
        if (row?.schedule_group == 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      dataField: "received_date",
      text: (
        <div>
          <span className="required">*</span>Received/Transaction Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-") && cell) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object" && cell) {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        if (cell) {
          return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
            "0" +
            (dateObj?.getUTCMonth() + 1)
          ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
        }
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Received/Transaction Date is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "received_date";
        }
      },
    },
    {
      dataField: "approved_date",
      text: (
        <div>
          <span className="required">*</span>Approval Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-") && cell) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object" && cell) {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        if (cell) {
          return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
            "0" +
            (dateObj?.getUTCMonth() + 1)
          ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
        }
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Approval Date is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "approved_date";
        }
      },
    },
    {
      dataField: "approved_amount",
      text: (
        <div>
          <span className="required">*</span>Approved Amount
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Approved amount is Required",
          };
        }
        return true;
      },
    },
    // {
    //   dataField: "difference",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Difference
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   validator: (newValue) => {
    //     if (newValue === "" || newValue === undefined || newValue === null) {
    //       return {
    //         valid: false,
    //         message: "Difference is Required",
    //       };
    //     }
    //     return true;
    //   },
    // },
    // {
    //   dataField: "difference_reason",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Reason for Difference
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   validator: (newValue, row) => {
    //     if (newValue === "" || newValue === undefined || newValue === null) {
    //       return {
    //         valid: false,
    //         message: "Difference Reason is Required",
    //       };
    //     }

    //     return true;
    //   },
    // },
    {
      dataField: "scheduled_date",
      text: (
        <div>
          <span className="required">*</span>Payment Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-")) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object") {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
          "0" +
          (dateObj?.getUTCMonth() + 1)
        ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Scheduled Date is Required",
          };
        }
        return true;
      },
    },
    {
      dataField: "scheduled_amount",
      text: "Payment Amount",
      editCellClasses: "edit-cell-excel-class",
      // validator: (newValue, row) => {
      //   if (newValue === "" || newValue === undefined || newValue === null) {
      //     return {
      //       valid: false,
      //       message: "Scheduled Amount is Required",
      //     };
      //   }
      //   let parentItem = excelData?.find(
      //     (i) =>
      //       i.invoice_number === row?.invoice_number && i.schedule_group === 0
      //   );
      //   if (Number(newValue) > Number(parentItem?.claimed_amount)) {
      //     return {
      //       valid: false,
      //       message: "Enter Amount should be less than Claimed amount",
      //     };
      //   }
      //   return true;
      // },
    },
    {
      dataField: "description",
      text: "Description",
      editCellClasses: "edit-cell-excel-class",
      // validator: (newValue) => {
      //   if (newValue === "" || newValue === undefined || newValue === null) {
      //     return {
      //       valid: false,
      //       message: "Description is Required",
      //     };
      //   }
      //   return true;
      // },
      validator: (newValue, row) => {
        if (row?.scheduled_amount) {
          if (newValue === "" || newValue === undefined || newValue === null) {
            return {
              valid: false,
              message: "Description is Required",
            };
          }
        }
        return true;
      },
    },
    {
      dataField: "amount_withheld",
      text: "Amount Withheld",
      editCellClasses: "edit-cell-excel-class",
      // validator: (newValue, row) => {
      //   let parentItem = excelData?.find(
      //     (i) =>
      //       i.invoice_number === row?.invoice_number && i.schedule_group === 0
      //   );
      //   if (Number(newValue) > Number(parentItem?.claimed_amount)) {
      //     return {
      //       valid: false,
      //       message: "Enter Amount should be less than Claimed amount",
      //     };
      //   }
      //   return true;
      // },
    },
    {
      dataField: "withheld_description",
      text: "Reason for Withholding",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue, row) => {
        if (row?.amount_withheld) {
          if (newValue === "" || newValue === undefined || newValue === null) {
            return {
              valid: false,
              message: "Reason is Required",
            };
          }
        }
        return true;
      },
    },
  ];

  const [selectedRow, setSelectedRow] = useState([]);

  let singleSelectedRows = [];
  let allSelectedRows = [];

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ["not-select"],
    selected: selectedRow,
    nonSelectableClasses: "not-selected-class",
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        // singleSelectedRows = [...selectedRow, row?.key];
        if (row.schedule_group === 0) {
          let data = excelData.filter(
            (i) => i.invoice_number === row.invoice_number
          );
          let keys = [];
          // eslint-disable-next-line
          data.map((i) => {
            keys.push(i.key);
          });
          let rowKey = removeDuplicates([...selectedRow, ...keys]);
          singleSelectedRows = rowKey;
          // selectedMaxRecord = [...selectMaxRowRecord, row?.key];
        } else {
          let data = excelData.filter(
            (i) => i.invoice_number === row.invoice_number
          );
          let parentData = data.find((i) => i.schedule_group === 0);
          if (selectedRow.includes(parentData.key)) {
            singleSelectedRows = [...selectedRow, row?.key];
          } else {
            singleSelectedRows = [...selectedRow, row?.key, parentData.key];
            // selectedMaxRecord = [...selectMaxRowRecord, parentData?.key]
          }
        }
      } else {
        // singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
        if (row.schedule_group === 0) {
          let data = excelData.filter(
            (i) => i.invoice_number === row.invoice_number
          );
          let keys = [];
          // eslint-disable-next-line
          data.map((i) => {
            keys.push(i.key);
          });
          let rowKey = selectedRow.filter(function (el) {
            return !keys.includes(el);
          });
          singleSelectedRows = rowKey;
          // selectedMaxRecord?.splice(selectMaxRowRecord?.indexOf(row?.key), 1);
        } else {
          singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
        }
      }
      // let index = excelData.findIndex(
      //   (i) =>
      //     String(i.invoice_number) === String(row?.invoice_number) &&
      //     i?.schedule_group === 0
      // );
      // let mainData = excelData.filter(
      //   (i) => String(i.invoice_number) === String(row?.invoice_number)
      // );
      // let claimed_amount = 0;
      // for (let j = 0; j < mainData?.length; j++) {
      //   if (singleSelectedRows?.find((item) => item === mainData[j]?.key)) {
      //     claimed_amount =
      //       claimed_amount +
      //       Number(mainData[j]?.amount_withheld) +
      //       Number(mainData[j]?.scheduled_amount);
      //   }
      // }
      // excelData[index].claimed_amount = claimed_amount.toFixed(2);
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    },
  };

  const ref = useRef();

  function sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  const dateFormatReader = (value) => {
    var utc_days = Math.floor(value - 25569);
    var utc_value = utc_days * 86400;
    const date = new Date(utc_value * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const getIndexInParent = (el) =>
    Array.from(el.parentNode.children).indexOf(el);

  const ExcelReader = () => {
    let f = files;
    const reader = new FileReader();
    let data = null;
    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      let selectItem = [];
      const businessExcelGenerator = (quantity) => {
        let items = [];
        if (
          String(data[1][0]).replace("*", "") !== "Reference/ID" ||
          String(data[1][1]).replace("*", "") !== "Contractor Name" ||
          String(data[1][2]).replace("*", "") !== "Company Name" ||
          String(data[1][3]).replace("*", "") !== "ABN" ||
          String(data[1][4]).replace("*", "") !== "Email" ||
          String(data[1][5]).replace("*", "") !== "Claim/Invoice Number" ||
          String(data[1][6]).replace("*", "") !== "Claim/Invoice Date" ||
          String(data[1][7]).replace("*", "") !== "Due Date" ||
          String(data[1][8]).replace("*", "") !== "Claimed Amount" ||
          String(data[1][9]).replace("*", "") !== "Received/Transaction Date" ||
          String(data[1][10]).replace("*", "") !== "Approval Date" ||
          String(data[1][11]).replace("*", "") !== "Approved Amount" ||
          // String(data[1][12]).replace("*", "") !== "Difference" ||
          // String(data[1][13]).replace("*", "") !== "Reason for Difference" ||
          String(data[1][12]).replace("*", "") !== "Payment Date" ||
          String(data[1][13]).replace("*", "") !== "Payment Amount from PTA" ||
          String(data[1][14]).replace("*", "") !== "Description" ||
          String(data[1][15]).replace("*", "") !== "Amount Withheld in RTA" ||
          String(data[1][16]).replace("*", "") !== "Reason for Withholding"
        ) {
          setShowExcelModal(false);
          setFiles();
          setIsImportLoading(false);
          toast.error(
            "Sorry, File is not in the given format, please download the latest format again"
          );
          setShowImport(false);
        } else {
          for (let i = 2; i < quantity; i++) {
            if (data[i].length > 0) {
              selectItem.push(i);
              let invoice_date =
                typeof data[i][6] === "number"
                  ? dateFormatReader(data[i][6])
                  : data[i][6]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][6])
                  : ExcelDateFormat(
                      data[i][6]?.split("-")?.reverse()?.join("-")
                    );
              let due_date =
                typeof data[i][7] === "number"
                  ? dateFormatReader(data[i][7])
                  : data[i][7]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][7])
                  : ExcelDateFormat(
                      data[i][7]?.split("-")?.reverse()?.join("-")
                    );
              let received_date = data[i][9]
                ? typeof data[i][9] === "number"
                  ? dateFormatReader(data[i][9])
                  : data[i][9]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][9])
                  : ExcelDateFormat(
                      data[i][9]?.split("-")?.reverse()?.join("-")
                    )
                : "";
              let approved_date = data[i][10]
                ? typeof data[i][10] === "number"
                  ? dateFormatReader(data[i][10])
                  : data[i][10]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][10])
                  : ExcelDateFormat(
                      data[i][10]?.split("-")?.reverse()?.join("-")
                    )
                : "";
              let scheduled_date =
                typeof data[i][12] === "number"
                  ? dateFormatReader(data[i][12])
                  : data[i][12]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][12])
                  : ExcelDateFormat(
                      data[i][12]?.split("-")?.reverse()?.join("-")
                    );

              items.push({
                key: i,
                schedule_group: data[i][5] && !data[i][8] ? 1 : 0,
                order:
                  data[i][5] && !data[i][8] ? (
                    <DragIndicatorOutlined
                      className="draggable handle"
                      type="swap"
                    />
                  ) : (
                    <></>
                  ),
                audit_no: data[i][0] ? data[i][0] : "",
                name: data[i][1] ? data[i][1] : "",
                company_name: data[i][2] ? data[i][2] : "",
                abn: data[i][3]
                  ? `${Number(String(data[i][3])?.split(" ")?.join(""))
                      .toLocaleString("en-US")
                      .replaceAll(",", " ")}`
                  : "",
                email: data[i][4] ? data[i][4] : "",
                invoice_number: data[i][5] ? data[i][5] : "",
                invoice_date:
                  typeof data[i][6] === "string"
                    ? invoice_date?.split("-")?.reverse()?.join("-")
                    : invoice_date
                    ? invoice_date
                    : "",
                due_date:
                  typeof data[i][7] === "string"
                    ? due_date?.split("-")?.reverse()?.join("-")
                    : due_date
                    ? due_date
                    : "",
                claimed_amount: data[i][8] ? data[i][8] : "",
                received_date:
                  typeof data[i][9] === "string"
                    ? received_date?.split("-")?.reverse()?.join("-")
                    : received_date
                    ? received_date
                    : "",
                approved_date:
                  typeof data[i][10] === "string"
                    ? approved_date?.split("-")?.reverse()?.join("-")
                    : approved_date
                    ? approved_date
                    : "",
                approved_amount: data[i][11]
                  ? Number(data[i][11]).toFixed(2)
                  : "",
                // difference: data[i][12] ? Number(data[i][12]).toFixed(2) : 0,
                // difference_reason: data[i][13] ? data[i][13] : "",

                scheduled_date:
                  typeof data[i][12] === "string"
                    ? scheduled_date?.split("-")?.reverse()?.join("-")
                    : scheduled_date
                    ? scheduled_date
                    : "",
                scheduled_amount: data[i][13] ? data[i][13] : "",
                description: data[i][14] ? data[i][14] : "",
                amount_withheld: data[i][15] ? data[i][15] : "",
                withheld_description: data[i][16] ? data[i][16] : "",
              });
            }
          }
        }
        items = sort_by_key(items, "invoice_number");
        return items;
      };
      setSelectedRow(selectItem);
      setExcelData(businessExcelGenerator(data?.length));
    };
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    showExcelModal && ExcelReader();
    // eslint-disable-next-line
  }, [showExcelModal]);

  const handleExcelModal = () => {
    setShowExcelModal(true);
  };

  const handleExcelTableChange = async (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    const getWithPromiseAll = async () => {
      let temp = await Promise.all(
        data.map(async (row) => {
          let newInvoiceNumber = null;
          const newRow = { ...row };

          if (dataField === "claimed_amount") {
            if (row?.key === rowId) {
              newRow[dataField] = newValue;
              // if (Number(newValue) < Number(newRow["approved_amount"])) {
              //   newRow["approved_amount"] = Number(newValue);
              // }
              // newRow["difference"] = Number(
              //   Number(newValue) - Number(newRow["approved_amount"])
              // ).toFixed(2);

              return newRow;
            }
          }

          if (dataField === "approved_amount") {
            if (row?.key === rowId) {
              // if (Number(newValue) <= Number(newRow["claimed_amount"])) {
              newRow[dataField] = newValue;
              // newRow["difference"] = Number(
              //   Number(newRow["claimed_amount"]) - Number(newValue)
              // ).toFixed(2);
              // } else {
              //   newRow[dataField] = "";
              // }

              return newRow;
            }
          }
          if (dataField === "amount_withheld") {
            if (newValue === "") {
              if (row?.key === rowId) {
                newRow[dataField] = newValue;
                // newRow["withheld_description"] = "";
                return newRow;
              }
            } else {
              if (row?.key === rowId) {
                if (Number(newValue) > 0) {
                  newRow[dataField] = newValue;
                  let index = excelData?.findIndex(
                    (i) =>
                      String(i.invoice_number) ===
                        String(row?.invoice_number) && i?.schedule_group === 0
                  );
                  let mainData = excelData.filter(
                    (i) =>
                      String(i.invoice_number) === String(row?.invoice_number)
                  );
                  let claimed_amount = 0;
                  for (let j = 0; j < mainData?.length; j++) {
                    if (mainData[j]?.key === row?.key) {
                      claimed_amount =
                        claimed_amount +
                        Number(newValue) +
                        Number(mainData[j]?.scheduled_amount);
                    } else {
                      claimed_amount =
                        claimed_amount +
                        Number(mainData[j]?.amount_withheld) +
                        Number(mainData[j]?.scheduled_amount);
                    }
                  }
                  excelData[index].claimed_amount = "";
                  excelData[index].claimed_amount = claimed_amount?.toFixed(2);
                  if (!selectedRow?.find((item) => item === row?.key)) {
                    if (row?.scheduled_amount || newRow[dataField]) {
                      setSelectedRow([...selectedRow, row?.key]);
                    }
                  } else {
                    if (row?.scheduled_amount || newRow[dataField]) {
                    } else {
                      setSelectedRow(
                        selectedRow?.fliter((item) => item !== row?.key)
                      );
                    }
                  }
                }
                return newRow;
              }
            }
          }
          if (dataField === "scheduled_amount") {
            if (row?.key === rowId) {
              // if (newValue) {
              newRow[dataField] = newValue;
              let index = excelData.findIndex(
                (i) =>
                  String(i.invoice_number) === String(row?.invoice_number) &&
                  i?.schedule_group === 0
              );
              let mainData = excelData.filter(
                (i) => String(i.invoice_number) === String(row?.invoice_number)
              );
              let claimed_amount = 0;
              for (let j = 0; j < mainData?.length; j++) {
                if (mainData[j]?.key === row?.key) {
                  claimed_amount =
                    claimed_amount +
                    Number(newValue) +
                    Number(mainData[j]?.amount_withheld);
                } else {
                  claimed_amount =
                    claimed_amount +
                    Number(mainData[j]?.amount_withheld) +
                    Number(mainData[j]?.scheduled_amount);
                }
              }
              excelData[index].claimed_amount = "";
              excelData[index].claimed_amount = claimed_amount.toFixed(2);
              // if(!newValue){
              //   newRow["description"] = ""
              // }
              // }
              if (!selectedRow?.find((item) => item === row?.key)) {
                setSelectedRow([...selectedRow, row?.key]);
              } else {
                if (row?.amount_withheld || newRow[dataField]) {
                } else {
                  setSelectedRow(
                    selectedRow?.filter((item) => item !== row?.key)
                  );
                }
              }
              return newRow;
            }
          }
          if (row?.key === rowId) {
            if (dataField !== "invoice_number") {
              newRow[dataField] = newValue;
            }
            if (dataField === "invoice_number") {
              newInvoiceNumber = row.invoice_number;
              newRow[dataField] = newValue;
            }
          }
          if (dataField === "invoice_number") {
            let tempData = excelData.filter(
              (i) => String(i.invoice_number) === newInvoiceNumber
            );
            // eslint-disable-next-line
            tempData.map((i) => {
              if (String(i.invoice_number) === String(newInvoiceNumber)) {
                i[dataField] = newValue;
              }
            });
          }
          if (row?.key === rowId) {
            return newRow;
          }
          return row;
        })
      );
      return temp;
    };
    const result = await getWithPromiseAll();
    setExcelData(result);
  };

  const handleReorder = (dragIndex, draggedIndex, el) => {
    setExcelData((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      let temp = el.previousSibling;
      let notes = null;
      let invoice_date = null;
      let due_date = null;
      for (var i = 0; i < temp.childNodes.length; i++) {
        if (temp.childNodes[i]?.className === "invoice_number") {
          notes = temp.childNodes[i]?.innerHTML;
        }
        if (temp.childNodes[i]?.className === "invoice_date") {
          invoice_date = temp.childNodes[i]?.innerHTML;
        }
        if (temp.childNodes[i]?.className === "due_date") {
          due_date = temp.childNodes[i]?.innerHTML;
        }
      }
      item.invoice_number = notes;
      item.invoice_date = invoice_date;
      item.due_date = due_date;
      newState.splice(draggedIndex, 0, item);
      let invoiceNumber = [];
      let parentData = newState?.filter((i) => i.schedule_group === 0);
      for (let j = 0; j < parentData?.length; j++) {
        invoiceNumber?.push(parentData[j]?.invoice_number);
      }
      for (let k = 0; k < invoiceNumber?.length; k++) {
        let mainData = newState?.filter(
          (item) => String(item?.invoice_number) === String(invoiceNumber[k])
        );
        let claimed_amount = 0;
        for (let m = 0; m < mainData?.length; m++) {
          claimed_amount =
            claimed_amount +
            Number(mainData[m]?.amount_withheld) +
            Number(mainData[m]?.scheduled_amount);
        }
        let parentIndex = newState?.findIndex(
          (i) =>
            i.schedule_group === 0 &&
            String(i?.invoice_number) === String(invoiceNumber[k])
        );
        newState[parentIndex].claimed_amount =
          Number(claimed_amount).toFixed(2);
      }
      return newState;
    });
  };

  const handleExcelUpload = async () => {
    if (selectedRow.length > 1000) {
      toast.warn("Maximum limit to import contacts is 1000");
    } else {
      if (files) {
        setShowExcelModal(true);
        const formData = new FormData();

        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trust_type);
        let mainData = [];
        for (let i = 0; i < selectedRow.length; i++) {
          let row = excelData.find((item) => item.key === selectedRow[i]);
          row.abn = row.abn.split(" ").join("");
          if (typeof row.invoice_date !== "object") {
            let invoicedateTemp = String(row.invoice_date)
              ?.split("T")[0]
              ?.split("-");
            if (invoicedateTemp[2]?.length === 4) {
              row.invoice_date = `${invoicedateTemp[0]}-${invoicedateTemp[1]}-${invoicedateTemp[2]}`;
            } else {
              row.invoice_date = `${invoicedateTemp[2]}-${invoicedateTemp[1]}-${invoicedateTemp[0]}`;
            }
          } else {
            row.invoice_date = moment(row?.invoice_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            );
          }
          if (typeof row.due_date !== "object") {
            let dueDateTemp = String(row.due_date)?.split("T")[0]?.split("-");
            // row.due_date = `${dueDateTemp[2]}-${dueDateTemp[1]}-${dueDateTemp[0]}`;
            if (dueDateTemp[2]?.length === 4) {
              row.due_date = `${dueDateTemp[0]}-${dueDateTemp[1]}-${dueDateTemp[2]}`;
            } else {
              row.due_date = `${dueDateTemp[2]}-${dueDateTemp[1]}-${dueDateTemp[0]}`;
            }
          } else {
            row.due_date = moment(row?.due_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            );
          }
          if (typeof row.scheduled_date !== "object") {
            let scheduledDateTemp = String(row.scheduled_date)
              ?.split("T")[0]
              ?.split("-");
            // row.scheduled_date = `${scheduledDateTemp[2]}-${scheduledDateTemp[1]}-${scheduledDateTemp[0]}`;
            if (scheduledDateTemp[2]?.length === 4) {
              row.scheduled_date = `${scheduledDateTemp[0]}-${scheduledDateTemp[1]}-${scheduledDateTemp[2]}`;
            } else {
              row.scheduled_date = `${scheduledDateTemp[2]}-${scheduledDateTemp[1]}-${scheduledDateTemp[0]}`;
            }
          } else {
            row.scheduled_date = moment(
              row?.scheduled_date,
              "YYYY-MM-DD"
            ).format("DD-MM-YYYY");
          }

          if (row.received_date) {
            if (typeof row.received_date !== "object") {
              let approvedDateTemp = String(row.received_date)
                ?.split("T")[0]
                ?.split("-");
              // row.received_date = `${approvedDateTemp[2]}-${approvedDateTemp[1]}-${approvedDateTemp[0]}`;
              if (approvedDateTemp[2]?.length === 4) {
                row.received_date = `${approvedDateTemp[0]}-${approvedDateTemp[1]}-${approvedDateTemp[2]}`;
              } else {
                row.received_date = `${approvedDateTemp[2]}-${approvedDateTemp[1]}-${approvedDateTemp[0]}`;
              }
            } else {
              row.received_date = moment(
                row?.received_date,
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY");
            }
          }

          if (row.approved_date) {
            if (typeof row.approved_date !== "object") {
              let approvedDateTemp = String(row.approved_date)
                ?.split("T")[0]
                ?.split("-");
              // row.approved_date = `${approvedDateTemp[2]}-${approvedDateTemp[1]}-${approvedDateTemp[0]}`;
              if (approvedDateTemp[2]?.length === 4) {
                row.approved_date = `${approvedDateTemp[0]}-${approvedDateTemp[1]}-${approvedDateTemp[2]}`;
              } else {
                row.approved_date = `${approvedDateTemp[2]}-${approvedDateTemp[1]}-${approvedDateTemp[0]}`;
              }
            } else {
              row.approved_date = moment(
                row?.approved_date,
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY");
            }
          }

          delete row.order;
          mainData.push(row);
          // formData.append(`paymentSchedule[${i}]`, JSON.stringify(row));
        }
        let invoiceArray = mainData?.filter(
          (item) => item.schedule_group === 0
        );
        for (let p = 0; p < invoiceArray.length; p++) {
          let itemData = mainData?.filter(
            (item) =>
              item.invoice_number == invoiceArray[p].invoice_number &&
              item.schedule_group === 1
          );
          let paymentItem = [];
          for (let k = 0; k < itemData?.length; k++) {
            let itemObj = {
              scheduled_date: itemData[k]?.scheduled_date,
              scheduled_amount: itemData[k]?.scheduled_amount,
              description: itemData[k]?.description,
              amount_withheld: itemData[k]?.amount_withheld,
              withheld_description: itemData[k]?.withheld_description,
            };
            paymentItem.push(itemObj);
          }
          let obj = {
            scheduled_date: invoiceArray[p]?.scheduled_date,
            scheduled_amount: invoiceArray[p]?.scheduled_amount,
            description: invoiceArray[p]?.description,
            amount_withheld: invoiceArray[p]?.amount_withheld,
            withheld_description: invoiceArray[p]?.withheld_description,
          };
          invoiceArray[p].items = [obj, ...paymentItem];
          let mainPayment = invoiceArray[p];
          delete mainPayment.scheduled_date;
          delete mainPayment.scheduled_amount;
          delete mainPayment.description;
          delete mainPayment.amount_withheld;
          delete mainPayment.withheld_description;
          delete mainPayment.schedule_group;

          for (const [key, value] of Object.entries(mainPayment)) {
            let total_scheduled_amount = 0;
            let total_amount_withheld = 0;
            if (key === "items") {
              for (
                let subItemIndex = 0;
                subItemIndex < mainPayment?.items?.length;
                subItemIndex++
              ) {
                for (const [subKey, subValue] of Object.entries(
                  mainPayment?.items[subItemIndex]
                )) {
                  formData.append(
                    `paymentSchedule[${p}][paymentScheduleItems][${subItemIndex}][${subKey}]`,
                    subValue
                  );
                }
                formData.append(
                  `paymentSchedule[${p}][paymentScheduleItems][${subItemIndex}][claimed_amount]`,
                  Number(mainPayment?.items[subItemIndex]?.scheduled_amount) +
                    Number(mainPayment?.items[subItemIndex]?.amount_withheld)
                );
                total_scheduled_amount =
                  total_scheduled_amount +
                  Number(mainPayment?.items[subItemIndex]?.scheduled_amount);
                total_amount_withheld =
                  total_amount_withheld +
                  Number(mainPayment?.items[subItemIndex]?.amount_withheld);
              }
              formData.append(
                `paymentSchedule[${p}][total_scheduled_amount]`,
                total_scheduled_amount
              );
              formData.append(
                `paymentSchedule[${p}][total_amount_withheld]`,
                total_amount_withheld
              );
            } else if (key === "name") {
              formData.append(`paymentSchedule[${p}][last_name]`, value);
            } else if (key === "company_name") {
              formData.append(`paymentSchedule[${p}][business_name]`, value);
            } else if (key === "claimed_amount") {
              formData.append(
                `paymentSchedule[${p}][total_claimed_amount]`,
                value
              );
            } else {
              formData.append(`paymentSchedule[${p}][${key}]`, value);
            }
          }
        }

        const config = { headers: { "Content-Type": "multipart/form-data" } };
        try {
          setIsImportLoading(true);
          const { data } = await fileUpload(
            `${API.IMPORT_PAYMENT_SCHEDULE}`,
            formData,
            config
          );
          setIsImportLoading(false);
          if (data) {
            toast.success(data?.message);
            setSelectedRow([]);
            handleExcelClose();
            fetchPaymentSchedules();
            handleImportClose();
            if (data?.data?.file) {
              const export_report_path = data?.data?.file;
              if (export_report_path) {
                var a = document.createElement("a");
                a.href = export_report_path;
                var file = export_report_path.split("/");
                a.setAttribute(
                  "download",
                  file[file.length - 1] || "error-tfn-update.xls"
                );
                a.setAttribute("target", "_blank");
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setIsLoadingDownload(false);
              }
            }
            setSelectedRow([]);
            handleExcelClose();
            fetchPaymentSchedules();
            handleImportClose();
          }
          return data?.data;
        } catch (error) {
          if (error?.response?.data?.errors) {
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
            if (error?.response?.data?.data?.failedData.length > 0) {
              let failData = [];
              for (
                let i = 0;
                i < error?.response?.data?.data?.failedData.length;
                i++
              ) {
                let scheduledItem =
                  error?.response?.data?.data?.failedData[i]?.data;
                if (scheduledItem?.phone) {
                  scheduledItem.phone = `+${Number(scheduledItem?.phone)
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`;
                } else {
                  scheduledItem.phone = "";
                }
                if (scheduledItem?.abn) {
                  scheduledItem.abn = `${Number(scheduledItem?.abn)
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`;
                } else {
                  scheduledItem.abn = "";
                }
                failData.push(scheduledItem);
              }
              setSelectedRow([]);
              setExcelData(failData);
            }
            setSelectedRow([]);
            handleExcelClose();
            fetchPaymentSchedules();
            handleImportClose();
            setIsImportLoading(false);
          } else {
            setSelectedRow([]);
            handleExcelClose();
            fetchPaymentSchedules();
            handleImportClose();
            toast.error(error?.message);
            setIsImportLoading(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    let start;
    let end;
    const container = document.querySelector(
      ".excel-table-list .react-bootstrap-table tbody"
    );
    const drake = dragula([container], {
      moves: (el, container, handle) => {
        // if(el?.childNodes[1]?.childNodes[0] !== undefined){
        start = getIndexInParent(el);
        return handle.classList.contains("handle");
        // }
      },
    });

    drake.on("drop", async (el) => {
      end = getIndexInParent(el);
      await handleReorder(start, end, el);
    });
    //eslint-disable-next-line
  }, [showExcelModal]);

  // *Excel Adjustment - Start
  // const adjustmentTypeList = [
  //   { label: "Select Type", value: "Select Type" },
  //   { label: "Deduct", value: "Deduct", excelData: "deduct", base_type: 0 },
  //   { label: "Add", value: "Add", excelData: "add", base_type: 1 },
  // ];

  // eslint-disable-next-line no-unused-vars
  const handleExcelAdjustmentDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(
        `${API.DOWNLOAD_ADJUSTMENT_TEMPLATE}?builder_project_id=${projectId}&trust_type=${trust_type}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "payment-schedules-adjustment.xls"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleImportBulkModal = async () => {
    setShowImportBulk(true);
  };

  const handleImportBulkClose = () => {
    setShowImportBulk(false);
    setIsImportBulkLoading(false);
    setFilesBulk();
  };

  const handleExcelBulkModal = () => {
    setShowExcelBulkModal(true);
  };

  const handleExcelBulkClose = () => {
    setSelectedRowBulk([]);
    setShowExcelBulkModal(false);
    setShowImportBulk(false);
    setIsImportBulkLoading(false);
    setFilesBulk();
  };

  const ExcelBulkColumns = [
    {
      dataField: "name",
      text: "Contractor Name",
      editCellClasses: "edit-cell-excel-class",
      editable: () => {
        return false;
      },
    },
    // {
    //   dataField: "email",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Email
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   validator: (newValue) => {
    //     if (newValue === "" || newValue === undefined || newValue === null) {
    //       return {
    //         valid: false,
    //         message: "Email is Required",
    //       };
    //     }
    //     if (newValue) {
    //       if (
    //         !newValue
    //           .toLowerCase()
    //           .match(
    //             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //           )
    //       ) {
    //         return {
    //           valid: false,
    //           message: "Please enter a valid email address",
    //         };
    //       }
    //     }
    //     return true;
    //   },
    //   editable: () => {
    //     return false;
    //   },
    //   classes: (cell) => {
    //     if (cell) {
    //       if (
    //         !String(cell)
    //           .toLowerCase()
    //           .match(
    //             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //           )
    //       ) {
    //         return "error-cell";
    //       }
    //     }
    //   },
    // },
    {
      dataField: "abn",
      text: (
        <div>
          <span className="required">*</span>ABN
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      editable: () => {
        return false;
      },
    },
    {
      dataField: "invoice_number",
      text: (
        <div>
          <span className="required">*</span>Claim/Invoice Number
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Invoice Number is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "invoice_number";
        }
      },
      editable: () => {
        return false;
      },
    },
    // {
    //   dataField: "adjustment_type",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Adjustment Type
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   editor: {
    //     type: Type.SELECT,
    //     options: adjustmentTypeList,
    //   },
    //   classes: (cell) => {
    //     if (String(cell)?.length === 0) {
    //       return "error-highlight-cell";
    //     } else if (String(cell) === "Select Type") {
    //       return "error-cell";
    //     }
    //   },
    // },
    // {
    //   dataField: "adjustment_amount",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Adjustment Amount
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   validator: (newValue, row) => {
    //     if (newValue === "" || newValue === undefined || newValue === null) {
    //       return {
    //         valid: false,
    //         message: "Adjustment Amount is Required",
    //       };
    //     }
    //     return true;
    //   },
    // },
    // {
    //   dataField: "adjustment_reason",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Adjustment Reason
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   validator: (newValue, row) => {
    //     if (newValue === "" || newValue === undefined || newValue === null) {
    //       return {
    //         valid: false,
    //         message: "Adjustment Reason is Required",
    //       };
    //     }

    //     return true;
    //   },
    // },
    // {
    //   dataField: "old_approved_amount",
    //   text: (
    //     <div>
    //       <span className="required">*</span>Old Approved Amount
    //     </div>
    //   ),
    //   editCellClasses: "edit-cell-excel-class",
    //   validator: (newValue, row) => {
    //     if (newValue === "" || newValue === undefined || newValue === null) {
    //       return {
    //         valid: false,
    //         message: "Old Approved Amount is Required",
    //       };
    //     }
    //     return true;
    //   },
    //   editable: () => {
    //     return false;
    //   },
    // },
    {
      dataField: "approved_amount",
      text: (
        <div>
          <span className="required">*</span>New Approved Amount =
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue, row) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Approved Amount is Required",
          };
        }
        return true;
      },
    },
    {
      dataField: "scheduled_amount",
      text: "Payment Amount +",
      editCellClasses: "edit-cell-excel-class",
      classes: (cell, row) => {
        if (row?.approved_amount) {
          // let new_approvedAmount =
          //   row?.adjustment_type === "Add"
          //     ? Number(row?.old_approved_amount) +
          //       Number(row?.adjustment_amount ? row?.adjustment_amount : 0)
          //     : row?.adjustment_type === "Deduct"
          //     ? Number(row?.old_approved_amount) +
          //       -Number(row?.adjustment_amount ? row?.adjustment_amount : 0)
          //     : 0;

          if (
            Number(row?.approved_amount) !=
            Number(row?.scheduled_amount ? row?.scheduled_amount : 0) +
              Number(row?.amount_withheld ? row?.amount_withheld : 0)
          ) {
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "amount_withheld",
      text: "Amount Withheld in RTA",
      editCellClasses: "edit-cell-excel-class",
      classes: (cell, row) => {
        if (row?.approved_amount) {
          // let new_approvedAmount =
          //   row?.adjustment_type === "Add"
          //     ? Number(row?.old_approved_amount) +
          //       Number(row?.adjustment_amount ? row?.adjustment_amount : 0)
          //     : row?.adjustment_type === "Deduct"
          //     ? Number(row?.old_approved_amount) +
          //       -Number(row?.adjustment_amount ? row?.adjustment_amount : 0)
          //     : 0;

          if (
            Number(row?.approved_amount) !=
            Number(row?.scheduled_amount ? row?.scheduled_amount : 0) +
              Number(row?.amount_withheld ? row?.amount_withheld : 0)
          )
            return "error-cell";
        }
      },
    },
    {
      dataField: "description",
      text: "Description",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue, row) => {
        if (row?.scheduled_amount) {
          if (newValue === "" || newValue === undefined || newValue === null) {
            return {
              valid: false,
              message: "Description is Required",
            };
          }
        }
        return true;
      },
    },
    {
      dataField: "withheld_description",
      text: "Reason for Withholding",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue, row) => {
        if (row?.amount_withheld) {
          if (newValue === "" || newValue === undefined || newValue === null) {
            return {
              valid: false,
              message: "Reason is Required",
            };
          }
        }
        return true;
      },
    },
  ];

  const [selectedRowBulk, setSelectedRowBulk] = useState([]);

  let singleSelectedRowsBulk = [];
  let allSelectedRowsBulk = [];

  const selectRowBulk = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ["not-select"],
    selected: selectedRowBulk,
    nonSelectableClasses: "not-selected-class",
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRowsBulk = [...selectedRowBulk];
      if (isSelect) {
        singleSelectedRowsBulk = [...selectedRowBulk, row?.key];
      } else {
        singleSelectedRowsBulk.splice(selectedRowBulk.indexOf(row?.key), 1);
      }
      setSelectedRowBulk(singleSelectedRowsBulk);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRowBulk.includes(rows[i].key)) {
          } else {
            allSelectedRowsBulk.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRowBulk.includes(rows[i].key)) {
            selectedRowBulk.splice(selectedRowBulk.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRowBulk([...selectedRowBulk, ...allSelectedRowsBulk]);
    },
  };

  const refBulk = useRef();

  const ExcelBulkReader = async () => {
    let f = filesBulk;
    const reader = new FileReader();
    let data = null;
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      let selectItem = [];
      const businessExcelGenerator = (quantity) => {
        const items = [];

        if (
          String(data[1][0]).replace("*", "") !== "Reference/ID" ||
          String(data[1][1]).replace("*", "") !== "Contractor Name" ||
          // String(data[1][2]).replace("*", "") !== "Email" ||
          String(data[1][2]).replace("*", "") !== "ABN" ||
          String(data[1][3]).replace("*", "") !== "Claim/Invoice Number" ||
          // String(data[1][3]).replace("*", "") !== "Adjustment Type" ||
          // String(data[1][4]).replace("*", "") !== "Adjustment Amount" ||
          // String(data[1][5]).replace("*", "") !== "Adjustment Reason" ||
          // String(data[1][3]).replace("*", "") !== "Old Approved Amount" ||
          String(data[1][4]).replace("*", "") !== "New Approved Amount" ||
          String(data[1][5]).replace("*", "") !== "Payment Amount" ||
          String(data[1][6]).replace("*", "") !== "Amount Withheld in RTA" ||
          String(data[1][7]).replace("*", "") !== "Description" ||
          String(data[1][8]).replace("*", "") !== "Reason for Withholding"
        ) {
          setShowExcelBulkModal(false);
          setFilesBulk();
          setIsImportBulkLoading(false);
          toast.error(
            "Sorry, File is not in the given format, please download the latest format again"
          );
        } else {
          for (let i = 2; i < quantity; i++) {
            if (data[i].length > 0) {
              // let invoice_date = dateFormatReader(data[i][2]);
              // let due_date = dateFormatReader(data[i][3]);
              selectItem.push(i);
              // let adjustmentType = data[i][3]
              //   ? adjustmentTypeList?.find(
              //       (item) =>
              //         item.excelData === String(data[i][3]).toLowerCase()
              //     )
              //     ? adjustmentTypeList?.find(
              //         (item) =>
              //           item.excelData === String(data[i][3]).toLowerCase()
              //       ).label
              //     : "Select Type"
              //   : "";

              // let oldApprovedAmount =
              //   adjustmentType === "Add"
              //     ? Number(data[i][6] ? data[i][6] : 0) -
              //       Number(data[i][4] ? data[i][4] : 0)
              //     : adjustmentType === "Deduct"
              //     ? Number(data[i][6] ? data[i][6] : 0) +
              //       Number(data[i][4] ? data[i][4] : 0)
              //     : data[i][6]
              //     ? Number(data[i][6] ? data[i][6] : 0)
              //     : "";
              items.push({
                key: i,
                id: data[i][0] ? data[i][0] : "",
                name: data[i][1] ? data[i][1] : "",
                // email: data[i][2] ? data[i][2] : "",
                abn: data[i][2]
                  ? `${Number(String(data[i][2])?.split(" ")?.join(""))
                      .toLocaleString("en-US")
                      .replaceAll(",", " ")}`
                  : "",
                invoice_number: data[i][3] ? data[i][3] : "",
                // adjustment_type: adjustmentType,
                // adjustment_amount: data[i][4] ? data[i][4] : "",
                // adjustment_reason: data[i][5] ? data[i][5] : "",
                // old_approved_amount: oldApprovedAmount,
                approved_amount: data[i][4] ? data[i][4] : "",
                scheduled_amount: data[i][5] ? data[i][5] : "",
                amount_withheld: data[i][6] ? data[i][6] : "",
                description: data[i][7] ? data[i][7] : "",
                withheld_description: data[i][8] ? data[i][8] : "",
              });
            }
          }
        }
        return items;
      };
      setSelectedRowBulk(selectItem);
      setExcelBulkData(businessExcelGenerator(data?.length));
    };
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    showExcelBulkModal && ExcelBulkReader();
    // eslint-disable-next-line
  }, [showExcelBulkModal]);

  const handleExcelTableChangeBulk = async (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    const getWithPromiseAll = async () => {
      let temp = await Promise.all(
        data.map(async (row) => {
          if (row?.key === rowId) {
            const newRow = { ...row };
            // if (dataField === "adjustment_amount") {
            //   if (row?.adjustment_type === "Add") {
            //     newRow["approved_amount"] =
            //       Number(newRow["old_approved_amount"]) + Number(newValue);
            //   } else if (row?.adjustment_type === "Deduct") {
            //     newRow["approved_amount"] =
            //       Number(newRow["old_approved_amount"]) + -Number(newValue);
            //   }
            //   newRow[dataField] = newValue;
            //   return newRow;
            // } else if (dataField === "adjustment_type") {
            //   if (newValue === "Add") {
            //     newRow["approved_amount"] =
            //       Number(newRow["old_approved_amount"]) +
            //       Number(newRow["adjustment_amount"]);
            //   } else if (newValue === "Deduct") {
            //     newRow["approved_amount"] =
            //       Number(newRow["old_approved_amount"]) +
            //       -Number(newRow["adjustment_amount"]);
            //   }
            //   newRow[dataField] = newValue;
            //   return newRow;
            // } else {
            newRow[dataField] = newValue;
            return newRow;
            // }
          }
          return row;
        })
      );
      return temp;
    };
    const result = await getWithPromiseAll();
    setExcelBulkData(result);
  };

  const handleExcelUploadBulk = async () => {
    if (selectedRowBulk.length > 1000) {
      toast.warn("Maximum limit to import payment Claim is 1000");
    } else {
      if (filesBulk) {
        setShowExcelBulkModal(true);
        const formData = new FormData();

        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trust_type);

        for (let i = 0; i < selectedRowBulk.length; i++) {
          let row = excelBulkData.find(
            (item) => item.key === selectedRowBulk[i]
          );
          row.abn = row.abn.split(" ").join("");
          // formData.append(`contractors[${i}]`, JSON.stringify(row));
          Object.keys(row).forEach((key) => {
            if (key === "adjustment_type") {
              formData.append(
                `adjustments[${i}][${key}]`,
                row[key] === "Add" ? "1" : "0"
              );
            } else if (key === "adjustment_reason") {
              formData.append(
                `adjustments[${i}][adjustment_description]`,
                row[key]
              );
            } else {
              formData.append(`adjustments[${i}][${key}]`, row[key]);
            }
          });
        }

        const config = { headers: { "Content-Type": "multipart/form-data" } };
        try {
          setIsImportBulkLoading(true);
          const { data } = await fileUpload(
            `${API.IMPORT_ADJUSTMENT}`,
            formData,
            config
          );
          setIsImportBulkLoading(false);
          if (data) {
            toast.success(data?.message);
            setSelectedRowBulk([]);
            handleExcelBulkClose();
            fetchPaymentSchedules();
            handleImportBulkClose();
            if (data?.data?.file) {
              const export_report_path = data?.data?.file;
              if (export_report_path) {
                var a = document.createElement("a");
                a.href = export_report_path;
                var file = export_report_path.split("/");
                a.setAttribute(
                  "download",
                  file[file.length - 1] || "error-subcontractor.xls"
                );
                a.setAttribute("target", "_blank");
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setIsLoadingDownload(false);
              }
            }
          }
          return data?.data;
        } catch (error) {
          if (error?.response?.data?.errors) {
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
            setSelectedRowBulk([]);
            setExcelBulkData([]);
            fetchPaymentSchedules();
            setIsImportBulkLoading(false);
          } else {
            toast.error(error?.message);
            setIsImportBulkLoading(false);
          }
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors?.data?.file) {
              const export_report_path =
                error?.response?.data?.errors?.data?.file;
              if (export_report_path) {
                // eslint-disable-next-line no-redeclare
                var a = document.createElement("a");
                a.href = export_report_path;
                // eslint-disable-next-line no-redeclare
                var file = export_report_path.split("/");
                a.setAttribute(
                  "download",
                  file[file.length - 1] || "error-sub-contractor.xls"
                );
                a.setAttribute("target", "_blank");
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setIsLoadingDownload(false);
              }
            }
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
            fetchPaymentSchedules();
            setIsImportBulkLoading(false);
          } else {
            toast.error(error?.message);
            setIsImportBulkLoading(false);
          }
        }
      }
    }
  };

  let singleMainSelectedRows = [];
  let allMainSelectedRows = [];

  const selectMainRow = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: mainNotSelectedRow,
    selected: mainSelectedRow,
    nonSelectableClasses: "not-selected-class",
    onSelect: (row, isSelect, rowIndex, e) => {
      singleMainSelectedRows = [...mainSelectedRow];
      if (isSelect) {
        singleMainSelectedRows = [...mainSelectedRow, row?.key];
      } else {
        singleMainSelectedRows.splice(mainSelectedRow.indexOf(row?.key), 1);
      }
      setMainSelectedRow(singleMainSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (mainSelectedRow.includes(rows[i].key)) {
          } else {
            allMainSelectedRows.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (mainSelectedRow.includes(rows[i].key)) {
            mainSelectedRow.splice(mainSelectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setMainSelectedRow([...mainSelectedRow, ...allMainSelectedRows]);
    },
  };

  // *Excel Adjustment - End

  useEffect(() => {
    navigate(
      `?search=${searchParam ? searchParam : ""}&limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}&from=${
        valueRange[0] ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
      }&to=${valueRange[1] ? moment(valueRange[1]).format("DD-MM-YYYY") : ""}`
    );
  }, [searchParam, limit, page, navigate, sortField, sortOrder, valueRange]);

  let tomorrow = new Date();
  tomorrow.setMonth(tomorrow.getMonth() + 1);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Payment Schedules
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section payment-scheduled-search">
                  <Form>
                    <FormControl
                      onChange={(e) => handleSearchChange(e)}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Payment Scheduled"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                  <MonthRangePicker
                    value={valueRange}
                    onChange={(date) => {
                      console.log(date);
                      
                      setValueRange(date);
                    }}
                    className="datefilter"
                    disabled={[[tomorrow, false]]}
                  />
                </div>
                <div className="table-btn" style={{ flexFlow: "nowrap" }}>
                  <div className="form-group tetra-width sort-by-approved-option">
                    <label>Sort By:</label>
                    <ReactSelect
                      name="project_status"
                      value={
                        sortByStatus
                          ? SortStatus?.find(
                              (item) => item?.value === Number(sortByStatus)
                            )
                          : ""
                      }
                      onChange={(e) => {
                        handleApprovedSort(e);
                      }}
                      options={SortStatus}
                      placeholder="Select Sort By "
                      classNamePrefix="selectbox"
                      autoComplete="off"
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                      isSearchable={false}
                    />
                  </div>
                  {!ProjectReadOnly && (
                    <div className="border-btn">
                      <DropdownButton className="info-icon" title="">
                        <Dropdown.Item>
                          <p>Name - Required</p>
                          <p>Invoice Number - Required</p>
                          <p>Invoice Date - Required</p>
                          <p>Description - Required</p>
                          <p>Claimed Amount - Required</p>
                          <p>Due Date - Required</p>
                          <p>Approval/Transaction Date - Required</p>
                          <p>Approved Amount - Required</p>
                          <p>Difference - Required</p>
                          <p>Reason - Required</p>
                          <p>Scheduled Amount - Required</p>
                        </Dropdown.Item>
                      </DropdownButton>
                      <Button
                        variant="link"
                        disabled={isLoadingDownload}
                        onClick={() => handleDownload()}
                        className="download-template"
                      >
                        Download
                      </Button>
                      <Button variant="primary" onClick={handleImportModal}>
                        Upload
                      </Button>
                    </div>
                  )}
                  {!ProjectReadOnly && (
                    <Button
                      variant="primary"
                      onClick={() => toggleApprovalModal()}
                      disabled={mainSelectedRow?.length <= 0}
                    >
                      Approve
                    </Button>
                  )}
                  {/* {!ProjectReadOnly && (
                    <div className="border-btn">
                      <DropdownButton className="info-icon" title="">
                        <Dropdown.Item>
                          <p>Name - Required</p>
                          <p>Invoice Number - Required</p>
                          <p>Invoice Date - Required</p>
                          <p>Description - Required</p>
                          <p>Claimed Amount - Required</p>
                          <p>Due Date - Required</p>
                          <p>Approval Date - Required</p>
                          <p>Approved Amount - Required</p>
                          <p>Difference - Required</p>
                          <p>Reason - Required</p>
                          <p>Scheduled Amount - Required</p>
                        </Dropdown.Item>
                      </DropdownButton>
                      <Button
                        variant="link"
                        disabled={isLoadingDownload}
                        onClick={() => handleExcelAdjustmentDownload()}
                        className="download-template"
                      >
                        Download
                      </Button>
                      <Button variant="primary" onClick={handleImportBulkModal}>
                        Bulk Adjustment
                      </Button>
                    </div>
                  )} */}
                  <Button variant="primary" onClick={toggleCalenderView}>
                    Calendar View
                  </Button>
                  {/* <Button
                    variant="primary"
                    onClick={() =>
                      navigate(`/projects/${projectId}/project/aba`, {
                        replace: true,
                      })
                    }
                  >
                    Payment Instruction
                  </Button> */}
                  {!ProjectReadOnly && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(
                          `/projects/${projectId}/${params?.trust_type}/payment-schedules/add`
                        );
                      }}
                    >
                      Add New
                    </Button>
                  )}
                </div>
              </div>
              <div className="custom-table payment-scheduled-table">
                {projectLoading && <Spin />}

                <BootstrapTable
                  keyField="key"
                  remote
                  selectRow={selectMainRow}
                  data={paymentSchedules || []}
                  columns={columns}
                  onTableChange={handleTableChange}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showImport && (
        <Modal
          size="lg"
          show={showImport}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Upload Payment Schedules
          </Modal.Header>
          <Modal.Body>
            <div>
              <UploadFile
                projectId={projectId}
                isLoading={isImportLoading}
                setFiles={setFiles}
                files={files}
                handleUpload={handleExcelModal}
                handleCancel={handleImportClose}
                acceptFileFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showImportBulk && (
        <Modal
          size="lg"
          show={showImportBulk}
          onHide={handleImportBulkClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Upload Adjustment
          </Modal.Header>
          <Modal.Body>
            <div>
              <UploadFile
                projectId={projectId}
                isLoading={isImportBulkLoading}
                setFiles={setFilesBulk}
                files={filesBulk}
                handleUpload={handleExcelBulkModal}
                handleCancel={handleImportBulkClose}
                acceptFileFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showCalender && (
        <Modal
          show={showCalender}
          onHide={toggleCalenderView}
          dialogClassName="small-review payment-schedule-calender-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Calender View
          </Modal.Header>
          <Modal.Body>
            <CalenderView projectData={ProjectData} />
          </Modal.Body>
        </Modal>
      )}
      {showExcelModal && (
        <ExcelEditorPaymentSchedule
          handleExcelModal={handleExcelModal}
          selectRow={selectRow}
          ExcelContactColumns={ExcelContactColumns}
          handleExcelTableChange={handleExcelTableChange}
          excelData={excelData}
          handleExcelClose={handleExcelClose}
          ref={ref}
          isImportLoading={isImportLoading}
          showExcelModal={showExcelModal}
          selectedRow={selectedRow}
          handleUpload={handleExcelUpload}
        />
      )}

      {approvalModal && (
        <PaymentScheduledApprovalModal
          showModal={approvalModal}
          handleModalClose={handleModalClose}
          handleSubmit={handleApprove}
          loading={projectLoading}
        />
      )}

      {showExcelBulkModal && (
        <ExcelEditorAdjustment
          handleExcelModal={handleExcelBulkModal}
          selectRow={selectRowBulk}
          ExcelBulkColumns={ExcelBulkColumns}
          handleExcelTableChange={handleExcelTableChangeBulk}
          excelData={excelBulkData}
          handleExcelClose={handleExcelBulkClose}
          ref={refBulk}
          isImportLoading={isImportBulkLoading}
          showExcelModal={showExcelBulkModal}
          selectedRow={selectedRowBulk}
          handleUpload={handleExcelUploadBulk}
        />
      )}
    </>
  );
};

export default PaymentSchedule;
