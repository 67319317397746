import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BackButton from "../../../components/Form/BackButton";
import { imageDefaultPrefixPath } from "../../../config";
import { getProjectData } from "../components/ProjectHelper";
import ProjectSetting from "./project-settings/ProjectSetting";
import Integrations from "./integrations/Integrations";
import Principal from "./Principal/Principal";
import Documents from "./documents/Documents";
import ContractorType from "./contactor-type/ContractorType";
import Workinprogrees from "../../../components/Workinprogrees";
import Withdrawal from "./withdrawal/Withdrawal";
import Trustee from "./trustee/Trustee";
import SubscriptionAfterLogin from "../../user-setting/components/SubscriptionAfterLogin";
import { useProjectBar } from "../../common/useProjectBar";

const tabsKey = {
  "project-settings": 1,
  integrations: 2,
  principal: 3,
  documents: 4,
  "contractor-types": 5,
  withdrawal: 6,
  trustee: 7,
  subscriptions: 8,
};

const Settings = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab");
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 8
      ? "subscriptions"
      : parseInt(defaultActiveKey) === 7
      ? "trustee"
      : parseInt(defaultActiveKey) === 6
      ? "withdrawal"
      : parseInt(defaultActiveKey) === 5
      ? "contractor-types"
      : parseInt(defaultActiveKey) === 4
      ? "documents"
      : parseInt(defaultActiveKey) === 2
      ? "integrations"
      : parseInt(defaultActiveKey) === 1
      ? "project-settings"
      : "principal"
  );


  const { ProjectName, ProjectLogo, ProjectReadOnly } = useProjectBar();

  useEffect(() => {
    if (selectedTab === "integrations") {
      setActiveTab("integrations");
    }
    if (selectedTab === "subscriptions") {
      setActiveTab("subscriptions");
    }
    if (selectedTab === "project-settings") {
      setActiveTab("project-settings");
    }
    if (selectedTab === "trustee") {
      setActiveTab("trustee");
    }
    if (selectedTab === "withdrawal") {
      setActiveTab("withdrawal");
    }
    if (selectedTab === "contractor-types") {
      setActiveTab("contractor-types");
    }
    if (selectedTab === "documents") {
      setActiveTab("documents");
    }
    if (selectedTab === "principal") {
      setActiveTab("principal");
    }
  }, [selectedTab]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Settings
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>

            <div className="content-details">
              {activeTab === "documents" ? <Workinprogrees /> : ""}
              <div className="table-top-btn">
                <ul
                  defaultactivekey={defaultActiveKey}
                  className="nav nav-tabs"
                  onClick={(event) => {
                    // setActiveKey(
                    //   event?.target["__reactFiber$ytws3iau74l"]?.key
                    // );
                    setActiveTab(
                      event?.target?.innerText.replace(/ /g, "-").toLowerCase()
                    );
                  }}
                >
                  <li
                    className={activeTab === "project-settings" ? "active" : ""}
                  >
                    <Link key={1}>Project Settings</Link>
                  </li>
                  <li className={activeTab === "principal" ? "active" : ""}>
                    <Link key={3}>Principal</Link>
                  </li>
                  <li className={activeTab === "trustee" ? "active" : ""}>
                    <Link key={7}>Trustee</Link>
                  </li>
                  <li className={activeTab === "withdrawal" ? "active" : ""}>
                    <Link key={6}>Withdrawal</Link>
                  </li>
                  <li className={activeTab === "integrations" ? "active" : ""}>
                    <Link key={2}>Integrations</Link>
                  </li>
                  {/* <li className={activeTab === "documents" ? "active" : ""}>
                    <Link key={4}>Documents</Link>
                  </li> */}
                  <li
                    className={activeTab === "contractor-types" ? "active" : ""}
                  >
                    <Link key={5}>Contractor Types</Link>
                  </li>
                  <li className={activeTab === "subscriptions" ? "active" : ""}>
                    <Link key={8}>Subscriptions</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "integrations" && (
                  <Integrations
                    getProjectData={getProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "project-settings" && (
                  <ProjectSetting
                    getProjectData={getProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "principal" && (
                  <Principal
                    getProjectData={getProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "withdrawal" && (
                  <Withdrawal
                    getProjectData={getProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "trustee" && (
                  <Trustee
                    getProjectData={getProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "documents" && (
                  <Documents
                    getProjectData={getProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "contractor-types" && <ContractorType ProjectReadOnly={ProjectReadOnly}/>}
                {activeTab === "subscriptions" && (
                  <SubscriptionAfterLogin project={true} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
